<template>
    <q-card style="width: 900px; max-width: 80vw;">
        <q-card-section>
            <q-toolbar>
                <q-toolbar-title>
                    Mercancia
                </q-toolbar-title>
                <q-btn v-if="objetoStore._id == ''" dense round color="green" icon="mdi-content-save" class="q-mr-sm"
                    @click="guardar()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>
                <q-btn v-else round color="orange" dense icon="mdi-content-save" class="q-mr-sm" @click="actualizar()">
                    <q-tooltip>
                        Editar
                    </q-tooltip>
                </q-btn>
                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
            <q-card-section class="scroll">
                <div class="row q-col-gutter-sm">
                    <!-- <div class="col-12">
                        <q-input dense filled label="Descripción" v-model="objetoStore.descripcion" />
                    </div> -->
                    <div class="col-12 ">
                        <q-input dense filled label="No. Identificación" v-model="objetoStore.noIdentificacion" />
                    </div>
                    <div class="col-12">
                        <q-select v-model="objetoStore.fraccionArancelaria" emit-value map-options
                            @filter="filtroFraccionArancelaria" :options="itemsFiltroFraccionArancelaria"
                            option-label="fraccionArancelaria" use-input input-debounce="0" dense filled
                            label="Fracción arancelaria" virtual-scroll-slice-size="1" />
                    </div>
                    <div class="col-12">
                        <q-select v-model="objetoStore.unidadAduana" emit-value map-options @filter="filtroUnidadAduana"
                            :options="itemsFiltroUnidadAduana" option-label="unidadAduana" use-input input-debounce="0"
                            dense filled label="Unidad Aduana" virtual-scroll-slice-size="1" />
                    </div>
                    <!-- <div class="col-md-3 col-12">
                        <q-input dense filled label="Marca" v-model="objetoStore.descripcionesEspecificas.marca" />
                    </div>
                    <div class="col-md-3 col-12">
                        <q-input dense filled label="Modelo" v-model="objetoStore.descripcionesEspecificas.modelo" />
                    </div>
                    <div class="col-md-3 col-12">
                        <q-input dense filled label="SubModelo" v-model="objetoStore.descripcionesEspecificas.subModelo" />
                    </div>
                    <div class="col-md-3 col-12">
                        <q-input dense filled label="Número de serie"
                            v-model="objetoStore.descripcionesEspecificas.numeroSerie" />
                    </div> -->
                </div>
            </q-card-section>
        </q-card-section>
    </q-card>
</template>

<script>
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'

export default {

    data() {
        return {
            itemsFiltroFraccionArancelaria: null,
            itemsFiltroUnidadAduana: null,
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        objetoStore() {
            return this.$store.state.mercanciaCEStore;
        },
        itemsFraccionArancelaria() {
            return this.$store.state.listaCatalogoFraccionArancelaria;
        },
        itemsUnidadAduana() {
            return this.$store.state.listaCatalogoUnidadAduana;
        }
    },

    created() {
    },
    methods: {
        cierraVentana() {
            this.$emit('cierraVentana')
        },

        async guardar() {

            console.log(this.objetoStore)

            // if (this.objetoStore.descripcion == '') {
            //     this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la descripción de la mercancia' })
            //     return;
            // }
            if (this.objetoStore.noIdentificacion == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el número de identificación de la mercancia' })
                return;
            }


            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando mercancia. Espere...', messageColor: 'white' })

            try {
                let response = await axios.post('CatalogosComprobantes/PostMercanciasCEAsync/erp_' + this.token.rfc, this.objetoStore)
                console.log(response)

                this.objetoStore._id = response.data
                this.$store.state.listaMercanciasCEStore.push(this.objetoStore)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `La mercancia ha sido guardada exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },

        async actualizar() {
            console.log(this.objetoStore)

            // if (this.objetoStore.descripcion == '') {
            //     this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la descripción de la mercancia' })
            //     return;
            // }
            if (this.objetoStore.noIdentificacion == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el número de identificación de la mercancia' })
                return;
            }

            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Actualizando mercancia. Espere...', messageColor: 'white' })

            try {
                let response = await axios.put('CatalogosComprobantes/PutMercanciasCEAsync/erp_' + this.token.rfc, this.objetoStore)
                console.log(response)

                let indice = this.$store.state.listaMercanciasCEStore.findIndex(x => x._id === this.objetoStore._id);
                Object.assign(this.$store.state.listaMercanciasCEStore[indice], this.objetoStore)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `La mercancia ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        filtroFraccionArancelaria(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroFraccionArancelaria = this.itemsFraccionArancelaria.filter(v => v.fraccionArancelaria.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroFraccionArancelaria.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        filtroUnidadAduana(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroUnidadAduana = this.itemsUnidadAduana.filter(v => v.unidadAduana.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroUnidadAduana.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
    }
}
</script>

<style></style>