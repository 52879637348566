<template>
    <q-card flat>
        <q-card-section>
            <q-toolbar>
                <!-- <q-btn label="AAA" @click="AsignaValores">
                </q-btn> -->
                <q-toolbar-title>
                    Domicilio
                </q-toolbar-title>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
            <q-card-section style="max-height: 700px; min-height: 400px;" class="scroll">
                <div class="row q-col-gutter-sm">
                    <!-- PAIS -->
                    <div class="col-12">
                        <q-select v-model="selectPais" emit-value map-options @filter="filtroPais"
                            :options="itemsFiltroPais" option-label="pais" use-input input-debounce="0" dense filled
                            label="País" virtual-scroll-slice-size="1" />
                    </div>
                    <!-- CODIGO POSTAL -->
                    <div class="col-12">
                        <q-input dense filled label="Código Postal" v-model="selectCodigoPostal" maxlength="5"
                            @keydown.enter="validarCodigoPostal()">
                            <template v-slot:after>
                                <q-btn flat round color="primary" icon="mdi-check" @click="validarCodigoPostal()">
                                    <q-tooltip>
                                        Validar
                                    </q-tooltip>
                                </q-btn>
                            </template>
                        </q-input>
                    </div>
                    <!-- ESTADO CON CATALOGO -->
                    <template v-if="banderaEstado">
                        <div class="col-12">
                            <q-select v-model="selectEstado" emit-value map-options :options="itemsFiltroEstado"
                                @filter="filtroEstado" option-label="estado" use-input input-debounce="0" dense filled
                                label="Estado" virtual-scroll-slice-size="1" />
                        </div>
                    </template>
                    <!-- ESTADO SIN CATALOGO -->
                    <template v-if="banderaEstadoX">
                        <div class="col-12">
                            <q-input dense filled label="Estado" v-model="selectEstadoX" />
                        </div>
                    </template>
                    <!-- MUNICIPIO, LOCALIDAD Y COLONIA SIN CATALOGO -->
                    <template v-if="banderaNoMexico">
                        <!-- MUNICIPIO -->
                        <div class="col-12">
                            <q-input dense filled label="Municipio" v-model="selectMunicipioX" />
                        </div>
                        <!-- LOCALIDAD -->
                        <div class="col-12">
                            <q-input dense filled label="Localidad" v-model="selectLocalidadX" />
                        </div>
                        <!-- COLONIA -->
                        <div class="col-12">
                            <q-input dense filled label="Colonia" v-model="selectColoniaX" />
                        </div>
                    </template>
                    <!-- MUNICIPIO, LOCALIDAD Y COLONIA CON CATALOGO -->
                    <template v-if="banderaMexico">
                        <!-- MUNICIPIO -->
                        <div class="col-12">
                            <q-select v-model="selectMunicipio" :options="itemsMunicipio" option-label="municipio" dense
                                filled label="Municipio" />
                        </div>
                        <!-- LOCALIDAD -->
                        <div class="col-12">
                            <q-select v-model="selectLocalidad" :options="itemsLocalidad" option-label="localidad" dense
                                filled label="Localidad" />
                        </div>
                        <!-- COLONIA -->
                        <div class="col-12">
                            <q-select v-model="selectColonia" :options="itemsColonia" option-label="colonia" dense
                                filled label="Colonia" />
                        </div>
                    </template>
                    <!-- DATOS GENERALES -->
                    <template v-if="banderaValida">
                        <!-- CALLE -->
                        <div class="col-12">
                            <q-input dense filled label="Calle" v-model="selectCalle" />
                        </div>
                        <!-- NUMERO EXTERIOR -->
                        <div class="col-12">
                            <q-input dense filled label="Número exterior" v-model="selectnumeroExterior" />
                        </div>
                        <!-- NUMERO INTERIOR -->
                        <div class="col-12">
                            <q-input dense filled label="Número interior" v-model="selectNumeroInterior" />
                        </div>
                        <!-- REFERENCIA -->
                        <div class="col-12">
                            <q-input dense filled label="Referencia" v-model="selectReferencia" />
                        </div>
                    </template>
                </div>
            </q-card-section>
        </q-card-section>
    </q-card>
</template>
<script>

import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'

export default {

    data() {
        return {
            itemsFiltroPais: null,
            itemsFiltroEstado: null,
            itemsFiltroMunicipio: null,
            itemsFiltroLocalidad: null,
            itemsFiltroColonia: null,

            //VALIDACIONES
            banderaValida: false,
            banderaEstado: false,
            banderaEstadoX: false,
            banderaMexico: false,
            banderaNoMexico: false,

            //CON CATALOGO
            selectPais: {
                clave: "MEX",
                descripcion: "México",
                pais: "MEX | México"
            },
            selectEstado: null,
            selectMunicipio: null,
            selectLocalidad: null,
            selectColonia: null,

            //SIN CATALOGO
            selectCodigoPostal: "",
            selectEstadoX: "",
            selectMunicipioX: "",
            selectLocalidadX: "",
            selectColoniaX: "",
            selectCalle: "",
            selectnumeroExterior: "",
            selectNumeroInterior: "",
            selectReferencia: "",
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        objetoStore() {
            return this.$store.state.domicilioStore;
        },

        itemsPais() {
            return this.$store.state.listaCatalogoPais;
        },

        itemsEstado() {
            return this.$store.state.listaCatalogoEstado;
        },

        itemsMunicipio() {
            return this.$store.state.listaCatalogoMunicipio;
        },

        itemsLocalidad() {
            return this.$store.state.listaCatalogoLocalidad;
        },

        itemsColonia() {
            return this.$store.state.listaCatalogoColonia;
        },

    },

    created() {
        this.ValoresIniciales();
    },
    methods: {
        async ValoresIniciales() {
            if (this.$store.state.domicilioStore._id === "0") {
                return;
            }
            let domicilio = { ...this.$store.state.domicilioStore };
            this.selectPais = domicilio.pais;
            this.selectCodigoPostal = domicilio.codigoPostal;
            await this.validarCodigoPostal();

            //VALIDAMOS EL ESTADO
            if (domicilio.estado.clave != "") {
                this.selectEstado = domicilio.estado;
            } else {
                this.selectEstadoX = domicilio.estado.descripcion;
            }

            //VALIDAMOS EL MUNICIPIO
            if (domicilio.municipio) {
                if (domicilio.municipio.clave != "") {
                    this.selectMunicipio = domicilio.municipio;
                } else {
                    this.selectMunicipioX = domicilio.municipio.descripcion;
                }
            }

            //VALIDAMOS LA LOCALIDAD
            if (domicilio.localidad) {
                if (domicilio.localidad.clave != "") {
                    this.selectLocalidad = domicilio.localidad;
                } else {
                    this.selectLocalidadX = domicilio.localidad.descripcion;
                }
            }

            //VALIDAMOS LA COLONIA
            if (domicilio.colonia) {
                if (domicilio.colonia.clave != "") {
                    this.selectColonia = domicilio.colonia;
                } else {
                    this.selectColoniaX = domicilio.colonia.descripcion;
                }
            }

            this.selectCalle = domicilio.calle;
            this.selectnumeroExterior = domicilio.numeroExterior;
            this.selectNumeroInterior = domicilio.numeroInterior;
            this.selectReferencia = domicilio.referencia;
        },

        filtroPais(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroPais = this.itemsPais.filter(v => v.pais.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroPais.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        filtroEstado(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroEstado = this.itemsEstado.filter(v => v.estado.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroEstado.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        filtroMunicipio(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroMunicipio = this.itemsMunicipio.filter(v => v.municipio.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroMunicipio.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        filtroLocalidad(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroLocalidad = this.itemsLocalidad.filter(v => v.localidad.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroLocalidad.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        filtroColonia(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroColonia = this.itemsColonia.filter(v => v.colonia.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroColonia.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        async validarCodigoPostal() {
            if (this.selectCodigoPostal === '') {
                this.$q.notify({
                    type: 'warning',
                    position: 'top-right',
                    message: 'Indique el Código postal',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            this.banderaEstado = false;
            this.banderaEstadoX = false;
            this.banderaMexico = false;
            this.banderaNoMexico = false;
            this.banderaValida = false;

            //SI EL PAIS ES MEXICO O EXTADOS UNIDOS, CARGAMOS LOS ESTADOS
            let pais_ = this.selectPais.clave;

            if (pais_ === "MEX" || pais_ === "USA") {
                this.banderaEstado = true;
                await this.GetCatalogoEstado(pais_)
            } else {
                this.banderaEstadoX = true;
            }

            if (pais_ === "MEX") {
                await this.GetCodigoPostal();
                this.banderaMexico = true;
            } else {
                this.banderaNoMexico = true;
            }
            this.banderaValida = true;

            // this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Validando Código postal. Espere...', messageColor: 'white' })


        },

        async GetCodigoPostal() {
            try {
                let response = await axios.get("CatalogosSat/GetCatCodigoPostalAsync/" + this.selectCodigoPostal);
                let catalogo = response.data;
                this.GetCatColonia(this.selectCodigoPostal);
                this.GetCatLocalidad(catalogo.estado, catalogo.localidad);
                this.GetCatMunicipio(catalogo.estado, catalogo.municipio);
                this.banderaValida = true;
            } catch (error) {
                console.log(error);
            }
        },

        async GetCatalogoEstado(pais) {
            try {
                let response = await axios.get("CatalogosSat/GetCatEstadoAsync/" + pais);
                let catalogo = response.data;
                this.$store.state.listaCatalogoEstado = catalogo;
            } catch (error) {
                console.log(error);
            }
        },

        async GetCatColonia(codigoPostal) {
            try {
                let response = await axios.get("CatalogosSat/GetCatColoniaAsync/" + codigoPostal);
                let catalogo = response.data;
                this.$store.state.listaCatalogoColonia = catalogo;

            } catch (error) {
                console.log(error);
            }
        },

        async GetCatLocalidad(estado, localidad) {

            try {
                let response = await axios.get("CatalogosSat/GetCatLocalidadAsync/" + estado + "/" + localidad);
                let catalogo = response.data;
                this.$store.state.listaCatalogoLocalidad = catalogo;

            } catch (error) {
                console.log(error);
            }
        },

        async GetCatMunicipio(estado, municipio) {
            try {
                let response = await axios.get("CatalogosSat/GetCatMunicipioAsync/" + estado + "/" + municipio);
                let catalogo = response.data;
                this.$store.state.listaCatalogoMunicipio = catalogo;

            } catch (error) {
                console.log(error);
            }
        },

        async AsignaValores() {
            //VALIDAMOS LOS CAMPOS OBLIGATORIOS
            if (this.selectCalle.trim() === "") {
                this.$q.notify({
                    type: 'warning',
                    position: 'top-right',
                    message: 'Indique la calle',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return null;
            }

            let ObjEstado = null;
            let ObjMunicipio = null;
            let ObjLocalidad = null;
            let ObjColonia = null;

            //ASIGNAMOS LOS VALORES SI VIENEN DE UN CATALOGO
            if (this.banderaEstado) {
                if (!this.selectEstado) {
                    this.$q.notify({
                        type: 'warning',
                        position: 'top-right',
                        message: 'Indique el estado',
                        actions: [
                            { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                        ]
                    })
                    return null;
                }

                ObjEstado = { ...this.selectEstado }
            }

            if (this.banderaMexico) {
                ObjMunicipio = { ...this.selectMunicipio }
                ObjLocalidad = { ...this.selectLocalidad }
                ObjColonia = { ...this.selectColonia }
            }

            //ASIGNAMOS LOS VALORES SI NO VIENEN DE UN CATALOGO
            if (this.banderaEstadoX) {
                if (this.selectEstadoX.trim() === "") {
                    this.$q.notify({
                        type: 'warning',
                        position: 'top-right',
                        message: 'Indique manualmente el nombre del estado',
                        actions: [
                            { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                        ]
                    })
                    return false;
                }

                if (this.selectEstadoX != "") {
                    ObjEstado = {
                        clave: "",
                        descripcion: this.selectEstadoX,
                        pais: this.selectPais.clave,
                        estado: this.selectEstadoX,
                    }
                }
            }

            if (this.banderaNoMexico) {
                if (this.selectMunicipioX != "") {
                    ObjMunicipio = {
                        clave: "",
                        descripcion: this.selectMunicipioX,
                        estado: "",
                        municipio: this.selectMunicipioX,
                    }
                }
                if (this.selectLocalidadX != "") {
                    ObjLocalidad = {
                        clave: "",
                        descripcion: this.selectLocalidadX,
                        estado: "",
                        localidad: this.selectLocalidadX,
                    }
                }
                if (this.selectColoniaX != "") {
                    ObjColonia = {
                        clave: "",
                        descripcion: this.selectColoniaX,
                        codigoPostal: "",
                        colonia: this.selectColoniaX,
                    }
                }
            }

            let objDomicilio = {
                _id: '',
                calle: this.selectCalle,
                numeroExterior: this.selectnumeroExterior,
                numeroInterior: this.selectNumeroInterior,
                colonia: ObjColonia,
                localidad: ObjLocalidad,
                referencia: this.selectReferencia,
                municipio: ObjMunicipio,
                estado: ObjEstado,
                pais: this.selectPais,
                codigoPostal: this.selectCodigoPostal,
            }
            this.$store.state.domicilioStore = { ...objDomicilio };
            return objDomicilio;
        },
    }
}
</script>
<style></style>