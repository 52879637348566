<template>
    <q-card class="my-card">
        <!-- BUSCAMOS EL FOLIO FISCAL SI ES QUE SE VA A RELACIONAR -->
        <q-dialog v-model="dialogBuscar" transition-show="scale" transition-hide="scale">
            <q-card style="min-width: 1200px;">
                <q-toolbar>
                    <q-toolbar-title>
                        BUSCAR COMPROBANTE
                    </q-toolbar-title>
                </q-toolbar>
                <q-separator class="full-width" color="primary" inset size="4px" />
                <q-card-section >
                    <div class="row q-col-gutter-xs">
                        <div class="col-12">
                            <q-input outlined v-model="textBuscar" label="Búsqueda por folio o folio fiscal" dense @keydown.enter="GetComprobantes">
                                <template v-slot:after>
                                    <q-btn round dense flat icon="mdi-magnify" @click="GetComprobantes">
                                        <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px" :offset="[10, 10]">Buscar comprobante</q-tooltip>
                                    </q-btn>
                                  </template>
                            </q-input>
                        </div>
                    </div>
                    <!-- TABLA DE DOCUMENTOS RELACIONADOS -->
                    <q-table title="Comprobantes" :data="comprobantesEncontrados" :columns="columnsComprobantes" row-key="item"
                    :rows-per-page-options="[10]" class="my-sticky-column-table" >
                    <template v-slot:body="props">
                        <q-tr :props="props">
                            <q-td auto-width key="acciones">
                                <q-btn size="md" color="primary" rounded flat dense @click="PushConceptos(props.row)"
                                    icon="mdi-plus">
                                    <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                        content-style="font-size: 14px" :offset="[10, 10]">Agregar</q-tooltip>
                                </q-btn>
                            </q-td>
                            <q-td key="serie" :props="props">{{ props.row.serie }}</q-td>
                            <q-td key="folio" :props="props">{{ props.row.folio }}</q-td>
                            <q-td key="fecha" :props="props">{{ FormatDate(props.row.fecha) }}</q-td>
                            <q-td key="rfc" :props="props">{{ props.row.rfc }}</q-td>
                            <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                            <q-td key="subTotal" :props="props">{{ FormatCurrency(props.row.subTotal) }}</q-td>
                            <q-td key="total" :props="props">{{ FormatCurrency(props.row.total) }}</q-td>
                            <q-td key="moneda" :props="props">{{ props.row.moneda }}</q-td>
                            <q-td key="folioFiscal" :props="props">{{ props.row.folioFiscal }}</q-td>
                            <q-td key="estatus" :props="props">{{ props.row.estatus }}</q-td>
                            <q-td key="tipoComprobante" :props="props">{{ props.row.tipoComprobante }}</q-td>
                        </q-tr>
                    </template>
                </q-table>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat label="Cerrar" color="red-10" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>
        <!-- DIALOG PARA CONFIRMAR LA CANCELACION -->
        <q-dialog v-model="dialogConfirma" persistent>
            <q-card>
                <q-card-section class="row items-center">
                    <q-avatar icon="mdi-cancel" color="red-10" text-color="white" />
                    <span class="q-ml-sm">Confirmar solicitud de cancelación</span>
                </q-card-section>
                <q-card-actions>
                    <q-btn flat label="Cancelar solicitud" color="red-10" v-close-popup />
                    <q-space />
                    <q-btn flat label="Confirmar solicitud" color="green-10" @click="PutSolicitaCancelacion()" />
                </q-card-actions>
            </q-card>
        </q-dialog>
        <!-- DIALOG PARA INFORMACION -->
        <q-dialog v-model="dialogInfo">
            <q-card>
                <q-toolbar>
                    <q-btn flat round dense icon="close" v-close-popup color="red-14">
                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                            content-style="font-size: 14px" :offset="[10, 10]">Cerrar</q-tooltip>
                    </q-btn>
                    <q-toolbar-title><span class="text-weight-bold">Información</span></q-toolbar-title>
                </q-toolbar>
                <q-separator color="primary" inset size="4px" />
                <q-card-section v-html="detallesInfo" />
            </q-card>
        </q-dialog>

        <q-toolbar>
            <q-btn push color="red-10" icon="mdi-close-circle-outline" round flat size="18px" padding="xs"
                @click="CierraDialog">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Cerrar</q-tooltip>
            </q-btn>
            <q-toolbar-title><span class="text-weight-bold">Solicitud de cancelación</span></q-toolbar-title>
            <q-btn push color="blue-10" icon="mdi-information-outline" round flat size="18px" padding="xs"
                @click="dialogInfo = true">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Información</q-tooltip>
            </q-btn>
            <q-btn push color="green-10" icon="mdi-send" round flat size="18px" padding="xs"
                @click="OpenDialogConfirm()">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Solicitar cancelacion</q-tooltip>
            </q-btn>
        </q-toolbar>
        <q-separator color="primary" inset size="4px" />
        <q-card-section>
            <div class="q-pa-md q-gutter-sm">
                <div class="col-12">
                    <q-input outlined v-model="item.folio" label="Folio" dense readonly />
                </div>
                <div class="col-12">
                    <q-input outlined v-model="item.rfcReceptor" label="RFC" dense readonly />
                </div>
                <div class="col-12">
                    <q-input outlined v-model="item.nombreReceptorr" label="Nombre" dense readonly />
                </div>
                <div class="col-12">
                    <q-input outlined v-model="item.total" label="Total" dense readonly />
                </div>
                <div class="col-12">
                    <q-input outlined v-model="item.folioFiscal" label="Folio Fiscal" dense readonly />
                </div>
                <div class="col-12">
                    <q-select outlined dense v-model="selectCancelacion" :options="itemsCancelacion"
                        label="Motivo de cancelación" option-label="descripcion" />
                </div>
                <div v-if="selectCancelacion">
                    <div class="col-12" v-if="selectCancelacion.clave === '01' || selectCancelacion.clave === '04'">
                        <q-input outlined v-model="folioFiscalRelacionado" label="Folio Fiscal relacionado" dense >
                            <template v-slot:after>
                                <q-btn round dense flat icon="mdi-magnify" @click="dialogBuscar = true"/>
                              </template>
                        </q-input>
                    </div>
                </div>
            </div>
        </q-card-section>

    </q-card>
</template>
<script>
import axios from 'axios'
import { QSpinnerCube } from 'quasar';

export default {
    components: {

    },

    data() {
        return {
            itemsCancelacion: [
                { clave: "01", descripcion: "01 | Comprobantes emitidos con errores con relación" },
                { clave: "02", descripcion: "02 | Comprobantes emitidos con errores sin relación" },
                { clave: "03", descripcion: "03 | No se llevó a cabo la operación" },
                { clave: "04", descripcion: "04 | Operación nominativa relacionada en una factura global" },
            ],
            selectCancelacion: null,
            dialogConfirma: false,
            dialogInfo: false,
            folioFiscalRelacionado: '',
            detallesInfo: "♦ 01 | Comprobantes emitidos con errores con relación: <br> El comprobante contiene error en la clave del producto, valor unitario, descuento o cualquier otro dato por el cual se debe reexpedir, relacionando un comprobante para su corección. <br><br> ♦ 02 | Comprobantes emitidos con errores sin relación: <br>El comprobante contiene un error en la clave del producto, valor unitario, descuento o cualquier otro dato y no se requiere relacionar con otra factura generada <br><br> ♦ 03 | No se llevó a cabo la operación: <br> Se facturó una operación que no se concretó <br><br> ♦ 04 | Operación nominativa relacionada en una factura global: <br> Se incluyó una venta en la factura global de operaciones con el público en general y, posteriormente, el cliente solicitó su factura nominativa",

            dialogBuscar: false,
            textBuscar: "",
            comprobantesEncontrados: [],
            columnsComprobantes: [
                { name: 'actions', align: 'left', label: 'Acciones', field: 'actions' },
                { name: 'serie', align: 'left', label: 'Serie', field: 'serie', sortable: true },
                { name: 'folio', align: 'left', label: 'Folio', field: 'folio', sortable: true },
                { name: 'fecha', align: 'left', label: 'Fecha', field: 'fecha', sortable: true },
                { name: 'rfc', align: 'left', label: 'RFC', field: 'rfc', sortable: true },
                { name: 'nombre', align: 'left', label: 'Nombre', field: 'nombre', sortable: true },
                { name: 'subTotal', align: 'right', label: 'SubTotal', field: 'subTotal', sortable: true },
                { name: 'total', align: 'right', label: 'Total', field: 'total', sortable: true },
                { name: 'moneda', align: 'left', label: 'Moneda', field: 'moneda', sortable: true },
                { name: 'folioFiscal', align: 'left', label: 'Folio fiscal', field: 'folioFiscal', sortable: true },
                { name: 'estatus', align: 'left', label: 'Estatus', field: 'estatus', sortable: true },
                { name: 'tipoComprobante', align: 'left', label: 'Tipo comprobante', field: 'tipoComprobante', sortable: true },
            ],

        }
    },

    computed: {
        token() {
            return this.$store.state.usuario;
        },

        item() {
            return this.$store.state.cancelacionStore;
        }

    },

    created() {

    },

    methods: {
        OpenDialogConfirm() {
            if (!this.selectCancelacion) {
                this.$q.notify({
                    type: 'warning',
                    // position: 'top-right',
                    message: 'Indique el motivo de la cancelación',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (this.selectCancelacion.clave === "01" || this.selectCancelacion.clave === "04") {
                if (this.folioFiscalRelacionado === "") {
                    this.$q.notify({
                        type: 'warning',
                        // position: 'top-right',
                        message: 'Indique el folio fiscal a relacionar',
                        actions: [
                            { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                        ]
                    })
                    return;
                }
            }
            this.dialogConfirma = true;
        },

        async PutSolicitaCancelacion() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Generando solicitud de cancelación...' });
            try{
                let objCancelacion = { ...this.item }
                let colectionName = "comprobantes_emitidos";

                //VALIDAMOS QUE COLECCION VAMOS A ACTUALIZAR
                if(objCancelacion.ventana === "Pago"){
                    colectionName = "comprobantes_pagos";
                }

                let total = objCancelacion.total;
                let numeroSinSimboloYComas = total.replace(/[$,]/g, "");
                let numeroDecimal = parseFloat(numeroSinSimboloYComas);

                objCancelacion.usuario = this.token.nombre;
                objCancelacion.motivoCancelacion = { ...this.selectCancelacion }
                objCancelacion.total = numeroDecimal;
                objCancelacion.uuidSustitucion = this.folioFiscalRelacionado;
                console.log(objCancelacion);
                let response = await axios.post('Comprobantes/PostSolicitudCancelacionAsync/' + this.token.empresaBase + '/' + colectionName, objCancelacion);
                console.log(response.data);
                this.$q.loading.hide()
                this.$q.notify({
                    type: 'positive',
                    timeout: 5000,
                    // position: 'top-right',
                    message: 'Solicitud generada con éxito, verifique el estatus en la lista para conocer el estatus del comprobantes en el portal del SAT',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
            }catch(error){
                console.log(error);
                this.$q.notify({
                    type: 'negative',
                    timeout: 5000,
                    // position: 'top-right',
                    message: 'Error al generar solicitud de cancelación: ' + error.response,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                this.$q.loading.hide()
            }
            this.dialogConfirma = false;
        },

        CierraDialog() {
            this.$emit("CloseDialogCancelacion")
        },
    
        async GetComprobantes(){
            this.comprobantesEncontrados = [];
            this.$q.loading.show({
                spinner: QSpinnerCube,
                spinnerColor: 'red-8',
                spinnerSize: 140,
                message: 'Consultando comprobantes, espere...',
            });

            let rfc = this.item.rfcReceptor;
            let parametro = this.textBuscar;
            try {
                let response = await axios.get('Comprobantes/GetComprobanteParaRelacionadoAsync/' + this.token.empresaBase + '/' + rfc + '/' + parametro)
                let x = response.data;
                // console.log(x)
                this.comprobantesEncontrados = [...x]
                this.$store.state.metodoPagoStore = [...x]
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },
    
        FormatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        FormatDate(value) {
            let fecha = new Date(value);
            let dia = fecha.getDate();
            let mes = fecha.toLocaleString('es-ES', { month: 'long' }); // Obtener el mes en formato de letra
            let anio = fecha.getFullYear();
            let formatoDeseado = `${dia}-${mes}-${anio}`;
            return formatoDeseado;
        },
    
        PushConceptos(item){
            this.folioFiscalRelacionado = item.folioFiscal;
            this.dialogBuscar = false;
        },
    },
}
</script>

<style>
.my-card {
    width: 100%;
}
</style>