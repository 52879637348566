<template>
    <q-card style="min-width: 1000px;">
        <q-toolbar>
            <q-btn push color="red-10" icon="mdi-close-circle-outline" round flat size="18px" padding="xs"
                @click="CierraDialog">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Cerrar</q-tooltip>
            </q-btn>
            <q-toolbar-title><span class="text-weight-bold">Proveedor</span></q-toolbar-title>

            <!-- BOTON PARA GUARDAR -->
            <q-btn v-if="proveedor._id == ''" push color="green-10" icon="mdi-content-save" round flat size="18px"
                padding="xs" @click="GuardarProveedor()">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Guardar</q-tooltip>
            </q-btn>
            <q-btn v-else push color="green-10" icon="mdi-content-save" round flat size="18px" padding="xs"
                @click="EditarProveedor()">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Editar</q-tooltip>
            </q-btn>
        </q-toolbar>
        <q-separator color="primary" inset size="4px" />
        <q-card-section class="q-pb-sm">
            <div class="row q-col-gutter-sm">
                <div class="col-3">
                    <q-input outlined v-model="proveedor.rfc" label="RFC Proveedor" dense />
                </div>
                <div class="col-9">
                    <q-input outlined v-model="proveedor.nombre" label="Nombre del Proveedor" dense />
                </div>
                <div class="col-12">
                    <q-input outlined v-model="proveedor.nombreComercial" label="Nombre Comercial" dense />
                </div>
                <div class="col-6">
                    <q-input outlined v-model="proveedor.correo" label="Correo" dense />
                </div>
                <div class="col-6">
                    <q-input outlined v-model="proveedor.telefono" label="Teléfono" dense />
                </div>
                <div class="col-12">
                    <q-input outlined v-model="proveedor.direccion" label="Dirección" dense />
                </div>
            </div>
        </q-card-section>
    </q-card>
</template>
<script>

import axios from 'axios'
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar';
import moment from 'moment';
import { component as VueNumber } from '@coders-tm/vue-number-format'
import { Datetime } from 'vue-datetime';

export default {
    components: {
        VueNumber, Datetime
    },
    data() {
        return {
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        proveedor() {
            return this.$store.state.proveedorStore
        },

    },
    created() {
    },
    methods: {
        async GuardarProveedor() {
            if (this.proveedor.rfc == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el RFC del proveedor.' })
                return;
            }

            if (this.proveedor.nombre == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre del proveedor.' })
                return;
            }

            if (this.proveedor.nombreComercial == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre comercial del Proveedor.' })
                return;
            }

            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando proveedor. Espere...', messageColor: 'white' })

            try {
                let response = await axios.post('Compras/PostProveedorAsync/erp_' + this.token.rfc, this.proveedor)
                console.log(response)

                this.proveedor._id = response.data._id
                this.$store.state.listaProveedoresStore.push(this.proveedor)
                this.CierraDialog();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `El proveedor ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        async EditarProveedor() {
            this.proveedor.usuarioModificador = this.token.nombre
            if (this.proveedor.rfc == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el RFC del proveedor.' })
                return;
            }

            if (this.proveedor.nombre == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre del proveedor.' })
                return;
            }

            if (this.proveedor.nombreComercial == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre comercial del Proveedor.' })
                return;
            }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Actualizando proveedor. Espere...', messageColor: 'white' })

            try {
                let response = await axios.put('Compras/PutProveedorAsync/erp_' + this.token.rfc, this.proveedor)
                console.log(response)

                let indice = this.$store.state.listaProveedoresStore.findIndex(x => x._id === this.proveedor._id);
                Object.assign(this.$store.state.listaProveedoresStore[indice], this.proveedor)
                this.CierraDialog();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `El proveedor ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        CierraDialog() {
            this.$emit("Close");
        },



    },
}
</script>
<style>
.my-card {
    width: 100%;
}
</style>