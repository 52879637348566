<template>
    <div>
        <div class="row q-col-gutter-sm">
            <!-- SERIE -->
            <div class="col-12 col-md-3">
                <q-select label="Serie" outlined v-model="cotizacion.serie" use-input input-debounce="0"
                    @new-value="createSerie" :options="optionsSerie" @filter="filterSerie" dense>
                    <template v-slot:no-option>
                        <q-item>
                            <q-item-section class="text-grey">
                                Sin resultados
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>
            </div>
            <!-- FOLIO -->
            <div class="col-12 col-md-3">
                <q-input outlined v-model="cotizacion.folio" label="Folio" dense />
            </div>
            <!-- FECHA -->
            <div class="col-12 col-md-3">
                <datetime v-model="cotizacion.fecha" title="Fecha" type="datetime" input-id="startDate"
                    :phrases="{ ok: 'Ok', cancel: 'Cancelar' }"
                    :format="{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit' }"
                    value-zone="America/Mexico_City" class="custom-datetime">
                </datetime>
            </div>
            <!-- FECHA DE ENTREGA -->
            <div class="col-12 col-md-3">
                <q-select label="Tiempo de Entrega" outlined dense v-model="cotizacion.tiempoEntrega"
                    :options="itemsTiempoEntrega" />
            </div>

            <!-- SOLICITANTE -->
            <div class="col-12">
                <q-input outlined v-model="cotizacion.solicitante" label="Solicitante" dense />
            </div>

            <!-- PROVEEDOR -->
            <div class="col-12 col-md-12">
                <q-select v-model="cotizacion.proveedor" use-input input-debounce="0" label="Proveedor"
                    :options="optionsProveedor" @filter="filterProveedor" fill-input hide-selected
                    :loading="loadingProveedor" dense outlined option-label="proveedor">
                    <template v-slot:no-option>
                        <q-item>
                            <q-item-section class="text-grey">
                                No exixte algún proveedor que coincida con los criterios
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>
            </div>

            <!-- PROYECTO -->
            <div class="col-12 col-md-12">
                <q-select v-model="cotizacion.proyecto" use-input input-debounce="0" label="Proyecto"
                    :options="optionsProyecto" @filter="filterProyecto" fill-input hide-selected
                    :loading="loadingProyecto" dense outlined option-label="proyecto">
                    <template v-slot:no-option>
                        <q-item>
                            <q-item-section class="text-grey">
                                No exixte algún proyecto que coincida con los criterios
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>
            </div>
            <!-- NOTAS -->
            <div class="col-12">
                <q-input outlined v-model="cotizacion.notas" label="Notas" dense />
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar';
import moment from 'moment';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';

export default {
    components: {
        Datetime
    },
    data() {
        return {
            optionsSerie: [],

            //CLIENTES
            loadingClientes: false,
            optionsClientes: [],

            // MONEDA
            optionsMoneda: [],
            loadingMoneda: false,

            // PROYECTO
            loadingProyecto: false,
            optionsProyecto: [],

            // PROVEEDOR
            loadingProveedor: false,
            optionsProveedor: [],

            model: null,

            itemsTiempoEntrega: ['1 Día', '3-5 Dias', '1 Semana', '15 Dias', '1 Mes', 'Mas de un mes']
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        cotizacion() {
            return this.$store.state.cotizacionCStore;
        },

        optionsClientesDefault() {
            return this.$store.state.listClienteStore
        },

        optionsMonedaDefault() {
            return this.$store.state.monedaStore;
        },

        optionsSerieDefault() {
            return this.$store.state.serieStore;
        },

        optionsProyectosDefault() {
            return this.$store.state.listaProyectosStore;
        },

        optionsProveedorDefault() {
            return this.$store.state.listaProveedoresStore;
        }

    },
    created() {

    },
    methods: {

        // DATOS Y FILTROS DE LOS COMPRONENTES GENERALES
        createSerie(val, done) {
            let stringOptions = this.optionsSerieDefault;
            if (val.length > 0) {
                if (!stringOptions.includes(val)) {
                    stringOptions.push(val)
                }
                done(val, 'toggle')
            }
        },

        setModelSerie(val) {
            this.cotizacion.serie = val
        },

        filterSerie(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.optionsSerie = this.optionsSerieDefault.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            })
        },

        filterCliente(val, update) {
            let stringOptions = this.optionsClientesDefault
            if (val === '') {
                update(() => {
                    this.optionsClientes = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsClientes = stringOptions.filter(v => v.receptor.toLowerCase().indexOf(needle) > -1)
            })
        },

        filterMoneda(val, update) {
            let stringOptions = this.optionsMonedaDefault
            if (val === '') {
                update(() => {
                    this.optionsMoneda = stringOptions
                })
                return
            }
            update(() => {
                const needle = val.toLowerCase()
                this.optionsMoneda = stringOptions.filter(v => v.moneda.toLowerCase().indexOf(needle) > -1)
            })
        },

        filterProyecto(val, update) {
            let stringOptions = this.optionsProyectosDefault
            if (val === '') {
                update(() => {
                    this.optionsProyecto = stringOptions
                })
                return
            }
            update(() => {
                const needle = val.toLowerCase()
                this.optionsProyecto = stringOptions.filter(v => v.proyecto.toLowerCase().indexOf(needle) > -1)
            })
        },

        filterProveedor(val, update) {
            let stringOptions = this.optionsProveedorDefault
            if (val === '') {
                update(() => {
                    this.optionsProveedor = stringOptions
                })
                return
            }
            update(() => {
                const needle = val.toLowerCase()
                this.optionsProveedor = stringOptions.filter(v => v.proveedor.toLowerCase().indexOf(needle) > -1)
            })
        },
    },
}
</script>