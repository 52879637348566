<template>
    <div>
        <q-dialog v-model="dialogSubirExcel" persistent>
            <q-card>
                <q-bar class="bg-primary text-white">
                    <div>Cargar Excel</div>
                    <q-space />

                    <q-btn dense flat icon="close" v-close-popup>
                        <q-tooltip>Close</q-tooltip>
                    </q-btn>
                </q-bar>

                <q-card-section class="text-center">
                    <q-uploader style="min-width: 100%;" label="Sube tu archivo Excel" accept=".xlsx, .xls"
                        @added="onFileAdded" />

                    <q-btn full-width class="q-mt-md" color="primary" flat label="DESCARGAR PLANTILLA DE EXCEL"
                        @click="downloadTemplate()" />
                </q-card-section>
            </q-card>
        </q-dialog>

        <div class="row q-col-gutter-sm">
            <!-- CONCEPTO -->
            <div class="col-12">
                <q-select v-model="concepto" use-input input-debounce="0" label="Concepto" :options="optionsConceptos"
                    @filter="filterConceptos" fill-input hide-selected :loading="loadingConceptos" dense outlined
                    option-label="concepto" @input="AsignaValores()">
                    <template v-slot:no-option>
                        <q-item>
                            <q-item-section class="text-grey">
                                No exixte algún concepto que coincida con los criterios
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>
            </div>

            <!-- NO IDENTIFICACION -->
            <div class="col-12">
                <q-input outlined v-model="noIdentificacion" label="No. Identificación" dense />
            </div>

            <!-- DESCRIPCION -->
            <div class="col-12">
                <!-- <q-input outlined v-model="descripcion" label="Descripción" dense /> -->
                <form autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false">
                    <q-editor v-model="descripcion" min-height="2rem" @paste.native="evt => pasteCapture(evt)"
                        placeholder="Descripción" dense ref="editor_ref" :toolbar="[]" />
                </form>
            </div>

            <!-- MATERIAL -->
            <div class="col-6">
                <q-input outlined v-model="material" label="Material" dense />
            </div>

            <!-- CANTIDAD -->
            <div class="col-12 col-md-2">
                <q-field v-model="cantidad" label="Cantidad" dense outlined>
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                        <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                            v-show="floatingLabel" v-bind="cantidadFormat"></vue-number>
                    </template>
                </q-field>
            </div>

            <!-- UNIDAD -->
            <div class="col-12 col-md-2">
                <q-input outlined v-model="unidad" label="Unidad" dense />
            </div>


            <!-- VALOR UNITARIO -->
            <div class="col-12 col-md-2">
                <q-field v-model="valorUnitario" label="Valor unitario" dense outlined>
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                        <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                            v-show="floatingLabel" v-bind="importeFormat"></vue-number>
                    </template>
                    <template v-slot:after>
                        <q-btn round dense flat icon="mdi-plus-circle-outline" color="green-10"
                            @click="calcularPorImporte">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Agregar
                                concepto</q-tooltip>
                        </q-btn>
                    </template>
                </q-field>
            </div>

            <div class="col-1">
                <q-field dense outlined>
                    <template v-slot:before>
                        <q-checkbox v-model="iva16" />
                    </template>
                    <template v-slot:control>
                        <div class="self-center full-width no-outline" tabindex="0">IVA 16% </div>
                    </template>
                </q-field>

            </div>
            <!-- OBJETO DE IMPUESTOS -->
            <div v-if="iva16 == false" class="col-11">
                <q-select outlined v-model="objImp" :options="optionsObjImp" label="Objeto de Impuestos" dense
                    option-label="objImp" />
            </div>
        </div>

        <!-- TABLAS DE IMPUESTOS -->
        <div v-if="iva16 == false">
            <template v-if="banderaObjImp">
                <q-splitter v-model="splitterModel">
                    <!-- TABLAS DE IMPUESTOS TRASLADADOS -->
                    <template v-slot:before>
                        <div class="q-pa-md">
                            <q-table title="Traslados" :data="optionsImpuestosTrasladados" :columns="columnsImpuestos"
                                row-key="nombre" selection="multiple" :selected.sync="selectImpuestosTrasladados" dense
                                :pagination="pagination" hide-bottom>
                            </q-table>
                        </div>
                    </template>
                    <!-- TABLAS DE IMPUESTOS RETENIDOS -->
                    <template v-slot:after>
                        <div class="q-pa-md">
                            <q-table title="Retenciones" :data="optionsImpuestosRetenidos" :columns="columnsImpuestos"
                                row-key="nombre" selection="multiple" :selected.sync="selectImpuestosRetenidos" dense
                                :pagination="pagination" hide-bottom>
                            </q-table>
                        </div>
                    </template>
                </q-splitter>
            </template>
        </div>

        <!-- TABLA DE CONCEPTOS -->
        <q-table title="Conceptos" :data="pedido.conceptos" :columns="columns" row-key="item"
            :rows-per-page-options="[10]" class="my-sticky-column-table q-mt-md">
            <template v-slot:top>
                <q-space />
                <q-btn round dense flat color="green" icon="mdi-file-excel" @click="dialogSubirExcel = true">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Cargar Excel</q-tooltip>
                </q-btn>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width key="acciones">
                        <q-btn size="md" color="primary" rounded flat dense @click="EliminarConcepto(props.row)"
                            icon="mdi-delete">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Eliminar Concepto</q-tooltip>
                        </q-btn>
                        <q-btn size="md" color="blue" rounded flat dense @click="EditConcepto(props.row)"
                            icon="mdi-pencil-outline">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Editar concepto</q-tooltip>
                        </q-btn>
                    </q-td>

                    <q-td key="item" :props="props">{{ props.row.item }}</q-td>
                    <q-td key="noIdentificacion" :props="props">{{ props.row.noIdentificacion }}
                        <q-popup-edit buttons v-model="props.row.noIdentificacion" v-slot="scope">
                            <q-input v-model="scope.value" dense autofocus counter @keyup.enter="scope.set" />
                        </q-popup-edit>
                    </q-td>
                    <q-td key="descripcion" :props="props">
                        <div v-html="props.row.descripcion"></div>
                        <q-popup-edit buttons v-model="props.row.descripcion" v-slot="scope">
                            <q-editor v-model="scope.value" min-height="5rem" autofocus @keyup.enter.stop
                                :toolbar="[]" />
                        </q-popup-edit>
                    </q-td>

                    <q-td key="cantidad" :props="props">{{ props.row.cantidad }}</q-td>
                    <q-td key="unidad" :props="props">{{ props.row.unidad }}
                        <q-popup-edit buttons v-model="props.row.unidad" v-slot="scope">
                            <q-input v-model="scope.value" dense autofocus counter @keyup.enter="scope.set" />
                        </q-popup-edit>
                    </q-td>
                    <q-td key="valorUnitario" :props="props">{{ props.row.valorUnitario }}</q-td>
                    <q-td key="importe" :props="props">{{ props.row.importe }}</q-td>
                    <q-td key="material" :props="props">{{ props.row.material }}
                        <q-popup-edit buttons v-model="props.row.material" v-slot="scope">
                            <q-input v-model="scope.value" dense autofocus counter @keyup.enter="scope.set" />
                        </q-popup-edit>
                    </q-td>
                    <q-td key="objImp" :props="props">{{ props.row.objImp.objImp }}</q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>
<script>
import axios from 'axios'
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar';
import moment from 'moment';
import { component as VueNumber } from '@coders-tm/vue-number-format'

export default {
    components: {
        VueNumber
    },
    data() {
        return {
            concepto: null,
            optionsConceptos: [],
            loadingConceptos: false,

            indice: -1,
            noItem: 1,
            claveProdServ: null,
            noIdentificacion: '',
            cantidad: 1,
            claveUnidad: null,
            unidad: '',
            descripcion: '',
            valorUnitario: 0,
            importe: 0,
            descuento: 0,
            objImp: null,
            impuestos: {
                traslados: [],
                retenciones: [],
            },
            material: '',

            columns: [
                { name: 'actions', align: 'left', label: 'Acciones', field: 'actions' },
                { name: 'item', align: 'left', label: 'No. Item', field: 'item', sortable: true },
                { name: 'noIdentificacion', align: 'left', label: 'No. Identificación', field: 'noIdentificacion', sortable: true },
                { name: 'descripcion', align: 'left', label: 'Descripción', field: 'descripcion', sortable: true },
                { name: 'cantidad', align: 'left', label: 'Cantidad', field: 'cantidad', sortable: true },
                { name: 'unidad', align: 'left', label: 'Unidad', field: 'unidad', sortable: true },
                { name: 'valorUnitario', align: 'left', label: 'Valor Unitario', field: 'valorUnitario', sortable: true },
                { name: 'importe', align: 'left', label: 'Importe', field: 'importe', sortable: true },
                { name: 'material', align: 'center', label: 'Material', field: 'material', sortable: true },
                { name: 'objImp', align: 'left', label: 'Obj. Impuestos', field: 'objImp', sortable: true },
            ],

            selectImpuestosTrasladados: [],
            selectImpuestosRetenidos: [],
            columnsImpuestos: [
                { name: 'nombre', align: 'left', label: 'Impuesto', field: 'nombre' },
                { name: 'tipoFactor', align: 'left', label: 'Tipo Factor', field: 'tipoFactor' },
                { name: 'tasaOCuota', align: 'left', label: 'Tasa O Cuota', field: 'tasaOCuota' },
            ],
            splitterModel: 50,

            importeFormat: {
                decimal: '.',
                separator: ',',
                prefix: '',
                precision: 6,
                masked: false,
                minimumFractionDigits: 6,
            },
            cantidadFormat: {
                decimal: '.',
                separator: ',',
                prefix: '',
                precision: 3,
                masked: false,
                minimumFractionDigits: 3,
            },

            pagination: {
                rowsPerPage: 0
            },
            iva16: true,
            dialogSubirExcel: false
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        pedido() {
            return this.$store.state.pedidoStore;
        },

        optionsConceptosDefault() {
            return this.$store.state.listaConceptosStore;
        },

        optionsObjImp() {
            return this.$store.state.objImpStore;
        },

        banderaObjImp() {
            if (!this.objImp) {
                return false;
            } else {
                if (this.objImp.clave === "01") {
                    return false;
                } else if (this.objImp.clave === "02") {
                    return true;
                }
            }
        },

        optionsImpuestosTrasladados() {
            return this.$store.state.trasladosStore;
        },

        optionsImpuestosRetenidos() {
            return this.$store.state.retencionesStore;
        },
    },
    created() {

    },
    methods: {
        onFileAdded(files) {
            let columns = []
            let tableData = []
            const file = files[0];
            const reader = new FileReader();

            reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                // Asumiendo que la primera hoja es la que queremos leer
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];

                // Convirtiendo la hoja a JSON
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                // Transformar los datos para q-table
                if (jsonData.length) {
                    columns = jsonData[0].map((header, index) => ({
                        name: index,
                        align: 'left',
                        label: header,
                        field: row => row[index],
                        sortable: true
                    }));
                    tableData = jsonData.slice(1).map((row, index) => ({
                        id: index,
                        ...row
                    }));

                    for (let c of tableData) {
                        this.claveProdServ = null;
                        this.noIdentificacion = c['0'];
                        this.cantidad = c['1'];
                        this.claveUnidad = null;
                        this.unidad = c['2'];
                        this.descripcion = c['3'];
                        this.valorUnitario = c['4'];
                        this.material = c['5'];
                        this.importe = 0;
                        this.descuento = 0;

                        var objetoImpuesto02 = {
                            clave: "02",
                            descripcion: "Sí objeto de impuesto.",
                            objImp: "02 | Sí objeto de impuesto."
                        }

                        var objetoImpuesto01 = {
                            clave: "01",
                            descripcion: "No objeto de impuesto.",
                            objImp: "01 | No objeto de impuesto."
                        }

                        var impuestos16Default = [
                            {
                                impuesto: "002",
                                tipoFactor: "Tasa",
                                tasaOCuota: 0.16,
                                tipo: "Traslado",
                                nombre: "IVA (16.0000%)",
                            }
                        ]

                        let llevaImpuestos = c['6'];
                        if (llevaImpuestos === 'SI') {
                            this.objImp = objetoImpuesto02
                            this.selectImpuestosTrasladados = impuestos16Default
                        } else {
                            this.objImp = objetoImpuesto01
                        }
                        this.cuentaPredial = null;

                        this.calcularPorImporte()
                    }
                    this.dialogSubirExcel = false
                    // console.log(tableData)
                    // console.log(columns)
                }
            };

            reader.readAsArrayBuffer(file);
        },
        EditConcepto(item) {
            this.indice = this.pedido.conceptos.findIndex(
                (f) => f.item === item.item
            );

            this.noItem = item.item;
            this.valorUnitario = item.valorUnitario;
            this.claveProdServ = item.claveProdServ;
            this.claveUnidad = item.claveUnidad;
            this.cantidad = item.cantidad;
            this.descuento = item.descuento;
            this.noIdentificacion = item.noIdentificacion;
            this.unidad = item.unidad;
            this.descripcion = item.descripcion;
            this.objImp = item.objImp;
            this.material = item.material
            this.cuentaPredial = item.cuentaPredial;
            //ASIGNAMOS LOS IMPUESTOS
            const trasladados = [...this.optionsImpuestosTrasladados];
            for (let i of item.impuestos.traslados) {
                let t = trasladados.find(o => o.impuesto === i.impuesto && o.tasaOCuota == i.tasaOCuota);
                this.selectImpuestosTrasladados.push(t)
            }

            const retenciones = [...this.optionsImpuestosRetenidos];
            for (let i of item.impuestos.retenciones) {
                let r = retenciones.find(o => o.impuesto === i.impuesto && o.tasaOCuota == i.tasaOCuota);
                this.selectImpuestosRetenidos.push(r)
            }
            // this.selectImpuestosRetenidos = item.impuestos.retenciones;
        },

        filterConceptos(val, update) {
            let stringOptions = this.optionsConceptosDefault
            if (val === '') {
                update(() => {
                    this.optionsConceptos = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsConceptos = stringOptions.filter(v => v.concepto.toLowerCase().indexOf(needle) > -1)
            })
        },


        AsignaValores() {
            console.log(this.concepto);
            let c = { ...this.concepto }
            this.claveProdServ = c.claveProdServ;
            this.claveUnidad = c.claveUnidad;
            this.noIdentificacion = c.noIdentificacion;
            this.cantidad = c.cantidad;
            this.unidad = c.unidad;
            this.descripcion = c.descripcion;
            this.valorUnitario = c.valorUnitario;
            this.importe = c.importe
            this.descuento = c.descuento;
            this.objImp = c.objImp;
            this.cuentaPredial = c.cuentaPredial;
            this.impuestos = c.impuestos;
        },

        calcularPorImporte() {
            var objetoImpuesto02 = {
                clave: "02",
                descripcion: "Sí objeto de impuesto.",
                objImp: "02 | Sí objeto de impuesto."
            }

            var impuestos16Default = [
                {
                    impuesto: "002",
                    tipoFactor: "Tasa",
                    tasaOCuota: 0.16,
                    tipo: "Traslado",
                    nombre: "IVA (16.0000%)",
                }
            ]

            if (this.iva16 == true) {
                this.objImp = objetoImpuesto02
                this.selectImpuestosTrasladados = impuestos16Default
            }

            let valorUnitario_ = parseFloat(this.valorUnitario);
            let cantidad_ = parseFloat(this.cantidad);
            let descuento_ = parseFloat(this.descuento);

            //VALIDAMOS QUE CUMPLA CON TODOS LOS CAMPOS
            if (this.descripcion === '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique la descripción del producto o servicio`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (cantidad_ <= 0) {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique la cantidad`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (valorUnitario_ <= 0) {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el valor unitario`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (!this.objImp) {
                this.$q.notify({
                    type: 'warning',
                    message: `Seleccione si es objeto de impuestos.`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (this.objImp.clave === '02') {
                if (this.selectImpuestosTrasladados.length + this.selectImpuestosRetenidos.length == 0) {
                    this.$q.notify({
                        type: 'warning',
                        message: `Indique al menos un impuesto trasladado o retenido`,
                        actions: [
                            { label: 'Cerrar', color: 'white', handler: () => { } }
                        ]
                    })
                    return;
                }
            }

            //ASIGNAMOS A VARIABLES GLOBALES
            let claveProdServ = this.claveProdServ;
            let claveUnidad = this.claveUnidad;
            let noIdentificacion = this.noIdentificacion;
            let cantidad = cantidad_.toFixed(3);
            let unidad = this.unidad;
            let descripcion = this.descripcion;
            let valorUnitario = valorUnitario_.toFixed(6);
            let importe = (valorUnitario * cantidad).toFixed(6)
            let descuento = descuento_.toFixed(6);
            let material = this.material
            let objImp = this.objImp;
            let impuestos = {
                traslados: [],
                retenciones: [],
            }

            //HACEMOS EL CALCULO PARA LOS IMPUESTOS
            for (let t of this.selectImpuestosTrasladados) {
                let base_ = (importe - descuento).toFixed(6);
                let tasaOCuota = t.tasaOCuota;
                let importe_ = (base_ * tasaOCuota).toFixed(6)
                let objT = {
                    base_: base_,
                    impuesto: t.impuesto,
                    tipoFactor: t.tipoFactor,
                    tasaOCuota: t.tasaOCuota,
                    importe: importe_,
                }
                impuestos.traslados.push(objT);
                objT = {};
            }

            for (let t of this.selectImpuestosRetenidos) {
                let base_ = (importe - descuento).toFixed(6);
                let tasaOCuota = t.tasaOCuota;
                let importe_ = (base_ * tasaOCuota).toFixed(6)
                let objR = {
                    base_: base_,
                    impuesto: t.impuesto,
                    tipoFactor: t.tipoFactor,
                    tasaOCuota: t.tasaOCuota,
                    importe: importe_,
                }
                impuestos.retenciones.push(objR);
                objR = {};
            }

            let item = 0;
            if (this.indice != -1) {
                item = this.noItem;
            } else {
                item = this.pedido.conceptos.length + 1;
            }

            // console.log(valorUnitario, cantidad, importe)
            let concepto = {
                item: item,
                claveProdServ: claveProdServ,
                noIdentificacion: noIdentificacion,
                claveUnidad: claveUnidad,
                cantidad: cantidad,
                unidad: unidad,
                descripcion: descripcion,
                valorUnitario: valorUnitario,
                importe: importe,
                descuento: descuento,
                objImp: objImp,
                cuentaPredial: null,
                impuestos: impuestos,
                material: material
            }

            if (this.indice != -1) {
                // this.comprobante.conceptos[this.indice] = concepto;
                Object.assign(this.pedido.conceptos[this.indice], concepto)
            } else {
                this.pedido.conceptos.push(concepto)
            }

            concepto = {};
            this.concepto = null;
            this.InicializaConcepto();
            this.AgrupaYCalculaTotales();
            this.selectImpuestosRetenidos = []
            this.selectImpuestosTrasladados = []
            this.indice = -1;
        },

        EliminarConcepto(item) {
            let indice = this.pedido.conceptos.indexOf(item);
            this.pedido.conceptos.splice(indice, 1);
            this.AgrupaYCalculaTotales();
        },

        InicializaConcepto() {
            this.indice = -1;
            this.claveProdServ = null;
            this.noIdentificacion = '';
            this.cantidad = 1;
            this.claveUnidad = null;
            this.unidad = '';
            this.descripcion = '';
            this.valorUnitario = 0;
            this.importe = 0;
            this.descuento = 0;
            this.objImp = null;
            this.impuestos = {
                traslados: [],
                retenciones: [],
            };
            this.cuentaPredial = null;
            this.material = ''
        },

        AgrupaYCalculaTotales() {
            let totalTraslados = 0;
            let totalRetenidos = 0;
            let subTotal = 0;
            let descuento = 0;
            let total = 0;
            let trasladados = [];
            let retenidos = [];

            //INICIALIZAMOS LOS IMPUESTOS
            this.pedido.impuestos.traslados = [];
            this.pedido.impuestos.retenciones = [];

            for (let c of this.pedido.conceptos) {
                for (let t of c.impuestos.traslados) {
                    trasladados.push(t);
                }

                for (let r of c.impuestos.retenciones) {
                    retenidos.push(r)
                }
            }

            subTotal = this.pedido.conceptos.reduce(function (acumulador, objeto) {
                return acumulador + parseFloat(objeto.importe);
            }, 0);
            descuento = this.pedido.conceptos.reduce(function (acumulador, objeto) {
                return acumulador + parseFloat(objeto.descuento);
            }, 0);
            subTotal = subTotal.toFixed(2);
            descuento = descuento.toFixed(2);

            //AGRUPAMOS LOS IMPUESTOS
            let t = this.AgrupaYCalculaImpuestos(trasladados);
            let r = this.AgrupaYCalculaImpuestos(retenidos);
            totalTraslados = t.reduce(function (acumulador, objeto) {
                return acumulador + parseFloat(objeto.importe);
            }, 0);
            totalRetenidos = r.reduce(function (acumulador, objeto) {
                return acumulador + parseFloat(objeto.importe);
            }, 0);
            totalTraslados = totalTraslados.toFixed(2);
            totalRetenidos = totalRetenidos.toFixed(2);

            //ASIGNAMOS LAS VARIABLES TOTALES
            this.pedido.impuestos.traslados = t;
            this.pedido.impuestos.retenciones = r;
            this.pedido.impuestos.totalImpuestosTrasladados = parseFloat(totalTraslados);
            this.pedido.impuestos.totalImpuestosRetenidos = parseFloat(totalRetenidos);
            this.pedido.subTotal = parseFloat(subTotal);
            this.pedido.descuento = parseFloat(descuento);

            //ASIGNAMOS EL TOTAL
            total = this.pedido.subTotal - this.pedido.descuento + this.pedido.impuestos.totalImpuestosTrasladados - this.pedido.impuestos.totalImpuestosRetenidos;
            total = total.toFixed(2);
            this.pedido.total = parseFloat(total);
        },

        AgrupaYCalculaImpuestos(lista) {
            var agrupado = lista.reduce(function (acumulador, elemento) {
                // Crear una clave única para el grupo
                var clave = elemento.impuesto + '-' + elemento.tipoFactor + '-' + elemento.tasaOCuota;

                // Inicializar el grupo si es la primera vez que se encuentra la clave
                if (!acumulador[clave]) {
                    acumulador[clave] = {
                        impuesto: elemento.impuesto,
                        tipoFactor: elemento.tipoFactor,
                        tasaOCuota: elemento.tasaOCuota,
                        base_: 0,
                        importe: 0,
                    };
                }

                // Sumar base e importe al grupo correspondiente
                acumulador[clave].base_ += parseFloat(elemento.base_);
                acumulador[clave].importe += parseFloat(elemento.importe);

                return acumulador;
            }, {});

            // Convertir el objeto agrupado de nuevo a una lista
            var resultado = Object.values(agrupado);

            for (let r of resultado) {
                r.base_ = r.base_.toFixed(2);
                r.importe = r.importe.toFixed(2);
            }
            return resultado;
        },

        pasteCapture(evt) {
            // Let inputs do their thing, so we don't break pasting of links.
            if (evt.target.nodeName === 'INPUT') return
            let text, onPasteStripFormattingIEPaste
            evt.preventDefault()
            if (evt.originalEvent && evt.originalEvent.clipboardData.getData) {
                text = evt.originalEvent.clipboardData.getData('text/plain')
                this.$refs.editor_ref.runCmd('insertText', text)
            }
            else if (evt.clipboardData && evt.clipboardData.getData) {
                text = evt.clipboardData.getData('text/plain')
                this.$refs.editor_ref.runCmd('insertText', text)
            }
            else if (window.clipboardData && window.clipboardData.getData) {
                if (!onPasteStripFormattingIEPaste) {
                    onPasteStripFormattingIEPaste = true
                    this.$refs.editor_ref.runCmd('ms-pasteTextOnly', text)
                }
                onPasteStripFormattingIEPaste = false
            }
        }
    },
}
</script>