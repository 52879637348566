import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    usuario: null,
    modoOscuro: false,

    // EMPRESA
    empresaStore: {
      _id: '',
      rfc: '',
      nombre: '',
      regimenFiscal: {
        regimenFiscal: ''
      },
      domicilioFiscal: '',
      logo: {
        base64: 'https://contago.com.mx/img/descargas/logo-contago.png',
      },
      nombreCorreo: '',
      correo: '',
      password: '',
      puerto: '',
      host: '',
    },

    clienteStore: {},

    //CLAVES DEL SAT
    claveProdServStore: [],
    claveUnidadStore: [],
    objImpStore: [],
    formaPagoStore: [],
    monedaStore: [],
    metodoPagoStore: [],
    usoCfdiStore: [],
    exportacionStore: [],
    periodicidadStore: [],
    mesesStore: [],
    cveTransporteStore: [],
    tipoPermisoStore: [],

    //CATALOGOS DEL SAT
    regimenFiscalStore: [],
    regimenAduaneroStore: [],
    listUsoCfdiStore: [],
    listClienteStore: [],
    listTipoRelacion: [],
    listMotivosCancelacionStore: [],

    //IMPUESTOS
    trasladosStore: [],
    retencionesStore: [],

    conceptoStore: {},
    listaConceptosStore: [],

    // PUENTO DE VENTA

    articuloStore: {},
    listaArticulosStore: [],
    listVentasStore: [],
    ventaStore: {
      _id: '',
      serie: '',
      folio: '',
      fecha: null,
      formaPago: { id: 1, clave: "01", formaPago: "01 | Efectivo", descripcion: "Efectivo" },
      total: 0,
      subTotal: 0,
      descuento: 0,
      moneda: {
        id: 100,
        clave: 'MXN',
        descripcion: 'Peso Mexicano',
        moneda: 'MXN | Peso Mexicano'
      },
      tipoComprobante: 'I',
      tipoComprobanteInterno: 'PuntoVenta',
      metodoPago: { id: 1, clave: "PUE", metodoPago: "PUE | Pago en una sola exhibición", descripcion: "Pago en una sola exhibición" },
      emisor: {
        rfc: '',
        nombre: '',
        regimenFiscal: {
          clave: "",
          moral: "",
          fisica: "",
          descripcion: "",
          regimenFiscal: ""
        },
      },
      receptor: {
        rfc: 'XAXX010101000',
        nombre: 'PUBLICO EN GENERAL',
        receptor: 'XAXX010101000 | PUBLICO EN GENERAL',
        domicilioFiscalReceptor: '',
        residenciaFiscal: {
          clave: '',
          descripcion: '',
          pais: '',
        },
        usoCfdi: {
          clave: "S01",
          moral: "Sí",
          fisica: "Sí",
          usoCfdi: "S01 | Sin efectos fiscales.",
          descripcion: "Sin efectos fiscales."
        },
        regimenFiscalReceptor: {
          clave: "616",
          moral: "Sí",
          fisica: "No",
          descripcion: "Sin obligaciones fiscales",
          regimenFiscal: "616 | Sin obligaciones fiscales"
        },
      },
      notas: '',
      estatus: 'Sin Timbrar',
      conceptos: [],
      impuestos: {
        traslados: [],
        retenciones: [],
      },
      idTurno: 0,
      usuarioVentas: '',
      pagoCon: 0.00,
      cambioTotal: 0.00,
      formasPago: [],
      lugarExpedicion: ''
    },

    //COMPROBANTES
    facturaStore: {},
    comprobanteStore: {},
    vistaPreviaStore: null,
    listIngresoCartaPorteStore: [],
    serieStore: [],
    //LISTAS DE COMPROBANTES
    listFacturaStore: [],
    listNotaCreditoStore: [],
    listComplementoDePagoStore: [],
    listIngresoComercioExterior: [],

    //ADDENDAS
    addendaVw: {},

    // USUARIOS INTERNON
    usuarioInternoStore: {},
    listaUsuariosInternosStore: [],

    // CATALOGOS CARTA PORTE
    ubicacionStore: null,
    domicilioStore: null,
    mercanciaStore: null,
    figuraTransporteStore: null,
    remolqueStore: null,
    seguroStore: null,
    identificacionStore: null,

    listaUbicacionesStore: [],
    listaDomiciliosStore: [],
    listaMercanciasStore: [],
    listaFigurasTransporteStore: [],
    listaRemolquesStore: [],
    listaSegurosStore: [],
    listaIdentificacionVehicularStore: [],
    listaTipoCadPagoStore: [],

    listaCatalogoPais: [],
    listaCatalogoColonia: [],
    listaCatalogoLocalidad: [],
    listaCatalogoMunicipio: [],
    listaCatalogoEstado: [],

    listaCatalogoConfigAutotransporte: [],

    listaCatalogoSubTipoRem: [],

    listaCatalogoClaveProServCP: [],
    listaCatalogoClaveUnidad: [],
    listaCatalogoTipoEmbalaje: [],
    listaCatalogoFraccionArancelaria: [],
    listaCatalogoTipoMateria: [],
    listaCatalogoDocAduanero: [],
    listaCatalogoCveTransporte: [],
    listaCatalogoMaterialPeligroso: [],
    listaCatalogoUnidadAduana: [],
    listaCatalogoTipoFigura: [],
    listaCatalogoPartesTransporte: [],


    // COMERCIO EXTERIOR
    listaEmisorCEStore: [],
    emisorCEStore: null,

    listaDestinatariosCEStore: [],
    destinatarioCEStore: null,

    listaPropietariosCEStore: [],
    propietarioCEStore: null,

    listaReceptorCEStore: [],
    receptorCEStore: null,

    listaMercanciasCEStore: [],
    mercanciaCEStore: null,
    listClavePedimento: [],
    listCertificadoOrigen: [],
    listIncoterm: [],

    //CONTADOR FOLIOS DISPONIBLES
    timbresDisponiblesStore: 0,

    // PROYECTOS 
    listaProyectosStore: [],
    proyectoStore: null,

    // VENTAS 
    // COTIZACIONES 
    listaCotizacionesStore: [],
    cotizacionStore: null,

    // PEDIDOS
    listaPedidosStore: [],
    pedidoStore: null,

    // REMISIONES 
    remisionStore: null,
    listaRemisionesStore: [],


    // COMPRAS 
    //PEDIDOS
    listaPedidosCStore: [],
    pedidoCStore: null,

    // COTIZACIONES 
    listaCotizacionesCStore: [],
    cotizacionCStore: null,

    // ORDENES DE COMPRA
    listaOrdenComprasStore: [],
    ordenCompraStore: null,

    // PROVEEDORES
    listaProveedoresStore: [],
    proveedorStore: null,

    //CANCELACIONES
    cancelacionStore: {},
    validaEstatusStore: {},
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setUsuario(state, usuario) {
      state.usuario = usuario
    },

    //FACTURA
    postFacturaStore(state, objComprobante) {
      state.listFacturaStore.unshift(objComprobante);
    },

    putFacturaStore(state, objComprobante) {
      let indice = state.listFacturaStore.findIndex(objeto => objeto._id === objComprobante._id);
      Vue.set(state.listFacturaStore, indice, objComprobante);
    },

    //NOTA DE CREDITO
    postNotaCreditoStore(state, objComprobante) {
      state.listNotaCreditoStore.unshift(objComprobante);
    },

    putNotaCreditoStore(state, objComprobante) {
      let indice = state.listNotaCreditoStore.findIndex(objeto => objeto._id === objComprobante._id);
      Vue.set(state.listNotaCreditoStore, indice, objComprobante);
    },

    //INGRESO CARTA PORTE
    postIngresoCartaPorteStore(state, objComprobante) {
      state.listIngresoCartaPorteStore.unshift(objComprobante);
    },

    putIngresoCartaPorteStore(state, objComprobante) {
      let indice = state.listIngresoCartaPorteStore.findIndex(objeto => objeto._id === objComprobante._id);
      Vue.set(state.listIngresoCartaPorteStore, indice, objComprobante);
    },

    //COMPLEMENTO DE PAGO
    postComplementoPagoStore(state, objComprobante) {
      state.listComplementoDePagoStore.unshift(objComprobante);
    },

    //COMERCIO EXTERIOR
    postComercioExterior(state, objComprobante) {
      state.listIngresoComercioExterior.unshift(objComprobante);
    },

    putComercioExterior(state, objComprobante) {
      let indice = state.listIngresoComercioExterior.findIndex(objeto => objeto._id === objComprobante._id);
      Vue.set(state.listIngresoComercioExterior, indice, objComprobante);
    },
  },
  actions: {
    guardarToken({ commit }, token) {
      commit("setToken", token)
      commit("setUsuario", decode(token))
      localStorage.setItem("token-erp", token)
    },
    autoLogin({ commit }) {
      let token = localStorage.getItem("token-erp")
      if (token) {
        commit("setToken", token)
        commit("setUsuario", decode(token))
        router.push({ name: 'Home' })
      }
    },
    salir({ commit }) {
      commit("setToken", null)
      commit("setUsuario", null)
      localStorage.removeItem("token-erp")
      router.push({ name: 'Login' })
    },
  },
  modules: {
  }
})
