<template>
    <q-layout view="hHh lpR fFf">
        <q-header elevated>
            <q-toolbar>
                <q-btn flat dense round icon="mdi-home" aria-label="Menu" @click="$router.push({ name: 'Home' })" />
                <q-toolbar-title>
                    <div class="text-h6 text-weight-bolder">COMPRAS</div>
                </q-toolbar-title>
                <div class="text-h6 q-mr-lg">{{ $store.state.usuario.rfc }}</div>
                <q-btn flat round dense icon="mdi-account" @click="drawerPerfil = !drawerPerfil" />
            </q-toolbar>
        </q-header>

        <!-- DRAWER DERECHO -->
        <q-drawer v-model="drawerPerfil" side="right" bordered>
            <drawerPerfil></drawerPerfil>
        </q-drawer>

        <!-- DRAWER IZQUIERDO -->
        <q-drawer v-model="leftDrawerOpen" show-if-above :mini="miniState" @mouseover="miniState = false"
            @mouseout="miniState = true" :width="250" :breakpoint="500" bordered content-class="bg-dark">
            <q-scroll-area class="fit" :horizontal-thumb-style="{ opacity: 0 }">
                <q-list padding>
                    <q-item active clickable v-ripple @click="irPedidos">
                        <q-item-section avatar>
                            <q-icon name="mdi-order-bool-ascending-variant" />
                        </q-item-section>
                        <q-item-section>
                            Pedidos
                        </q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item active clickable v-ripple @click="irCotizacion">
                        <q-item-section avatar>
                            <q-icon name="mdi-clipboard-text-clock-outline" />
                        </q-item-section>
                        <q-item-section>
                            Cotización
                        </q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item active clickable v-ripple @click="irOrdenCompra">
                        <q-item-section avatar>
                            <q-icon name="mdi-dolly" />
                        </q-item-section>
                        <q-item-section>
                            Orden de Compra
                        </q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item active clickable v-ripple @click="irArticulos">
                        <q-item-section avatar>
                            <q-icon name="mdi-basket-fill" />
                        </q-item-section>
                        <q-item-section>
                            Artículos
                        </q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item active clickable v-ripple @click="irProyectos">
                        <q-item-section avatar>
                            <q-icon name="mdi-folder" />
                        </q-item-section>
                        <q-item-section>
                            Proyectos
                        </q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item active clickable v-ripple @click="irProveedores">
                        <q-item-section avatar>
                            <q-icon name="mdi-human-dolly" />
                        </q-item-section>
                        <q-item-section>
                            Proveedores
                        </q-item-section>
                    </q-item>
                    <q-separator />

                </q-list>
            </q-scroll-area>
        </q-drawer>
        <q-page-container>
            <router-view />
        </q-page-container>

        <q-footer style="height: 30px;" bordered class="bg-dark text-white">
            <q-toolbar>
                <q-toolbar-title>
                </q-toolbar-title>
            </q-toolbar>
        </q-footer>
    </q-layout>
</template>

<script>
import { ref } from 'vue'
import axios from "axios";
import drawerPerfil from "../DrawerPerfil/DrawerPerfil.vue"
import { QSpinnerCube } from 'quasar';


export default {

    name: 'MainLayout',
    components: {
        drawerPerfil
    },
    data() {
        return {
            drawerPerfil: false,
            isDarkMode: false,
            leftDrawerOpen: false,
            drawer: false,
            miniState: true,
        }
    },

    watch: {
        isDarkMode(val) {
            this.$q.dark.set(val);
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.GetCatalogos()
    },
    methods: {
        irCotizacion() {
            this.$router.push({ name: 'ListaCotizacionC' })
        },
        irPedidos() {
            this.$router.push({ name: 'ListaPedidosC' })
        },
        irOrdenCompra() {
            this.$router.push({ name: 'ListaOrdenCompraC' })
        },
        irProyectos() {
            this.$router.push({ name: 'ListaProyectosC' })
        },
        irArticulos() {
            this.$router.push({ name: 'ListaConceptosC' })
        },
        irProveedores() {
            this.$router.push({ name: 'ListaProveedor' });
        },
        async GetCatalogos() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'primary', spinnerSize: 140, message: 'Consultando empresa...' });
            await this.GetEmpresa();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'primary', spinnerSize: 140, message: 'Consultando clientes / receptores...' });
            await this.GetReceptor();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'primary', spinnerSize: 140, message: 'Consultando obetos de impuestos...' });
            await this.GetObjImp();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'primary', spinnerSize: 140, message: 'Consultando impuestos...' });
            await this.GetImpuestos();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'primary', spinnerSize: 140, message: 'Consultando monedas...' });
            await this.GetMoneda();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'primary', spinnerSize: 140, message: 'Consultando proyectos...' });
            await this.getProyectos();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'primary', spinnerSize: 140, message: 'Consultando conceptos...' });
            await this.GetConceptos();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'primary', spinnerSize: 140, message: 'Consultando métodos de pago...' });
            await this.GetMetodoPago();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'primary', spinnerSize: 140, message: 'Consultando formas de pago...' });
            await this.GetFormaPago();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'primary', spinnerSize: 140, message: 'Consultando proveedores...' });
            await this.getProveedor();
            this.$q.loading.hide()
        },
        async getProveedor() {
            try {
                let response = await axios.get("Compras/GetProveedorAsync/erp_" + this.token.rfc);
                let catalogo = response.data;
                this.$store.state.listaProveedoresStore = catalogo;
            } catch (error) {
                console.log(error);
            }
        },
        async GetEmpresa() {
            try {
                let response = await axios.get("Empresa/GetEmpresaAsync/" + this.token.empresaBase);
                let x = response.data;
                this.$store.state.empresaStore = { ...x }
            } catch (error) {
                console.log(error)
            }
        },

        async GetReceptor() {
            try {
                let response = await axios.get("CatalogosComprobantes/GetReceptorAsync/" + this.token.empresaBase);
                let x = response.data;
                this.$store.state.listClienteStore = [...x]
            } catch (error) {
                console.log(error)
            }
        },

        async GetObjImp() {
            if (this.$store.state.objImpStore.length != 0) {
                return;
            } else {
                try {
                    let response = await axios.get('CatalogosSat/GetCatObjImpAsync')
                    let x = response.data;
                    this.$store.state.objImpStore = [...x]
                } catch (error) {
                    console.log(error)
                }
            }
        },

        async GetImpuestos() {
            if (this.$store.state.trasladosStore.length != 0) {
                return;
            } else {
                try {
                    let response = await axios.get('CatalogosSat/GetCatImpuestosSatAsync')
                    let x = response.data;
                    this.$store.state.trasladosStore = x.filter(f => f.tipo === 'Traslado');
                    this.$store.state.retencionesStore = x.filter(f => f.tipo === 'Retencion');
                    // this.$store.state.objImpStore = [...x]
                } catch (error) {
                    console.log(error)
                }
            }
        },

        async GetMetodoPago() {
            if (this.$store.state.metodoPagoStore.length != 0) {
                return;
            } else {
                try {
                    let response = await axios.get('CatalogosSat/GetCatMetodoPagoAsync')
                    let x = response.data;
                    this.$store.state.metodoPagoStore = [...x]
                } catch (error) {
                    console.log(error)
                }
            }
        },

        async GetFormaPago() {
            if (this.$store.state.formaPagoStore.length != 0) {
                return;
            } else {
                try {
                    let response = await axios.get('CatalogosSat/GetCatFormaPagoAsync')
                    let x = response.data;
                    this.$store.state.formaPagoStore = [...x]
                } catch (error) {
                    console.log(error)
                }
            }
        },

        async GetMoneda() {
            if (this.$store.state.monedaStore.length != 0) {
                return;
            } else {
                try {
                    let response = await axios.get('CatalogosSat/GetCatMonedaAsync')
                    let x = response.data;
                    this.$store.state.monedaStore = [...x]
                } catch (error) {
                    console.log(error)
                }
            }
        },

        async getProyectos() {
            try {
                let response = await axios.get("Ventas/GetProyectoAsync/erp_" + this.token.rfc);
                let catalogo = response.data;
                this.$store.state.listaProyectosStore = catalogo;

            } catch (error) {
                console.log(error)
            }
        },

        async GetConceptos() {
            try {
                let response = await axios.get("CatalogosComprobantes/GetConceptosAsync/" + this.token.empresaBase);
                console.log(response)
                this.$store.state.listaConceptosStore = response.data;
            } catch (error) {
                console.log(error);
            }
        },
    }
}
</script>
