<template>
    <div>
        <q-dialog v-model="dialogBuscar" transition-show="scale" transition-hide="scale">
            <q-card>
                <q-toolbar>
                    <q-toolbar-title>
                        BUSCAR COMPROBANTE
                    </q-toolbar-title>
                </q-toolbar>
                <q-separator class="full-width" color="primary" inset size="4px" />
                <q-card-section>
                    <div class="row q-col-gutter-xs">
                        <div class="col-12">
                            <q-input outlined v-model="textBuscar" label="Búsqueda por folio o folio fiscal" dense @keydown.enter="GetComprobantes">
                                <template v-slot:after>
                                    <q-btn round dense flat icon="mdi-magnify" @click="GetComprobantes">
                                        <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px" :offset="[10, 10]">Buscar comprobante</q-tooltip>
                                    </q-btn>
                                  </template>
                            </q-input>
                        </div>
                    </div>
                    <!-- TABLA DE DOCUMENTOS RELACIONADOS -->
                    <q-table title="Comprobantes" :data="comprobantesEncontrados" :columns="columnsComprobantes" row-key="item"
                    :rows-per-page-options="[10]" class="my-sticky-column-table" >
                    <template v-slot:body="props">
                        <q-tr :props="props">
                            <q-td auto-width key="acciones">
                                <q-btn size="md" color="primary" rounded flat dense @click="PushConceptos(props.row)"
                                    icon="mdi-plus">
                                    <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                        content-style="font-size: 14px" :offset="[10, 10]">Agregar</q-tooltip>
                                </q-btn>
                            </q-td>
                            <q-td key="serie" :props="props">{{ props.row.serie }}</q-td>
                            <q-td key="folio" :props="props">{{ props.row.folio }}</q-td>
                            <q-td key="fecha" :props="props">{{ FormatDate(props.row.fecha) }}</q-td>
                            <q-td key="rfc" :props="props">{{ props.row.rfc }}</q-td>
                            <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                            <q-td key="subTotal" :props="props">{{ FormatCurrency(props.row.subTotal) }}</q-td>
                            <q-td key="total" :props="props">{{ FormatCurrency(props.row.total) }}</q-td>
                            <q-td key="moneda" :props="props">{{ props.row.moneda }}</q-td>
                            <q-td key="folioFiscal" :props="props">{{ props.row.folioFiscal }}</q-td>
                            <q-td key="estatus" :props="props">{{ props.row.estatus }}</q-td>
                            <q-td key="tipoComprobante" :props="props">{{ props.row.tipoComprobante }}</q-td>
                        </q-tr>
                    </template>
                </q-table>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat label="Cerrar" color="red-10" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>
        <div class="row q-col-gutter-sm">
            <!-- TIPO DE RELACION -->
            <div class="col-12">
                <q-select v-model="comprobante.cfdiRelacionados[0].tipoRelacion" label="Tipo de relación" :options="optionsTipoRelacion" dense
                    outlined option-label="tipoRelacion">
                </q-select>
            </div>
        </div>
        <!-- TABLA DE DOCUMENTOS RELACIONADOS -->
        <q-table title="Documentos relacionados" :data="comprobante.cfdiRelacionados[0].uuid" :columns="columns" row-key="item"
        :rows-per-page-options="[10]" class="my-sticky-column-table" >
           <template v-slot:top-right>
               <q-btn push color="white" text-color="primary" round icon="mdi-file-search-outline"
                   @click="OpenDialogBuscar">
                   <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                       :offset="[10, 10]">Buscar comprobante</q-tooltip>
               </q-btn>
           </template>
           <template v-slot:body="props">
               <q-tr :props="props">
                   <q-td auto-width key="acciones">
                       <q-btn size="md" color="primary" rounded flat dense @click="DeleteComprobante(props.row)"
                           icon="mdi-delete">
                           <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                               content-style="font-size: 14px" :offset="[10, 10]">Eliminar</q-tooltip>
                       </q-btn>
                   </q-td>
                   <q-td key="folioFiscal" :props="props">{{ props.row }}</q-td>
               </q-tr>
           </template>
       </q-table>
    </div>
</template>
<script>
    import axios from 'axios'
    import { es } from 'date-fns/locale';
    import { QSpinnerCube } from 'quasar';
    
    export default {
        components: {
            
        },
        data() {
            return {
                columns: [
                    { name: 'actions', align: 'left', label: 'Acciones', field: 'actions' },
                    { name: 'folioFiscal', align: 'left', label: 'Folio fiscal', field: 'folioFiscal', sortable: true },
                ],
                dialogBuscar: false,
                textBuscar: '',
                comprobantesEncontrados: [],
                columnsComprobantes: [
                    { name: 'actions', align: 'left', label: 'Acciones', field: 'actions' },
                    { name: 'serie', align: 'left', label: 'Serie', field: 'serie', sortable: true },
                    { name: 'folio', align: 'left', label: 'Folio', field: 'folio', sortable: true },
                    { name: 'fecha', align: 'left', label: 'Fecha', field: 'fecha', sortable: true },
                    { name: 'rfc', align: 'left', label: 'RFC', field: 'rfc', sortable: true },
                    { name: 'nombre', align: 'left', label: 'Nombre', field: 'nombre', sortable: true },
                    { name: 'subTotal', align: 'right', label: 'SubTotal', field: 'subTotal', sortable: true },
                    { name: 'total', align: 'right', label: 'Total', field: 'total', sortable: true },
                    { name: 'moneda', align: 'left', label: 'Moneda', field: 'moneda', sortable: true },
                    { name: 'folioFiscal', align: 'left', label: 'Folio fiscal', field: 'folioFiscal', sortable: true },
                    { name: 'estatus', align: 'left', label: 'Estatus', field: 'estatus', sortable: true },
                    { name: 'tipoComprobante', align: 'left', label: 'Tipo comprobante', field: 'tipoComprobante', sortable: true },
                ],
                
            }
        },
        computed: {
            token() {
                return this.$store.state.usuario;
            },
    
            comprobante() {
                return this.$store.state.comprobanteStore.comprobante;
            },

            optionsTipoRelacion() {
            return this.$store.state.listTipoRelacion;
        },
    
        },
        created() {
    
        },
        methods: {
            OpenDialogBuscar(){
                this.dialogBuscar = true;
            },

            DeleteComprobante(item){
                let lista = this.comprobante.cfdiRelacionados[0].uuid;
                let indice = lista.findIndex(f => f === item);
                lista.splice(indice, 1);
            },

            PushConceptos(item){
                let lista = this.comprobante.cfdiRelacionados[0].uuid;
                let listaComprobantes = this.comprobantesEncontrados;
                lista.push(item.folioFiscal);
                let indice = listaComprobantes.findIndex(f => f.folioFiscal === item.folioFiscal);
                listaComprobantes.splice(indice, 1);
            },

            async GetComprobantes(){
                this.comprobantesEncontrados = [];
                this.$q.loading.show({
                    spinner: QSpinnerCube,
                    spinnerColor: 'red-8',
                    spinnerSize: 140,
                    message: 'Consultando comprobantes, espere...',
                })

                let rfc = this.comprobante.receptor.rfc;
                let parametro = this.textBuscar;
                try {
                    let response = await axios.get('Comprobantes/GetComprobanteParaRelacionadoAsync/' + this.token.empresaBase + '/' + rfc + '/' + parametro)
                    let x = response.data;
                    // console.log(x)
                    this.comprobantesEncontrados = [...x]
                    this.$store.state.metodoPagoStore = [...x]
                    this.$q.loading.hide()
                } catch (error) {
                    console.log(error)
                    this.$q.loading.hide()
                }
            },

            FormatCurrency(value) {
                return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            },

            FormatDate(value) {
                let fecha = new Date(value);
                let dia = fecha.getDate();
                let mes = fecha.toLocaleString('es-ES', { month: 'long' }); // Obtener el mes en formato de letra
                let anio = fecha.getFullYear();
                let formatoDeseado = `${dia}-${mes}-${anio}`;
                return formatoDeseado;
            },
        
        },
    }
    </script>