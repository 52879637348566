<template>
    <div>
        <!-- BOTON DE CONCILIAR -->
        <div class="row q-col-gutter-sm">
            <div class="col-12" v-if="!expanded">
                <q-btn align="around" class="full-width" color="primary" label="Conciliar Parte" push
                    @click="Conciliar()" />
            </div>
        </div>
        <!-- PARA AGTEGAR CONCEPTOS DE FORMA MANUAL -->
        <q-expansion-item v-model="expanded" icon="perm_identity" label="Agregar parte" caption="Modo manual">
            <q-card class="full-width">
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <div class="col-12">
                            <q-input outlined v-model="parteLocal.descripcionMaterial" label="Descripción del Material"
                                dense />
                        </div>
                        <div class="col-12 col-md-6">
                            <q-input outlined v-model="parteLocal.cantidadMaterial" label="Cantidad de Material" dense
                                type="number" />
                        </div>
                        <div class="col-12 col-md-6">
                            <q-input outlined v-model="parteLocal.unidadMedida" label="Unidad de Medida" dense />
                        </div>
                        <div class="col-12 col-md-6">
                            <q-input outlined v-model="parteLocal.precioUnitario" label="Precio Unitario" dense
                                type="number" />
                        </div>
                        <div class="col-12 col-md-6">
                            <q-input outlined v-model="parteLocal.montoLinea" label="Monto de Linea" dense
                                type="number" />
                        </div>
                        <div class="col-12">
                            <q-input outlined v-model="parteLocal.numeroMaterial" label="Número de Material" dense />
                        </div>
                        <div class="col-12 col-md-6">
                            <q-input outlined v-model="parteLocal.pesoBruto" label="Peso Bruto" dense type="number" />
                        </div>
                        <div class="col-12 col-md-6">
                            <q-input outlined v-model="parteLocal.pesoNeto" label="Peso Neto" dense type="number" />
                        </div>
                        <div class="col-12">
                            <q-input outlined v-model="parteLocal.ordenCompra" label="Orden de Compra" dense
                                hint="With placeholder">
                                <template v-slot:hint>
                                    {{ ordenDeCompraG }}
                                </template>
                            </q-input>
                        </div>
                        <div class="col-12">
                            <q-btn align="around" class="full-width " color="primary" label="Agregar Parte" push
                                @click="PushParte()" />
                        </div>
                    </div>
                </q-card-section>
            </q-card>
        </q-expansion-item>
        <!-- TABLA DE PARTES -->
        <q-table title="Partes" :data="dataPartes" :columns="columns" row-key="_id" :rows-per-page-options="[10]"
            class="my-sticky-column-table">
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width>
                        <q-btn size="md" color="red-10" rounded flat dense @click="DeleteArchivo(props.row)"
                            icon="mdi-delete">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Eliminar</q-tooltip>
                        </q-btn>
                    </q-td>
                    <q-td key="codigoImpuesto" :props="props">{{ props.row.codigoImpuesto.descripcion }}</q-td>
                    <q-td key="posicion" :props="props">{{ props.row.posicion }}</q-td>
                    <q-td key="descripcionMaterial" :props="props">{{ props.row.descripcionMaterial }}
                        <q-popup-edit buttons v-model="props.row.descripcionMaterial" v-slot="scope">
                            <q-input v-model="scope.value" dense autofocus counter @keyup.enter="scope.set"
                                maxlength="40" />
                        </q-popup-edit>
                    </q-td>
                    <q-td key="cantidadMaterial" :props="props">{{ props.row.cantidadMaterial }}</q-td>
                    <q-td key="unidadMedida" :props="props">{{ props.row.unidadMedida }}
                        <q-popup-edit buttons v-model="props.row.unidadMedida" v-slot="scope">
                            <q-input v-model="scope.value" dense autofocus counter @keyup.enter="scope.set"
                                maxlength="3" />
                        </q-popup-edit>
                    </q-td>
                    <q-td key="precioUnitario" :props="props">{{ props.row.precioUnitario }}</q-td>
                    <q-td key="montoLinea" :props="props">{{ props.row.montoLinea }}</q-td>
                    <q-td key="numeroMaterial" :props="props">{{ props.row.numeroMaterial }}</q-td>
                    <q-td key="pesoBruto" :props="props">{{ props.row.pesoBruto }}</q-td>
                    <q-td key="pesoNeto" :props="props">{{ props.row.pesoNeto }}</q-td>
                    <q-td key="ordenCompra" :props="props">{{ props.row.ordenCompra }}</q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>
<script>
import axios from 'axios'
import { QSpinnerCube } from 'quasar';

export default {
    components: {

    },
    data() {
        return {
            columns: [
                { name: 'actions', align: 'left', label: 'Acciones', field: 'actions' },
                { name: 'codigoImpuesto', align: 'left', label: 'Código de Impuesto', field: 'codigoImpuesto', sortable: false },
                { name: 'posicion', align: 'left', label: 'Posición', field: 'posicion', sortable: false },
                { name: 'descripcionMaterial', align: 'left', label: 'Descripicón de Material', field: 'descripcionMaterial', sortable: false },
                { name: 'cantidadMaterial', align: 'left', label: 'Cantidad de Material', field: 'cantidadMaterial', sortable: false },
                { name: 'unidadMedida', align: 'left', label: 'Unidad de Medida', field: 'unidadMedida', sortable: false },
                { name: 'precioUnitario', align: 'left', label: 'Precio Unitario', field: 'precioUnitario', sortable: false },
                { name: 'montoLinea', align: 'left', label: 'Monto de Linea', field: 'montoLinea', sortable: false },
                { name: 'numeroMaterial', align: 'left', label: 'Número de Material', field: 'numeroMaterial', sortable: false },
                { name: 'pesoBruto', align: 'left', label: 'Peso Bruto', field: 'pesoBruto', sortable: false },
                { name: 'pesoNeto', align: 'left', label: 'Peso Neto', field: 'pesoNeto', sortable: false },
                { name: 'ordenCompra', align: 'left', label: 'Orden de Compra', field: 'ordenCompra', sortable: false },
            ],

            expanded: false,

            parteLocal: {
                codigoImpuesto: { clave: "1A", descripcion: "1A | IVA 16%" },
                posicion: 0,
                descripcionMaterial: "",
                cantidadMaterial: 0,
                unidadMedida: "",
                precioUnitario: 0,
                montoLinea: 0,
                numeroMaterial: "",
                pesoBruto: 0,
                pesoNeto: 0,
                ordenCompra: "",
            },
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        dataPartes() {
            return this.$store.state.addendaVw.partes;
        },

        folioFiscal() {
            return this.$store.state.addendaVw.folioFiscal;
        },

        ordenDeCompraG() {
            return this.$store.state.addendaVw.ordenCompra;
        },

    },
    created() {

    },
    methods: {
        async Conciliar() {
            this.$store.state.addendaVw.partes = [];
            let comprobante = await this.GetComprobante()
            let ordenDeCompra = comprobante.oc;
            let conceptos = comprobante.conceptos;
            let pos = 1;
            for (let c of conceptos) {
                let textoPlano = c.descripcion.replace(/<\/?[^>]+(>|$)/g, "");
                let descripcion = textoPlano.substring(0, 40);
                let medida = c.unidad.substring(0, 3);
                let objConcepto = {
                    codigoImpuesto: { clave: "1A", descripcion: "1A | IVA 16%" },
                    posicion: pos,
                    descripcionMaterial: descripcion.trim(),
                    cantidadMaterial: c.cantidad,
                    unidadMedida: medida.trim(),
                    precioUnitario: c.valorUnitario,
                    montoLinea: c.importe,
                    numeroMaterial: "",
                    pesoBruto: 0,
                    pesoNeto: 0,
                    ordenCompra: ordenDeCompra.trim(),
                }
                pos++
                this.$store.state.addendaVw.partes.push(objConcepto);
            }
        },

        async GetComprobante() {
            try {
                this.$q.loading.show({
                    spinner: QSpinnerCube,
                    spinnerColor: 'primary',
                    spinnerSize: 140,
                    message: 'Consultando, esprere..',
                })
                let folioFiscal = this.folioFiscal;
                let response = await axios.get('Comprobantes/GetComprobanteAsync/erp_' + this.token.rfc + '/comprobantes_emitidos/' + folioFiscal);
                let x = response.data;
                this.$q.loading.hide()
                return x;
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
            }
        },

        DeleteArchivo(item) {
            let lista = this.$store.state.addendaVw.partes;
            let indice = lista.findIndex(obj => obj.nombre === item.nombre);
            if (indice !== -1) {
                lista.splice(indice, 1);
            }

            let posicion = 1;
            for (let p of this.$store.state.addendaVw.partes) {
                p.posicion = posicion;
                posicion++;
            }
        },

        PushParte() {
            let parte = { ...this.parteLocal };
            if (parte.descripcionMaterial === "") {
                this.$q.notify({
                    type: 'warning', message: 'Ingrese la descripción del material', actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }
            if (parte.cantidadMaterial === "") {
                this.$q.notify({
                    type: 'warning', message: 'Ingrese la cantidad de material', actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }
            if (parte.unidadMedida === "") {
                this.$q.notify({
                    type: 'warning', message: 'Ingrese la unidad de medida', actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }
            if (parte.precioUnitario === "") {
                this.$q.notify({
                    type: 'warning', message: 'Ingrese el precio unitario', actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }
            if (parte.precioUnitario === "0") {
                this.$q.notify({
                    type: 'warning', message: 'Ingrese el precio unitario', actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }
            if (parte.montoLinea === "") {
                this.$q.notify({
                    type: 'warning', message: 'Ingrese el monto de la linea', actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }
            if (parte.montoLinea === "0") {
                this.$q.notify({
                    type: 'warning', message: 'Ingrese el monto de la linea', actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }
            if (parte.ordenCompra === "") {
                this.$q.notify({
                    type: 'warning', message: 'Ingrese la orden de compra', actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            this.$store.state.addendaVw.partes.push(parte);

            this.parteLocal.codigoImpuesto = { clave: "1A", descripcion: "1A | IVA 16%" };
            this.parteLocal.posicion = 0;
            this.parteLocal.descripcionMaterial = "";
            this.parteLocal.cantidadMaterial = 0;
            this.parteLocal.unidadMedida = "";
            this.parteLocal.precioUnitario = 0;
            this.parteLocal.montoLinea = 0;
            this.parteLocal.numeroMaterial = "";
            this.parteLocal.pesoBruto = 0;
            this.parteLocal.pesoNeto = 0;

            let posicion = 1;
            for (let p of this.$store.state.addendaVw.partes) {
                p.posicion = posicion;
                posicion++;
            }

        },
    }
}
</script>