<template>
  <div class="row q-col-gutter-sm">
    <!-- DIALOG DOCUMENTOS RELACIONADOS -->
    <q-dialog v-model="dialogDocumentoRelacionado" transition-show="scale" transition-hide="scale" maximized>
      <q-card>
        <q-toolbar>
          <q-btn push color="red-10" icon="mdi-close-circle-outline" round flat size="18px" padding="xs" v-close-popup>
            <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
              :offset="[10, 10]">Cerrar</q-tooltip>
          </q-btn>
          <q-toolbar-title> SELECCIONE SU COMPROBANTE </q-toolbar-title>
        </q-toolbar>
        <q-separator class="full-width" color="primary" inset size="4px" />
        <q-card-section>
          <div class="row q-col-gutter-xs">
            <div class="col-12">
              <q-table title="Documentos Relacionados" :data="doctoRelacionadoBusqueda" :columns="columnsBusqueda"
                row-key="item" :rows-per-page-options="[10]" class="my-sticky-column-table" dense
                :filter="filterDoctoRelacionado">
                <template v-slot:top-right>
                  <q-input borderless dense debounce="300" v-model="filterDoctoRelacionado" placeholder="Buscar">
                    <template v-slot:append>
                      <q-icon name="search" />
                    </template>
                  </q-input>
                </template>
                <template v-slot:body="props">
                  <q-tr :props="props">
                    <q-td auto-width>
                      <q-btn size="md" color="green-10" rounded flat dense @click="OpenDialogRegistraPago(props.row)"
                        icon="mdi-currency-usd">
                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                          content-style="font-size: 14px" :offset="[10, 10]">Registrar pago</q-tooltip>
                      </q-btn>
                    </q-td>
                    <q-td key="idDocumento" :props="props">{{
      props.row.idDocumento
    }}</q-td>
                    <q-td key="serie" :props="props">{{
      props.row.serie
    }}</q-td>
                    <q-td key="folio" :props="props">{{
      props.row.folio
    }}</q-td>
                    <q-td key="monedaDR" :props="props">{{
      props.row.monedaDR.moneda
    }}</q-td>
                    <q-td key="numParcialidad" :props="props">{{
      props.row.numParcialidad
    }}</q-td>
                    <q-td key="impSaldoInsoluto" :props="props">{{
      FormatCurrency(props.row.impSaldoInsoluto)
    }}</q-td>
                  </q-tr>
                </template>
              </q-table>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <!-- DIALOG DEL PAGO -->
    <q-dialog v-model="dialogPago" transition-show="scale" transition-hide="scale">
      <q-card>
        <q-toolbar>
          <q-btn push color="red-10" icon="mdi-close-circle-outline" round flat size="18px" padding="xs" v-close-popup>
            <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
              :offset="[10, 10]">Cerrar</q-tooltip>
          </q-btn>
          <q-toolbar-title> PAGO </q-toolbar-title>
        </q-toolbar>
        <q-separator class="full-width" color="primary" inset size="4px" />
        <q-card-section>
          <div class="row q-col-gutter-xs">
            <!-- ID DOCUMENTO RELACIONADO -->
            <div class="col-12">
              <q-input outlined v-model="doctoRelacionado.idDocumento" label="Id documento relacionado" dense
                readonly />
            </div>
            <!-- SERIE -->
            <div class="col-12 col-md-6">
              <q-input outlined v-model="doctoRelacionado.serie" label="Serie" dense readonly />
            </div>
            <!-- FOLIO -->
            <div class="col-12 col-md-6">
              <q-input outlined v-model="doctoRelacionado.folio" label="Folio" dense readonly />
            </div>
            <!-- OBJETO DE IMPUESTOS DR -->
            <div class="col-12" v-if="doctoRelacionado.objetoImpDR">
              <q-input outlined v-model="doctoRelacionado.objetoImpDR.objImp" label="Objeto Imp DR" dense readonly />
            </div>
            <!-- IMPORTE SALDO ANTERIOR -->
            <div class="col-12" v-if="doctoRelacionado.monedaDR">
              <q-field v-model="doctoRelacionado.impSaldoAnt" label="Importe saldo anterior" dense outlined readonly
                :suffix="doctoRelacionado.monedaDR.clave">
                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                  <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                    v-show="floatingLabel" v-bind="numberFormat" readonly></vue-number>
                </template>
              </q-field>
            </div>
            <!-- IMPORTE SALDO INSOLUTO -->
            <div class="col-12" v-if="doctoRelacionado.monedaDR">
              <q-field v-model="doctoRelacionado.impSaldoInsoluto" label="Importe saldo insoluto" dense outlined
                :suffix="doctoRelacionado.monedaDR.clave" reverse-fill-mask readonly>
                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                  <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                    v-show="floatingLabel" v-bind="numberFormat" readonly></vue-number>
                </template>
                <template v-slot:before>
                  <q-btn round dense flat icon="mdi-plus" @click="AgregarInsoluto" />
                </template>
              </q-field>
            </div>
            <!-- NUM PARCIALIDAD -->
            <div class="col-12 col-md-4">
              <q-input outlined v-model="doctoRelacionado.numParcialidad" label="Num parcialidad" dense
                input-class="text-right" />
            </div>
            <!-- EQUIVALENCIA DR -->
            <div class="col-12 col-md-4">
              <q-field v-model="doctoRelacionado.equivalenciaDR" label="Equivalencia DR" dense outlined>
                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                  <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                    v-show="floatingLabel" v-bind="numberFormatTipoCambio"></vue-number>
                </template>
              </q-field>
            </div>
            <!-- IMPORTE PAGADO -->
            <div class="col-12 col-md-4" v-if="doctoRelacionado.monedaDR">
              <q-field v-model="doctoRelacionado.impPagado" label="Importe pagado" dense outlined
                :suffix="doctoRelacionado.monedaDR.clave" @input="CalculaInsoluto">
                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                  <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                    v-show="floatingLabel" v-bind="numberFormat"></vue-number>
                </template>
              </q-field>
            </div>
            <!-- BOTON PARA AGREGAR EL PAGO -->
            <div class="col-12">
              <q-btn color="green-10" class="full-width" @click="PushPago">
                <div class="text-center">Agregar pago</div>
              </q-btn>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <!-- TOTALES -->
    <q-linear-progress size="40px" :value="100" color="primary" class="q-mt-sm">
      <div class="absolute-full flex flex-center">
        <q-badge color="primary" text-color="white" label="TOTALES" class="text-h5" />
      </div>
    </q-linear-progress>
    <!-- TOTAL RETENCIONES IVA -->
    <div class="col-12 col-md-4">
      <q-field v-model="comprobante.totales.totalRetencionesIVA" label="Total retenciones IVA (MXN)" dense outlined
        readonly>
        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
          <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
            v-show="floatingLabel" v-bind="numberFormat"></vue-number>
        </template>
      </q-field>
    </div>
    <!-- TOTAL RETENCIONES ISR -->
    <div class="col-12 col-md-4">
      <q-field v-model="comprobante.totales.totalRetencionesISR" label="Total retenciones ISR (MXN)" dense outlined
        readonly>
        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
          <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
            v-show="floatingLabel" v-bind="numberFormat"></vue-number>
        </template>
      </q-field>
    </div>
    <!-- TOTAL RETENCIONES IEPS -->
    <div class="col-12 col-md-4">
      <q-field v-model="comprobante.totales.totalRetencionesIEPS" label="Total retenciones ISR (MXN)" dense outlined
        readonly>
        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
          <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
            v-show="floatingLabel" v-bind="numberFormat"></vue-number>
        </template>
      </q-field>
    </div>
    <!-- TOTAL TRASLADOS BASE IVA 16 -->
    <div class="col-12 col-md-6">
      <q-field v-model="comprobante.totales.totalTrasladosBaseIVA16" label="Total base IVA 16% (MXN)" dense outlined
        readonly>
        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
          <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
            v-show="floatingLabel" v-bind="numberFormat"></vue-number>
        </template>
      </q-field>
    </div>
    <!-- TOTAL TRASLADOS IMPUESTO IVA 16 -->
    <div class="col-12 col-md-6">
      <q-field v-model="comprobante.totales.totalTrasladosImpuestoIVA16" label="Total IVA 16% (MXN)" dense outlined
        readonly>
        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
          <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
            v-show="floatingLabel" v-bind="numberFormat"></vue-number>
        </template>
      </q-field>
    </div>
    <!-- TOTAL TRASLADO BASE IVA 8 -->
    <div class="col-12 col-md-6">
      <q-field v-model="comprobante.totales.totalTrasladosBaseIVA8" label="Total base IVA 8% (MXN)" dense outlined
        readonly>
        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
          <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
            v-show="floatingLabel" v-bind="numberFormat"></vue-number>
        </template>
      </q-field>
    </div>
    <!-- TOTAL TRASLADO IMPUESTO IVA 8 -->
    <div class="col-12 col-md-6">
      <q-field v-model="comprobante.totales.totalTrasladosImpuestoIVA8" label="Total IVA 8% (MXN)" dense outlined
        readonly>
        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
          <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
            v-show="floatingLabel" v-bind="numberFormat"></vue-number>
        </template>
      </q-field>
    </div>
    <!-- TOTAL TRASLADO IMPUESTO IVA 0 -->
    <div class="col-12 col-md-6">
      <q-field v-model="comprobante.totales.totalTrasladosBaseIVA0" label="Total base IVA 0% (MXN)" dense outlined
        readonly>
        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
          <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
            v-show="floatingLabel" v-bind="numberFormat"></vue-number>
        </template>
      </q-field>
    </div>
    <!-- TOTAL TRASLADO IMPUESTO IVA 0 -->
    <div class="col-12 col-md-6">
      <q-field v-model="comprobante.totales.totalTrasladosImpuestoIVA0" label="Total IVA 0% (MXN)" dense outlined
        readonly>
        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
          <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
            v-show="floatingLabel" v-bind="numberFormat"></vue-number>
        </template>
      </q-field>
    </div>
    <!-- TOTAL TRASLADO BASE IVA EXENTO -->
    <div class="col-12">
      <q-field v-model="comprobante.totales.totalTrasladosBaseIVAExento" label="Total base IVA exento (MXN)" dense
        outlined readonly>
        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
          <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
            v-show="floatingLabel" v-bind="numberFormat"></vue-number>
        </template>
      </q-field>
    </div>
    <!-- MONTO TOTAL PAGOS  -->
    <div class="col-12">
      <q-field v-model="comprobante.totales.montoTotalPagos" label="Monto total pagos (MXN)" dense outlined readonly>
        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
          <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
            v-show="floatingLabel" v-bind="numberFormat"></vue-number>
        </template>
      </q-field>
    </div>
    <!-- PAGO -->
    <q-linear-progress size="40px" :value="100" color="primary" class="q-mt-sm">
      <div class="absolute-full flex flex-center">
        <q-badge color="primary" text-color="white" label="PAGO" class="text-h5" />
      </div>
    </q-linear-progress>
    <!-- FECHA DE PAGO -->
    <div class="col-12 col-md-6">
      <datetime v-model="comprobante.pagos[0].fechaPago" type="datetime" :phrases="{ ok: 'Ok', cancel: 'Cancelar' }"
        :format="{
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    }" value-zone="America/Mexico_City" class="custom-datetime"></datetime>
    </div>
    <!-- FORMA DE PAGO -->
    <div class="col-12 col-md-6">
      <q-select v-model="comprobante.pagos[0].formaDePagoP" label="Forma de Pago" :options="optionsFormaPago" dense
        outlined option-label="formaPago">
      </q-select>
    </div>
    <!-- MONEDA -->
    <div class="col-12 col-md-4">
      <q-select v-model="comprobante.pagos[0].monedaP" use-input input-debounce="0" label="Moneda P"
        :options="optionsMoneda" @filter="filterMoneda" fill-input hide-selected :loading="loadingMoneda" dense outlined
        option-label="moneda">
        <template v-slot:no-option>
          <q-item>
            <q-item-section class="text-grey">
              No existe algúna moneda que coincida con los criterios
            </q-item-section>
          </q-item>
        </template>
      </q-select>
    </div>
    <!-- TIPO DE CAMBIO P -->
    <div class="col-12 col-md-4">
      <q-field v-model="comprobante.pagos[0].tipoCambioP" label="Tipo de cambio P" dense outlined>
        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
          <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
            v-show="floatingLabel" v-bind="numberFormatTipoCambio"></vue-number>
        </template>
      </q-field>
    </div>
    <!-- MONTO -->
    <div class="col-12 col-md-4">
      <q-field v-model="comprobante.pagos[0].monto" label="Monto" dense outlined readonly>
        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
          <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
            v-show="floatingLabel" v-bind="numberFormat"></vue-number>
        </template>
      </q-field>
    </div>
    <!-- NUMERO DE OPERACION -->
    <div class="col-12 col-md-3">
      <q-input outlined v-model="comprobante.pagos[0].numOperacion" label="Número de operación (Opcional)" dense />
    </div>
    <!-- RFC EMISOR CUENTA ORDENANTE -->
    <div class="col-12 col-md-3">
      <q-input outlined v-model="comprobante.pagos[0].rfcEmisorCtaOrd" label="RFC emisor cuenta ordenante (Opcional)"
        dense />
    </div>
    <!-- NOMBRE BANCO CUENTA ORDENANTE EXT -->
    <div class="col-12 col-md-3">
      <q-input outlined v-model="comprobante.pagos[0].nomBancoOrdExt"
        label="Nombre banco ordenante extranjero(Opcional)" dense />
    </div>
    <!-- CUENTA ORDENANTE -->
    <div class="col-12 col-md-3">
      <q-input outlined v-model="comprobante.pagos[0].ctaOrdenante" label="Cuenta ordenante(Opcional)" dense />
    </div>
    <!-- RFC EMISOR CUENTA ORDENANTE -->
    <div class="col-12 col-md-4">
      <q-input outlined v-model="comprobante.pagos[0].rfcEmisorCtaBen" label="RFC emisor cuenta beneficiario(Opcional)"
        dense />
    </div>
    <!-- NOMBRE BANCO CUENTA ORDENANTE EXT -->
    <div class="col-12 col-md-4">
      <q-input outlined v-model="comprobante.pagos[0].ctaBeneficiario"
        label="Nombre banco cuenta ordenate extranjero(Opcional)" dense />
    </div>
    <!-- TIPO CADENA DE PAGO -->
    <div class="col-12 col-md-4">
      <q-select v-model="comprobante.pagos[0].tipoCadPago" label="Tipo cad pago(Opcional)" :options="optionsTipoCadPago"
        dense outlined option-label="tipoCadPago">
        <template v-slot:append>
          <q-icon name="close" @click="comprobante.pagos[0].tipoCadPago = null" class="cursor-pointer" />
        </template>
      </q-select>
    </div>
    <!-- VALIDAMOS SI TIENE TIPO CAD PAGO -->
    <template v-if="comprobante.pagos[0].tipoCadPago">
      <!-- CERTIFICADO DEL PAGO -->
      <div class="col-12">
        <q-input outlined v-model="comprobante.pagos[0].certPago" label="Certificado del pago(Opcional)" dense />
      </div>
      <!-- CADENA DEL PAGO -->
      <div class="col-12">
        <q-input outlined v-model="comprobante.pagos[0].cadPago" label="Cadena del pago(Opcional)" dense />
      </div>
      <!-- SELLO DEL PAGO -->
      <div class="col-12">
        <q-input outlined v-model="comprobante.pagos[0].selloPago" label="Sello del pago(Opcional)" dense />
      </div>
    </template>
    <!-- TABLA DE DOCUMENTOS RELACIONADOS -->
    <div class="col-12">
      <q-table title="Documentos Relacionados" :data="comprobante.pagos[0].doctoRelacionados" :columns="columns"
        row-key="item" :rows-per-page-options="[10]" class="my-sticky-column-table" dense>
        <template v-slot:top-right>
          <q-btn push color="white" text-color="primary" round icon="mdi-plus" @click="openDialogDocumentoRelacionado">
            <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
              :offset="[10, 10]">Agregar Comprobante</q-tooltip>
          </q-btn>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td auto-width>
              <q-btn size="md" color="primary" rounded flat dense @click="EliminaDocumentoRelacionado(props.row)"
                icon="mdi-delete">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                  :offset="[10, 10]">Eliminar</q-tooltip>
              </q-btn>
            </q-td>
            <q-td key="idDocumento" :props="props">{{
      props.row.idDocumento
    }}</q-td>
            <q-td key="serie" :props="props">{{ props.row.serie }}</q-td>
            <q-td key="folio" :props="props">{{ props.row.folio }}</q-td>
            <q-td key="monedaDR" :props="props">{{
      props.row.monedaDR.clave
    }}</q-td>
            <q-td key="equivalenciaDR" :props="props">{{
      FormatCurrency(props.row.equivalenciaDR)
    }}</q-td>
            <q-td key="numParcialidad" :props="props">{{
      props.row.numParcialidad
    }}</q-td>
            <q-td key="impSaldoAnt" :props="props">{{
      FormatCurrency(props.row.impSaldoAnt)
              }}</q-td>
            <q-td key="impPagado" :props="props">{{
              FormatCurrency(props.row.impPagado)
              }}</q-td>
            <q-td key="impSaldoInsoluto" :props="props">{{
              FormatCurrency(props.row.impSaldoInsoluto)
              }}</q-td>
            <q-td key="objetoImpDR" :props="props">{{
              props.row.objetoImpDR.objImp
              }}</q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import * as XLSX from "xlsx";
import { format } from "date-fns";
import { parse } from "date-fns";
import { endOfMonth } from "date-fns";
import { es } from "date-fns/locale";
import { QSpinnerCube } from "quasar";
import moment from "moment";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { component as VueNumber } from "@coders-tm/vue-number-format";

export default {
  components: {
    Datetime,
    VueNumber,
  },
  data() {
    return {
      numberFormat: {
        decimal: ".",
        separator: ",",
        prefix: "",
        precision: 2,
        masked: false,
        minimumFractionDigits: 2,
      },
      numberFormatTipoCambio: {
        decimal: ".",
        separator: ",",
        prefix: "",
        precision: 6,
        masked: false,
        minimumFractionDigits: 6,
      },

      doctoRelacionado: {
        idDocumento: "",
        serie: "",
        folio: "",
        monedaDR: null,
        equivalenciaDR: 0,
        numParcialidad: "",
        impSaldoAnt: 0,
        impPagado: 0,
        impSaldoInsoluto: 0,
        objetoImpDR: null,
        impuestosDR: {
          retencionesDr: [],
          trasladosDr: [],
        },
      },
      totalFactura: 0,

      // MONEDA
      optionsMoneda: [],
      loadingMoneda: false,

      // DOCUMENTOS RELACIONADOS
      columns: [
        { name: "actions", align: "left", label: "Acciones", field: "actions" },
        {
          name: "idDocumento",
          align: "left",
          label: "Id documento",
          field: "idDocumento",
          sortable: true,
        },
        {
          name: "serie",
          align: "left",
          label: "Serie",
          field: "serie",
          sortable: true,
        },
        {
          name: "folio",
          align: "left",
          label: "Folio",
          field: "folio",
          sortable: true,
        },
        {
          name: "monedaDR",
          align: "left",
          label: "Moneda Dr",
          field: "monedaDR",
          sortable: true,
        },
        {
          name: "equivalenciaDR",
          align: "left",
          label: "Equivalencia Dr",
          field: "equivalenciaDR",
          sortable: true,
        },
        {
          name: "numParcialidad",
          align: "left",
          label: "Num parcialidad",
          field: "numParcialidad",
          sortable: true,
        },
        {
          name: "impSaldoAnt",
          align: "right",
          label: "Imp saldo anterior",
          field: "impSaldoAnt",
          sortable: true,
        },
        {
          name: "impPagado",
          align: "right",
          label: "Imp pagado",
          field: "impPagado",
          sortable: true,
        },
        {
          name: "impSaldoInsoluto",
          align: "right",
          label: "Imp saldo insoluto",
          field: "impSaldoInsoluto",
          sortable: true,
        },
        {
          name: "objetoImpDR",
          align: "left",
          label: "Objeto de impuestos Dr",
          field: "objetoImpDR",
          sortable: true,
        },
      ],
      columnsBusqueda: [
        { name: "actions", align: "left", label: "Acciones", field: "actions" },
        {
          name: "idDocumento",
          align: "left",
          label: "Id documento",
          field: "idDocumento",
          sortable: true,
        },
        {
          name: "serie",
          align: "left",
          label: "Serie",
          field: "serie",
          sortable: true,
        },
        {
          name: "folio",
          align: "left",
          label: "Folio",
          field: "folio",
          sortable: true,
        },
        {
          name: "monedaDR",
          align: "left",
          label: "Moneda Dr",
          field: "monedaDR",
          sortable: true,
        },
        {
          name: "numParcialidad",
          align: "left",
          label: "Num pagos previos",
          field: "numParcialidad",
          sortable: true,
        },
        {
          name: "impSaldoInsoluto",
          align: "right",
          label: "Imp saldo insoluto",
          field: "impSaldoInsoluto",
          sortable: true,
        },
      ],
      dialogDocumentoRelacionado: false,
      filterDoctoRelacionado: "",
      doctoRelacionadoBusqueda: [],

      //PAGO
      dialogPago: false,
    };
  },
  watch: {},
  computed: {
    token() {
      return this.$store.state.usuario;
    },

    comprobante() {
      return this.$store.state.comprobanteStore.comprobante.pago;
    },

    optionsFormaPago() {
      return this.$store.state.formaPagoStore;
    },

    optionsMonedaDefault() {
      return this.$store.state.monedaStore;
    },

    optionsTipoCadPago() {
      return this.$store.state.listaTipoCadPagoStore;
    },

    impSaldoInsoluto() {
      return 0;
    },
  },
  created() { },
  methods: {
    filterMoneda(val, update) {
      let stringOptions = this.optionsMonedaDefault;
      if (val === "") {
        update(() => {
          this.optionsMoneda = stringOptions;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.optionsMoneda = stringOptions.filter(
          (v) => v.moneda.toLowerCase().indexOf(needle) > -1
        );
      });
    },

    FormatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },

    //DOCUMENTOS RELACIPNADOS
    async GetDocumentosRelacionados() {
      this.$q.loading.show({
        spinner: QSpinnerCube,
        spinnerColor: "red-8",
        spinnerSize: 140,
        message: "Consultando comprobantes pendientes de pago...",
      });
      try {
        let nombre =
          this.$store.state.comprobanteStore.comprobante.receptor.nombre;
        let response = await axios.get(
          "Comprobantes/GetPagosPendientesAsync/" +
          this.token.empresaBase +
          "/" +
          nombre
        );
        let lista = [...response.data];
        let resultado = lista.filter(
          (f) =>
            !this.comprobante.pagos[0].doctoRelacionados.some(
              (s) => f.idDocumento === s.idDocumento
            )
        );
        this.doctoRelacionadoBusqueda = resultado;
        this.$q.loading.hide();
      } catch (error) {
        console.log(error);
        this.$q.loading.hide();
      }
    },

    async openDialogDocumentoRelacionado() {
      await this.GetDocumentosRelacionados();
      this.dialogDocumentoRelacionado = true;
    },

    EliminaDocumentoRelacionado(item) {
      //BORRAMOS EL FOLIO FISCAL AGREGADO
      let indice = this.comprobante.pagos[0].doctoRelacionados.findIndex(
        (f) => f.idDocumento === item.idDocumento
      );
      console.log(indice);
      this.comprobante.pagos[0].doctoRelacionados.splice(indice, 1);

      // VALIDAMOS EL TIPO DE CAMBIO Y LA EQUIVALENCIA
      var tipoCambioP = parseFloat(this.comprobante.pagos[0].tipoCambioP);
      if (tipoCambioP == 0) {
        tipoCambioP = 1;
      }
      var equivalenciaDr = parseFloat(item.equivalenciaDR);
      if (equivalenciaDr == 0) {
        equivalenciaDr = 1;
      }

      //CALCULAMOS
      let trasladados = [];
      let retenidos = [];
      this.comprobante.pagos[0].impuestosP.retencionesP = [];
      this.comprobante.pagos[0].impuestosP.trasladosP = [];

      for (let c of this.comprobante.pagos[0].doctoRelacionados) {
        for (let t of c.impuestosDR.trasladosDr) {
          trasladados.push(t);
        }

        for (let r of c.impuestosDR.retencionesDr) {
          retenidos.push(r);
        }
      }

      this.comprobante.pagos[0].impuestosP.trasladosP = [
        ...this.AgrupaYCalculaImpuestos(trasladados),
      ];

      this.comprobante.pagos[0].impuestosP.retencionesP = [
        ...this.AgrupaYCalculaImpuestos(retenidos),
      ];

      //CALCULAMOS LOS TOTALES
      let isrR = 0;
      let ivaR = 0;
      let iepsR = 0;

      let baseIva16 = 0;
      let iva16 = 0;
      let baseIva8 = 0;
      let iva8 = 0;
      let baseIva0 = 0;
      let iva0 = 0;
      let baseExento = 0;
      let montoTotal = 0;
      let monto = 0;

      let listaPagos = this.comprobante.pagos[0];
      listaPagos.doctoRelacionados.forEach((e) => {
        let m = (e.impPagado / e.equivalenciaDR).toFixed(2);
        monto += parseFloat(m);
      });
      monto = monto.toFixed(2);
      montoTotal = (monto * tipoCambioP).toFixed(2);
      this.comprobante.pagos[0].monto = parseFloat(monto);
      this.comprobante.totales.montoTotalPagos = parseFloat(montoTotal);

      console.log(listaPagos.impuestosP);
      listaPagos.impuestosP.retencionesP.forEach((r) => {
        if (r.impuestoP === "001") {
          isrR = r.importeP;
        } else if (r.impuestoP === "002") {
          ivaR = r.importeP;
        } else if (r.impuestoP === "003") {
          iepsR = r.importeP;
        }
      });

      isrR = (isrR * tipoCambioP).toFixed(2);
      ivaR = (ivaR * tipoCambioP).toFixed(2);
      iepsR = (iepsR * tipoCambioP).toFixed(2);

      listaPagos.impuestosP.trasladosP.forEach((t) => {
        if (t.tipoFactorP === "Tasa") {
          if (t.tasaOCuotaP == 0.16) {
            iva16 = t.importeP;
            baseIva16 = t.baseP;
          } else if (t.tasaOCuotaP == 0.08) {
            iva8 = t.importeP;
            baseIva8 = t.baseP;
          } else if (t.tasaOCuotaP == 0) {
            iva0 = t.importeP;
            baseIva0 = t.baseP;
          }
        } else if (t.tipoFactorP === "Exento") {
          baseExento = t.baseP;
        }
      });

      baseIva16 = (baseIva16 * tipoCambioP).toFixed(2);
      iva16 = (iva16 * tipoCambioP).toFixed(2);
      baseIva8 = (baseIva8 * tipoCambioP).toFixed(2);
      iva8 = (iva8 * tipoCambioP).toFixed(2);
      baseIva0 = (baseIva0 * tipoCambioP).toFixed(2);
      iva0 = (iva0 * tipoCambioP).toFixed(2);
      baseExento = (baseExento * tipoCambioP).toFixed(2);

      this.comprobante.totales.totalRetencionesISR = parseFloat(isrR);
      this.comprobante.totales.totalRetencionesIVA = parseFloat(ivaR);
      this.comprobante.totales.totalRetencionesIEPS = parseFloat(iepsR);
      this.comprobante.totales.totalTrasladosBaseIVA16 = parseFloat(baseIva16);
      this.comprobante.totales.totalTrasladosImpuestoIVA16 = parseFloat(iva16);
      this.comprobante.totales.totalTrasladosBaseIVA8 = parseFloat(baseIva8);
      this.comprobante.totales.totalTrasladosImpuestoIVA8 = parseFloat(iva8);
      this.comprobante.totales.totalTrasladosBaseIVA0 = parseFloat(baseIva0);
      this.comprobante.totales.totalTrasladosImpuestoIVA0 = parseFloat(iva0);
      this.comprobante.totales.totalTrasladosBaseIVAExento = parseFloat(baseExento);
    },

    OpenDialogRegistraPago(item) {
      this.dialogPago = true;
      this.totalFactura = item.impSaldoInsoluto;
      let insoluto = item.impSaldoAnt;
      let equivalenciaDR = 1;
      let numParcialidad = Number(item.numParcialidad) + 1;

      let doctoRelacionado = {
        idDocumento: item.idDocumento,
        serie: item.serie,
        folio: item.folio,
        monedaDR: item.monedaDR,
        equivalenciaDR: equivalenciaDR,
        numParcialidad: numParcialidad,
        impSaldoAnt: item.impSaldoAnt,
        impPagado: 0,
        impSaldoInsoluto: insoluto,
        objetoImpDR: item.objetoImpDR,
        impuestosDR: item.impuestosDR,
      };
      this.doctoRelacionado = { ...doctoRelacionado };
    },

    CloseDialogRegistraPago() {
      this.dialogPago = false;
    },

    PushPago() {
      //VALIDAMOS
      const impSaldoAnt = parseFloat(this.doctoRelacionado.impSaldoAnt);
      const impSaldoInsoluto = parseFloat(
        this.doctoRelacionado.impSaldoInsoluto
      );
      const impPagado = parseFloat(this.doctoRelacionado.impPagado);

      if (impSaldoInsoluto < 0) {
        this.$q.notify({
          type: "warning",
          message: `El importe pagado, no puede ser mayor al importe del saldo anterior`,
          actions: [{ label: "Cerrar", color: "white", handler: () => { } }],
        });
        return;
      }
      if (impPagado <= 0) {
        this.$q.notify({
          type: "warning",
          message: `El importe pagado debe ser mayor a 0`,
          actions: [{ label: "Cerrar", color: "white", handler: () => { } }],
        });
        return;
      }

      // VALIDAMOS EL TIPO DE CAMBIO Y LA EQUIVALENCIA
      var tipoCambioP = parseFloat(this.comprobante.pagos[0].tipoCambioP);
      if (tipoCambioP == 0) {
        tipoCambioP = 1;
      }
      var equivalenciaDr = parseFloat(this.doctoRelacionado.equivalenciaDR);
      if (equivalenciaDr == 0) {
        equivalenciaDr = 1;
      }

      const facturado = parseFloat(this.totalFactura);
      var porcentaje = impPagado / facturado;
      porcentaje = parseFloat(porcentaje.toFixed(6))

      //AGREGAMOS
      this.doctoRelacionado.impSaldoAnt = parseFloat(impSaldoAnt);
      this.doctoRelacionado.impSaldoInsoluto = parseFloat(impSaldoInsoluto);
      this.doctoRelacionado.impPagado = parseFloat(impPagado);
      this.doctoRelacionado.equivalenciaDR = parseFloat(equivalenciaDr);
      this.comprobante.pagos[0].doctoRelacionados.push(this.doctoRelacionado);

      //CALCULAMOS
      let trasladados = [];
      let retenidos = [];
      this.comprobante.pagos[0].impuestosP.retencionesP = [];
      this.comprobante.pagos[0].impuestosP.trasladosP = [];

      for (let c of this.comprobante.pagos[0].doctoRelacionados) {
        for (let t of c.impuestosDR.trasladosDr) {
          trasladados.push(t);
        }

        for (let r of c.impuestosDR.retencionesDr) {
          retenidos.push(r);
        }
      }
      this.comprobante.pagos[0].impuestosP.trasladosP = [
        ...this.AgrupaYCalculaImpuestos(trasladados),
      ];
      this.comprobante.pagos[0].impuestosP.retencionesP = [
        ...this.AgrupaYCalculaImpuestos(retenidos),
      ];

      //CALCULAMOS LOS TOTALES
      let isrR = 0;
      let ivaR = 0;
      let iepsR = 0;

      let baseIva16 = 0;
      let iva16 = 0;
      let baseIva8 = 0;
      let iva8 = 0;
      let baseIva0 = 0;
      let iva0 = 0;
      let baseExento = 0;
      let montoTotal = 0;
      let monto = 0;

      let listaPagos = this.comprobante.pagos[0];
      listaPagos.doctoRelacionados.forEach((e) => {
        let m = (e.impPagado / e.equivalenciaDR).toFixed(2);
        monto += parseFloat(m);
      });
      monto = monto.toFixed(2);
      montoTotal = (monto * tipoCambioP).toFixed(2);
      this.comprobante.pagos[0].monto = parseFloat(monto);
      this.comprobante.totales.montoTotalPagos = parseFloat(montoTotal);

      console.log(listaPagos.impuestosP);
      listaPagos.impuestosP.retencionesP.forEach((r) => {
        if (r.impuestoP === "001") {
          isrR = r.importeP;
        } else if (r.impuestoP === "002") {
          ivaR = r.importeP;
        } else if (r.impuestoP === "003") {
          iepsR = r.importeP;
        }
      });

      isrR = (isrR * tipoCambioP).toFixed(2);
      ivaR = (ivaR * tipoCambioP).toFixed(2);
      iepsR = (iepsR * tipoCambioP).toFixed(2);

      listaPagos.impuestosP.trasladosP.forEach((t) => {
        if (t.tipoFactorP === "Tasa") {
          if (t.tasaOCuotaP == 0.16) {
            iva16 = t.importeP;
            baseIva16 = t.baseP;
          } else if (t.tasaOCuotaP == 0.08) {
            iva8 = t.importeP;
            baseIva8 = t.baseP;
          } else if (t.tasaOCuotaP == 0) {
            iva0 = t.importeP;
            baseIva0 = t.baseP;
          }
        } else if (t.tipoFactorP === "Exento") {
          baseExento = t.baseP;
        }
      });

      baseIva16 = (baseIva16 * tipoCambioP).toFixed(2);
      iva16 = (iva16 * tipoCambioP).toFixed(2);
      baseIva8 = (baseIva8 * tipoCambioP).toFixed(2);
      iva8 = (iva8 * tipoCambioP).toFixed(2);
      baseIva0 = (baseIva0 * tipoCambioP).toFixed(2);
      iva0 = (iva0 * tipoCambioP).toFixed(2);
      baseExento = (baseExento * tipoCambioP).toFixed(2);

      this.comprobante.totales.totalRetencionesISR = parseFloat(isrR);
      this.comprobante.totales.totalRetencionesIVA = parseFloat(ivaR);
      this.comprobante.totales.totalRetencionesIEPS = parseFloat(iepsR);
      this.comprobante.totales.totalTrasladosBaseIVA16 = parseFloat(baseIva16);
      this.comprobante.totales.totalTrasladosImpuestoIVA16 = parseFloat(iva16);
      this.comprobante.totales.totalTrasladosBaseIVA8 = parseFloat(baseIva8);
      this.comprobante.totales.totalTrasladosImpuestoIVA8 = parseFloat(iva8);
      this.comprobante.totales.totalTrasladosBaseIVA0 = parseFloat(baseIva0);
      this.comprobante.totales.totalTrasladosImpuestoIVA0 = parseFloat(iva0);
      this.comprobante.totales.totalTrasladosBaseIVAExento = parseFloat(baseExento);

      //BORRAMOS EL FOLIO FISCAL AGREGADO
      let indice = this.doctoRelacionadoBusqueda.findIndex(
        (f) => f.idDocumento === this.doctoRelacionado.idDocumento
      );
      this.doctoRelacionadoBusqueda.splice(indice, 1);
      this.CloseDialogRegistraPago();
    },

    CalculaInsoluto() {
      const impSaldoAnt = parseFloat(this.doctoRelacionado.impSaldoAnt);
      const impPagado = parseFloat(this.doctoRelacionado.impPagado);
      const resultado = impSaldoAnt - impPagado;
      this.doctoRelacionado.impSaldoInsoluto = resultado;
    },

    AgregarInsoluto() {
      this.doctoRelacionado.impPagado = this.doctoRelacionado.impSaldoInsoluto;
      const impSaldoAnt = parseFloat(this.doctoRelacionado.impSaldoAnt);
      const impPagado = parseFloat(this.doctoRelacionado.impPagado);
      const resultado = impSaldoAnt - impPagado;
      this.doctoRelacionado.impSaldoInsoluto = resultado;
    },

    formatNumber(value) {
      return new Intl.NumberFormat("es", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },

    AgrupaYCalculaImpuestos(lista) {
      var agrupado = lista.reduce(function (acumulador, elemento) {
        // Crear una clave única para el grupo
        var clave =
          elemento.impuestoDr +
          "-" +
          elemento.tipoFactorDr +
          "-" +
          elemento.tasaOCuotaDr;

        // Inicializar el grupo si es la primera vez que se encuentra la clave
        if (!acumulador[clave]) {
          acumulador[clave] = {
            impuestoP: elemento.impuestoDr,
            tipoFactorP: elemento.tipoFactorDr,
            tasaOCuotaP: elemento.tasaOCuotaDr,
            baseP: 0,
            importeP: 0,
          };
        }

        // Sumar base e importe al grupo correspondiente
        acumulador[clave].baseP += parseFloat(elemento.baseDr);
        acumulador[clave].importeP += parseFloat(elemento.importeDr);

        return acumulador;
      }, {});

      // Convertir el objeto agrupado de nuevo a una lista
      var resultado = Object.values(agrupado);

      for (let r of resultado) {
        r.baseP = r.baseP.toFixed(2);
        r.importeP = r.importeP.toFixed(2);
      }
      return resultado;
    },
  },
};
</script>
