<template>
    <div>
        <q-dialog v-model="dialogConcilia" maximized transition-show="slide-up" transition-hide="slide-down">
            <div class="q-pa-md q-gutter-sm">
                <q-card class="my-card">
                    <q-toolbar>
                        <q-btn push color="red-10" icon="mdi-close-circle-outline" round flat size="18px" padding="xs"
                            v-close-popup>
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Cerrar</q-tooltip>
                        </q-btn>
                        <q-toolbar-title>
                            CONCILIAR CLIENTES
                        </q-toolbar-title>
                        <q-btn push color="green-10" icon="mdi-account-multiple-check" round flat size="18px"
                            padding="xs" @click="conciliaClientes">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Agregar clientes</q-tooltip>
                        </q-btn>
                    </q-toolbar>
                    <q-separator class="full-width" color="primary" inset size="4px" />
                    <q-card-section>
                        <span>A continuación se muestra la lista de clientes que se importaran al sistema</span>
                        <q-table title="Clientes" :loading="loadingTabla" :data="itemsConcilia"
                            :columns="columnsConcilia" row-key="rfc">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="rfc" :props="props">{{ props.row.rfc }}</q-td>
                                    <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                                    <q-td key="domicilioFiscalReceptor" :props="props">{{
            props.row.domicilioFiscalReceptor }}</q-td>
                                    <q-td key="regimenFiscalReceptor" :props="props">{{
            props.row.regimenFiscalReceptor.regimenFiscal }}</q-td>
                                    <q-td key="usoCfdi" :props="props">{{ props.row.usoCfdi.usoCfdi }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-card-section>
                </q-card>
            </div>
        </q-dialog>
        <q-dialog v-model="dialogNuevoCliente" persistent transition-show="scale" transition-hide="scale">
            <Cliente @cierraVentana="closeDialogCliente()"></Cliente>
        </q-dialog>
        <q-table title="Clientes" :loading="loadingTabla" :data="itemsClientes" :columns="columns" row-key="rfc"
            :filter="filter">
            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>
            <template v-slot:top-right>
                <q-input borderless dense debounce="300" v-model="filter" placeholder="Filtrar">
                    <template v-slot:append>
                        <q-icon name="search" />
                    </template>
                </q-input>
                <q-btn round flat color="green" icon="mdi-plus" @click="nuevoCliente()" class="q-mr-sm">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Nuevo cliente</q-tooltip>
                </q-btn>
                <q-btn round flat color="blue" icon="mdi-magnify" @click="getClientes()" class="q-mr-sm">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Consultar clientes</q-tooltip>
                </q-btn>
                <q-btn round flat color="red-10" icon="mdi-account-convert-outline" @click="openDialogConcilia()">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Conciliar clientes</q-tooltip>
                </q-btn>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width>
                        <q-btn size="md" color="blue" rounded flat dense @click="editarCliente(props.row)"
                            icon="mdi-pencil">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Editar</q-tooltip>
                        </q-btn>
                        <q-btn size="md" color="red" rounded flat dense @click="eliminarCliente(props.row)"
                            icon="mdi-delete">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Eliminar</q-tooltip>
                        </q-btn>
                    </q-td>
                    <q-td key="rfc" :props="props">{{ props.row.rfc }}</q-td>
                    <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                    <q-td key="domicilioFiscalReceptor" :props="props">{{ props.row.domicilioFiscalReceptor }}</q-td>
                    <q-td key="regimenFiscalReceptor" :props="props">{{ props.row.regimenFiscalReceptor.regimenFiscal
                        }}</q-td>
                    <q-td key="usoCfdi" :props="props">{{ props.row.usoCfdi.usoCfdi }}</q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>
<script>
import axios from 'axios'
import Cliente from './Cliente.vue'
import { QSpinnerCube } from 'quasar';

export default {
    components: { Cliente },
    data() {
        return {
            columns: [
                { name: 'actions', label: 'Acciones', field: 'actions' },
                { name: 'rfc', align: 'left', label: 'RFC', field: 'rfc', sortable: true, classes: 'bg-grey-2 ellipsis', headerClasses: 'bg-primary text-white' },
                { name: 'nombre', align: 'left', label: 'Nombre', field: 'nombre', sortable: true },
                { name: 'domicilioFiscalReceptor', align: 'left', label: 'Domicilio Fiscal', field: 'domicilioFiscalReceptor', sortable: true },
                { name: 'regimenFiscalReceptor', align: 'left', label: 'Régimen Fiscal', field: 'regimenFiscalReceptor', sortable: true },
                { name: 'usoCfdi', align: 'left', label: 'Uso del CFDI', field: 'usoCfdi', sortable: true },
            ],
            dialogNuevoCliente: false,
            loadingTabla: false,
            filter: '',

            //CONCILIACION DE CLIENTES
            dialogConcilia: false,
            itemsConcilia: [],
            columnsConcilia: [
                { name: 'rfc', align: 'left', label: 'RFC', field: 'rfc', sortable: true, classes: 'bg-grey-2 ellipsis', headerClasses: 'bg-primary text-white' },
                { name: 'nombre', align: 'left', label: 'Nombre', field: 'nombre', sortable: true },
                { name: 'domicilioFiscalReceptor', align: 'left', label: 'Domicilio Fiscal', field: 'domicilioFiscalReceptor', sortable: true },
                { name: 'regimenFiscalReceptor', align: 'left', label: 'Régimen Fiscal', field: 'regimenFiscalReceptor', sortable: true },
                { name: 'usoCfdi', align: 'left', label: 'Uso del CFDI', field: 'usoCfdi', sortable: true },
            ],
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        itemsClientes() {
            return this.$store.state.listClienteStore;
        },

        regimenFiscalDefault() {
            return this.$store.state.regimenFiscalStore;
        },

        usoCfdiDefault() {
            return this.$store.state.usoCfdiStore;
        },

        residenciaFiscalDefault() {
            return this.$store.state.listaCatalogoPais;
        },
    },
    created() {
        this.getClientes();
    },
    methods: {
        nuevoCliente() {
            this.iniciaizarObjeto();
            this.dialogNuevoCliente = true
        },

        closeDialogCliente() {
            this.dialogNuevoCliente = false
        },

        async getClientes() {
            this.loadingTabla = true
            try {
                let response = await axios.get("CatalogosComprobantes/GetReceptorAsync/" + this.token.empresaBase);
                this.$store.state.listClienteStore = response.data;
                this.loadingTabla = false
            } catch (error) {
                console.log(error);
                this.loadingTabla = false
            }
        },

        async eliminarCliente(value) {
            try {
                let response = await axios.put("Cliente/EliminarCliente/" + this.token.empresaBase + "/" + value._id);
                this.$q.notify({ type: 'positive', message: `Cliente ha sido eliminado.` })

                var index = this.itemsClientes.indexOf(value);
                this.itemsClientes.splice(index, 1);


            } catch (error) {
                console.log(error);
                this.$q.notify({ type: 'negative', message: 'Error al eliminar cliente, intentelo de nuevo.', color: 'red' })
            }
        },

        iniciaizarObjeto() {
            let clienteStore = {
                _id: '',
                rfc: '',
                nombre: '',
                domicilioFiscalReceptor: '',
                residenciaFiscal: null,
                numRegIdTrib: '',
                regimenFiscalReceptor: null,
                usoCfdi: null,
                direccion: '',
                receptor: '',
            }
            this.$store.state.clienteStore = clienteStore
        },

        editarCliente(data) {
            this.$store.state.clienteStore = { ...data }
            this.dialogNuevoCliente = true
        },

        async openDialogConcilia() {
            this.$q.loading.show({ group: 'first', message: 'Consultando los Regimenes Fiscales', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetRegimenFiscal();
            this.$q.loading.show({ group: 'second', message: 'Consultando el Uso del CFDI', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetUsoCfdi();
            this.$q.loading.show({ group: 'first', message: 'Consultando las Residencias Fiscales', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetResidenciaFiscal();
            this.$q.loading.show({ group: 'first', message: 'Consultando clientes del sistema anterior', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetClientesViejos();
            this.$q.loading.hide()
        },

        async GetClientesViejos() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando clientes...' });
            this.itemsConcilia = [];
            try {
                let response = await axios.get('Protocolos/GetReceptorAsync/erp_' + this.token.rfc);
                // let response = await axios.get('Protocolos/GetReceptorAsync/erp_dmm100728b66');
                let pre = [...response.data]

                //CONCILIAMOS QUE LOS CLIENTES NO ESTEN PREVIAMENTE DADOS DE ALTA
                let confirmados = pre.filter(f => !this.$store.state.listClienteStore.some(s => f.nombre === s.nombre));
                confirmados.forEach(e => {
                    if (e.residenciaFiscal != null) {
                        let residencia = this.residenciaFiscalDefault.find(f => f.clave === e.residenciaFiscal.clave);
                        e.residenciaFiscal = residencia;
                    }
                    if (e.regimenFiscalReceptor != null) {
                        let regimen = this.regimenFiscalDefault.find(f => f.clave === e.regimenFiscalReceptor.clave);
                        e.regimenFiscalReceptor = regimen;
                    }
                    if (e.usoCfdi != null) {
                        let usoCfdi = this.usoCfdiDefault.find(f => f.clave === e.usoCfdi.clave);
                        e.usoCfdi = usoCfdi;
                    } else {
                        let usoCfdi = this.usoCfdiDefault.find(f => f.clave === 'G03');
                        e.usoCfdi = usoCfdi;
                    }
                });
                this.itemsConcilia = [...confirmados];
                this.$q.loading.hide();
                this.dialogConcilia = true;
            } catch (error) {
                console.log(response.data);
                this.$q.loading.hide()
            }
        },

        async conciliaClientes() {
            this.$q.loading.show({ group: 'first', message: 'Guardando clientes', spinnerColor: 'amber', messageColor: 'amber' })
            for (let e of this.itemsConcilia) {
                const rfc = e.rfc.trim();
                const nombre = e.nombre.trim();
                const domicilioFiscalReceptor = e.domicilioFiscalReceptor.trim()
                const residenciaFiscal = e.residenciaFiscal;
                const numRegIdTrib = e.numRegIdTrib.trim();
                const regimenFiscalReceptor = e.regimenFiscalReceptor;
                const usoCfdi = e.usoCfdi;
                const direccion = e.direccion;

                let receptor = {
                    _id: '',
                    rfc: rfc,
                    nombre: nombre,
                    domicilioFiscalReceptor: domicilioFiscalReceptor,
                    residenciaFiscal: residenciaFiscal,
                    numRegIdTrib: numRegIdTrib,
                    regimenFiscalReceptor: regimenFiscalReceptor,
                    usoCfdi: usoCfdi,
                    direccion: direccion,
                    receptor: '',
                }
                try {
                    let response = await axios.post('CatalogosComprobantes/PostReceptorAsync/' + this.token.empresaBase, receptor)
                    receptor._id = response.data
                    this.$store.state.listClienteStore.push(receptor)
                    console.log('Guardado')
                } catch (error) {
                    console.log(error)
                }
            };
            this.$q.loading.hide()
        },

        //CATALOGOS INICIALES
        async GetRegimenFiscal() {
            if (this.$store.state.regimenFiscalStore.length == 0) {
                try {
                    let response = await axios.get("CatalogosSat/GetCatRegimenFiscalAsync/");
                    this.$store.state.regimenFiscalStore = response.data;
                } catch (error) {
                    console.log(error);
                }
            }
        },

        async GetUsoCfdi() {
            if (this.$store.state.usoCfdiStore.length == 0) {
                try {
                    let response = await axios.get("CatalogosSat/GetCatUsoCfdiAsync");
                    this.$store.state.usoCfdiStore = response.data;
                } catch (error) {
                    console.log(error);
                }
            }
        },

        async GetResidenciaFiscal() {
            if (this.$store.state.listaCatalogoPais.length == 0) {
                try {
                    let response = await axios.get("CatalogosSat/GetCatPaisAsync");
                    this.$store.state.listaCatalogoPais = response.data;
                } catch (error) {
                    console.log(error);
                }
            }
        },

    }
}
</script>