<template>
    <div>
        <div class="row q-col-gutter-sm">
            <!-- CONCEPTO -->
            <div class="col-12">
                <q-select v-model="concepto" use-input input-debounce="0" label="Concepto" :options="optionsConceptos"
                    @filter="filterConceptos" fill-input hide-selected :loading="loadingConceptos" dense outlined
                    option-label="concepto" @input="AsignaValores()">
                    <template v-slot:no-option>
                        <q-item>
                            <q-item-section class="text-grey">
                                No exixte algún concepto que coincida con los criterios
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>
            </div>

            <!-- DESCRIPCION -->
            <div class="col-12">
                <!-- <q-input outlined v-model="descripcion" label="Descripción" dense /> -->
                <form autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false">
                    <q-editor v-model="descripcion" min-height="2rem" @paste.native="evt => pasteCapture(evt)"
                        placeholder="Descripción" dense ref="editor_ref" :toolbar="[]" />
                </form>
            </div>

            <!-- MEDIDAS -->
            <div class="col-10">
                <q-input outlined v-model="unidad" label="Unidad" dense />
            </div>

            <!-- CANTIDAD -->
            <div class="col-12 col-md-2">
                <q-field v-model="cantidad" label="Cantidad" dense outlined>
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                        <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                            v-show="floatingLabel" v-bind="cantidadFormat"></vue-number>
                    </template>
                </q-field>
            </div>

            <!-- DETALLE -->
            <div class="col-12 col-md-12">
                <q-input outlined v-model="material" label="Material" dense />
            </div>
            <!-- OBJETO DE IMPUESTOS -->
            <div class="col-10">
                <q-select outlined v-model="objImp" :options="optionsObjImp" label="Objeto de Impuestos" dense
                    option-label="objImp" />
            </div>

            <!-- VALOR UNITARIO -->
            <div class="col-12 col-md-2">
                <q-field v-model="precio" label="Precio" dense outlined>
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                        <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                            v-show="floatingLabel" v-bind="importeFormat"></vue-number>
                    </template>
                    <template v-slot:after>
                        <q-btn round dense flat icon="mdi-plus-circle-outline" color="green-10"
                            @click="pushConceptoTotal">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Agregar
                                concepto</q-tooltip>
                        </q-btn>
                    </template>
                </q-field>
            </div>
        </div>

        <!-- TABLAS DE IMPUESTOS -->
        <div>
            <template v-if="banderaObjImp">
                <q-splitter v-model="splitterModel">
                    <!-- TABLAS DE IMPUESTOS TRASLADADOS -->
                    <template v-slot:before>
                        <div class="q-pa-md">
                            <q-table title="Traslados" :data="optionsImpuestosTrasladados" :columns="columnsImpuestos"
                                row-key="nombre" selection="multiple" :selected.sync="selectImpuestosTrasladados" dense
                                :pagination="pagination" hide-bottom>
                            </q-table>
                        </div>
                    </template>
                    <!-- TABLAS DE IMPUESTOS RETENIDOS -->
                    <template v-slot:after>
                        <div class="q-pa-md">
                            <q-table title="Retenciones" :data="optionsImpuestosRetenidos" :columns="columnsImpuestos"
                                row-key="nombre" selection="multiple" :selected.sync="selectImpuestosRetenidos" dense
                                :pagination="pagination" hide-bottom>
                            </q-table>
                        </div>
                    </template>
                </q-splitter>
            </template>
        </div>

        <!-- TABLA DE CONCEPTOS -->
        <q-table title="Conceptos" :data="ordenCompra.conceptos" :columns="columns" row-key="item"
            :rows-per-page-options="[10]" class="my-sticky-column-table q-mt-md">
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width key="acciones">
                        <q-btn size="md" color="primary" rounded flat dense @click="EliminarConcepto(props.row)"
                            icon="mdi-delete">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Eliminar Concepto</q-tooltip>
                        </q-btn>
                        <q-btn size="md" color="blue" rounded flat dense @click="EditConcepto(props.row)"
                            icon="mdi-pencil-outline">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Editar concepto</q-tooltip>
                        </q-btn>
                    </q-td>
                    <q-td key="item" :props="props">{{ props.row.item }}</q-td>
                    <q-td key="descripcion" :props="props">
                        <div v-html="props.row.descripcion"></div>
                        <q-popup-edit buttons v-model="props.row.descripcion" v-slot="scope">
                            <q-editor v-model="scope.value" min-height="5rem" autofocus @keyup.enter.stop
                                :toolbar="[]" />
                        </q-popup-edit>
                    </q-td>
                    <q-td key="unidad" :props="props">{{ props.row.unidad }}
                        <q-popup-edit buttons v-model="props.row.unidad" v-slot="scope">
                            <q-input v-model="scope.value" dense autofocus counter @keyup.enter="scope.set" />
                        </q-popup-edit>
                    </q-td>
                    <q-td key="material" :props="props">{{ props.row.material }}
                        <q-popup-edit buttons v-model="props.row.material" v-slot="scope">
                            <q-input v-model="scope.value" dense autofocus counter @keyup.enter="scope.set" />
                        </q-popup-edit>
                    </q-td>
                    <q-td key="cantidad" :props="props">{{ props.row.cantidad }}</q-td>
                    <q-td key="valorUnitario" :props="props">{{ props.row.valorUnitario }}</q-td>
                    <q-td key="importe" :props="props">{{ props.row.importe }}
                    </q-td>
                    <q-td key="objImp" :props="props">{{ props.row.objImp.objImp }}
                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>
<script>
import axios from 'axios'
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar';
import moment from 'moment';
import { component as VueNumber } from '@coders-tm/vue-number-format'

export default {
    components: {
        VueNumber
    },
    data() {
        return {
            concepto: null,
            optionsConceptos: [],
            loadingConceptos: false,

            indice: -1,
            noItem: 1,
            material: '',
            unidad: '',
            cantidad: 1,
            descripcion: '',
            valorUnitario: 0,
            importe: 0,
            precio: 0,
            objImp: null,
            impuestos: {
                traslados: [],
                retenciones: [],
            },
            columns: [
                { name: 'actions', align: 'left', label: 'Acciones', field: 'actions' },
                { name: 'item', align: 'left', label: 'No. Item', field: 'item', sortable: true },
                { name: 'descripcion', align: 'left', label: 'Descripción', field: 'descripcion', sortable: true },
                { name: 'unidad', align: 'left', label: 'Unidad', field: 'unidad', sortable: true },
                { name: 'material', align: 'left', label: 'Material', field: 'material', sortable: true },
                { name: 'cantidad', align: 'left', label: 'Cantidad', field: 'cantidad', sortable: true },
                { name: 'valorUnitario', align: 'left', label: 'Valor Unitario', field: 'valorUnitario', sortable: true },
                { name: 'importe', align: 'left', label: 'Importe', field: 'importe', sortable: true },
                { name: 'objImp', align: 'left', label: 'Obj. Impuestos', field: 'objImp', sortable: true },
            ],

            selectImpuestosTrasladados: [],
            selectImpuestosRetenidos: [],
            columnsImpuestos: [
                { name: 'nombre', align: 'left', label: 'Impuesto', field: 'nombre' },
                { name: 'tipoFactor', align: 'left', label: 'Tipo Factor', field: 'tipoFactor' },
                { name: 'tasaOCuota', align: 'left', label: 'Tasa O Cuota', field: 'tasaOCuota' },
            ],
            splitterModel: 50,

            importeFormat: {
                decimal: '.',
                separator: ',',
                prefix: '',
                precision: 6,
                masked: false,
                minimumFractionDigits: 6,
            },
            cantidadFormat: {
                decimal: '.',
                separator: ',',
                prefix: '',
                precision: 3,
                masked: false,
                minimumFractionDigits: 3,
            },

            pagination: {
                rowsPerPage: 0
            },
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        ordenCompra() {
            return this.$store.state.ordenCompraStore;
        },

        optionsConceptosDefault() {
            return this.$store.state.listaConceptosStore;
        },

        optionsObjImp() {
            return this.$store.state.objImpStore;
        },

        banderaObjImp() {
            if (!this.objImp) {
                return false;
            } else {
                if (this.objImp.clave === "01") {
                    return false;
                } else if (this.objImp.clave === "02") {
                    return true;
                }
            }
        },

        optionsImpuestosTrasladados() {
            return this.$store.state.trasladosStore;
        },

        optionsImpuestosRetenidos() {
            return this.$store.state.retencionesStore;
        },
    },
    created() {

    },
    methods: {
        filterConceptos(val, update) {
            let stringOptions = this.optionsConceptosDefault
            if (val === '') {
                update(() => {
                    this.optionsConceptos = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsConceptos = stringOptions.filter(v => v.concepto.toLowerCase().indexOf(needle) > -1)
            })
        },

        EditConcepto(item) {
            console.log(item)
            this.indice = this.ordenCompra.conceptos.findIndex(
                (f) => f.item === item.item
            );
            this.noItem = item.item;
            this.material = item.material;
            this.cantidad = item.cantidad;
            this.descripcion = item.descripcion;
            this.unidad = item.unidad;
            this.valorUnitario = item.valorUnitario;
            this.importe = item.importe
            this.precio = item.precio;
            this.objImp = item.objImp;

            const trasladados = [...this.optionsImpuestosTrasladados];
            for (let i of item.impuestos.traslados) {
                let t = trasladados.find(o => o.impuesto === i.impuesto && o.tasaOCuota == i.tasaOCuota);
                this.selectImpuestosTrasladados.push(t)
            }

            const retenciones = [...this.optionsImpuestosRetenidos];
            for (let i of item.impuestos.retenciones) {
                let r = retenciones.find(o => o.impuesto === i.impuesto && o.tasaOCuota == i.tasaOCuota);
                this.selectImpuestosRetenidos.push(r)
            }
        },

        AsignaValores() {
            console.log(this.concepto);
            let c = { ...this.concepto }
            this.cantidad = c.cantidad;
            this.unidad = c.unidad;
            this.descripcion = c.descripcion;
            this.valorUnitario = c.valorUnitario;
            this.importe = c.importe
            this.precio = 0
            this.objImp = c.objImp;
            this.impuestos = c.impuestos;
        },

        pushConceptoTotal() {
            // selectImpuestosTrasladados: [],
            // selectImpuestosRetenidos: [],
            let cantidad_ = parseFloat(this.cantidad);

            let precio = this.precio;
            let sumT = this.sum(this.selectImpuestosTrasladados, 'tasaOCuota')
            let sumR = this.sum(this.selectImpuestosRetenidos, 'tasaOCuota')
            let sumaImpuestos = ((sumT - sumR)) + 1
            let valorUnitario_ = precio / sumaImpuestos;


            let cantidad = cantidad_.toFixed(3);
            let unidad = this.unidad;
            let material = this.material;
            let descripcion = this.descripcion;
            let valorUnitario = valorUnitario_.toFixed(6);
            let importe = (valorUnitario * cantidad).toFixed(6)
            let descuento = 0;
            let objImp = this.objImp;
            let total = this.cantidad * precio
            var impuestosDefault = {
                traslados: [],
                retenciones: [],
            }
            let impuestos = {
                traslados: [],
                retenciones: [],
            }

            let item = 0;
            if (this.indice != -1) {
                item = this.noItem;
            } else {
                item = this.ordenCompra.conceptos.length + 1;
            }

            //HACEMOS EL CALCULO PARA LOS IMPUESTOS
            for (let t of this.selectImpuestosTrasladados) {
                let base_ = (importe - descuento).toFixed(6);
                let tasaOCuota = t.tasaOCuota;
                let importe_ = (base_ * tasaOCuota).toFixed(6)
                let objT = {
                    base_: base_,
                    impuesto: t.impuesto,
                    tipoFactor: t.tipoFactor,
                    tasaOCuota: t.tasaOCuota,
                    importe: importe_,
                }
                impuestos.traslados.push(objT);
                objT = {};
            }

            for (let t of this.selectImpuestosRetenidos) {
                let base_ = (importe - descuento).toFixed(6);
                let tasaOCuota = t.tasaOCuota;
                let importe_ = (base_ * tasaOCuota).toFixed(6)
                let objR = {
                    base_: base_,
                    impuesto: t.impuesto,
                    tipoFactor: t.tipoFactor,
                    tasaOCuota: t.tasaOCuota,
                    importe: importe_,
                }
                impuestos.retenciones.push(objR);
                objR = {};
            }

            // console.log(valorUnitario, cantidad, importe)
            let concepto = {
                item: item,
                material: material,
                cantidad: cantidad,
                unidad: unidad,
                descripcion: descripcion,
                valorUnitario: valorUnitario,
                importe: importe,
                objImp: objImp,
                impuestos: impuestos,
                descuento: 0,
                precio: precio,
            }
            if (this.indice != -1) {
                Object.assign(this.ordenCompra.conceptos[this.indice], concepto)
            } else {
                this.ordenCompra.conceptos.push(concepto)
            }
            concepto = {};
            this.concepto = null;
            this.InicializaConcepto();
            this.AgrupaYCalculaTotales();
        },

        sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },

        EliminarConcepto(item) {
            let indice = this.ordenCompra.conceptos.indexOf(item);
            this.ordenCompra.conceptos.splice(indice, 1);
            this.AgrupaYCalculaTotales();
        },

        InicializaConcepto() {
            this.indice = -1;
            this.unidad = '';
            this.cantidad = 1;
            this.material = '';
            this.descripcion = '';
            this.valorUnitario = 0;
            this.precio = 0;
            this.importe = 0;
            this.objImp = null;
            this.impuestos = {
                traslados: [],
                retenciones: [],
            };
        },

        AgrupaYCalculaTotales() {
            let totalTraslados = 0;
            let totalRetenidos = 0;
            let subTotal = 0;
            let descuento = 0;
            let total = 0;
            let trasladados = [];
            let retenidos = [];

            //INICIALIZAMOS LOS IMPUESTOS
            this.ordenCompra.impuestos.traslados = [];
            this.ordenCompra.impuestos.retenciones = [];

            for (let c of this.ordenCompra.conceptos) {
                for (let t of c.impuestos.traslados) {
                    trasladados.push(t);
                }

                for (let r of c.impuestos.retenciones) {
                    retenidos.push(r)
                }
            }

            subTotal = this.ordenCompra.conceptos.reduce(function (acumulador, objeto) {
                return acumulador + parseFloat(objeto.importe);
            }, 0);
            descuento = this.ordenCompra.conceptos.reduce(function (acumulador, objeto) {
                return acumulador + parseFloat(objeto.descuento);
            }, 0);
            subTotal = subTotal.toFixed(2);
            descuento = descuento.toFixed(2);

            //AGRUPAMOS LOS IMPUESTOS
            let t = this.AgrupaYCalculaImpuestos(trasladados);
            let r = this.AgrupaYCalculaImpuestos(retenidos);
            totalTraslados = t.reduce(function (acumulador, objeto) {
                return acumulador + parseFloat(objeto.importe);
            }, 0);
            totalRetenidos = r.reduce(function (acumulador, objeto) {
                return acumulador + parseFloat(objeto.importe);
            }, 0);
            totalTraslados = totalTraslados.toFixed(2);
            totalRetenidos = totalRetenidos.toFixed(2);

            //ASIGNAMOS LAS VARIABLES TOTALES
            this.ordenCompra.impuestos.traslados = t;
            this.ordenCompra.impuestos.retenciones = r;
            this.ordenCompra.impuestos.totalImpuestosTrasladados = parseFloat(totalTraslados);
            this.ordenCompra.impuestos.totalImpuestosRetenidos = parseFloat(totalRetenidos);
            this.ordenCompra.subTotal = parseFloat(subTotal);
            this.ordenCompra.descuento = parseFloat(descuento);

            //ASIGNAMOS EL TOTAL
            total = this.ordenCompra.subTotal - this.ordenCompra.descuento + this.ordenCompra.impuestos.totalImpuestosTrasladados - this.ordenCompra.impuestos.totalImpuestosRetenidos;
            total = total.toFixed(2);
            this.ordenCompra.total = parseFloat(total);
        },

        AgrupaYCalculaImpuestos(lista) {
            var agrupado = lista.reduce(function (acumulador, elemento) {
                // Crear una clave única para el grupo
                var clave = elemento.impuesto + '-' + elemento.tipoFactor + '-' + elemento.tasaOCuota;

                // Inicializar el grupo si es la primera vez que se encuentra la clave
                if (!acumulador[clave]) {
                    acumulador[clave] = {
                        impuesto: elemento.impuesto,
                        tipoFactor: elemento.tipoFactor,
                        tasaOCuota: elemento.tasaOCuota,
                        base_: 0,
                        importe: 0,
                    };
                }

                // Sumar base e importe al grupo correspondiente
                acumulador[clave].base_ += parseFloat(elemento.base_);
                acumulador[clave].importe += parseFloat(elemento.importe);

                return acumulador;
            }, {});

            // Convertir el objeto agrupado de nuevo a una lista
            var resultado = Object.values(agrupado);

            for (let r of resultado) {
                r.base_ = r.base_.toFixed(2);
                r.importe = r.importe.toFixed(2);
            }
            return resultado;
        },

    },
}
</script>