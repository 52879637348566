<template>
    <div>
        <div class="row q-col-gutter-sm">
            <!-- SERIE -->
            <div class="col-12 col-md-4">
                <q-select label="Serie" outlined v-model="comprobante.serie" use-input input-debounce="0"
                    @new-value="createSerie" :options="optionsSerie" @filter="filterSerie" dense>
                    <template v-slot:no-option>
                        <q-item>
                            <q-item-section class="text-grey">
                                Sin resultados
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>
            </div>
            <!-- FOLIO -->
            <div class="col-12 col-md-4">
                <q-input outlined v-model="comprobante.folio" label="Folio" dense />
            </div>
            <!-- FECHA -->
            <div class="col-12 col-md-4">
                <datetime v-model="comprobante.fecha" type="datetime" :phrases="{ ok: 'Ok', cancel: 'Cancelar' }"
                    :format="{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit' }"
                    value-zone="America/Mexico_City" class="custom-datetime"></datetime>
            </div>
            <!-- CLIENTE -->
            <div class="col-12">
                <q-select v-model="comprobante.receptor" use-input input-debounce="0" label="Cliente"
                    :options="optionsClientes" @filter="filterCliente" fill-input hide-selected :loading="loadingClientes"
                    dense outlined option-label="receptor" @input="AsingaUsoCfdi">
                    <template v-slot:no-option>
                        <q-item>
                            <q-item-section class="text-grey">
                                No exixte algún cliente que coincida con los criterios
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>
            </div>
            <!-- USO DEL CDFI -->
            <div class="col-12">
                <q-input outlined v-model="comprobante.receptor.usoCfdi.usoCfdi" label="Uso del CDFI" dense readonly />
            </div>
            <!-- MONEDA -->
            <div class="col-12 col-md-6">
                <q-input outlined v-model="comprobante.moneda.moneda" label="Moneda" dense readonly />
            </div>
            <!-- NOTAS -->
            <div class="col-12 col-md-6">
                <q-input outlined v-model="comprobante.notas" label="Notas" dense />
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar';
import moment from 'moment';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';

export default {
    components: {
        Datetime
    },
    data() {
        return {
            optionsSerie: [],

            //CLIENTES
            loadingClientes: false,
            optionsClientes: [],

            // MONEDA
            optionsMoneda: [],
            loadingMoneda: false,

            model: null,
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        comprobante() {
            return this.$store.state.comprobanteStore.comprobante;
        },

        fehaIMasked() {
            moment.locale('es-mx');
            return this.fechaI ? moment(this.fechaI).format('DD/MMMM/yyyy') : ''
        },

        fechaFMasked() {
            moment.locale('es-mx');
            return this.fechaF ? moment(this.fechaF).format('DD/MMMM/yyyy') : ''
        },

        optionsClientesDefault() {
            return this.$store.state.listClienteStore
        },

        optionsSerieDefault() {
            return this.$store.state.serieStore;
        },

    },
    created() {

    },
    methods: {

        // DATOS Y FILTROS DE LOS COMPRONENTES GENERALES
        createSerie(val, done) {
            let stringOptions = this.optionsSerieDefault;
            if (val.length > 0) {
                if (!stringOptions.includes(val)) {
                    stringOptions.push(val)
                }
                done(val, 'toggle')
            }
        },

        setModelSerie(val) {
            this.comprobante.serie = val
        },

        filterSerie(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.options = this.optionsSerieDefault.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            })
        },

        filterCliente(val, update) {
            let stringOptions = this.optionsClientesDefault
            if (val === '') {
                update(() => {
                    this.optionsClientes = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsClientes = stringOptions.filter(v => v.receptor.toLowerCase().indexOf(needle) > -1)
            })
        },

        AsingaUsoCfdi() {
            let usoCfdi = {
                clave: "CP01",
                descripcion: "Pagos",
                fisica: "Sí",
                moral: "Sí",
                regimen_fiscal: "601, 603, 605, 606, 608, 610, 611, 612, 614, 616, 620, 621, 622, 623, 624, 607, 615, 625, 626",
                usoCfdi: "CP01 | Pagos",
            }
            this.comprobante.receptor.usoCfdi = { ...usoCfdi }
        },

    },
}
</script>