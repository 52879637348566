<template>
    <q-page class=" q-pa-lg ">
        <!-- DIALOGO PARA CREAR UN NUEVO PACIENTE -->
        <q-dialog full-width v-model="dialogNuevo" persistent transition-show="scale" transition-hide="scale">
            <Mercancia @cierraVentana="dialogNuevo = false"></Mercancia>
        </q-dialog>

        <!-- DIALOGO DE CONFIRMACION -->
        <q-dialog v-model="dialogConfirm" persistent>
            <q-card style="width: 600px;">
                <q-card-section class="row items-center">
                    <q-avatar icon="mdi-delete-alert" color="primary" text-color="white" />
                    <span class="text-h5 q-ml-md">Estás seguro de eliminar la Mercancia.?</span>
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat label="Aceptar" color="primary" @click="deleteItem()" />
                    <q-btn flat label="Cerrar" color="primary" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <div class="row	flex-left q-mb-md">
            <div class="col-12 col-md-6 col-sm-6 text-left ">
            </div>
            <div class="col-12 col-md-6 col-sm-6 text-right ">
                <q-btn round color="blue" class="q-mr-sm" icon="mdi-update" @click="GetItems()" />
                <q-btn round color="green" icon="mdi-plus" @click="nuevoElemento" />
            </div>
        </div>
        <div>
            <q-table class="shadow-0" title="Mercancias" :data="items" :columns="columns" row-key="_id" :filter="filter"
                :loading="loading" :pagination="pagination">
                <template v-slot:loading>
                    <q-inner-loading showing color="primary" />
                </template>
                <template v-slot:top-right>
                    <q-input filled borderless dense debounce="300" v-model="filter" placeholder="Buscar">
                        <template v-slot:append>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" color="red" rounded flat dense icon="mdi-delete" @click="eliminar(props.row)">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                            <q-btn size="md" color="blue" rounded flat dense icon="mdi-pencil" @click="editar(props.row)">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Editar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="bienesTransp" :props="props">{{ props.row.bienesTransp.claveProdServCP }}</q-td>
                        <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                        <q-td key="cantidad" :props="props">{{ props.row.cantidad }}</q-td>
                        <q-td key="claveUnidad" :props="props">{{ props.row.claveUnidad.claveUnidad }}</q-td>
                        <q-td key="unidad" :props="props">{{ props.row.unidad }}</q-td>
                        <q-td key="dimensiones" :props="props">{{ props.row.dimensiones }}</q-td>
                        <q-td key="materialPeligroso" :props="props">{{ props.row.materialPeligroso }}</q-td>
                        <q-td key="cveMaterialPeligroso" :props="props">{{ props.row.cveMaterialPeligroso.materialPeligroso
                        }}</q-td>
                        <q-td key="embalaje" :props="props">{{ props.row.embalaje.tipoEmbalaje }}</q-td>
                        <q-td key="descripEmbalaje" :props="props">{{ props.row.descripEmbalaje }}</q-td>
                        <q-td key="pesoEnKg" :props="props">{{ props.row.pesoEnKg }}</q-td>
                        <!-- <q-td key="fraccionArancelaria" :props="props">{{ props.row.fraccionArancelaria.fraccionArancelaria -->
                        <!-- }}</q-td> -->
                        <!-- <q-td key="uuidComercioExt" :props="props">{{ props.row.uuidComercioExt }}</q-td> -->
                        <q-td key="tipoMateria" :props="props">{{ props.row.tipoMateria.tipoMateria }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
    </q-page>
</template>
<script>

import moment from 'moment';
import { QSpinnerCube } from 'quasar'
import axios from 'axios'
import Mercancia from './Mercancia.vue'
export default {
    components: { Mercancia },
    data() {
        return {
            filter: '',
            loading: false,
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', },
                { name: 'bienesTransp', align: 'center', label: 'Bienes transportadas', field: 'bienesTransp', sortable: true },
                { name: 'descripcion', align: 'center', label: 'Descripción', field: 'descripcion', sortable: true },
                { name: 'cantidad', align: 'center', label: 'Cantidad', field: 'cantidad', sortable: true },
                { name: 'claveUnidad', align: 'center', label: 'Clave unidad', field: 'claveUnidad', sortable: true },
                { name: 'unidad', align: 'center', label: 'Unidad', field: 'unidad', sortable: true },
                { name: 'dimensiones', align: 'center', label: 'Dimensiones', field: 'dimensiones', sortable: true },
                { name: 'materialPeligroso', align: 'center', label: 'Material Peligroso', field: 'materialPeligroso', sortable: true },
                { name: 'cveMaterialPeligroso', align: 'center', label: 'Clave del Material Peligroso', field: 'cveMaterialPeligroso', sortable: true },
                { name: 'embalaje', align: 'center', label: 'Embalaje', field: 'embalaje', sortable: true },
                { name: 'descripEmbalaje', align: 'center', label: 'Descripción del embalaje', field: 'descripEmbalaje', sortable: true },
                { name: 'pesoEnKg', align: 'center', label: 'Peso en Kg', field: 'pesoEnKg', sortable: true },
                // { name: 'fraccionArancelaria', align: 'center', label: 'Fracción arancelaria', field: 'fraccionArancelaria', sortable: true },
                // { name: 'uuidComercioExt', align: 'center', label: 'UUID Comercio Exterior', field: 'uuidComercioExt', sortable: true },
                { name: 'tipoMateria', align: 'center', label: 'Tipo de Materia', field: 'tipoMateria', sortable: true },
            ],
            data: [],
            dialogNuevo: false,
            pagination: {
                sortBy: 'subTipoRem',
                descending: false,
                page: 1,
                rowsPerPage: 10
                // rowsNumber: xx if getting data from a server
            },
            dialogConfirm: false
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        items() {
            return this.$store.state.listaMercanciasStore
        }
    },
    created() {
        this.GetCatClaveUnidadAsync();
        this.GetCatCveTransporteAsync();
        this.GetCatDocumentoAduaneroAsync();
        this.GetCatFraccionArancelariaAsync();
        this.GetCatMaterialPeligrosoAsync();
        this.GetCatTipoEmbalajeAsync();
        this.GetCatTipoMateriaAsync();
        this.GetClaveClaveProdServCPAsync();
        this.GetItems();
    },
    methods: {
        nuevoElemento() {
            let objeto = {
                _id: '',
                bienesTransp: null,
                descripcion: '',
                cantidad: 1,
                claveUnidad: null,
                unidad: '',
                dimensiones: '',
                materialPeligroso: '',
                cveMaterialPeligroso: { materialPeligroso: '' },
                embalaje: null,
                descripEmbalaje: '',
                pesoEnKg: 0,
                fraccionArancelaria: null,
                uuidComercioExt: '',
                tipoMateria: null,
                documentacionAduanera: [],
                cantidadTransporta: [],
            }
            this.$store.state.mercanciaStore = objeto
            this.dialogNuevo = true
        },
        async GetClaveClaveProdServCPAsync() {
            if (this.$store.state.listaCatalogoClaveProServCP.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetClaveClaveProdServCPAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoClaveProServCP = catalogo;

            } catch (error) {
                console.log(error);
            }
        },
        async GetCatClaveUnidadAsync() {
            if (this.$store.state.listaCatalogoClaveUnidad.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatClaveUnidadAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoClaveUnidad = catalogo;

            } catch (error) {
                console.log(error);
            }
        },
        async GetCatMaterialPeligrosoAsync() {
            if (this.$store.state.listaCatalogoMaterialPeligroso.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatMaterialPeligrosoAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoMaterialPeligroso = catalogo;

            } catch (error) {
                console.log(error);
            }
        },
        async GetCatTipoEmbalajeAsync() {
            if (this.$store.state.listaCatalogoTipoEmbalaje.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatTipoEmbalajeAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoTipoEmbalaje = catalogo;

            } catch (error) {
                console.log(error);
            }
        },
        async GetCatFraccionArancelariaAsync() {
            if (this.$store.state.listaCatalogoFraccionArancelaria.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatFraccionArancelariaAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoFraccionArancelaria = catalogo;

            } catch (error) {
                console.log(error);
            }
        },
        async GetCatTipoMateriaAsync() {
            if (this.$store.state.listaCatalogoTipoMateria.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatTipoMateriaAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoTipoMateria = catalogo;

            } catch (error) {
                console.log(error);
            }
        },
        async GetCatDocumentoAduaneroAsync() {
            if (this.$store.state.listaCatalogoDocAduanero.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatDocumentoAduaneroAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoDocAduanero = catalogo;

            } catch (error) {
                console.log(error);
            }
        },
        async GetCatCveTransporteAsync() {
            if (this.$store.state.listaCatalogoCveTransporte.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatCveTransporteAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoCveTransporte = catalogo;

            } catch (error) {
                console.log(error);
            }
        },
        async GetItems() {
            this.loading = true
            try {
                let response = await axios.get("CatalogosComprobantes/GetMercancias/erp_" + this.token.rfc);
                let catalogo = response.data;
                this.$store.state.listaMercanciasStore = catalogo;
                this.loading = false

            } catch (error) {
                this.loading = false
                console.log(error);
            }
        },
        eliminar(item) {
            this.dialogConfirm = true
            this.$store.state.mercanciaStore = item
        },
        async deleteItem() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Eliminando mercancia. Espere...', messageColor: 'white' })
            let mercancia = this.$store.state.mercanciaStore

            try {
                let response = await axios.put('CatalogosComprobantes/DeleteMercancia/erp_' + this.token.rfc + '/' + mercancia._id)
                console.log(response)

                // SI LA ACCION ES ELIMINAR, LO QUITAMOS DE LA LISTA
                let indice = this.items.indexOf(mercancia)
                this.items.splice(indice, 1)


                this.dialogConfirm = false
                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `La mercancia ha sido eliminado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error, intentelo mas tarde.', color: 'red' })
            }
        },
        editar(data) {
            console.log(data)
            this.$store.state.mercanciaStore = { ...data }
            this.dialogNuevo = true
        },
    }
}
</script>
    
<style></style>
<style lang="sass">
</style>
    