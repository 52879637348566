<template>
    <div class="q-pa-md">
        <q-card class="my-card">
            <q-toolbar>
                <q-btn push color="red-10" icon="mdi-close-circle-outline" round flat v-close-popup size="18px"
                    padding="xs">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Cerrar</q-tooltip>
                </q-btn>
                <q-toolbar-title><span class="text-weight-bold">SELECCIONE LOS DATOS DE LA FACTURA</span></q-toolbar-title>
                <q-btn push color="green-10" icon="mdi-check-circle-outline" round flat size="18px" padding="xs"
                    @click="Timbrar">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Timbrar factura por ticket</q-tooltip>
                </q-btn>
                <q-btn push color="blue-10" icon="mdi-check-circle-outline" round flat size="18px" padding="xs"
                    @click="TimbrarMasivo">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Timbrar todos los tickets en una sola factura</q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator size="5px" color="primary" />
            <q-card-section>
                <div class="row q-col-gutter-xs">
                    <!-- SERIE -->
                    <div class="col-12 col-md-4">
                        <q-select label="Serie" outlined v-model="comprobante.serie" use-input input-debounce="0" @new-value="createSerie" :options="optionsSerie" @filter="filterSerie" dense>
                            <template v-slot:no-option>
                                <q-item>
                                    <q-item-section class="text-grey">
                                        Sin resultados
                                    </q-item-section> 
                                </q-item>
                            </template>
                        </q-select>
                    </div>
                    <!-- FOLIO -->
                    <div class="col-12 col-md-4">
                        <q-input outlined v-model="comprobante.folio" label="Folio" dense />
                    </div>
                    <!-- FECHA -->
                    <div class="col-12 col-md-4">
                        <datetime
                            v-model="comprobante.fecha"
                            type="datetime"
                            :phrases="{ ok: 'Ok', cancel: 'Cancelar' }"
                            :format="{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit' }"
                            value-zone="America/Mexico_City"
                            class="custom-datetime"
                        ></datetime>
                    </div>
                    <!-- CLIENTE -->
                    <div class="col-12">
                        <q-select v-model="comprobante.receptor" use-input input-debounce="0" label="Cliente"
                            :options="optionsReceptor" @filter="filterReceptor" fill-input hide-selected
                            :loading="loadingReceptor" dense outlined option-label="receptor">
                            <template v-slot:no-option>
                                <q-item>
                                    <q-item-section class="text-grey">
                                        No exixte algún cliente que coincida con los criterios
                                    </q-item-section>
                                </q-item>
                            </template>
                        </q-select>
                    </div>
                    <!-- USO DE CFDI -->
                    <div class="col-12">
                        <q-select v-model="comprobante.receptor.usoCfdi" label="Uso de CFDI" :options="optionsUsoCfdi" dense
                            outlined option-label="usoCfdi">
                        </q-select>
                    </div>
                    <!-- METODO DE PAGO -->
                    <div class="col-12 col-md-6">
                        <q-select v-model="comprobante.metodoPago" label="Metodo de Pago" :options="optionsMetodoPago" dense
                            outlined option-label="metodoPago">
                        </q-select>
                    </div>
                    <!-- FORMA DE PAGO -->
                    <div class="col-12 col-md-6">
                        <q-select v-model="comprobante.formaPago" label="Forma de Pago" :options="optionsFormaPago" dense
                            outlined option-label="formaPago">
                        </q-select>
                    </div>
                    <!-- CONDICIONES DE PAGO -->
                    <div class="col-12">
                        <q-input outlined v-model="comprobante.condicionesDePago" label="Condiciones de Pago" dense />
                    </div>
                    <!-- PERIODICIDAD -->
                    <template v-if="comprobante.receptor.rfc === 'XAXX010101000'">
                        <div class="col-4">
                            <q-select v-model="comprobante.informacionGlobal.periodicidad" label="Periodicidad" :options="optionsPeriodicidad" dense
                                outlined option-label="periodicidad">
                            </q-select>
                        </div>
                        <!-- MES -->
                        <div class="col-4">
                            <q-select v-model="comprobante.informacionGlobal.meses" label="Mes" :options="optionsMes" dense
                                outlined option-label="meses">
                            </q-select>
                        </div>
                        <!-- AÑO -->
                        <div class="col-4">
                            <q-select v-model="comprobante.informacionGlobal.año" label="Año" :options="optionsAño" dense
                                outlined>
                            </q-select>
                        </div>
                    </template>
                </div>
            </q-card-section>
        </q-card>
        <q-table :title="sumaTotales" :data="items" :columns="columns" row-key="numDespacho" :rows-per-page-options="[10]"
            :filter="filter" class="my-sticky-column-table">
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width>
                        <template v-if="props.row.folioFiscal != ''">
                            <q-btn size="md" color="red" rounded flat dense @click="VerComprobante(props.row)"
                                icon="mdi-file-pdf-box">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px" :offset="[10, 10]">Ver Comprobante</q-tooltip>
                            </q-btn>
                        </template>
                    </q-td>
                    <q-td key="fecha" :props="props">{{ FormatDate(props.row.fecha) }}</q-td>
                    <q-td key="numDespacho" :props="props">{{ props.row.numDespacho }}</q-td>
                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                    <q-td key="cantidad" :props="props">{{ FormatQuantity(props.row.cantidad) }}</q-td>
                    <q-td key="unidad" :props="props">{{ props.row.unidad }}</q-td>
                    <q-td key="importe" :props="props">{{ FormatCurrency(props.row.importe) }}</q-td>
                    <q-td key="total" :props="props">{{ FormatCurrency(props.row.total) }}</q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>
<script>
import axios from 'axios'
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar';
import moment from 'moment';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';

export default {
    components: {
        Datetime
    },
    data() {
        return {
            columns: [
                { name: 'actions', align: 'left', label: 'Acciones', field: 'actions' },
                { name: 'fecha', align: 'left', label: 'Fecha', field: 'fecha' },
                { name: 'numDespacho', align: 'left', label: 'Num Despacho', field: 'numDespacho' },
                { name: 'descripcion', align: 'left', label: 'Descripción', field: 'descripcion' },
                { name: 'cantidad', align: 'right', label: 'Cantidad', field: 'cantidad' },
                { name: 'unidad', align: 'left', label: 'Unidad', field: 'unidad' },
                { name: 'importe', align: 'right', label: 'SubTotal', field: 'importe' },
                { name: 'total', align: 'right', label: 'Total', field: 'total' },
            ],
            filter: '',
            comprobante: {
                serie: '',
                folio: '',
                // fecha: this.formatCurrentDateTime,
                fecha: new Date().toISOString(),
                receptor: {
                    rfc:'',
                    receptor: '',
                    usoCfdi: null
                },
                metodoPago: null,
                formaPago: null,
                condicionesDePago: '',
                informacionGlobal: {
                    periodicidad: null,
                    meses: null,
                    año: '2023'
                }

            },
            optionsSerie: [],
            optionsSerieDefault: [],
            loadingReceptor: false,
            optionsReceptor: [],

            //FCATURA PUBLICO EN GENERAL
            optionsPeriodicidad: [
                {clave: '01', descripcion: 'Diario', periodicidad: '01 | Diario'},
                {clave: '02', descripcion: 'Semanal', periodicidad: '02 | Semanal'},
                {clave: '03', descripcion: 'Quincenal', periodicidad: '03 | Quincenal'},
                {clave: '04', descripcion: 'Mensual', periodicidad: '04 | Mensual'},
                {clave: '05', descripcion: 'Bimestral', periodicidad: '05 | Bimestral'},
            ],
            optionsMes: [
                {clave: '01', descripcion: 'Enero', meses: '01 | Enero'},
                {clave: '02', descripcion: 'Febrero', meses: '02 | Febrero'},
                {clave: '03', descripcion: 'Marzo', meses: '03 | Marzo'},
                {clave: '04', descripcion: 'Abril', meses: '04 | Abril'},
                {clave: '05', descripcion: 'Mayo', meses: '05 | Mayo'},
                {clave: '06', descripcion: 'Junio', meses: '06 | Junio'},
                {clave: '07', descripcion: 'Julio', meses: '07 | Julio'},
                {clave: '08', descripcion: 'Agosto', meses: '08 | Agosto'},
                {clave: '09', descripcion: 'Septiembre', meses: '09 | Septiembre'},
                {clave: '10', descripcion: 'Octubre', meses: '10 | Octubre'},
                {clave: '11', descripcion: 'Noviembre', meses: '11 | Noviembre'},
                {clave: '12', descripcion: 'Diciembre', meses: '12 | Diciembre'},
                {clave: '13', descripcion: 'Enero-Febrero', meses: '13 | Enero-Febrero'},
                {clave: '14', descripcion: 'Marzo-Abril', meses: '14 | Marzo-Abril'},
                {clave: '15', descripcion: 'Mayo-Junio', meses: '15 | Mayo-Junio'},
                {clave: '16', descripcion: 'Julio-Agosto', meses: '16 | Julio-Agosto'},
                {clave: '17', descripcion: 'Septiembre-Octubre', meses: '17 | Septiembre-Octubre'},
                {clave: '18', descripcion: 'Noviembre-Diciembre', meses: '18 | Noviembre-Diciembre'},
            ],
            optionsAño: ['2023'],
        }
    },

    computed: {
        token() {
            return this.$store.state.usuario;
        },

        items() {
            return this.$store.state.ticketsStore;
        },

        fehaIMasked() {
            moment.locale('es-mx');
            return this.comprobante.fecha ? moment(this.comprobante.fecha).format('DD/MMMM/yyyy') : ''
        },

        optionsUsoCfdi() {
            return this.$store.state.usoCfdiStore;
        },

        optionsReceptorDefault() {
            return this.$store.state.listClienteStore;
        },

        optionsMetodoPago() {
            return this.$store.state.metodoPagoStore;
        },

        optionsFormaPago() {
            return this.$store.state.formaPagoStore;
        },

        sumaTotales() {
            let suma = this.$store.state.ticketsStore.reduce((acumulador, objeto) => acumulador + objeto.total, 0);
            let sumaFormateada = suma.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            return 'Comprobantes: ' + sumaFormateada;
        },
    },

    created() {
        this.GetCatalogos();
    },

    mounted(){
        // setLocale('es', es);
    },

    methods: {
        //CATALOGOS DEL SAT
        async GetCatalogos() {
            this.$q.loading.show({
                spinner: QSpinnerCube,
                spinnerColor: 'red-8',
                spinnerSize: 140,
                message: 'Consultando, espere...',
            })
            await this.GetCliente();
            await this.GetUsoCfdi();
            await this.GetMetodoPago();
            await this.GetFormaPago();
            await this.GetMoneda();
            this.$q.loading.hide()

        },

        async GetCliente() {
            try {
                let response = await axios.get('Cliente/GetClientes/erp_' + this.token.rfc)
                let x = response.data;
                this.$store.state.listClienteStore = [...x]
                // console.log(x)
            } catch (error) {
                console.log(error)
            }
        },

        async GetUsoCfdi() {
            if (this.$store.state.usoCfdiStore.length != 0) {
                return;
            } else {
                try {
                    let response = await axios.get('ClaveSat/GetUsoCfdiAsync')
                    let x = response.data;
                    this.$store.state.usoCfdiStore = [...x]
                } catch (error) {
                    console.log(error)
                }
            }
        },

        async GetMetodoPago() {
            if (this.$store.state.metodoPagoStore.length != 0) {
                return;
            } else {
                try {
                    let response = await axios.get('ClaveSat/GetMetodoPagoAsync')
                    let x = response.data;
                    this.$store.state.metodoPagoStore = [...x]
                } catch (error) {
                    console.log(error)
                }
            }
        },

        async GetFormaPago() {
            if (this.$store.state.formaPagoStore.length != 0) {
                return;
            } else {
                try {
                    let response = await axios.get('ClaveSat/GetFormaPagoAsync')
                    let x = response.data;
                    this.$store.state.formaPagoStore = [...x]
                } catch (error) {
                    console.log(error)
                }
            }
        },

        async GetMoneda() {
            if (this.$store.state.monedaStore.length != 0) {
                return;
            } else {
                try {
                    let response = await axios.get('ClaveSat/GetMonedaAsync')
                    let x = response.data;
                    this.$store.state.monedaStore = [...x]
                } catch (error) {
                    console.log(error)
                }
            }
        },

        async Timbrar() {
            //VALIDAMOS QUE ESTEN TODOS LOS DATOS
            if(this.comprobante.receptor.rfc === ''){
                this.$q.notify({
                    type: 'warning',
                    message: `Seleccione un cliente`,
                    position: 'top-right',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => {  } }
                    ]
                })
                return;
            }

            if(!this.comprobante.receptor.usoCfdi){
                this.$q.notify({
                    type: 'warning',
                    message: `Seleccione un uso de CFDI`,
                    position: 'top-right',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => {  } }
                    ]
                })
                return;
            }

            if(!this.comprobante.metodoPago){
                this.$q.notify({
                    type: 'warning',
                    message: `Seleccione un método de pago`,
                    position: 'top-right',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => {  } }
                    ]
                })
                return;
            }

            if(!this.comprobante.formaPago){
                this.$q.notify({
                    type: 'warning',
                    message: `Seleccione una forma de pago`,
                    position: 'top-right',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => {  } }
                    ]
                })
                return;
            }

            this.$q.loading.show({
                spinner: QSpinnerCube,
                spinnerColor: 'purple-8',
                spinnerSize: 140,
                message: 'Generando facturas, espere...',
            })
            let fechaI_ = new Date(this.comprobante.fecha);
            let fecha = format(fechaI_, "yyyy-MM-dd HH:mm:ss");
            let serie = this.comprobante.serie;
            let folio = this.comprobante.folio;
            let receptor = this.comprobante.receptor;
            let informacionGlobal = this.comprobante.informacionGlobal;
            let metodoPago = this.comprobante.metodoPago;
            let formaPago = this.comprobante.formaPago;
            let condicionesDePago =  this.comprobante.condicionesDePago;

            for (var x of this.items) {
                // x.fecha = '2023-12-19 10:00:00';
                let datos = {
                    serie: serie,
                    folio: folio,
                    fecha: fecha,
                    receptor: receptor,
                    ticket: x,
                    informacionGlobal: informacionGlobal,
                    metodoPago: metodoPago,
                    formaPago: formaPago,
                    condicionesDePago: condicionesDePago,
                };

                console.log(datos)
                try {
                    let response = await axios.post('https://localhost:44394/api/Timbrado/PostFacturaKioskoGas/' + this.token.rfc, datos)
                } catch (error) {
                    console.log(error)
                }
            }
            this.$emit('cierraExitoso')
            this.$q.loading.hide()
        },

        async TimbrarMasivo() {
            //VALIDAMOS QUE ESTEN TODOS LOS DATOS
            if(this.comprobante.receptor.rfc === ''){
                this.$q.notify({
                    type: 'warning',
                    message: `Seleccione un cliente`,
                    position: 'top-right',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => {  } }
                    ]
                })
                return;
            }

            if(!this.comprobante.receptor.usoCfdi){
                this.$q.notify({
                    type: 'warning',
                    message: `Seleccione un uso de CFDI`,
                    position: 'top-right',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => {  } }
                    ]
                })
                return;
            }

            if(!this.comprobante.metodoPago){
                this.$q.notify({
                    type: 'warning',
                    message: `Seleccione un método de pago`,
                    position: 'top-right',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => {  } }
                    ]
                })
                return;
            }

            if(!this.comprobante.formaPago){
                this.$q.notify({
                    type: 'warning',
                    message: `Seleccione una forma de pago`,
                    position: 'top-right',
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => {  } }
                    ]
                })
                return;
            }

            this.$q.loading.show({
                spinner: QSpinnerCube,
                spinnerColor: 'purple-8',
                spinnerSize: 140,
                message: 'Generando facturas, espere...',
            })
            
            let fechaI_ = new Date(this.comprobante.fecha);
            let fecha = format(fechaI_, "yyyy-MM-dd HH:mm:ss");
            let serie = this.comprobante.serie;
            let folio = this.comprobante.folio;
            let receptor = this.comprobante.receptor;
            let informacionGlobal = this.comprobante.informacionGlobal;
            let metodoPago = this.comprobante.metodoPago;
            let formaPago = this.comprobante.formaPago;
            let condicionesDePago =  this.comprobante.condicionesDePago;

            let datos = {
                serie: serie,
                folio: folio,
                fecha: fecha,
                receptor: receptor,
                tickets: [],
                informacionGlobal: informacionGlobal,
                metodoPago: metodoPago,
                formaPago: formaPago,
                condicionesDePago: condicionesDePago,
            };
            for (var x of this.items) {
                datos.tickets.push(x);
            }
            try {
                let response = await axios.post('https://localhost:44394/api/Timbrado/PostFacturaKioskoGasAcumulado/' + this.token.rfc, datos)
                console.log(response.data);
                this.$q.loading.hide()
            this.$emit('cierraExitoso')
            } catch (error) {
                this.$q.loading.hide()
                console.log(error)
            }
            // console.log(datos)
        },

        FormatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        FormatQuantity(item) {
            const formatoNumerico = item.toLocaleString('en-US', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
                useGrouping: true  // Agregar separador de miles
            });
            return formatoNumerico
        },

        FormatDate(value) {
            let fecha_a = value.replace('T', ' ');
            const cadenaFechaConHora = fecha_a;
            const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
            const formato = "dd-MMMM-yyyy";
            const configuracionLocal = { locale: es };
            let resultado = format(fecha, formato, configuracionLocal);
            return resultado;
        },

        // DATOS Y FILTROS DE LOS COMPRONENTES GENERALES
        createSerie (val, done) {
            let stringOptions = this.optionsSerieDefault;
            if (val.length > 0) {
                if (!stringOptions.includes(val)) {
                stringOptions.push(val)
                }
                done(val, 'toggle')
            }
        },
        
        filterSerie (val, update) {
            let stringOptions = this.optionsSerieDefault;
            update(() => {
                if (val === '') {
                this.optionsSerie = stringOptions
                }
                else {
                const needle = val.toLowerCase()
                this.optionsSerie = stringOptions.filter(
                    v => v.toLowerCase().indexOf(needle) > -1
                )
                }
            })
        },

        filterSerie(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.optionsSerie = this.optionsSerieDefault.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            })
        },

        filterReceptor(val, update) {
            let stringOptions = this.optionsReceptorDefault
            if (val === '') {
                update(() => {
                    this.optionsReceptor = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsReceptor = stringOptions.filter(v => v.receptor.toLowerCase().indexOf(needle) > -1)
            })
        },
    },
}
</script>
<style>
.my-card {
    width: 100%;
}
.vdatetime-input{
    margin: 0px;
    padding: 8px 10px;
    font-size: 15px;
    border: solid 1px #999797ab;
    color: #444;
    width: 100%;
    border-radius: 4px;
}
.vdatetime.custom-datetime{
    padding: 0px;
}
</style>