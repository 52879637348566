<template>
    <q-page class=" q-pa-lg ">
        <q-dialog v-model="dialogNuevo" persistent transition-show="scale" transition-hide="scale">
            <Remolque @cierraVentana="dialogNuevo = false"></Remolque>
        </q-dialog>

        <!-- DIALOGO DE CONFIRMACION -->
        <q-dialog v-model="dialogConfirm" persistent>
            <q-card style="width: 600px;">
                <q-card-section class="row items-center">
                    <q-avatar icon="mdi-delete-alert" color="primary" text-color="white" />
                    <span class="text-h5 q-ml-md">Estas seguro de eliminar el remolque.?</span>
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat label="Aceptar" color="primary" @click="deleteItem()" />
                    <q-btn flat label="Cerrar" color="primary" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <div class="row	flex-left q-mb-md">
            <div class="col-12 col-md-6 col-sm-6 text-left ">
            </div>
            <div class="col-12 col-md-6 col-sm-6 text-right ">
                <q-btn round color="blue" class="q-mr-sm" icon="mdi-update" @click="GetItems()" />
                <q-btn round color="green" icon="mdi-plus" @click="nuevoElemento" />
            </div>
        </div>
        <div>
            <q-table class="shadow-0" title="Remolques" :data="items" :columns="columns" row-key="_id" :filter="filter"
                :loading="loading" :pagination="pagination">
                <template v-slot:loading>
                    <q-inner-loading showing color="primary" />
                </template>
                <template v-slot:top-right>
                    <q-input filled borderless dense debounce="300" v-model="filter" placeholder="Buscar">
                        <template v-slot:append>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" color="red" rounded flat dense icon="mdi-delete" @click="eliminar(props.row)">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                            <q-btn size="md" color="blue" rounded flat dense icon="mdi-pencil" @click="editar(props.row)">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Editar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="subTipoRem" :props="props">{{ props.row.subTipoRem.subTipoRem
                        }}</q-td>
                        <q-td key="placa" :props="props">{{ props.row.placa }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
    </q-page>
</template>
<script>

import moment from 'moment';
import { QSpinnerCube } from 'quasar'
import axios from 'axios'
import Remolque from './Remolque.vue'
export default {
    components: { Remolque },
    data() {
        return {
            filter: '',
            loading: false,
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', },
                { name: 'subTipoRem', align: 'center', label: 'Tipo de remolque', field: 'subTipoRem', sortable: true },
                { name: 'placa', align: 'center', label: 'Placa', field: 'placa', sortable: true },
            ],
            data: [],
            dialogNuevo: false,
            pagination: {
                sortBy: 'subTipoRem',
                descending: false,
                page: 1,
                rowsPerPage: 10
                // rowsNumber: xx if getting data from a server
            },
            dialogConfirm: false
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        items() {
            return this.$store.state.listaRemolquesStore
        }
    },
    created() {
        this.GetCatSubTipoRemAsync();
        this.GetItems();
    },
    methods: {
        nuevoElemento() {
            let objeto = {
                _id: '',
                subTipoRem: null,
                placa: '',
            }
            this.$store.state.remolqueStore = objeto
            this.dialogNuevo = true
        },
        async GetCatSubTipoRemAsync() {
            if (this.$store.state.listaCatalogoSubTipoRem.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatSubTipoRemAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoSubTipoRem = catalogo;

            } catch (error) {
                console.log(error);
            }
        },
        async GetItems() {
            this.loading = true
            try {
                let response = await axios.get("CatalogosComprobantes/GetRemolques/erp_" + this.token.rfc);
                let catalogo = response.data;
                this.$store.state.listaRemolquesStore = catalogo;
                this.loading = false

            } catch (error) {
                this.loading = false
                console.log(error);
            }
        },
        eliminar(item) {
            this.dialogConfirm = true
            this.$store.state.remolqueStore = item
        },
        async deleteItem() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Eliminando remolque. Espere...', messageColor: 'white' })
            let remolque = this.$store.state.remolqueStore

            try {
                let response = await axios.put('CatalogosComprobantes/DeleteRemolque/erp_' + this.token.rfc + '/' + remolque._id)
                console.log(response)

                // SI LA ACCION ES ELIMINAR, LO QUITAMOS DE LA LISTA
                let indice = this.items.indexOf(remolque)
                this.items.splice(indice, 1)


                this.dialogConfirm = false
                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `El remolque ha sido eliminado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error, intentelo mas tarde.', color: 'red' })
            }
        },
        editar(data) {
            console.log(data)
            this.$store.state.remolqueStore = { ...data }
            this.dialogNuevo = true
        },
    }
}
</script>
    
<style></style>
<style lang="sass">
</style>
    