<template>
    <div class="row q-col-gutter-sm">
        <!-- DIALOG UBICACIONES -->
        <q-dialog v-model="dialogUbicaciones" transition-show="scale" transition-hide="scale">
            <q-card>
                <q-toolbar>
                    <q-toolbar-title>
                        {{ selectUbicacion.tipoUbicacion }}
                    </q-toolbar-title>
                </q-toolbar>
                <q-separator class="full-width" color="primary" inset size="4px" />
                <q-card-section>
                    <div class="row q-col-gutter-xs">
                        <!-- UBICACION -->
                        <div class="col-12">
                            <q-select v-model="selectUbicacion" use-input input-debounce="0" label="Ubicación"
                                :options="optionsUbicaciones" @filter="filterUbicaciones" fill-input hide-selected
                                :loading="loadingUbicaciones" dense outlined option-label="ubicacion">
                                <template v-slot:no-option>
                                    <q-item>
                                        <q-item-section class="text-grey">
                                            No exixte algún cliente que coincida con los criterios
                                        </q-item-section>
                                    </q-item>
                                </template>
                            </q-select>
                        </div>
                        <!-- FECHA -->
                        <div class="col-12">
                            <datetime v-model="selectUbicacion.fechaHoraSalidaLlegada" type="datetime"
                                :phrases="{ ok: 'Ok', cancel: 'Cancelar' }"
                                :format="{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit' }"
                                value-zone="America/Mexico_City" class="custom-datetime"></datetime>
                        </div>
                        <!-- DISTANCIA RECORRIDA -->
                        <template v-if="selectUbicacion.tipoUbicacion === 'DE'">
                            <div class="col-12">
                                <q-input outlined v-model="selectUbicacion.distanciaRecorrida"
                                    label="Distacia recorrida" dense>
                                    <template v-slot:append>
                                        KM
                                    </template>
                                </q-input>
                            </div>
                        </template>
                        <!-- DIRECCION -->
                        <template v-if="selectUbicacion">
                            <div class="col-12">
                                <q-expansion-item label="Dirección" header-class="bg-primary text-white">
                                    <div class="row q-col-gutter-xs" v-if="selectUbicacion.domicilio">
                                        <!-- CALLE -->
                                        <div class="col-12">
                                            <q-input outlined v-model="selectUbicacion.domicilio.calle" label="Calle"
                                                dense readonly>
                                            </q-input>
                                        </div>
                                        <!-- NUMERO EXTERIOR -->
                                        <div class="col-12">
                                            <q-input outlined v-model="selectUbicacion.domicilio.numeroExterior"
                                                label="Num. Exterior" dense readonly>
                                            </q-input>
                                        </div>
                                        <!-- NUMERO INTERIOR -->
                                        <div class="col-12">
                                            <q-input outlined v-model="selectUbicacion.domicilio.numeroInterior"
                                                label="Num. Interior" dense readonly>
                                            </q-input>
                                        </div>
                                        <!-- COLONIA -->
                                        <div class="col-12" v-if="selectUbicacion.domicilio.colonia">
                                            <q-input outlined v-model="selectUbicacion.domicilio.colonia.colonia"
                                                label="Colonia" dense readonly>
                                            </q-input>
                                        </div>
                                        <!-- LOCALIDAD -->
                                        <div class="col-12" v-if="selectUbicacion.domicilio.localidad">
                                            <q-input outlined v-model="selectUbicacion.domicilio.localidad.localidad"
                                                label="Localidad" dense readonly>
                                            </q-input>
                                        </div>
                                        <!-- REFERENCIA -->
                                        <div class="col-12">
                                            <q-input outlined v-model="selectUbicacion.domicilio.referencia"
                                                label="Referencia" dense readonly>
                                            </q-input>
                                        </div>
                                        <!-- MUNICIPIO -->
                                        <div class="col-12" v-if="selectUbicacion.domicilio.municipio">
                                            <q-input outlined v-model="selectUbicacion.domicilio.municipio.municipio"
                                                label="Municipio" dense readonly>
                                            </q-input>
                                        </div>
                                        <!-- ESTADO -->
                                        <div class="col-12" v-if="selectUbicacion.domicilio.estado">
                                            <q-input outlined v-model="selectUbicacion.domicilio.estado.estado"
                                                label="Estado" dense readonly>
                                            </q-input>
                                        </div>
                                        <!-- PAIS -->
                                        <div class="col-12" v-if="selectUbicacion.domicilio.pais">
                                            <q-input outlined v-model="selectUbicacion.domicilio.pais.pais" label="Pais"
                                                dense readonly>
                                            </q-input>
                                        </div>
                                        <!-- CODIGO POSTAL -->
                                        <div class="col-12">
                                            <q-input outlined v-model="selectUbicacion.domicilio.codigoPostal"
                                                label="Código postal" dense readonly>
                                            </q-input>
                                        </div>
                                    </div>
                                </q-expansion-item>
                            </div>
                        </template>
                    </div>
                </q-card-section>
                <q-card-actions align="around">
                    <q-btn flat v-close-popup>Cerrar</q-btn>
                    <q-btn flat @click="PushUbicacion">Agregar</q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>
        <!-- DIALOG MERCANCIAS -->
        <q-dialog v-model="dialogMercancia" transition-show="scale" transition-hide="scale">
            <q-card>
                <q-toolbar>
                    <q-toolbar-title>
                        MERCANCIA
                    </q-toolbar-title>
                </q-toolbar>
                <q-separator class="full-width" color="primary" inset size="4px" />
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <!-- MERCANCIA -->
                        <div class="col-12">
                            <q-select v-model="selectMercancia" use-input input-debounce="0" label="Mercancia"
                                :options="optionsMercancias" @filter="filterMercancias" fill-input hide-selected
                                :loading="loadingMercancias" dense outlined option-label="descripcion">
                                <template v-slot:no-option>
                                    <q-item>
                                        <q-item-section class="text-grey">
                                            No exixte algúna mercancia que coincida con los criterios
                                        </q-item-section>
                                    </q-item>
                                </template>
                            </q-select>
                        </div>
                        <!-- BIENES TRANSPORTADOS -->
                        <div class="col-12">
                            <q-select v-model="selectMercancia.bienesTransp" use-input input-debounce="0"
                                label="Bienes trasnsportados" :options="optionsBienesTransp"
                                @filter="filterBienesTransp" fill-input hide-selected :loading="loadingBienesTransp"
                                dense outlined option-label="claveProdServCP" bottom-slots>
                                <template v-slot:no-option>
                                    <q-item>
                                        <q-item-section class="text-grey">
                                            No exixte algúna mercancia que coincida con los criterios
                                        </q-item-section>
                                    </q-item>
                                </template>
                                <template v-slot:hint>
                                    *Obligatorio
                                </template>
                            </q-select>
                        </div>
                        <!-- DESCRIPCION -->
                        <div class="col-12">
                            <q-input outlined v-model="selectMercancia.descripcion" label="Descripción" dense
                                bottom-slots>
                                <template v-slot:hint>
                                    *Obligatorio
                                </template>
                            </q-input>
                        </div>
                        <!-- CANTIDAD -->
                        <div class="col-12">
                            <q-input outlined v-model="selectMercancia.cantidad" label="Cantidad" dense bottom-slots>
                                <template v-slot:hint>
                                    *Obligatorio
                                </template>
                            </q-input>
                        </div>
                        <!-- CLAVE UNIDAD -->
                        <div class="col-12 col-md-6">
                            <q-select v-model="selectMercancia.claveUnidad" use-input input-debounce="0"
                                label="Clave Unidad (SAT)" :options="optionsClaveUnidad" @filter="filterClaveUnidad"
                                fill-input hide-selected :loading="loadingClaveUnidad" dense outlined
                                option-label="claveUnidad" bottom-slots>
                                <template v-slot:no-option>
                                    <q-item>
                                        <q-item-section class="text-grey">
                                            No exixte algúna clave del SAT que coincida con los criterios
                                        </q-item-section>
                                    </q-item>
                                </template>
                                <template v-slot:hint>
                                    *Obligatorio
                                </template>
                            </q-select>
                        </div>
                        <!-- UNIDAD -->
                        <div class="col-12 col-md-6">
                            <q-input outlined v-model="selectMercancia.unidad" label="Unidad" dense bottom-slots>
                                <template v-slot:hint>
                                    Opcional
                                </template>
                            </q-input>
                        </div>
                        <!-- DIMENSIONES -->
                        <div class="col-12">
                            <q-input outlined v-model="selectMercancia.dimensiones" label="Dimensiones" dense
                                bottom-slots>
                                <template v-slot:hint>
                                    Opcional
                                </template>
                            </q-input>
                        </div>
                        <!-- MATERIAL PELIGROSO -->
                        <div class="col-12">
                            <q-select v-model="selectMercancia.materialPeligroso" label="Material peligroso"
                                :options="optionsTranspInternac" dense outlined bottom-slots>
                                <template v-slot:hint>
                                    *Obligatorio
                                </template>
                            </q-select>
                        </div>
                        <!-- SI ES MATERIAL PELIGROSO, MUESTRA LOS SIGUIENTES CAMPOS -->
                        <template v-if="selectMercancia.materialPeligroso === 'Sí'">
                            <!-- CLAVE DEL MATERIAL PELIGROSO -->
                            <div class="col-12">
                                <q-select v-model="selectMercancia.cveMaterialPeligroso" use-input input-debounce="0"
                                    label="Clave material peligroso" :options="optionsMaterialPeligroso"
                                    @filter="filterMaterialPeligroso" fill-input hide-selected
                                    :loading="loadingMaterialPeligroso" dense outlined option-label="materialPeligroso"
                                    bottom-slots>
                                    <template v-slot:no-option>
                                        <q-item>
                                            <q-item-section class="text-grey">
                                                No exixte algúna clave del SAT que coincida con los criterios
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                    <template v-slot:hint>
                                        *Obligatorio si la opción matrial peligroso es Sí
                                    </template>
                                </q-select>
                            </div>
                            <!-- TIPO DE EMBALAJE -->
                            <div class="col-12 col-md-12">
                                <q-select v-model="selectMercancia.embalaje" use-input input-debounce="0"
                                    label="Tipo de embalaje" :options="optionsTipoEmbalaje" @filter="filterTipoEmbalaje"
                                    fill-input hide-selected :loading="loadingTipoEmbalaje" dense outlined
                                    option-label="tipoEmbalaje" bottom-slots>
                                    <template v-slot:no-option>
                                        <q-item>
                                            <q-item-section class="text-grey">
                                                No exixte algúna clave del SAT que coincida con los criterios
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                    <template v-slot:hint>
                                        Opcional
                                    </template>
                                </q-select>
                            </div>
                            <!-- DESCRIPCION DEL EMBALAJE -->
                            <div class="col-12">
                                <q-input outlined v-model="selectMercancia.descripEmbalaje"
                                    label="Descripción del embalaje" dense bottom-slots>
                                    <template v-slot:hint>
                                        Opcional
                                    </template>
                                </q-input>
                            </div>
                        </template>
                        <!-- PESO EN KG -->
                        <div class="col-12">
                            <q-input outlined v-model="selectMercancia.pesoEnKg" label="Peso en KG" dense bottom-slots>
                                <template v-slot:hint>
                                    *Obligatorio
                                </template>
                            </q-input>
                        </div>
                        <template v-if="cartaPorte.transpInternac === 'Sí'">
                            <!-- FRACCION ARANCELARIA -->
                            <div class="col-12">
                                <q-select v-model="selectMercancia.fraccionArancelaria" use-input input-debounce="0"
                                    label="Fracción arancelaria" :options="optionsFraccionArancelaria"
                                    @filter="filterFraccionArancelaria" fill-input hide-selected
                                    :loading="loadingFraccionArancelaria" dense outlined
                                    option-label="fraccionArancelaria" bottom-slots>
                                    <template v-slot:no-option>
                                        <q-item>
                                            <q-item-section class="text-grey">
                                                No exixte algúna clave del SAT que coincida con los criterios
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                    <template v-slot:hint>
                                        Opcional
                                    </template>
                                </q-select>
                            </div>
                            <!-- UUID COMERCIO EXTERIOR -->
                            <div class="col-12">
                                <q-input outlined v-model="selectMercancia.uuidComercioExt"
                                    label="UUID comercio exterior" dense bottom-slots>
                                    <template v-slot:hint>
                                        Opcional
                                    </template>
                                </q-input>
                            </div>
                            <!-- TIPO MATERIA -->
                            <div class="col-12">
                                <q-select v-model="selectMercancia.tipoMateria" use-input input-debounce="0"
                                    label="Tipo Materia" :options="optionsTipoMateria" @filter="filterTipoMateria"
                                    fill-input hide-selected :loading="loadingTipoMateria" dense outlined
                                    option-label="tipoMateria" bottom-slots>
                                    <template v-slot:no-option>
                                        <q-item>
                                            <q-item-section class="text-grey">
                                                No exixte algúna clave del SAT que coincida con los criterios
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                    <template v-slot:hint>
                                        *Obligatorio
                                    </template>
                                </q-select>
                            </div>
                            <!-- DESCRIPCION DE MATERIA -->
                            <template v-if="selectMercancia.tipoMateria">
                                <template v-if="selectMercancia.tipoMateria.clave === '05'">
                                    <div class="col-12">
                                        <q-input outlined v-model="selectMercancia.descripcionMateria"
                                            label="Descripción materia" dense bottom-slots>
                                            <template v-slot:hint>
                                                *Obligatorio
                                            </template>
                                        </q-input>
                                    </div>
                                </template>
                            </template>
                        </template>
                        <!-- DOCUMENTACION ADUANERA -->
                        <div class="col-12">
                            <q-table title="Documentación aduanera" :data="selectMercancia.documentacionAduanera"
                                :columns="columnsDocumentacionAduanera" row-key="item" :rows-per-page-options="[10]"
                                class="my-sticky-column-table">
                                <template v-slot:top-right>
                                    <q-btn push color="white" text-color="primary" round icon="mdi-plus"
                                        @click="openDialogDocumentacionAduanera">
                                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                            content-style="font-size: 14px" :offset="[10, 10]">Agregar documentación
                                            aduanera</q-tooltip>
                                    </q-btn>
                                </template>
                                <template v-slot:body="props">
                                    <q-tr :props="props">
                                        <q-td auto-width>
                                            <q-btn size="md" color="primary" rounded flat dense
                                                @click="DeleteDocumentacionAduanera(props.row)" icon="mdi-delete">
                                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                                    content-style="font-size: 14px"
                                                    :offset="[10, 10]">Eliminar</q-tooltip>
                                            </q-btn>
                                        </q-td>
                                        <q-td key="tipoDocumento" :props="props">{{
            props.row.tipoDocumento.documentoAduanero }}</q-td>
                                        <q-td key="numPedimento" :props="props">{{ props.row.numPedimento }}</q-td>
                                        <q-td key="rfcImpo" :props="props">{{ props.row.rfcImpo }}</q-td>
                                    </q-tr>
                                </template>
                            </q-table>
                        </div>
                        <!-- CANTIDAD TRANSPORTA -->
                        <div class="col-12">
                            <q-table title="Cantidad transporta" :data="selectMercancia.cantidadTransporta"
                                :columns="columnsCantidadtransporta" row-key="item" :rows-per-page-options="[10]"
                                class="my-sticky-column-table">
                                <template v-slot:top-right>
                                    <q-btn push color="white" text-color="primary" round icon="mdi-plus"
                                        @click="openDialogCantidadTransporta">
                                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                            content-style="font-size: 14px" :offset="[10, 10]">Agregar cantidad
                                            transporta</q-tooltip>
                                    </q-btn>
                                </template>
                                <template v-slot:body="props">
                                    <q-tr :props="props">
                                        <q-td auto-width>
                                            <q-btn size="md" color="primary" rounded flat dense
                                                @click="DeleteCantidadTranposta(props.row)" icon="mdi-delete">
                                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                                    content-style="font-size: 14px"
                                                    :offset="[10, 10]">Eliminar</q-tooltip>
                                            </q-btn>
                                        </q-td>
                                        <q-td key="cantidad" :props="props">{{ props.row.cantidad }}</q-td>
                                        <q-td key="idOrigen" :props="props">{{ props.row.idOrigen }}</q-td>
                                        <q-td key="idDestino" :props="props">{{ props.row.idDestino }}</q-td>
                                        <q-td key="cveTransporte" :props="props">{{
            props.row.cveTransporte.cveTransporte
        }}</q-td>
                                    </q-tr>
                                </template>
                            </q-table>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="around">
                    <q-btn flat v-close-popup>Cerrar</q-btn>
                    <q-btn flat @click="PushMercancia">Agregar</q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>
        <!-- DIALOG DOCUMENTACION ADUANERA -->
        <q-dialog v-model="dialogDocumentacionAduanera" transition-show="scale" transition-hide="scale">
            <q-card>
                <q-toolbar>
                    <q-toolbar-title>
                        DOCUMENTACIÓN ADUANERA
                    </q-toolbar-title>
                </q-toolbar>
                <q-separator class="full-width" color="primary" inset size="4px" />
                <q-card-section>
                    <div class="row q-col-gutter-xs">
                        <!-- TIPO DE DOCUMENTO -->
                        <div class="col-12">
                            <q-select v-model="selectDocumentacionAduanera.tipoDocumento" use-input input-debounce="0"
                                label="Documento aduanero" :options="optionsDocumentoAduanero"
                                @filter="filterDocumentoAduanero" fill-input hide-selected
                                :loading="loadingDocumentoAduanero" dense outlined option-label="documentoAduanero"
                                bottom-slots>
                                <template v-slot:no-option>
                                    <q-item>
                                        <q-item-section class="text-grey">
                                            No exixte algúna clave del SAT que coincida con los criterios
                                        </q-item-section>
                                    </q-item>
                                </template>
                                <template v-slot:hint>
                                    *Obligatorio
                                </template>
                            </q-select>
                        </div>
                        <!-- NUMERO DE PEDIMENTO -->
                        <div class="col-12">
                            <q-input outlined v-model="selectDocumentacionAduanera.numPedimento" label="Num. Pedimento"
                                dense bottom-slots>
                                <template v-slot:hint>
                                    *Obligatorio
                                </template>
                            </q-input>
                        </div>
                        <!-- RFC IMPORTADOR -->
                        <div class="col-12">
                            <q-input outlined v-model="selectDocumentacionAduanera.rfcImpo" label="RFC importación"
                                dense bottom-slots>
                                <template v-slot:hint>
                                    Opcional
                                </template>
                            </q-input>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="around">
                    <q-btn flat v-close-popup>Cerrar</q-btn>
                    <q-btn flat @click="PushDocumentacionAduanera">Agregar</q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>
        <!-- DIALOG CANTIDAD TRANSPORTA -->
        <q-dialog v-model="dialogCantidadTransporta" transition-show="scale" transition-hide="scale">
            <q-card>
                <q-toolbar>
                    <q-toolbar-title>
                        CANTIDAD TRANSPORTA
                    </q-toolbar-title>
                </q-toolbar>
                <q-separator class="full-width" color="primary" inset size="4px" />
                <q-card-section>
                    <div class="row q-col-gutter-xs">
                        <!-- CANTIDAD TRANSPORTA -->
                        <div class="col-12">
                            <q-input outlined v-model="selectCantidadTransporta.cantidad" label="Cantidad" dense
                                bottom-slots>
                                <template v-slot:hint>
                                    *Obligatorio
                                </template>
                            </q-input>
                        </div>
                        <!-- ID ORIGEN -->
                        <div class="col-12">
                            <q-select v-model="selectCantidadTransporta.idOrigen" label="Id Origen"
                                :options="optionsOrigenCantdadTransporta" dense outlined bottom-slots>
                                <template v-slot:hint>
                                    *Obligatorio
                                </template>
                            </q-select>
                        </div>
                        <!-- ID DESTINO -->
                        <div class="col-12">
                            <q-select v-model="selectCantidadTransporta.idDestino" label="Id Destino"
                                :options="optionsDestinoCantdadTransporta" dense outlined bottom-slots>
                                <template v-slot:hint>
                                    *Obligatorio
                                </template>
                            </q-select>
                        </div>
                        <!-- CLAVE DE TRANSPORTE -->
                        <div class="col-12">
                            <q-select v-model="selectCantidadTransporta.cveTransporte" label="Clave de trasporte"
                                :options="optionsCveTransporte" dense outlined option-label="cveTransporte" readonly
                                bottom-slots>
                                <template v-slot:hint>
                                    *Obligatorio
                                </template>
                            </q-select>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="around">
                    <q-btn flat v-close-popup>Cerrar</q-btn>
                    <q-btn flat @click="PushCantidadTranposta">Agregar</q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>
        <!-- DIALOG REMOLQUE -->
        <q-dialog v-model="dialogRemolque" transition-show="scale" transition-hide="scale">
            <q-card>
                <q-toolbar>
                    <q-toolbar-title>
                        REMOLQUES
                    </q-toolbar-title>
                </q-toolbar>
                <q-separator class="full-width" color="primary" inset size="4px" />
                <q-card-section>
                    <div class="row q-col-gutter-xs">
                        <!-- REMOLQUE -->
                        <div class="col-12">
                            <q-select v-model="selectRemolque" label="Remolque (placa)" :options="optionsRemolque" dense
                                outlined option-label="placa">
                            </q-select>
                        </div>
                        <template v-if="selectRemolque.subTipoRem">
                            <!-- SUB TIPO DE REMOLQUE -->
                            <div class="col-12">
                                <q-input outlined v-model="selectRemolque.subTipoRem.subTipoRem"
                                    label="Sub tipo de remolque" dense readonly>
                                </q-input>
                            </div>
                            <!-- PLACA DEL REMOLQUE -->
                            <div class="col-12">
                                <q-input outlined v-model="selectRemolque.placa" label="Placa" dense readonly>
                                </q-input>
                            </div>
                        </template>
                    </div>
                </q-card-section>
                <q-card-actions align="around">
                    <q-btn flat v-close-popup>Cerrar</q-btn>
                    <q-btn flat @click="PushRemolque">Agregar</q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>
        <!-- DIALOG FIGURA DE TRANSPORTE -->
        <q-dialog v-model="dialogFiguraTransporte" transition-show="scale" transition-hide="scale">
            <q-card>
                <q-toolbar>
                    <q-toolbar-title>
                        FIGURA DE TRANSPORTE
                    </q-toolbar-title>
                </q-toolbar>
                <q-separator class="full-width" color="primary" inset size="4px" />
                <q-card-section>
                    <div class="row q-col-gutter-xs">
                        <!-- TIPO DE FIGURA -->
                        <div class="col-12">
                            <q-select v-model="selectTipoFigura" label="Tipo de figura"
                                :options="optionsTipoFiguraTransporte" dense outlined option-label="tipoFigura"
                                @input="OnChangeTipoFiguraTransporte">
                            </q-select>
                        </div>
                        <template v-if="selectTipoFigura">
                            <!-- FIGURA DE TRANSPORTE -->
                            <div class="col-12">
                                <q-select v-model="selectFiguraTransporte" use-input input-debounce="0"
                                    label="Figura de transporte (nombre)" :options="optionsFiguraTransporte"
                                    @filter="filterFiguraTransporte" fill-input hide-selected
                                    :loading="loadingFiguraTransporte" dense outlined option-label="nombreFigura">
                                    <template v-slot:no-option>
                                        <q-item>
                                            <q-item-section class="text-grey">
                                                No exixte algúna figura de transporte que coincida con los criterios
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                </q-select>
                                <!-- <q-select v-model="selectFiguraTransporte" label="Figura de transporte (nombre)"
                                    :options="optionsFiguraDeTransporte" dense outlined option-label="nombreFigura">
                                </q-select> -->
                            </div>
                        </template>
                        <template v-if="selectFiguraTransporte">
                            <!-- RFC -->
                            <div class="col-12">
                                <q-input outlined v-model="selectFiguraTransporte.rfcFigura" label="RFC" dense readonly>
                                </q-input>
                            </div>
                            <!-- NOMBRE -->
                            <div class="col-12">
                                <q-input outlined v-model="selectFiguraTransporte.nombreFigura" label="Nombre" dense
                                    readonly>
                                </q-input>
                            </div>
                            <!-- NUMERO DE LICENCIA -->
                            <div class="col-12" v-if="selectFiguraTransporte.numLicencia != ''">
                                <q-input outlined v-model="selectFiguraTransporte.numLicencia" label="Num. Licencia"
                                    dense readonly>
                                </q-input>
                            </div>
                        </template>
                        <template v-if="selectFiguraTransporte">
                            <div class="col-12">
                                <q-expansion-item label="Dirección" header-class="bg-primary text-white">
                                    <div class="row q-col-gutter-xs" v-if="selectFiguraTransporte.domicilio">
                                        <!-- CALLE -->
                                        <div class="col-12">
                                            <q-input outlined v-model="selectFiguraTransporte.domicilio.calle"
                                                label="Calle" dense readonly>
                                            </q-input>
                                        </div>
                                        <!-- NUMERO EXTERIOR -->
                                        <div class="col-12">
                                            <q-input outlined v-model="selectFiguraTransporte.domicilio.numeroExterior"
                                                label="Num. Exterior" dense readonly>
                                            </q-input>
                                        </div>
                                        <!-- NUMERO INTERIOR -->
                                        <div class="col-12">
                                            <q-input outlined v-model="selectFiguraTransporte.domicilio.numeroInterior"
                                                label="Num. Interior" dense readonly>
                                            </q-input>
                                        </div>
                                        <!-- COLONIA -->
                                        <div class="col-12" v-if="selectFiguraTransporte.domicilio.colonia">
                                            <q-input outlined v-model="selectFiguraTransporte.domicilio.colonia.colonia"
                                                label="Colonia" dense readonly>
                                            </q-input>
                                        </div>
                                        <!-- LOCALIDAD -->
                                        <div class="col-12" v-if="selectFiguraTransporte.domicilio.localidad">
                                            <q-input outlined
                                                v-model="selectFiguraTransporte.domicilio.localidad.localidad"
                                                label="Localidad" dense readonly>
                                            </q-input>
                                        </div>
                                        <!-- REFERENCIA -->
                                        <div class="col-12">
                                            <q-input outlined v-model="selectFiguraTransporte.domicilio.referencia"
                                                label="Referencia" dense readonly>
                                            </q-input>
                                        </div>
                                        <!-- MUNICIPIO -->
                                        <div class="col-12" v-if="selectFiguraTransporte.domicilio.municipio">
                                            <q-input outlined
                                                v-model="selectFiguraTransporte.domicilio.municipio.municipio"
                                                label="Municipio" dense readonly>
                                            </q-input>
                                        </div>
                                        <!-- ESTADO -->
                                        <div class="col-12" v-if="selectFiguraTransporte.domicilio.estado">
                                            <q-input outlined v-model="selectFiguraTransporte.domicilio.estado.estado"
                                                label="Estado" dense readonly>
                                            </q-input>
                                        </div>
                                        <!-- PAIS -->
                                        <div class="col-12" v-if="selectFiguraTransporte.domicilio.pais">
                                            <q-input outlined v-model="selectFiguraTransporte.domicilio.pais.pais"
                                                label="Pais" dense readonly>
                                            </q-input>
                                        </div>
                                        <!-- CODIGO POSTAL -->
                                        <div class="col-12">
                                            <q-input outlined v-model="selectFiguraTransporte.domicilio.codigoPostal"
                                                label="Código postal" dense readonly>
                                            </q-input>
                                        </div>
                                    </div>
                                </q-expansion-item>
                            </div>
                            <div class="col-12">
                                <q-expansion-item label="Parte del transporte" header-class="bg-primary text-white">
                                    <div class="row q-col-gutter-xs">
                                        <div class="col-12">

                                            <q-table title="Partes del transporte"
                                                :data="selectFiguraTransporte.partesTransporte"
                                                :columns="columnsPartesTransporte" row-key="item"
                                                :rows-per-page-options="[10]" class="my-sticky-column-table">
                                                <template v-slot:body="props">
                                                    <q-tr :props="props">
                                                        <q-td key="clave" :props="props">{{ props.row.clave }}</q-td>
                                                        <q-td key="descripcion" :props="props">{{ props.row.descripcion
                                                            }}</q-td>
                                                    </q-tr>
                                                </template>
                                            </q-table>
                                        </div>
                                    </div>
                                </q-expansion-item>
                            </div>
                        </template>
                    </div>
                </q-card-section>
                <q-card-actions align="around">
                    <q-btn flat v-close-popup>Cerrar</q-btn>
                    <q-btn flat @click="PushFiguraDeTransporte">Agregar</q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>
        <!-- DATOS GENERALES -->
        <div class="col-12">
            <q-select v-model="cartaPorte.transpInternac" label="Transp Internacional" :options="optionsTranspInternac"
                dense outlined>
            </q-select>
        </div>
        <template v-if="cartaPorte.transpInternac === 'Sí'">
            <!-- REGIMEN ADUANERO -->
            <div class="col-12 col-md-3">
                <q-select v-model="cartaPorte.regimenAduanero" label="Régimen aduanero"
                    :options="optionsRegimenAduanero" dense outlined option-label="regimenAduanero" fill-input
                    hide-selected use-input>
                </q-select>
            </div>
            <!-- ENTRADA O SALIDA DE MERCANCIAS -->
            <div class="col-12 col-md-3">
                <q-select v-model="cartaPorte.entradaSalidaMerc" label="Entrada o salida"
                    :options="optionsEntradaSalidaMerc" dense outlined>
                </q-select>
            </div>
            <!-- PAIS DE ORIGEN O DESTINO -->
            <div class="col-12 col-md-3">
                <q-select v-model="cartaPorte.paisOrigenDestino" use-input input-debounce="0"
                    label="Pais de origen o destino" :options="optionsPais" @filter="filterPais" fill-input
                    hide-selected :loading="loadingPais" dense outlined option-label="pais">
                    <template v-slot:no-option>
                        <q-item>
                            <q-item-section class="text-grey">
                                No exixte algún cliente que coincida con los criterios
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>
            </div>
            <!-- CLAVE DE TRANSPORTE -->
            <div class="col-12 col-md-3">
                <q-select v-model="cartaPorte.viaEntradaSalida" label="Clave de trasporte"
                    :options="optionsCveTransporte" dense outlined option-label="cveTransporte" readonly>
                </q-select>
            </div>
        </template>
        <!-- TOTAL DISTANCIA RECORRIDA -->
        <div class="col-12">
            <q-input outlined v-model="cartaPorte.totalDistRec" label="Total distancia recorrida" dense>
                <template v-slot:append>
                    KM
                </template>
            </q-input>
        </div>
        <!-- UBICACIONES -->
        <q-linear-progress size="40px" :value="progress1" color="primary" class="q-mt-sm">
            <div class="absolute-full flex flex-center">
                <q-badge color="primary" text-color="white" label="UBICACIONES" class="text-h5" />
            </div>
        </q-linear-progress>
        <div class="col-12">
            <q-table title="Ubicaciones" :data="cartaPorte.ubicaciones" :columns="columnsUbicaciones" row-key="item"
                :rows-per-page-options="[10]" class="my-sticky-column-table">
                <template v-slot:top-right>
                    <q-btn push color="white" text-color="primary" round icon="mdi-plus" @click="OpenDialogUbicaciones">
                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                            content-style="font-size: 14px" :offset="[10, 10]">Agregar ubicación</q-tooltip>
                    </q-btn>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width>
                            <q-btn size="md" color="primary" rounded flat dense @click="DeleteUbicacion(props.row)"
                                icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px" :offset="[10, 10]">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="tipoUbicacion" :props="props">{{ props.row.tipoUbicacion }}</q-td>
                        <q-td key="ubicacion" :props="props">{{ props.row.ubicacion }}</q-td>
                        <q-td key="fechaHoraSalidaLlegada" :props="props">{{ props.row.fechaHoraSalidaLlegada }}</q-td>
                        <q-td key="distanciaRecorrida" :props="props">{{ props.row.distanciaRecorrida }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
        <!-- MERCANCIAS -->
        <q-linear-progress size="40px" :value="progress1" color="primary" class="q-mt-sm">
            <div class="absolute-full flex flex-center">
                <q-badge color="primary" text-color="white" label="MERCANCIAS" class="text-h5" />
            </div>
        </q-linear-progress>
        <!-- PESO BRUTO TOTAL -->
        <div class="col-12 col-md-3">
            <q-input outlined v-model="cartaPorte.mercancias.pesoBrutoTotal" label="Peso bruto total" dense>
                <template v-slot:append>
                    KG
                </template>
            </q-input>
        </div>
        <!-- UNIDAD DE PESO -->
        <div class="col-12 col-md-3">
            <q-input outlined v-model="cartaPorte.mercancias.unidadPeso.claveUnidadPeso" label="Unidad de Peso" dense
                readonly>
            </q-input>
        </div>
        <!-- NUM TOTAL DE MERCANCIAS -->
        <div class="col-12 col-md-3">
            <q-input outlined v-model="cartaPorte.mercancias.numTotalMercancias" label="Num. Total de Mercancias" dense>
            </q-input>
        </div>
        <!-- LOGISTICA INVERSA RECOLECCION DEVOLUCION  -->
        <div class="col-12 col-md-3">
            <q-select v-model="cartaPorte.mercancias.logisticaInversaRecoleccionDevolucion"
                label="Logística inversa de recolección o devolución" :options="optionsTranspInternac" dense outlined>
            </q-select>
        </div>
        <!-- TABLA CON MERCANCIAS -->
        <div class="col-12">
            <q-table title="Mercancias" :data="cartaPorte.mercancias.mercancia" :columns="columnsMercancia"
                row-key="item" :rows-per-page-options="[10]" class="my-sticky-column-table">
                <template v-slot:top-right>
                    <q-btn push color="white" text-color="primary" round icon="mdi-plus" @click="openDialogMercancia">
                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                            content-style="font-size: 14px" :offset="[10, 10]">Agregar mercancía</q-tooltip>
                    </q-btn>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width>
                            <q-btn size="md" color="primary" rounded flat dense @click="DeleteMercancia(props.row)"
                                icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px" :offset="[10, 10]">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="bienesTransp" :props="props">{{ props.row.bienesTransp.claveProdServCP }}</q-td>
                        <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                        <q-td key="cantidad" :props="props">{{ props.row.cantidad }}</q-td>
                        <q-td key="claveUnidad" :props="props">{{ props.row.claveUnidad.claveUnidad }}</q-td>
                        <q-td key="materialPeligroso" :props="props">{{ props.row.materialPeligroso }}</q-td>
                        <q-td key="pesoEnKg" :props="props">{{ props.row.pesoEnKg }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
        <!-- AUTO TRANSPORTE -->
        <q-linear-progress size="40px" :value="progress1" color="primary" class="q-mt-sm">
            <div class="absolute-full flex flex-center">
                <q-badge color="primary" text-color="white" label="PERMISO SCT" class="text-h5" />
            </div>
        </q-linear-progress>
        <!-- PERMISO SCT -->
        <div class="col-12 col-md-6">
            <q-select v-model="cartaPorte.mercancias.autotransporte.permSCT" label="Permiso SCT"
                :options="optionsTipoPermiso" dense outlined option-label="tipoPermiso">
            </q-select>
        </div>
        <!-- NUM PERMISO SCT -->
        <div class="col-12 col-md-6">
            <q-input outlined v-model="cartaPorte.mercancias.autotransporte.numPermisoSCT" label="Num. Permiso SCT"
                dense>
            </q-input>
        </div>
        <!-- IDENTIFICADOR VEHICULAR -->
        <q-linear-progress size="40px" :value="progress1" color="primary" class="q-mt-sm">
            <div class="absolute-full flex flex-center">
                <q-badge color="primary" text-color="white" label="IDENTIFICADOR VEHICULAR" class="text-h5" />
            </div>
        </q-linear-progress>
        <!-- IDENTIFICADOR VEHICULAR -->
        <div class="col-12 col-md-12">
            <q-select v-model="cartaPorte.mercancias.autotransporte.identificadorVehicular" use-input input-debounce="0"
                label="Identificador Vehicular (Placa)" :options="optionsIdentificacionVehicular"
                @filter="filterIdentificacionVehicular" fill-input hide-selected
                :loading="loadingIdentificacionVehicular" dense outlined option-label="placaVM">
                <template v-slot:no-option>
                    <q-item>
                        <q-item-section class="text-grey">
                            No exixte algúna mercancia que coincida con los criterios
                        </q-item-section>
                    </q-item>
                </template>
            </q-select>

            <!-- <q-select v-model="cartaPorte.mercancias.autotransporte.identificadorVehicular"
                label="Identificador Vehicular (Placa)" :options="optionsIdentificacionVehicular" dense outlined
                option-label="placaVM">
            </q-select> -->
        </div>
        <template v-if="cartaPorte.mercancias.autotransporte.identificadorVehicular.configVehicular">
            <!-- CONFIGURACION VEHICULAR -->
            <div class="col-12 col-md-12">
                <q-input outlined
                    v-model="cartaPorte.mercancias.autotransporte.identificadorVehicular.configVehicular.configAutotransporte"
                    label="Configuración vehicular" dense readonly>
                </q-input>
            </div>
            <!-- PESO BRUTO VEHICULAR -->
            <div class="col-12 col-md-4">
                <q-input outlined v-model="cartaPorte.mercancias.autotransporte.identificadorVehicular.placaVM"
                    label="Placa VM" dense readonly>
                </q-input>
            </div>
            <!-- PACA VM -->
            <div class="col-12 col-md-4">
                <q-input outlined
                    v-model="cartaPorte.mercancias.autotransporte.identificadorVehicular.pesoBrutoVehicular"
                    label="Peso bruto vehicular" dense readonly>
                </q-input>
            </div>
            <!-- AÑO MODELO VM -->
            <div class="col-12 col-md-4">
                <q-input outlined v-model="cartaPorte.mercancias.autotransporte.identificadorVehicular.anioModeloVM"
                    label="Año/Modelo VM" dense readonly>
                </q-input>
            </div>
        </template>
        <!-- SEGUROS -->
        <q-linear-progress size="40px" :value="progress1" color="primary" class="q-mt-sm">
            <div class="absolute-full flex flex-center">
                <q-badge color="primary" text-color="white" label="SEGUROS" class="text-h5" />
            </div>
        </q-linear-progress>
        <!-- SEGUROS -->
        <div class="col-12 col-md-12">
            <q-select v-model="cartaPorte.mercancias.autotransporte.seguros" use-input input-debounce="0"
                label="Seguro (Póliza resp. civíl)" :options="optionsSeguros" @filter="filterSeguros" fill-input
                hide-selected :loading="loadingSeguros" dense outlined option-label="polizaRespCivil">
                <template v-slot:no-option>
                    <q-item>
                        <q-item-section class="text-grey">
                            No exixte algúna mercancia que coincida con los criterios
                        </q-item-section>
                    </q-item>
                </template>
            </q-select>

            <!-- <q-select v-model="cartaPorte.mercancias.autotransporte.seguros" label="Seguro (Póliza resp. civíl)"
                :options="optionsSeguros" dense outlined option-label="polizaRespCivil">
            </q-select> -->
        </div>
        <!-- DATOS DEL SEGURO -->
        <template v-if="cartaPorte.mercancias.autotransporte.seguros">
            <!-- ASEGURA RESP CIVIL -->
            <div class="col-12 col-md-6">
                <q-input outlined v-model="cartaPorte.mercancias.autotransporte.seguros.aseguraRespCivil"
                    label="Aseguradora responsabilidad civíl" dense readonly>
                </q-input>
            </div>
            <!-- POLIZA RESP CIVIL -->
            <div class="col-12 col-md-6">
                <q-input outlined v-model="cartaPorte.mercancias.autotransporte.seguros.polizaRespCivil"
                    label="Póliza responsabilidad civíl" dense readonly>
                </q-input>
            </div>
            <!-- ASEGURA MEDIO AMBIENTE -->
            <div class="col-12 col-md-6">
                <q-input outlined v-model="cartaPorte.mercancias.autotransporte.seguros.aseguraMedAmbiente"
                    label="Aseguradora medio ambiente" dense readonly>
                </q-input>
            </div>
            <!-- POLIZA MEDIO AMBIENTE -->
            <div class="col-12 col-md-6">
                <q-input outlined v-model="cartaPorte.mercancias.autotransporte.seguros.polizaMedAmbiente"
                    label="Póliza medio ambiente" dense readonly>
                </q-input>
            </div>
            <!-- ASEGURA CARGA -->
            <div class="col-12 col-md-4">
                <q-input outlined v-model="cartaPorte.mercancias.autotransporte.seguros.aseguraCarga"
                    label="Aseguradora de la carga" dense readonly>
                </q-input>
            </div>
            <!-- POLIZA CARGA -->
            <div class="col-12 col-md-4">
                <q-input outlined v-model="cartaPorte.mercancias.autotransporte.seguros.polizaCarga"
                    label="Póliza de la carga" dense readonly>
                </q-input>
            </div>
            <!-- PRIMA SEGURO -->
            <div class="col-12 col-md-4">
                <q-input outlined v-model="cartaPorte.mercancias.autotransporte.seguros.primaSeguro"
                    label="Prima del seguro" dense readonly>
                </q-input>
            </div>
        </template>
        <!-- APARTADO DE LOS REMOLQUES -->
        <q-linear-progress size="40px" :value="progress1" color="primary" class="q-mt-sm">
            <div class="absolute-full flex flex-center">
                <q-badge color="primary" text-color="white" label="REMOLQUES" class="text-h5" />
            </div>
        </q-linear-progress>
        <!-- TABLA DE REMOLQUES -->
        <div class="col-12">
            <q-table title="Remolques" :data="cartaPorte.mercancias.autotransporte.remolques"
                :columns="columnsRemolques" row-key="item" :rows-per-page-options="[10]" class="my-sticky-column-table">
                <template v-slot:top-right>
                    <q-btn push color="white" text-color="primary" round icon="mdi-plus" @click="openDialogRemolque">
                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                            content-style="font-size: 14px" :offset="[10, 10]">Agregar remolque</q-tooltip>
                    </q-btn>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width>
                            <q-btn size="md" color="primary" rounded flat dense @click="DeleteRemolque(props.row)"
                                icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px" :offset="[10, 10]">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="subTipoRem" :props="props">{{ props.row.subTipoRem.subTipoRem }}</q-td>
                        <q-td key="placa" :props="props">{{ props.row.placa }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
        <!-- APARTADO DE FIGURAS DEL TRANSPORTE -->
        <q-linear-progress size="40px" :value="progress1" color="primary" class="q-mt-sm">
            <div class="absolute-full flex flex-center">
                <q-badge color="primary" text-color="white" label="FIGURAS DEL TRANSPORTE" class="text-h5" />
            </div>
        </q-linear-progress>
        <!-- TABLA DE FIGURAS DE TRANSPORTE -->
        <div class="col-12">
            <q-table title="Figuras de transporte" :data="cartaPorte.figuraTransporte"
                :columns="columnsFiguraTransporte" row-key="item" :rows-per-page-options="[10]"
                class="my-sticky-column-table">
                <template v-slot:top-right>
                    <q-btn push color="white" text-color="primary" round icon="mdi-plus"
                        @click="OpenDialogFiguraTransporte">
                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                            content-style="font-size: 14px" :offset="[10, 10]">Agregar figura de transporte</q-tooltip>
                    </q-btn>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width>
                            <q-btn size="md" color="primary" rounded flat dense
                                @click="DeleteFiguraDeTransporte(props.row)" icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px" :offset="[10, 10]">Eliminar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="rfcFigura" :props="props">{{ props.row.rfcFigura }}</q-td>
                        <q-td key="nombreFigura" :props="props">{{ props.row.nombreFigura }}</q-td>
                        <q-td key="tipoFigura" :props="props">{{ props.row.tipoFigura.tipoFigura }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar';
import moment from 'moment';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';

export default {
    components: {
        Datetime
    },
    data() {
        return {
            optionsTranspInternac: ["Sí", "No"],
            optionsEntradaSalidaMerc: ['Entrada', 'Salida'],
            optionsPais: [],
            loadingPais: false,
            progress1: 1,

            //UBICACIONES
            optionsUbicaciones: [],
            columnsUbicaciones: [
                { name: 'actions', label: 'Acciones', field: 'actions', align: 'left' },
                { name: 'tipoUbicacion', label: 'Tipo ubicación', field: 'tipoUbicacion', align: 'left' },
                { name: 'ubicacion', label: 'Ubicacion', field: 'nubicacion', align: 'left' },
                { name: 'fechaHoraSalidaLlegada', label: 'Fecha salida/llegada', field: 'fechaHoraSalidaLlegada', align: 'left' },
                { name: 'distanciaRecorrida', label: 'Distancia recorrida', field: 'distanciaRecorrida', align: 'left' },
            ],

            dialogUbicaciones: false,
            selectUbicacion: {
                _id: '',
                tipoUbicacion: 'ORIGEN',
                idUbicacion: '',
                rfcRemitenteDestinatario: '',
                nombreRemitenteDestinatario: '',
                numRegIdTrib: '',
                residenciaFiscal: '',
                fechaHoraSalidaLlegada: new Date().toISOString(),
                distanciaRecorrida: '',
                domicilio: null,
                ubicacion: '',
            },
            loadingUbicaciones: false,

            //MERCANCIAS
            columnsMercancia: [
                { name: 'actions', label: 'Acciones', field: 'actions', align: 'left' },
                { name: 'bienesTransp', label: 'Bienes transportados', field: 'bienesTransp', align: 'left' },
                { name: 'descripcion', label: 'Descripción', field: 'descripcion', align: 'left' },
                { name: 'cantidad', label: 'Cantidad', field: 'cantidad', align: 'left' },
                { name: 'claveUnidad', label: 'Clave unidad', field: 'claveUnidad', align: 'left' },
                { name: 'materialPeligroso', label: 'Material peligroso', field: 'materialPeligroso', align: 'left' },
                { name: 'pesoEnKg', label: 'Peso en KG', field: 'pesoEnKg', align: 'left' },
            ],
            dialogMercancia: false,
            optionsMercancias: [],
            loadingMercancias: false,
            selectMercancia: {
                _id: '',
                bienesTransp: null,
                descripcion: '',
                cantidad: 0,
                claveUnidad: null,
                unidad: '',
                dimensiones: '',
                materialPeligroso: '',
                cveMaterialPeligroso: null,
                embalaje: null,
                descripEmbalaje: '',
                pesoEnKg: 0,
                fraccionArancelaria: null,
                uuidComercioExt: '',
                tipoMateria: null,
                descripcionMateria: '',
                documentacionAduanera: [],
                cantidadTransporta: [],
            },
            // MERCANCIAS BIENES TRANSPORTADOS
            optionsBienesTransp: [],
            loadingBienesTransp: false,
            // MERCANCIAS CLAVE UNIDAD
            optionsClaveUnidad: [],
            loadingClaveUnidad: false,
            //MERCANCIAS MATERIAL PELIGROSO
            optionsMaterialPeligroso: [],
            loadingMaterialPeligroso: false,
            //MERCANCIAS TIPO EMBALAJE
            optionsTipoEmbalaje: [],
            loadingTipoEmbalaje: false,
            //MERCANCIAS FRACCION ARANCELARIA
            optionsFraccionArancelaria: [],
            loadingFraccionArancelaria: false,
            //MERCANCIAS TIPO MATERIA
            optionsTipoMateria: [],
            loadingTipoMateria: false,
            //MERCANCIAS DOCUMENTACION ADUANERA
            dialogDocumentacionAduanera: false,
            documentacionAduanera: [],
            columnsDocumentacionAduanera: [
                { name: 'actions', label: 'Acciones', field: 'actions', align: 'left' },
                { name: 'tipoDocumento', label: 'Tipo de documento', field: 'tipoDocumento', align: 'left' },
                { name: 'numPedimento', label: 'Num. Pedimento', field: 'numPedimento', align: 'left' },
                { name: 'rfcImpo', label: 'RFC importación', field: 'rfcImpo', align: 'left' },
            ],
            selectDocumentacionAduanera: {
                tipoDocumento: null,
                numPedimento: '',
                rfcImpo: '',
            },
            //MERCANCIAS DOCUMENTACION ADUANERA DOCUMENTO ADUANERO
            optionsDocumentoAduanero: [],
            loadingDocumentoAduanero: false,
            //CANTIDAD TRANSPORTA
            dialogCantidadTransporta: false,
            columnsCantidadtransporta: [
                { name: 'actions', label: 'Acciones', field: 'actions', align: 'left' },
                { name: 'cantidad', label: 'Cantidad', field: 'cantidad', align: 'left' },
                { name: 'idOrigen', label: 'Id Origen', field: 'idOrigen', align: 'left' },
                { name: 'idDestino', label: 'Id Destino', field: 'idDestino', align: 'left' },
                { name: 'cveTransporte', label: 'Clave trasporte', field: 'cveTransporte', align: 'left' },
            ],
            selectCantidadTransporta: {
                cantidad: 0,
                idOrigen: '',
                idDestino: '',
                cveTransporte: { "clave": "01", "descripcion": "Autotransporte", "cveTransporte": "01 | Autotransporte" },
            },
            // PERMISO SICT
            permSCT: null,
            numPermisoSCT: '',
            //REMOLQUES
            dialogRemolque: false,
            columnsRemolques: [
                { name: 'actions', label: 'Acciones', field: 'actions', align: 'left' },
                { name: 'subTipoRem', label: 'Sub tipo remolque', field: 'subTipoRem', align: 'left' },
                { name: 'placa', label: 'Placa', field: 'placa', align: 'left' },
            ],
            selectRemolque: {
                subTipoRem: null,
                placa: '',
            },

            //IDENTIFICADOR VEHICULAR
            optionsIdentificacionVehicular: [],
            loadingIdentificacionVehicular: false,

            //SEGUROS
            optionsSeguros: [],
            loadingSeguros: false,

            //FIGURAS DEL TRANSPORTE
            dialogFiguraTransporte: false,
            selectTipoFigura: null,
            optionsFiguraDeTransporte: [],
            columnsFiguraTransporte: [
                { name: 'actions', label: 'Acciones', field: 'actions', align: 'left' },
                { name: 'rfcFigura', label: 'RFC figura', field: 'rfcFigura', align: 'left' },
                { name: 'nombreFigura', label: 'Nombre figura', field: 'nombreFigura', align: 'left' },
                { name: 'tipoFigura', label: 'Tipo figura', field: 'tipoFigura', align: 'left' },
            ],
            columnsPartesTransporte: [
                { name: 'clave', label: 'Clave', field: 'clave', align: 'left' },
                { name: 'descripcion', label: 'Descripción', field: 'descripcion', align: 'left' },
            ],
            figuraTransporte: [],
            selectFiguraTransporte: null,
            optionsFiguraTransporte: [],
            loadingFiguraTransporte: false,

        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        cartaPorte() {
            return this.$store.state.comprobanteStore.comprobante.cartaPorte;
        },

        optionsRegimenAduanero() {
            return this.$store.state.regimenAduaneroStore;
        },

        optionsPaisDefault() {
            return this.$store.state.listaCatalogoPais;
        },

        optionsCveTransporte() {
            return this.$store.state.cveTransporteStore;
        },

        optionsUbicacionesDefault() {
            let ubicaciones = [];
            if (this.$store.state.comprobanteStore.comprobante.cartaPorte.ubicaciones.length == 0) {
                ubicaciones = this.$store.state.listaUbicacionesStore.filter(f => f.tipoUbicacion === 'OR');
            }
            else {
                ubicaciones = this.$store.state.listaUbicacionesStore.filter(f => f.tipoUbicacion === 'DE');
            }
            return ubicaciones;
        },

        optionsMercanciasDefault() {
            return this.$store.state.listaMercanciasStore;
        },

        optionsBienesTranspDefault() {
            return this.$store.state.listaCatalogoClaveProServCP;
        },

        optionsClaveUnidadDefault() {
            return this.$store.state.claveUnidadStore;
        },

        optionsMaterialPeligrosoDefault() {
            return this.$store.state.listaCatalogoMaterialPeligroso;
        },

        optionsTipoEmbalajeDefault() {
            return this.$store.state.listaCatalogoTipoEmbalaje;
        },

        optionsFraccionArancelariaDefault() {
            return this.$store.state.listaCatalogoFraccionArancelaria;
        },

        optionsTipoMateriaDefault() {
            return this.$store.state.listaCatalogoTipoMateria;
        },

        optionsDocumentoAduaneroDefault() {
            return this.$store.state.listaCatalogoDocAduanero;
        },

        optionsOrigenCantdadTransporta() {
            let filtro = this.cartaPorte.ubicaciones.filter(f => f.tipoUbicacion === 'OR')
            let final = filtro.map(o => o.ubicacion);
            return final;
        },

        optionsDestinoCantdadTransporta() {
            let filtro = this.cartaPorte.ubicaciones.filter(f => f.tipoUbicacion === 'DE')
            let final = filtro.map(o => o.ubicacion);
            return final;
        },

        optionsTipoPermiso() {
            return this.$store.state.tipoPermisoStore;
        },

        optionsIdentificacionVehicularDefault() {
            return this.$store.state.listaIdentificacionVehicularStore;
        },

        optionsSegurosDefault() {
            return this.$store.state.listaSegurosStore;
        },

        optionsRemolque() {
            return this.$store.state.listaRemolquesStore;
        },

        optionsTipoFiguraTransporte() {
            return this.$store.state.listaCatalogoTipoFigura;
        },

        optionsFiguraDeTransporteDefault() {
            return this.$store.state.listaFigurasTransporteStore;
        },


    },

    created() {
    },

    mounted() {
        this.GetIniciales();
    },

    methods: {
        async GetIniciales() {
            this.$q.loading.show({ group: 'first', message: 'Consultando los Regimenes Fiscales', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetRegimenAduanero();
            await this.GetPais();
            await this.GetClaveDeTransporte();
            await this.GetUbicaciones();
            await this.GetMercancias();
            await this.GetBienesTransp();
            await this.GetMaterialPeligroso();
            await this.GetTipoEmbalaje();
            await this.GetFraccionArancelaria();
            await this.GetCatTipoMateria();
            await this.GetCatDocumentoAduanero();
            await this.GetTipoPermiso();
            await this.GetIdentificadorVehicular();
            await this.GetSeguros();
            await this.GetRemolque();
            await this.GetFigurasTransporte();
            await this.GetTipoFiguraTransporte();
            this.$q.loading.hide()
        },

        async GetRegimenAduanero() {
            if (this.$store.state.regimenAduaneroStore.length == 0) {
                try {
                    let response = await axios.get("CatalogosSat/GetCatRegimenAduaneroAsync");
                    this.$store.state.regimenAduaneroStore = response.data;
                } catch (error) {
                    console.log(error);
                }
            }
        },

        async GetPais() {
            if (this.$store.state.listaCatalogoPais.length == 0) {
                try {
                    let response = await axios.get("CatalogosSat/GetCatPaisAsync");
                    this.$store.state.listaCatalogoPais = response.data;
                } catch (error) {
                    console.log(error);
                }
            }
        },

        async GetClaveDeTransporte() {
            if (this.$store.state.cveTransporteStore.length == 0) {
                try {
                    let response = await axios.get("CatalogosSat/GetCatCveTransporteAsync");
                    this.$store.state.cveTransporteStore = response.data;
                } catch (error) {
                    console.log(error);
                }
            }
        },

        async GetUbicaciones() {
            try {
                let response = await axios.get("CatalogosComprobantes/GetUbicaciones/erp_" + this.token.rfc);
                let catalogo = response.data;
                let fechaAct = new Date().toISOString();
                catalogo.forEach(objeto => {
                    objeto.fechaHoraSalidaLlegada = fechaAct;
                });

                this.$store.state.listaUbicacionesStore = catalogo;
            } catch (error) {
                console.log(error);
            }
        },

        async GetMercancias() {
            try {
                let response = await axios.get("CatalogosComprobantes/GetMercancias/erp_" + this.token.rfc);
                let catalogo = response.data;
                this.$store.state.listaMercanciasStore = catalogo;
            } catch (error) {
                console.log(error);
            }
        },

        async GetBienesTransp() {
            try {

                if (this.$store.state.listaCatalogoClaveProServCP.length == 0) {
                    try {
                        let response = await axios.get("CatalogosSat/GetClaveClaveProdServCPAsync");
                        this.$store.state.listaCatalogoClaveProServCP = response.data;
                    } catch (error) {
                        console.log(error);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },

        async GetMaterialPeligroso() {
            try {

                if (this.$store.state.listaCatalogoMaterialPeligroso.length == 0) {
                    try {
                        let response = await axios.get("CatalogosSat/GetCatMaterialPeligrosoAsync");
                        this.$store.state.listaCatalogoMaterialPeligroso = response.data;
                    } catch (error) {
                        console.log(error);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },

        async GetTipoEmbalaje() {
            try {

                if (this.$store.state.listaCatalogoTipoEmbalaje.length == 0) {
                    try {
                        let response = await axios.get("CatalogosSat/GetCatTipoEmbalajeAsync");
                        this.$store.state.listaCatalogoTipoEmbalaje = response.data;
                    } catch (error) {
                        console.log(error);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },

        async GetFraccionArancelaria() {
            if (this.$store.state.listaCatalogoFraccionArancelaria.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatFraccionArancelariaAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoFraccionArancelaria = catalogo;

            } catch (error) {
                console.log(error);
            }
        },

        async GetCatTipoMateria() {
            if (this.$store.state.listaCatalogoTipoMateria.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatTipoMateriaAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoTipoMateria = catalogo;

            } catch (error) {
                console.log(error);
            }
        },

        async GetCatDocumentoAduanero() {
            if (this.$store.state.listaCatalogoDocAduanero.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatDocumentoAduaneroAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoDocAduanero = catalogo;

            } catch (error) {
                console.log(error);
            }
        },

        async GetTipoPermiso() {
            if (this.$store.state.tipoPermisoStore.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatTipoPermisoAsync/");
                let catalogo = response.data;
                this.$store.state.tipoPermisoStore = catalogo;

            } catch (error) {
                console.log(error);
            }
        },

        async GetIdentificadorVehicular() {
            try {
                let response = await axios.get("CatalogosComprobantes/GetIdentVehicular/erp_" + this.token.rfc);
                let catalogo = response.data;
                this.$store.state.listaIdentificacionVehicularStore = catalogo;
            } catch (error) {
                console.log(error);
            }
        },

        async GetSeguros() {
            try {
                let response = await axios.get("CatalogosComprobantes/GetSeguros/erp_" + this.token.rfc);
                let catalogo = response.data;
                this.$store.state.listaSegurosStore = catalogo;
            } catch (error) {
                console.log(error);
            }
        },

        async GetRemolque() {
            try {
                let response = await axios.get("CatalogosComprobantes/GetRemolques/erp_" + this.token.rfc);
                let catalogo = response.data;
                this.$store.state.listaRemolquesStore = catalogo;
            } catch (error) {
                console.log(error);
            }
        },

        async GetFigurasTransporte() {
            try {
                let response = await axios.get("CatalogosComprobantes/GetFigurasTransporte/erp_" + this.token.rfc);
                let catalogo = response.data;
                this.$store.state.listaFigurasTransporteStore = catalogo;
            } catch (error) {
                console.log(error);
            }
        },

        async GetTipoFiguraTransporte() {
            if (this.$store.state.listaCatalogoTipoFigura.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatTipoFiguraAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoTipoFigura = catalogo;
            } catch (error) {
                console.log(error);
            }
        },

        filterPais(val, update) {
            let stringOptions = this.optionsPaisDefault
            if (val === '') {
                update(() => {
                    this.optionsPais = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsPais = stringOptions.filter(v => v.pais.toLowerCase().indexOf(needle) > -1)
            })
        },

        filterUbicaciones(val, update) {
            let stringOptions = this.optionsUbicacionesDefault
            if (val === '') {
                update(() => {
                    this.optionsUbicaciones = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsUbicaciones = stringOptions.filter(v => v.ubicacion.toLowerCase().indexOf(needle) > -1)
            })
        },

        filterMercancias(val, update) {
            let stringOptions = this.optionsMercanciasDefault
            if (val === '') {
                update(() => {
                    this.optionsMercancias = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsMercancias = stringOptions.filter(v => v.descripcion.toLowerCase().indexOf(needle) > -1)
            })
        },

        filterBienesTransp(val, update) {
            let stringOptions = this.optionsBienesTranspDefault
            if (val === '') {
                update(() => {
                    this.optionsBienesTransp = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsBienesTransp = stringOptions.filter(v => v.claveProdServCP.toLowerCase().indexOf(needle) > -1)
            })
        },

        filterClaveUnidad(val, update) {
            let stringOptions = this.optionsClaveUnidadDefault
            if (val === '') {
                update(() => {
                    this.optionsClaveUnidad = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsClaveUnidad = stringOptions.filter(v => v.claveUnidad.toLowerCase().indexOf(needle) > -1)
            })
        },

        filterMaterialPeligroso(val, update) {
            let stringOptions = this.optionsMaterialPeligrosoDefault
            if (val === '') {
                update(() => {
                    this.optionsMaterialPeligroso = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsMaterialPeligroso = stringOptions.filter(v => v.materialPeligroso.toLowerCase().indexOf(needle) > -1)
            })
        },

        filterTipoEmbalaje(val, update) {
            let stringOptions = this.optionsTipoEmbalajeDefault
            if (val === '') {
                update(() => {
                    this.optionsTipoEmbalaje = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsTipoEmbalaje = stringOptions.filter(v => v.tipoEmbalaje.toLowerCase().indexOf(needle) > -1)
            })
        },

        filterFraccionArancelaria(val, update) {
            let stringOptions = this.optionsFraccionArancelariaDefault
            if (val === '') {
                update(() => {
                    this.optionsFraccionArancelaria = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsFraccionArancelaria = stringOptions.filter(v => v.fraccionArancelaria.toLowerCase().indexOf(needle) > -1)
            })
        },

        filterTipoMateria(val, update) {
            let stringOptions = this.optionsTipoMateriaDefault
            if (val === '') {
                update(() => {
                    this.optionsTipoMateria = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsTipoMateria = stringOptions.filter(v => v.tipoMateria.toLowerCase().indexOf(needle) > -1)
            })
        },

        filterDocumentoAduanero(val, update) {
            let stringOptions = this.optionsDocumentoAduaneroDefault
            if (val === '') {
                update(() => {
                    this.optionsDocumentoAduanero = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsDocumentoAduanero = stringOptions.filter(v => v.documentoAduanero.toLowerCase().indexOf(needle) > -1)
            })
        },

        filterIdentificacionVehicular(val, update) {
            let stringOptions = this.optionsIdentificacionVehicularDefault
            if (val === '') {
                update(() => {
                    this.optionsIdentificacionVehicular = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsIdentificacionVehicular = stringOptions.filter(v => v.placaVM.toLowerCase().indexOf(needle) > -1)
            })
        },

        filterSeguros(val, update) {
            let stringOptions = this.optionsSegurosDefault
            if (val === '') {
                update(() => {
                    this.optionsSeguros = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsSeguros = stringOptions.filter(v => v.polizaRespCivil.toLowerCase().indexOf(needle) > -1)
            })
        },

        filterFiguraTransporte(val, update) {
            let stringOptions = this.optionsFiguraTransporteDefault
            if (val === '') {
                update(() => {
                    this.optionsFiguraTransporte = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsFiguraTransporte = stringOptions.filter(v => v.nombreFigura.toLowerCase().indexOf(needle) > -1)
            })
        },

        //UBICACIONES
        OpenDialogUbicaciones() {
            this.selectUbicacion.tipoUbicacion = 'OR'
            if (this.cartaPorte.ubicaciones.length != 0) {
                this.selectUbicacion.tipoUbicacion = 'DE'
            }
            this.dialogUbicaciones = true;
        },

        PushUbicacion() {
            //VALIDAMOS
            if (!this.selectUbicacion) {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique una ubicación`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }
            if (this.selectUbicacion.tipoUbicacion === 'DE') {
                if (this.selectUbicacion.distanciaRecorrida <= 0) {
                    this.$q.notify({
                        type: 'warning',
                        message: `Indique la distancia recorrida`,
                        actions: [
                            { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                        ]
                    })
                    return;
                }
            }
            const ubicacion = { ...this.selectUbicacion };
            this.$store.state.comprobanteStore.comprobante.cartaPorte.ubicaciones.push(ubicacion);
            let totalDistRec = this.$store.state.comprobanteStore.comprobante.cartaPorte.ubicaciones.reduce(function (acumulador, objeto) {
                return acumulador + parseFloat(objeto.distanciaRecorrida);
            }, 0);
            this.selectUbicacion = {
                _id: '',
                tipoUbicacion: 'DE',
                idUbicacion: '',
                rfcRemitenteDestinatario: '',
                nombreRemitenteDestinatario: '',
                numRegIdTrib: '',
                residenciaFiscal: '',
                // fechaHoraSalidaLlegada: new Date().toISOString(),
                distanciaRecorrida: '',
                domicilio: null,
                ubicacion: '',
            };
            this.cartaPorte.totalDistRec = totalDistRec;
        },

        DeleteUbicacion(item) {
            console.log(item);
            let indice = this.$store.state.comprobanteStore.comprobante.cartaPorte.ubicaciones.findIndex(f => f._id === item._id)
            this.$store.state.comprobanteStore.comprobante.cartaPorte.ubicaciones.splice(indice, 1);

            let totalDistRec = this.$store.state.comprobanteStore.comprobante.cartaPorte.ubicaciones.reduce(function (acumulador, objeto) {
                return acumulador + parseFloat(objeto.distanciaRecorrida);
            }, 0);
            this.cartaPorte.totalDistRec = totalDistRec;
        },

        //MERCANCIAS
        openDialogMercancia() {
            this.dialogMercancia = true;
        },

        PushMercancia() {
            //VAMOS A VALIDAR
            if (!this.selectMercancia.bienesTransp) {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el tipo de bien trasportado`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                });
                return;
            }

            if (this.selectMercancia.descripcion === '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique la descripción`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                });
                return;
            }

            if (this.selectMercancia.cantidad === '' || this.selectMercancia.cantidad == 0) {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique la cantidad`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                });
                return;
            }

            if (!this.selectMercancia.claveUnidad) {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique la clave de la unidad`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                });
                return;
            }

            if (!this.selectMercancia.materialPeligroso) {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique si es material peligroso`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                });
                return;
            }

            if (this.selectMercancia.pesoEnKg === '' || this.selectMercancia.pesoEnKg == 0) {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el peso en KG`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                });
                return;
            }

            let ObjMercancia = { ...this.selectMercancia };
            this.cartaPorte.mercancias.mercancia.push(ObjMercancia);
            let ObjDefault = {
                _id: '',
                bienesTransp: null,
                descripcion: '',
                cantidad: 0,
                claveUnidad: null,
                unidad: '',
                dimensiones: '',
                materialPeligroso: '',
                cveMaterialPeligroso: null,
                embalaje: null,
                descripEmbalaje: '',
                pesoEnKg: 0,
                fraccionArancelaria: null,
                uuidComercioExt: '',
                tipoMateria: null,
                descripcionMateria: '',
                documentacionAduanera: [],
                cantidadTransporta: [],
            };
            this.selectMercancia = { ...ObjDefault }
            let pesoBruto = this.cartaPorte.mercancias.mercancia.reduce(function (acumulador, objeto) {
                return acumulador + parseFloat(objeto.pesoEnKg);
            }, 0);
            let numTotalMercancias = this.cartaPorte.mercancias.mercancia.length;

            this.cartaPorte.mercancias.pesoBrutoTotal = pesoBruto;
            this.cartaPorte.mercancias.numTotalMercancias = numTotalMercancias;
        },

        DeleteMercancia(item) {
            let indice = this.cartaPorte.mercancias.mercancia.indexOf(item);
            this.cartaPorte.mercancias.mercancia.splice(indice, 1);

            let pesoBruto = this.cartaPorte.mercancias.mercancia.reduce(function (acumulador, objeto) {
                return acumulador + parseFloat(objeto.pesoEnKg);
            }, 0);
            let numTotalMercancias = this.cartaPorte.mercancias.mercancia.length;

            this.cartaPorte.mercancias.pesoBrutoTotal = pesoBruto;
            this.cartaPorte.mercancias.numTotalMercancias = numTotalMercancias;
        },

        //DOCUMENTACION ADUANERA
        openDialogDocumentacionAduanera() {
            this.dialogDocumentacionAduanera = true;
        },

        PushDocumentacionAduanera() {
            //VALIDAMOS
            if (!this.selectDocumentacionAduanera.tipoDocumento) {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el tipo de documanto`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                });
                return;
            }
            if (this.selectDocumentacionAduanera.numPedimento === '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el número de pedimento`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            let ObjAduanera = { ...this.selectDocumentacionAduanera };
            this.selectMercancia.documentacionAduanera.push(ObjAduanera);
            let ObjDefalut = {
                tipoDocumento: null,
                numPedimento: '',
                rfcImpo: '',
            };
            this.selectDocumentacionAduanera = { ...ObjDefalut }
        },

        DeleteDocumentacionAduanera(item) {
            let indice = this.selectMercancia.documentacionAduanera.indexOf(item);
            this.selectMercancia.documentacionAduanera.splice(indice, 1);
        },

        //CANTIDAD TRANSPORTA
        openDialogCantidadTransporta() {
            this.dialogCantidadTransporta = true;
        },

        PushCantidadTranposta() {
            //VALIDAMOS
            if (this.selectCantidadTransporta.cantidad === '' || this.selectCantidadTransporta.cantidad == 0) {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique la cantidad a transportar`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }
            if (this.selectCantidadTransporta.idOrigen === '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el origen del transporte`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                });
                return;
            }
            if (this.selectCantidadTransporta.idDestino === '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el destino del transporte`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                });
                return;
            }

            let ObjCantidadTransporta = { ...this.selectCantidadTransporta };

            this.selectMercancia.cantidadTransporta.push(ObjCantidadTransporta);
            let ObjDefalut = {
                cantidad: 0,
                idOrigen: '',
                idDestino: '',
            }
            this.selectCantidadTransporta = { ...ObjDefalut }
        },

        DeleteCantidadTranposta(item) {
            let indice = this.selectMercancia.cantidadTransporta.indexOf(item);
            this.selectMercancia.cantidadTransporta.splice(indice, 1);
        },

        //REMOLQUES
        openDialogRemolque() {
            this.dialogRemolque = true;
        },

        PushRemolque() {
            let ObjRemolque = { ...this.selectRemolque };

            let ObjDefault = {
                subTipoRem: null,
                placa: '',
            };

            this.cartaPorte.mercancias.autotransporte.remolques.push(ObjRemolque);
            this.selectRemolque = { ...ObjDefault }
        },

        DeleteRemolque(item) {
            let indice = this.cartaPorte.mercancias.autotransporte.remolques.indexOf(item);
            this.cartaPorte.mercancias.autotransporte.remolques.splice(indice, 1);
        },

        // FIGURA DE TRANSPORTE
        OnChangeTipoFiguraTransporte() {
            let tipo = this.selectTipoFigura.clave;
            let filtro = this.optionsFiguraDeTransporteDefault.filter(f => f.tipoFigura.clave === tipo);
            console.log(filtro)
            this.selectFiguraTransporte = null;
            this.optionsFiguraTransporteDefault = filtro;
        },

        OpenDialogFiguraTransporte() {
            this.dialogFiguraTransporte = true;
        },

        PushFiguraDeTransporte() {
            if (!this.selectFiguraTransporte) {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique la figura del transporte a agregar`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                });
                return;
            }
            let ObjFigura = { ...this.selectFiguraTransporte }
            this.cartaPorte.figuraTransporte.push(ObjFigura);
            this.selectFiguraTransporte = null;
        },

        DeleteFiguraDeTransporte(item) {
            let indice = this.cartaPorte.figuraTransporte.indexOf(item);
            this.cartaPorte.figuraTransporte.splice(indice, 1);
        },

    },
}
</script>