<template>
    <q-layout view="hHh lpR fFf">
        <q-header elevated>
            <q-toolbar>
                <q-btn flat dense round icon="mdi-home" aria-label="Menu" @click="$router.push({ name: 'Home' })" />
                <q-toolbar-title>
                    <div class="text-h6 text-weight-bolder">COMERCIO EXTERIOR</div>
                </q-toolbar-title>
                <div class="text-h6 q-mr-lg">{{ $store.state.usuario.rfc }}</div>
                <q-btn flat round dense icon="mdi-account" @click="drawerPerfil = !drawerPerfil" />
            </q-toolbar>
        </q-header>

        <!-- DRAWER DERECHO -->
        <q-drawer v-model="drawerPerfil" side="right" bordered>
            <drawerPerfil></drawerPerfil>
        </q-drawer>

        <!-- DRAWER IZQUIERDO -->
        <q-drawer v-model="leftDrawerOpen" show-if-above :mini="miniState" @mouseover="miniState = false"
            @mouseout="miniState = true" :width="250" :breakpoint="500" bordered content-class="bg-dark">
            <q-scroll-area class="fit" :horizontal-thumb-style="{ opacity: 0 }">
                <q-list padding>
                    <q-item active clickable v-ripple @click="irListComercioExterior">
                        <q-item-section avatar>
                            <q-icon name="mdi-account-arrow-right" />
                        </q-item-section>
                        <q-item-section>
                            Comercio Exterior
                        </q-item-section>
                    </q-item>
                    <q-separator />

                    <q-item active clickable v-ripple @click="irListaClientesComercioExterior">
                        <q-item-section avatar>
                            <q-icon name="mdi-account-arrow-right" />
                        </q-item-section>
                        <q-item-section>
                            Clientes
                        </q-item-section>
                    </q-item>
                    <q-separator />

                    <q-item active clickable v-ripple @click="irEmisor">
                        <q-item-section avatar>
                            <q-icon name="mdi-account-arrow-right" />
                        </q-item-section>
                        <q-item-section>
                            Emisor
                        </q-item-section>
                    </q-item>
                    <q-separator />

                    <q-item active clickable v-ripple @click="irPropietarios">
                        <q-item-section avatar>
                            <q-icon name="mdi-account-star" />
                        </q-item-section>
                        <q-item-section>
                            Propietario
                        </q-item-section>
                    </q-item>
                    <q-separator />

                    <q-item active clickable v-ripple @click="irReceptor">
                        <q-item-section avatar>
                            <q-icon name="mdi-account-arrow-left" />
                        </q-item-section>
                        <q-item-section>
                            Receptor
                        </q-item-section>
                    </q-item>
                    <q-separator />

                    <q-item active clickable v-ripple @click="irDestinatarios">
                        <q-item-section avatar>
                            <q-icon name="mdi-map-marker-radius" />
                        </q-item-section>
                        <q-item-section>
                            Destinatario
                        </q-item-section>
                    </q-item>
                    <q-separator />

                    <q-item active clickable v-ripple @click="irMercancias">
                        <q-item-section avatar>
                            <q-icon name="mdi-dolly" />
                        </q-item-section>
                        <q-item-section>
                            Mercancias
                        </q-item-section>
                    </q-item>

                    <q-separator />

                </q-list>
            </q-scroll-area>
        </q-drawer>

        <q-page-container>
            <router-view />
        </q-page-container>

        <q-footer style="height: 30px;" bordered class="bg-dark text-white">
            <q-toolbar>
                <q-toolbar-title>
                </q-toolbar-title>
            </q-toolbar>
        </q-footer>
    </q-layout>
</template>

<script>
import { ref } from 'vue'
import axios from "axios";
import drawerPerfil from "../../DrawerPerfil/DrawerPerfil.vue"

export default {

    name: 'MainLayout',
    components: {
        drawerPerfil
    },
    data() {
        return {
            drawerPerfil: false,
            isDarkMode: false,
            leftDrawerOpen: false,
            drawer: false,
            miniState: true,
        }
    },

    watch: {
        isDarkMode(val) {
            this.$q.dark.set(val);
        }
    },
    methods: {
        irListComercioExterior() {
            this.$router.push({ name: 'ListComercioExterior' })
        },

        irListaClientesComercioExterior() {
            this.$router.push({ name: 'ListaClientesComercioExterior' })
        },

        irEmisor() {
            this.$router.push({ name: 'ListaEmisor' })
        },

        irReceptor() {
            this.$router.push({ name: 'ListaReceptor' })
        },

        irPropietarios() {
            this.$router.push({ name: 'ListaPropietario' })
        },

        irDestinatarios() {
            this.$router.push({ name: 'ListaDestinatarios' })
        },

        irMercancias() {
            this.$router.push({ name: 'ListaMercanciasCE' })
        }
    }
}
</script>
