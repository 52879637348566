<template>
    <q-card class="my-card">
        <q-toolbar>
            <q-btn push color="red-10" icon="mdi-close-circle-outline" round flat size="18px" padding="xs"
                @click="CierraDialog">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Cerrar</q-tooltip>
            </q-btn>
            <!-- id:{{ item.comprobante._id }} -->

            <q-toolbar-title><span class="text-weight-bold">Addenda VW</span></q-toolbar-title>
            <!-- BOTON PARA GENERAR -->
            <q-btn push color="green-14" icon="mdi-check-circle-outline" round flat size="18px" padding="xs"
                @click="PostAddenda()">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Generar Addenda</q-tooltip>
            </q-btn>
        </q-toolbar>
        <q-separator color="primary" inset size="4px" />
        <q-card-section>
            <q-stepper v-model="step" header-nav ref="stepper" color="primary" animated flat>
                <q-step :name="1" title="Datos Generales" icon="mdi-clipboard-edit-outline">
                    <general></general>
                </q-step>
                <q-step :name="2" title="Archivos" icon="mdi-format-list-bulleted-type">
                    <archivos></archivos>
                </q-step>
                <q-step :name="3" title="Partes" icon="mdi-bookmark" caption="Opcional">
                    <partes></partes>
                </q-step>
            </q-stepper>
        </q-card-section>
    </q-card>
</template>
<script>
import axios from 'axios'
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar';
import moment from 'moment';
import General from './AddendaVwGeneral.vue'
import Archivos from './AddendaVwArchivos.vue'
import Partes from './AddendaVwPartes.vue'
export default {
    components: {
        General,
        Archivos,
        Partes,
    },
    data() {
        return {
            step: 1,

        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        item() {
            return this.$store.state.addendaVw;
        },


    },
    created() {

    },
    methods: {
        async PostAddenda() {
            let valida = await this.Valida();
            if (!valida) {
                return;
            }
            let addenda = { ...this.$store.state.addendaVw }
            console.log(addenda);
            try {
                this.$q.loading.show({
                    spinner: QSpinnerCube,
                    spinnerColor: 'red-8',
                    spinnerSize: 140,
                    message: 'Timbrando, espere...',
                })
                let response = await axios.post('Comprobantes/PostAddendaVwPsvAsync/' + this.token.empresaBase + '/comprobantes_emitidos', addenda);

                const base64 = response.data;
                let folio = "";
                if (this.item.serie != "") {
                    folio = this.item.serie + "_";
                }

                if (this.item.folio != "") {
                    folio = folio + this.item.folio;
                }
                console.log(this.item)
                const fileName = 'Addenda_' + folio + "_" + this.item.rfcReceptor + "_" + this.item.folioFiscal + ".xml";
                await this.descargaComprobanteXml(base64, fileName);

                this.$q.loading.hide()
                this.$q.notify({
                    type: 'positive', message: 'Addenda generada con éxito', actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                this.CierraDialog();
            } catch (error) {
                this.$q.loading.hide()
                this.$q.notify({
                    type: 'negative', message: 'Error al generar addenda ' + error.response.data, actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
            }
        },

        CierraDialog() {
            this.$emit("CloseDialogAddenda");
        },

        async descargaComprobanteXml(base64, nombre) {
            const xmlBase64 = base64
            const fileName = nombre;
            const link = document.createElement("a");
            link.href = "data:text/xml;base64," + xmlBase64;
            link.download = fileName;
            link.click();
        },

        async Valida() {
            let addenda = { ...this.$store.state.addendaVw }
            console.log(addenda);
            if (addenda.moneda.tipoCambio === "0") {
                this.$q.notify({
                    type: 'warning', message: 'Ingrese el tipo de cambio, en caso de que la moneda sea MXP indicar 1', actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return false;
            }
            if (addenda.moneda.tipoCambio === "") {
                this.$q.notify({
                    type: 'warning', message: 'Ingrese el tipo de cambio, en caso de que la moneda sea MXP indicar 1', actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return false;
            }
            if (addenda.proveedorVwPsv.correoContacto === "") {
                this.$q.notify({
                    type: 'warning', message: 'Ingrese el correo de contacto', actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return false;
            }
            if (addenda.proveedorVwPsv.codigo === "") {
                this.$q.notify({
                    type: 'warning', message: 'Ingrese el código del proveedor', actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return false;
            }
            if (addenda.referencia === "") {
                this.$q.notify({
                    type: 'warning', message: 'Ingrese la referencia del proveedor', actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return false;
            }

            if (addenda.solicitanteVwPsv.correo === "") {
                this.$q.notify({
                    type: 'warning', message: 'Ingrese el correo del solicitante', actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return false;
            }

            if (addenda.solicitanteVwPsv.nombre === "") {
                this.$q.notify({
                    type: 'warning', message: 'Ingrese el nombre del solicitante', actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return false;
            }

            if (addenda.archivos.length == 0) {
                this.$q.notify({
                    type: 'warning', message: 'Agregue al menos un archivo a la lista de archivos', actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return false;
            }

            if (addenda.partes.length == 0) {
                this.$q.notify({
                    type: 'warning', message: 'Agregue al menos una parte a la lista de partes', actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return false;
            }
            return true;
        },
    },
}
</script>
<style>
.my-card {
    width: 100%;
}
</style>