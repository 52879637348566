<template >
  <q-layout>
    <q-page-container class="flex flex-center">
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
//import Inicio from "./views/Home.vue"
import Inicio from "./components/Inicio/MainPage.vue"
import axios from 'axios'
export default {
  name: 'LayoutDefault',

  components: {
    Inicio
  },

  data() {
    return {
      leftDrawerOpen: false
    }
  },
  created() {
    this.$store.dispatch("autoLogin");
    this.GetEmpresa();
  },
  computed: {
    token() {
      return this.$store.state.usuario;
    },
  },
  methods: {
    async GetEmpresa() {
      try {
        let response = await axios.get('Empresa/GetEmpresa/erp_' + this.token.rfc + '/' + this.token.rfc);
        console.log(response.data)
        this.$store.state.empresaStore = response.data
      } catch (error) {
        console.log(error);
      }
    },
  }
}
</script>


