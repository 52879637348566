<template>
    <q-card style="width: 800px;">
        <q-card-section>
            <q-toolbar>
                <q-toolbar-title>
                    Seguro
                </q-toolbar-title>
                <q-btn v-if="objetoStore._id == ''" dense round color="green" icon="mdi-content-save" class="q-mr-sm"
                    @click="guardar()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>

                <q-btn v-else round color="orange" dense icon="mdi-content-save" class="q-mr-sm" @click="actualizar()">
                    <q-tooltip>
                        Editar
                    </q-tooltip>
                </q-btn>

                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
            <q-card-section class="scroll">
                <div class="row q-col-gutter-sm">
                    <!-- <div class="col-12">
                        <q-select v-model="tipoAseguradora"
                            :options="['Responsabilidad Civil', 'Daños al medio ambiente', 'Carga Transportada']"
                            input-debounce="0" dense filled label="Tipo de aseguradora" />
                    </div> -->
                    <div class="col-12 ">
                        <q-input dense filled label="Aseguradora de Responsabilidad civil"
                            v-model="objetoStore.aseguraRespCivil" />
                    </div>
                    <div class="col-12  ">
                        <q-input dense filled label="Póliza de Responsabilidad civil"
                            v-model="objetoStore.polizaRespCivil" />
                    </div>
                    <div class="col-12  ">
                        <q-input dense filled label="Aseguradora de Medio ambiente"
                            v-model="objetoStore.aseguraMedAmbiente" />
                    </div>
                    <div class="col-12  ">
                        <q-input dense filled label="Póliza de Medio ambiente"
                            v-model="objetoStore.polizaMedAmbiente" />
                    </div>
                    <div class="col-12 ">
                        <q-input dense filled label="Aseguradora de Carga" v-model="objetoStore.aseguraCarga" />
                    </div>
                    <div class="col-12 ">
                        <q-input dense filled label="Póliza de Carga" v-model="objetoStore.polizaCarga" />
                    </div>
                    <div class="col-12  ">
                        <q-field dense filled label="Prima Seguro" v-model="objetoStore.primaSeguro">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                        </q-field>
                    </div>
                </div>
            </q-card-section>
        </q-card-section>
    </q-card>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import { Money } from 'v-money'

export default {
    components: {
        Money
    },
    data() {
        return {
            // tipoAseguradora: 'Responsabilidad Civil',
            moneyFormat: {
                decimal: '.',
                thousands: ',',
                precision: 2,
                masked: false
            },
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        objetoStore() {
            return this.$store.state.seguroStore;
        },
    },

    created() {
    },
    methods: {
        cierraVentana() {
            this.$emit('cierraVentana')
        },
        async guardar() {

            console.log(this.objetoStore)

            // if (this.tipoAseguradora == 'Responsabilidad Civil') {
            if (this.objetoStore.aseguraRespCivil == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la aseguradora' })
                return;
            }
            if (this.objetoStore.polizaRespCivil == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la póliza' })
                return;
            }
            // }

            // if (this.tipoAseguradora == 'Daños al medio ambiente') {
            //     if (this.objetoStore.aseguraMedAmbiente == '') {
            //         this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la aseguradora' })
            //         return;
            //     }
            //     if (this.objetoStore.polizaMedAmbiente == '') {
            //         this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la póliza' })
            //         return;
            //     }
            // }
            // if (this.tipoAseguradora == 'Carga Transportada') {
            //     if (this.objetoStore.aseguraCarga == '') {
            //         this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la aseguradora' })
            //         return;
            //     }
            //     if (this.objetoStore.polizaCarga == '') {
            //         this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la póliza' })
            //         return;
            //     }
            //     if (this.objetoStore.primaSeguro == 0) {
            //         this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la prima seguro' })
            //         return;
            //     }
            // }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando seguro. Espere...', messageColor: 'white' })

            try {
                let response = await axios.post('CatalogosComprobantes/PostSeguro/erp_' + this.token.rfc, this.objetoStore)
                console.log(response)

                this.objetoStore._id = response.data
                this.$store.state.listaSegurosStore.push(this.objetoStore)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `El seguro ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        async actualizar() {
            console.log(this.objetoStore)
            // if (this.tipoAseguradora == 'Responsabilidad Civil') {
            if (this.objetoStore.aseguraRespCivil == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la aseguradora de responsabilidad civil' })
                return;
            }
            if (this.objetoStore.polizaRespCivil == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la póliza de seguro de responsabilidad civil' })
                return;
            }

            // }
            // if (this.tipoAseguradora == 'Daños al medio ambiente') {
            //     if (this.objetoStore.aseguraMedAmbiente == '') {
            //         this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la aseguradora' })
            //         return;
            //     }
            //     if (this.objetoStore.polizaMedAmbiente == '') {
            //         this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la póliza' })
            //         return;
            //     }
            // }
            // if (this.tipoAseguradora == 'Carga Transportada') {
            //     if (this.objetoStore.aseguraCarga == '') {
            //         this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la aseguradora' })
            //         return;
            //     }
            //     if (this.objetoStore.polizaCarga == '') {
            //         this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la póliza' })
            //         return;
            //     }
            //     if (this.objetoStore.primaSeguro == 0) {
            //         this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la prima seguro' })
            //         return;
            //     }
            // }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Actualizando seguro. Espere...', messageColor: 'white' })

            try {
                let response = await axios.put('CatalogosComprobantes/PutSeguro/erp_' + this.token.rfc, this.objetoStore)
                console.log(response)

                let indice = this.$store.state.listaSegurosStore.findIndex(x => x._id === this.objetoStore._id);
                Object.assign(this.$store.state.listaSegurosStore[indice], this.objetoStore)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `El seguro ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },
    }
}
</script>
<style></style>