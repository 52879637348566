<template>
    <q-page class=" q-pa-lg ">

        <q-dialog v-model="dialogNuevo" persistent transition-show="scale" transition-hide="scale">
            <Ubicacion @cierraVentana="dialogNuevo = false"></Ubicacion>
        </q-dialog>

        <!-- DIALOGO DE CONFIRMACION -->
        <q-dialog v-model="dialogConfirm" persistent>
            <q-card style="width: 600px;">
                <q-card-section class="row items-center">
                    <q-avatar icon="mdi-delete-alert" color="primary" text-color="white" />
                    <span class="text-h5 q-ml-md">Estás seguro de eliminar la ubicación.?</span>
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat label="Aceptar" color="primary" @click="deleteItem()" />
                    <q-btn flat label="Cerrar" color="primary" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <div class="row	flex-left q-mb-md">
            <div class="col-12 col-md-6 col-sm-6 text-left ">
            </div>
            <div class="col-12 col-md-6 col-sm-6 text-right ">
                <q-btn round color="blue" class="q-mr-sm" icon="mdi-update" @click="GetItems()" />
                <q-btn round color="green" icon="mdi-plus" @click="nuevoElemento" />
            </div>
        </div>
        <div>
            <q-table class="shadow-0" title="Ubicaciones" :data="items" :columns="columns" row-key="_id"
                :filter="filter" :loading="loading" :pagination="pagination">
                <template v-slot:loading>
                    <q-inner-loading showing color="primary" />
                </template>
                <template v-slot:top-right>
                    <q-input filled borderless dense debounce="300" v-model="filter" placeholder="Buscar">
                        <template v-slot:append>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" color="red" rounded flat dense icon="mdi-delete"
                                @click="eliminar(props.row)">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                            <q-btn size="md" color="blue" rounded flat dense icon="mdi-pencil"
                                @click="editar(props.row)">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Editar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="tipoUbicacion" :props="props">{{ props.row.tipoUbicacion
                            }}</q-td>
                        <q-td key="idUbicacion" :props="props">{{ props.row.idUbicacion }}</q-td>
                        <q-td key="rfcRemitenteDestinatario" :props="props">{{ props.row.rfcRemitenteDestinatario
                            }}</q-td>
                        <q-td key="nombreRemitenteDestinatario" :props="props">{{ props.row.nombreRemitenteDestinatario
                            }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
    </q-page>
</template>
<script>

import moment from 'moment';
import { QSpinnerCube } from 'quasar'
import axios from 'axios'
import Ubicacion from './Ubicacion.vue'
export default {
    components: { Ubicacion },
    data() {
        return {
            filter: '',
            loading: false,
            columns: [
                { name: 'acciones', align: 'left', label: 'Acciones', field: 'acciones', },
                { name: 'tipoUbicacion', align: 'left', label: 'Tipo de Ubicación', field: 'tipoUbicacion', sortable: true },
                { name: 'idUbicacion', align: 'left', label: 'ID Ubicación', field: 'idUbicacion', sortable: true },
                { name: 'rfcRemitenteDestinatario', align: 'left', label: 'RFC del remitente o destinatario', field: 'rfcRemitenteDestinatario', sortable: true },
                { name: 'nombreRemitenteDestinatario', align: 'left', label: 'Nombre del remitente o destinatario', field: 'nombreRemitenteDestinatario', sortable: true },
            ],
            data: [],
            dialogNuevo: false,
            pagination: {
                sortBy: 'subTipoRem',
                descending: false,
                page: 1,
                rowsPerPage: 10
                // rowsNumber: xx if getting data from a server
            },
            dialogConfirm: false
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        items() {
            return this.$store.state.listaUbicacionesStore
        }
    },
    created() {
        this.getCatalogoPais();
        this.GetItems();
        this.GetCatalogos();
    },
    methods: {
        nuevoElemento() {
            let domicilio = {
                _id: '0',
                calle: '',
                numeroExterior: '',
                numeroInterior: '',
                colonia: null,
                localidad: null,
                referencia: '',
                municipio: null,
                estado: null,
                pais: {
                    clave: "MEX",
                    descripcion: "México",
                    pais: "MEX | México"
                },
                codigoPostal: '',
            }
            this.$store.state.domicilioStore = domicilio

            let objeto = {
                _id: '',
                tipoUbicacion: '',
                idUbicacion: '',
                rfcRemitenteDestinatario: '',
                nombreRemitenteDestinatario: '',
                numRegIdTrib: '',
                residenciaFiscal: null,
                domicilio: null,
            }
            this.$store.state.ubicacionStore = objeto
            this.dialogNuevo = true
        },

        async getCatalogoPais() {
            if (this.$store.state.listaCatalogoPais.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatPaisAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoPais = catalogo;

            } catch (error) {
                console.log(error);
            }
        },

        async GetItems() {
            this.loading = true
            try {
                let response = await axios.get("CatalogosComprobantes/GetUbicaciones/erp_" + this.token.rfc);
                let catalogo = response.data;
                this.loading = false

                this.$store.state.listaUbicacionesStore = catalogo;

            } catch (error) {
                this.loading = false
                console.log(error);
            }
        },

        eliminar(item) {
            this.dialogConfirm = true
            this.$store.state.ubicacionStore = item
        },

        async deleteItem() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Eliminando ubicación. Espere...', messageColor: 'white' })
            let ubicacion = this.$store.state.ubicacionStore

            try {
                let response = await axios.put('CatalogosComprobantes/DeleteUbicacion/erp_' + this.token.rfc + '/' + ubicacion._id)
                console.log(response)

                // SI LA ACCION ES ELIMINAR, LO QUITAMOS DE LA LISTA
                let indice = this.items.indexOf(ubicacion)
                this.items.splice(indice, 1)


                this.dialogConfirm = false
                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `La ubicación ha sido eliminado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error, intentelo mas tarde.', color: 'red' })
            }
        },

        editar(data) {
            this.$store.state.ubicacionStore = { ...data };
            data.domicilio._id = "1";
            this.$store.state.domicilioStore = data.domicilio;
            this.dialogNuevo = true;
        },

        async GetCatalogos() {
            await this.getCatalogoPais();
        },

        async getCatalogoPais() {
            if (this.$store.state.listaCatalogoPais.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatPaisAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoPais = catalogo;

            } catch (error) {
                console.log(error);
            }
        },

        async GetCatEstado() {
            if (this.$store.state.listaCatalogoEstado.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatEstadoAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoEstado = catalogo;

            } catch (error) {
                console.log(error);
            }
        },
    }
}
</script>

<style></style>
<style lang="sass">
</style>