<template>
    <div>

        <!-- TIKCET A IMPRIMIR -->
        <div hidden>
            <div class="ticket" id="ticket">
                <!-- CABECERA DEL COMPROBANTE -->
                <div class="row justify-center">
                    <div class="col-12" style="text-align: center;">
                        <a style="text-align: center;" href="https://contago.com.mx/" target="_blank">
                            <img :src="empresa.logo.base64" width="150">
                        </a>
                    </div>
                    <div class="col-12 text-center">
                        <font size="3">
                            <div><strong>Ticket</strong></div>
                        </font>
                        <h4>{{ venta.serie }} {{ venta.folio }}</h4>
                        <div>{{ venta.fecha }}</div>
                        <div><b>Lugar de Expedición: </b>{{ empresa.domicilioFiscal }}</div>
                    </div>
                    <div class="col-12 text-center">
                        <font size="2">
                            <div>{{ empresa.rfc }}</div>
                        </font>
                        <font size="2">
                            <div>{{ empresa.nombre }}</div>
                        </font>
                    </div>
                    <!-- DATOS DEL CLIENTE -->
                    <div class="col-12 text-center">
                        <font size="2">
                            <div>Datos del cliente:</div>
                        </font>
                        <font size="2">
                            <div><strong>{{ venta.receptor.receptor }}</strong></div>
                        </font>
                    </div>
                    <!-- CONCEPTOS -->
                    <div class="col-12">
                        <q-table hide-header hide-bottom :data="venta.conceptos" :columns="columnsConceptos"
                            row-key="item" />
                    </div>

                    <!-- SUMAS DE IMPORTES E IMPORTE CON LETRA -->
                    <div class="row  ">
                        <div class="col-md-6 q-pt-sm">
                            <font size="2">
                                <div>Pago con: {{ formatCurrency(venta.pagoCon) }}</div>
                            </font>
                            <font size="2">
                                <div>Cambio: {{ formatCurrency(venta.cambioTotal) }}</div>
                            </font>
                        </div>
                        <div class="col-md-6 mr-0 pr-0">
                            <q-table hide-header hide-bottom :data="itemsImpuestos" :columns="columnsImpuestos"
                                row-key="impuesto" />
                        </div>
                        <div class="col-12 text-left">
                            <!-- <p style="font-size: 14px; text-align: center;"> {{
                                importeLetra }}
                            </p> -->
                        </div>
                        <div class="col-12">
                            <p style="font-size: 14px; text-align: center;">{{ venta.notas }}</p>
                        </div>
                        <div class="col-12">
                            <p style="font-size: 10px; text-align: center;">Para emitir su factura, visite la pagina
                                web:<br>
                                <b>www.contago.com.mx</b>
                            </p>
                        </div>
                        <div class="col-12">
                            <p style="font-size: 14px; text-align: center;">¡Gracias por su Compra!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- DIALOGO PARA CAMBIAR O AGREGAR DETALLES A LA VENTA -->
        <q-dialog v-model="dialogConfiguracion" persistent>
            <q-card style="width: 1000px" class="bg-black q-mt-sm  full-width">
                <q-tabs v-model="tab" dense class="text-grey" active-color="primary" indicator-color="primary"
                    align="justify" narrow-indicator>
                    <q-tab name="Cliente" label="Cliente" />
                    <q-tab name="Notas" label="Notas" />
                    <q-tab name="Checador" label="Checador" />
                </q-tabs>
                <q-separator />

                <q-tab-panels v-model="tab" animated>
                    <q-tab-panel name="Cliente">
                        <q-select v-model="venta.receptor" :options="listaFiltroClientes" filled
                            class="full-width q-mx-lg q-pt-md" color="black" emit-value map-options use-input
                            input-debounce="0" label="Cliente" @filter="filtroClientes"
                            option-label="receptor"></q-select>
                        <div class="q-mt-md" align="right">
                            <q-btn flat label="Aceptar" color="primary" v-close-popup />
                        </div>

                    </q-tab-panel>
                    <q-tab-panel name="Notas">
                        <q-input v-model="venta.notas" filled clearable type="textarea" color="red-12"
                            label="Escriba observaciones y/o notas de la venta." />
                        <div class="q-mt-md" align="right">
                            <q-btn flat label="Aceptar" color="primary" v-close-popup />
                        </div>
                    </q-tab-panel>
                    <q-tab-panel name="Checador">
                        <q-select v-model="conceptoChecador" :options="listaFiltroConceptosChecador" filled
                            class="full-width q-mx-lg q-pt-md" color="black" emit-value map-options use-input
                            input-debounce="0" label="Articulo" virtual-scroll-slice-size="1"
                            @filter="filtroConceptosChecador" @input="opcionSeleccionadaChecador"
                            option-label="concepto"></q-select>
                        <div class=" col-12 q-pt-md  q-pb-md " style="background-color: #e9eef3;">
                            <H5 class="q-mb-none q-mt-none" style=" color: #000095;text-align:center;">{{
                                descripcionChecador
                            }}
                            </H5>
                            <H3 class="q-mb-none q-mt-none" style=" color: #000095;text-align:center;">{{
                                    formatCurrency(precioChecador) }}
                            </H3>
                        </div>
                        <div class="q-mt-md" align="right">
                            <q-btn flat label="Cerrar" color="primary" v-close-popup />
                            <q-btn flat label="Agregar a la venta" color="primary" @click="agregarConceptoCheck()" />
                        </div>
                    </q-tab-panel>
                </q-tab-panels>
            </q-card>
        </q-dialog>

        <!-- DIALOGO PARA HACER EL COBRO -->
        <q-dialog v-model="dialogCobro" persistent>
            <q-card style="width: 1200px; max-width: 80vw;" class="q-mt-sm  full-width">
                <q-card-section>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="row">

                                <div class="col-6 col-md-6" style="background-color: #e9eef3;">
                                    <h5 style=" color: #000095;text-align:center; margin-top:10px; margin-bottom: 0px;">
                                        Total
                                    </h5>
                                    <h3 style=" color: #000095;text-align:center; margin-top:10px; margin-bottom: 0px;">
                                        {{
                                formatCurrency(venta.total) }}
                                    </h3>
                                </div>
                                <div class="col-6 col-md-6" style="background-color: #e9eef3;">
                                    <h5 style=" color: #000095;text-align:center; margin-top:10px; margin-bottom: 0px;">
                                        {{
                                mensajeCobro }}</h5>
                                    <h3 style=" color: #000095;text-align:center; margin-top:10px; margin-bottom: 0px;">
                                        {{
                                formatCurrency(computedSumaCobros) }}</h3>
                                </div>
                                <div class="col-12">
                                    <q-select dense class="full-width q-mx-lg q-pt-md" filled
                                        v-model="objFormaPago.formaPago" :options="itemsFormaPago" label="Forma de Pago"
                                        option-label="formaPago" />
                                </div>
                                <div class="col-md-6 q-pr-sm">
                                    <q-input dense v-model="objFormaPago.referencia" class="full-width q-mx-lg q-pt-md"
                                        filled label="Referencia" />
                                </div>

                                <div class="col-md-6">
                                    <q-field class="full-width q-mx-lg q-pt-md" v-model="objFormaPago.monto"
                                        label="Monto" dense filled>
                                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                            <vue-number :id="id" class="q-field__input text-right" :value="value"
                                                @input="emitValue" v-show="floatingLabel"
                                                v-bind="importeFormat"></vue-number>
                                        </template>
                                        <template v-slot:after>
                                            <q-btn round color="green" icon="mdi-plus" @click="pushCobro()" />
                                        </template>
                                    </q-field>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="row">
                                <div class="col-12">
                                    <q-table :rows-per-page-options="[0]" hide-bottom class="shadow-0"
                                        title="Formas de Pago" :data="venta.formasPago" :columns="columnsCobro">
                                        <template v-slot:body="props">
                                            <q-tr :props="props">
                                                <q-td key="formaPago" :props="props">{{ props.row.formaPago.formaPago
                                                    }}</q-td>
                                                <q-td key="referencia" :props="props">{{ props.row.referencia }}</q-td>
                                                <q-td key="monto" :props="props">{{ formatCurrency(props.row.monto)
                                                    }}</q-td>
                                                <q-td auto-width>
                                                    <q-btn size="md" color="red" rounded flat dense
                                                        @click="eliminarCobro(props.row)" icon="mdi-delete">
                                                        <q-tooltip transition-show="flip-right"
                                                            transition-hide="flip-left"
                                                            content-style="font-size: 14px">Eliminar</q-tooltip>
                                                    </q-btn>
                                                </q-td>
                                            </q-tr>
                                        </template>
                                    </q-table>
                                </div>

                            </div>
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions>
                    <div class="col-3 q-pr-md">
                        <q-btn unelevated label="Cobrar Imprimir" color="primary" class="full-width q-mb-md "
                            @click="imprimirTicket()" />

                    </div>
                    <div class="col-3 q-pr-md">
                        <q-btn unelevated label="Cobrar sin Imprimir" color="primary" class="full-width q-mb-md"
                            @click="cobrarSinImprimir" />
                    </div>

                    <div class="col-3  q-pr-md">
                        <q-btn unelevated label="Enviar Correo" color="primary" class="full-width q-mb-md" />
                    </div>

                    <div class="col-3">
                        <q-btn unelevated label="Cancelar" color="red" class="full-width q-mb-md"
                            @click="dialogCobro = false" />
                    </div>
                    <!-- </div> -->
                </q-card-actions>
            </q-card>
        </q-dialog>

        <q-card class=" full-width shadow-0">
            <div class="row">
                <div class="col-12 q-pa-md">
                    <q-toolbar class="full-width">
                        <q-select dense autofocus ref="selectConceptos" v-model="conceptoSelect" id="my-textbox"
                            :options="listaFiltroConceptos" filled class="full-width" color="black" emit-value
                            map-options use-input input-debounce="0" label="Articulo" @filter="filtroConceptos"
                            option-label="concepto" @input="opcionSeleccionada">

                        </q-select>
                        <div class="q-pl-md">
                            <q-input dense v-model.number="cantidad" type="number" filled style="max-width: 100px" />
                        </div>
                        <q-btn icon="mdi-cog" round color="black" class="q-ml-lg" @click="mostrar" />
                    </q-toolbar>
                </div>
            </div>

            <!-- <q-splitter v-model="splitterModel" style="height: 100%;overflow: hidden;">
                <template v-slot:before>
                    <div class="q-pa-md">
                        
                    </div>
                </template>

                <template v-slot:after> -->
            <div class="q-pa-md">
                <q-table color="primary" class="my-sticky-header-column-table shadow-0" dense style="height: 500px"
                    title="Productos" :data="venta.conceptos" :columns="columns" row-key="item" virtual-scroll
                    :pagination.sync="pagination" :rows-per-page-options="[0]">
                    <template v-slot:body="props">
                        <q-tr :props="props">
                            <q-td key="item" :props="props">{{ props.row.item
                                }}</q-td>
                            <q-td key="noIdentificacion" :props="props">{{ props.row.noIdentificacion
                                }}</q-td>
                            <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                            <q-td key="cantidad" :props="props">{{ props.row.cantidad
                                }}</q-td>
                            <q-td key="precio" :props="props">{{ formatCurrency(props.row.precio)
                                }}</q-td>
                            <q-td key="total" :props="props">{{ formatCurrency(props.row.total) }}</q-td>
                            <q-td key="acciones" auto-width>
                                <q-btn size="md" color="red" rounded flat dense @click="eliminarArticulo(props.row)"
                                    icon="mdi-delete">
                                    <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                        content-style="font-size: 14px">Eliminar</q-tooltip>
                                </q-btn>
                            </q-td>
                        </q-tr>
                    </template>
                </q-table>
            </div>

            <!-- </template> -->
            <!-- </q-splitter> -->

        </q-card>

        <div class="footerVenta">
            <q-btn unelevated color="primary" size="lg" class="full-width q-mb-md" @click="realizarCobro">
                COBRAR
                <q-space />
                {{ formatCurrency(venta.total) }}
            </q-btn>
            <q-toolbar class="bg-black text-white ">
                <q-avatar size="40px" color="orange" class="q-mr-md" style="color: black;">{{ cantidadProducto
                    }}</q-avatar>
                Productos a cobrar.
                <q-space />
                <q-btn flat round dense icon="mdi-minus-box-outline" label="Eliminar Ultimo" class="q-mx-md"
                    @click="EliminarUltimo()" />
                <q-separator vertical color="orange" inset />
                <q-btn flat round dense icon="mdi-plus-box-outline" label="Agregar Ultimo" class="q-mx-md"
                    @click="AgregarUltimo()" />
                <q-separator vertical color="orange" inset />
                <q-btn flat round dense icon="mdi-printer" label="Imprimir Ultimo Ticket" class="q-mx-md" />
                <!-- <q-separator vertical color="orange" inset /> -->
                <!-- <q-btn flat round dense label="Checador" class="q-mx-md" /> -->
                <q-separator vertical color="orange" inset />
                <q-btn flat round dense icon="mdi-delete-empty" label="Cancelar" class="q-mx-md"
                    @click="cancelarVenta()" />
            </q-toolbar>
        </div>

    </div>
</template>
<script>
import { Dialog } from 'quasar';
import axios from 'axios';
import moment from 'moment'
import { QSpinnerCube } from 'quasar'
import { component as VueNumber } from '@coders-tm/vue-number-format'
import print from 'print-js'
import html2pdf from "html2pdf.js";
export default {
    components: { VueNumber },

    data() {
        return {
            importeFormat: {
                decimal: '.',
                separator: ',',
                prefix: '',
                precision: 2,
                masked: false,
                minimumFractionDigits: 2,
            },

            splitterModel: 35,
            columns: [
                { name: 'item', label: 'item', field: 'item', align: 'center', },
                { name: 'noIdentificacion', label: 'Código de Barras', field: 'noIdentificacion', align: 'center', classes: 'bg-grey-2 text-center ellipsis', headerClasses: 'bg-primary text-white' },
                { name: 'descripcion', align: 'center', label: 'Descripción del Producto', field: 'descripcion', style: 'text-align: left;' },
                { name: 'cantidad', label: 'Cantidad', field: 'cantidad', align: 'center', },
                { name: 'precio', label: 'Precio', field: 'precio', align: 'center', style: 'text-align: right;' },
                { name: 'total', label: 'Total', field: 'total', align: 'center', classes: 'bg-grey-2 ellipsis', headerClasses: 'bg-blue text-white text-center', style: 'text-align: right;' },
                { name: 'acciones', label: 'Acciones', field: 'acciones', align: 'center', style: 'text-align: center;' },
            ],
            pagination: {
                rowsPerPage: 0
            },
            data: [],

            tab: 'Cliente',
            dialogConfiguracion: false,
            cantidad: 1,

            listaFiltroConceptosChecador: null,
            conceptoChecador: { concepto: '', descripcion: '', precio: 0.00 },

            descripcionChecador: '',
            precioChecador: 0.00,

            conceptoSelect: null,
            listaFiltroConceptos: null,

            listaFiltroClientes: null,

            concepto: {
                descripcion: '',
                claveProdServ: {
                    clave: '',
                    claveProdServ: '',
                },
                claveUnidad: {
                    clave: '',
                    claveUnidad: '',
                },
                concepto: '',
                cantidad: 1,
                precio: 0,
                objImp: {
                    clave: '',
                    objImp: '',
                },
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
                imagen: { base64: '' }
            },

            dialogCobro: false,
            mensajeCobro: ' ',
            formasPago: [],
            objFormaPago: {
                formaPago: { id: 1, clave: "01", formaPago: "01 | Efectivo", descripcion: "Efectivo" },
                monto: 0,
                referencia: ''
            },

            columnsCobro: [
                { name: 'formaPago', label: 'Forma de Pago ', field: 'formaPago', align: 'center' },
                { name: 'referencia', label: 'Referencia', field: 'referencia', align: 'center' },
                { name: 'monto', label: 'Monto', field: 'monto', align: 'right' }
            ],

            columnsImpuestos: [
                { name: 'impuesto', label: 'impuesto', field: 'impuesto', align: 'left' },
                { name: 'importe', label: 'importe', field: 'importe', align: 'right' },
            ],

            columnsConceptos: [
                { name: 'descripcion', label: 'descripcion', field: 'descripcion', align: 'left' },
                { name: 'cantidad', label: 'cantidad', field: 'cantidad', align: 'center' },
                { name: 'precio', label: 'precio', field: 'precio', align: 'right', },
            ],
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        empresa() {
            return this.$store.state.empresaStore;
        },
        venta() {

            return this.$store.state.ventaStore
        },
        itemsClientes() {
            return this.$store.state.listClienteStore;
        },
        itemsConceptos() {
            return this.$store.state.listaArticulosStore;
        },
        itemsFormaPago() {
            return this.$store.state.formaPagoStore.filter(objeto => objeto.clave == '01' || objeto.clave == '04' || objeto.clave == '28');;
        },
        computedSumaCobros() {
            var total_ = 0;
            for (var i = 0; i < this.venta.formasPago.length; i++) {
                this.mensajeCobro = 'Por Cobrar:'
                total_ = total_ + Number(this.venta.formasPago[i].monto);
            }
            total_ = this.venta.total - total_
            if (total_ < 0) {
                this.mensajeCobro = 'Cambio:'
                total_ = total_ * -1
            }
            return total_
        },
        cantidadProducto() {
            let cantidad = 0
            cantidad = this.venta.conceptos.length
            return cantidad
        },
        itemsImpuestos() {
            var lista = [];
            var subTotal = { impuesto: 'SubTotal', importe: this.$store.state.ventaStore.subTotal }
            lista.push(subTotal)
            for (var x of this.$store.state.ventaStore.impuestos.traslados) {
                console.log(x)
                let nombre = '';
                let tasa = x.tasaOCuota * 100
                tasa = Math.round(tasa * 10000) / 10000

                if (x.impuesto === '001') {
                    nombre = 'ISR'
                }
                if (x.impuesto === '002') {
                    nombre = 'IVA'
                }
                if (x.impuesto === '003') {
                    nombre = 'IEPS'
                }

                if (x.tipoFactor === 'Exento') {
                    nombre = nombre + ' Exento'
                } else {
                    nombre = nombre + '(' + tasa + ')%'
                }
                var ob = { impuesto: nombre, importe: x.importe }
                lista.push(ob)
                ob = {};
            }
            for (var x of this.$store.state.ventaStore.impuestos.retenciones) {
                let nombre = '';
                let tasa = x.tasaOCuota * 100
                tasa = Math.round(tasa * 10000) / 10000

                if (x.impuesto === '001') {
                    nombre = 'ISR'
                }
                if (x.impuesto === '002') {
                    nombre = 'IVA'
                }
                if (x.impuesto === '003') {
                    nombre = 'IEPS'
                }

                nombre = nombre + '(' + tasa + ')%'
                var ob = { impuesto: nombre, importe: (x.importe) * -1 }
                lista.push(ob)
                ob = {};
            }
            var total = { impuesto: 'Total', importe: this.$store.state.ventaStore.total }
            lista.push(total)
            // console.log(lista)
            return lista;
        },
    },
    created() {
        this.GetFormaPago();
    },
    mounted() {
        window.addEventListener("keyup", this.handleKeyUp);
        this.setFocusToMyComponent();
    },
    destroyed() {
        window.removeEventListener('keyup', this.handleKeyUp)
    },
    methods: {
        setFocusToMyComponent() {
            this.$refs.selectConceptos.$el.autofocus;
        },

        mostrar() {
            this.dialogConfiguracion = true
            this.getClientes()
        },

        filtroConceptos(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.listaFiltroConceptos = this.itemsConceptos.filter(v => v.concepto.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.listaFiltroConceptos.length > 0) {
                        ref.setOptionIndex(-1) // reset optionIndex in case there is something selected
                        ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
                    }
                }
            )

        },

        filtroConceptosChecador(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.listaFiltroConceptosChecador = this.itemsConceptos.filter(v => v.concepto.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    console.log(ref)
                    if (val !== '' && this.listaFiltroConceptosChecador.length > 0) {
                        ref.setOptionIndex(-1) // reset optionIndex in case there is something selected
                        ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
                    }
                }
            )

        },

        filtroClientes(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.listaFiltroClientes = this.itemsClientes.filter(v => v.receptor.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.listaFiltroClientes.length > 0) {
                        ref.setOptionIndex(-1) // reset optionIndex in case there is something selected
                        ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
                    }
                }
            )

        },

        opcionSeleccionadaChecador() {
            if (this.conceptoChecador != null) {
                this.precioChecador = this.conceptoChecador.precio
                this.descripcionChecador = this.conceptoChecador.descripcion
            } else {
                this.precioChecador = 0
                this.descripcionChecador = ''
                this.conceptoChecador.precio = 0
                this.conceptoChecador.descripcion = ''
                this.conceptoChecador.concepto = ''
            }
        },

        opcionSeleccionada() {
            console.log(this.conceptoSelect)

            this.concepto = { ...this.conceptoSelect }
            this.impuestos = { ...this.conceptoSelect.impuestos }

            if (this.concepto.descripcion == '') {
                this.$q.notify({ type: 'negative', message: 'Articulo no existe.', color: 'red' })
                return;
            }
            if (this.cantidad == 0) {
                this.$q.notify({ type: 'negative', message: 'La Cantidad debe ser mayor a 0.', color: 'red' })
                return;
            }

            this.pushConceptoTotal();
        },

        agregarConceptoCheck() {
            if (this.conceptoChecador == null) {
                this.$q.notify({ type: 'negative', message: 'Busque un articulo.', color: 'red' })
                return;
            }

            if (this.conceptoChecador.descripcion == '' || this.conceptoChecador == null) {
                this.$q.notify({ type: 'negative', message: 'Busque un articulo.', color: 'red' })
                return;
            }

            this.concepto = { ...this.conceptoChecador }
            this.impuestos = { ...this.conceptoChecador.impuestos }

            this.pushConceptoTotal();
        },

        pushConceptoTotal() {
            let precio = this.concepto.precio;
            let sumT = this.sum(this.impuestos.traslados, 'tasaOCuota')
            let sumR = this.sum(this.impuestos.retenciones, 'tasaOCuota')
            let sumaImpuestos = ((sumT - sumR)) + 1
            let valorUnitario_ = precio / sumaImpuestos;


            let claveProdServ = this.concepto.claveProdServ;
            let noIdentificacion = this.concepto.noIdentificacion;
            let cantidad = this.cantidad.toFixed(3);
            let claveUnidad = this.concepto.claveUnidad;
            let unidad = this.concepto.unidad;
            let descripcion = this.concepto.descripcion;
            let valorUnitario = valorUnitario_.toFixed(6);
            let importe = (valorUnitario * cantidad).toFixed(6)
            let descuento = this.concepto.descuento.toFixed(6);
            let objImp = this.concepto.objImp;
            let total = this.cantidad * precio
            var impuestosDefault = {
                traslados: [],
                retenciones: [],
            }
            let impuestos = {
                traslados: [],
                retenciones: [],
            }

            let item = this.venta.conceptos.length + 1;

            //HACEMOS EL CALCULO PARA LOS IMPUESTOS
            for (let t of this.impuestos.traslados) {
                let base_ = (importe - descuento).toFixed(6);
                let tasaOCuota = t.tasaOCuota;
                let importe_ = (base_ * tasaOCuota).toFixed(6)
                let objT = {
                    base_: base_,
                    impuesto: t.impuesto,
                    tipoFactor: t.tipoFactor,
                    tasaOCuota: t.tasaOCuota,
                    importe: importe_,
                }
                impuestos.traslados.push(objT);
                objT = {};
            }

            for (let t of this.impuestos.retenciones) {
                let base_ = (importe - descuento).toFixed(6);
                let tasaOCuota = t.tasaOCuota;
                let importe_ = (base_ * tasaOCuota).toFixed(6)
                let objR = {
                    base_: base_,
                    impuesto: t.impuesto,
                    tipoFactor: t.tipoFactor,
                    tasaOCuota: t.tasaOCuota,
                    importe: importe_,
                }
                impuestos.retenciones.push(objR);
                objR = {};
            }

            // console.log(valorUnitario, cantidad, importe)
            let concepto = {
                item: item,
                claveProdServ: claveProdServ,
                noIdentificacion: noIdentificacion,
                cantidad: cantidad,
                claveUnidad: claveUnidad,
                unidad: unidad,
                descripcion: descripcion,
                valorUnitario: valorUnitario,
                importe: importe,
                descuento: descuento,
                objImp: objImp,
                impuestos, impuestos,
                cuentaPredial: null,
                precio: precio,
                total: total
            }
            console.log(concepto)
            this.venta.conceptos.push(concepto)
            concepto = {};
            this.AgrupaYCalculaTotales();

            this.conceptoSelect = null;
            this.conceptoVerificar = null
            this.impuestos = {};
            this.impuestos = Object.assign({}, impuestosDefault);
            this.concepto = {};

            this.descripcionPrecio = ''
            this.precio = 0
            this.cantidad = 1
        },

        AgrupaYCalculaTotales() {
            let totalTraslados = 0;
            let totalRetenidos = 0;
            let subTotal = 0;
            let descuento = 0;
            let total = 0;
            let trasladados = [];
            let retenidos = [];

            //INICIALIZAMOS LOS IMPUESTOS
            this.venta.impuestos.traslados = [];
            this.venta.impuestos.retenciones = [];

            for (let c of this.venta.conceptos) {
                for (let t of c.impuestos.traslados) {
                    trasladados.push(t);
                }

                for (let r of c.impuestos.retenciones) {
                    retenidos.push(r)
                }
            }

            subTotal = this.venta.conceptos.reduce(function (acumulador, objeto) {
                return acumulador + parseFloat(objeto.importe);
            }, 0);
            descuento = this.venta.conceptos.reduce(function (acumulador, objeto) {
                return acumulador + parseFloat(objeto.descuento);
            }, 0);
            subTotal = subTotal.toFixed(2);
            descuento = descuento.toFixed(2);

            //AGRUPAMOS LOS IMPUESTOS
            let t = this.AgrupaYCalculaImpuestos(trasladados);
            let r = this.AgrupaYCalculaImpuestos(retenidos);
            totalTraslados = t.reduce(function (acumulador, objeto) {
                return acumulador + parseFloat(objeto.importe);
            }, 0);
            totalRetenidos = r.reduce(function (acumulador, objeto) {
                return acumulador + parseFloat(objeto.importe);
            }, 0);
            totalTraslados = totalTraslados.toFixed(2);
            totalRetenidos = totalRetenidos.toFixed(2);

            //ASIGNAMOS LAS VARIABLES TOTALES
            this.venta.impuestos.traslados = t;
            this.venta.impuestos.retenciones = r;
            this.venta.impuestos.totalImpuestosTrasladados = parseFloat(totalTraslados);
            this.venta.impuestos.totalImpuestosRetenidos = parseFloat(totalRetenidos);
            this.venta.subTotal = parseFloat(subTotal);
            this.venta.descuento = parseFloat(descuento);

            //ASIGNAMOS EL TOTAL
            total = this.sum(this.venta.conceptos, 'total')
            // total = this.venta.subTotal - this.venta.descuento + this.venta.impuestos.totalImpuestosTrasladados - this.venta.impuestos.totalImpuestosRetenidos;
            total = total.toFixed(2);
            this.venta.total = parseFloat(total);
        },

        sumatoria() {
            let indice = 1;
            for (let x of this.venta.conceptos) {
                x.item = indice;
                indice++;
            }

            this.venta.subTotal = Math.round(this.sum(this.venta.conceptos, 'importe') * 100) / 100;
            let tras = Math.round(this.sum(this.$store.state.ventaStore.impuestos.traslados, 'importe') * 100) / 100;
            let ret = Math.round(this.sum(this.$store.state.ventaStore.impuestos.retenciones, 'importe') * 100) / 100;
            this.venta.total = this.venta.subTotal + tras - ret;
        },

        sum(array, key) {
            return array.reduce((a, b) => a + (b[key] || 0), 0);
        },

        AgrupaYCalculaImpuestos(lista) {
            var agrupado = lista.reduce(function (acumulador, elemento) {
                // Crear una clave única para el grupo
                var clave = elemento.impuesto + '-' + elemento.tipoFactor + '-' + elemento.tasaOCuota;

                // Inicializar el grupo si es la primera vez que se encuentra la clave
                if (!acumulador[clave]) {
                    acumulador[clave] = {
                        impuesto: elemento.impuesto,
                        tipoFactor: elemento.tipoFactor,
                        tasaOCuota: elemento.tasaOCuota,
                        base_: 0,
                        importe: 0,
                    };
                }

                // Sumar base e importe al grupo correspondiente
                acumulador[clave].base_ += parseFloat(elemento.base_);
                acumulador[clave].importe += parseFloat(elemento.importe);

                return acumulador;
            }, {});

            // Convertir el objeto agrupado de nuevo a una lista
            var resultado = Object.values(agrupado);

            for (let r of resultado) {
                r.base_ = r.base_.toFixed(2);
                r.importe = r.importe.toFixed(2);
            }
            return resultado;
        },

        async imprimirTicket() {

            let empresa = { ...this.$store.state.empresaStore };

            let comprobante = { ...this.venta }
            this.venta.folio = '0';
            this.venta.emisor = {
                rfc: empresa.rfc,
                nombre: empresa.nombre,
                regimenFiscal: { ...empresa.regimenFiscal },
                facAtrAdquirente: '',
            };
            this.venta.fecha = moment(new Date).format('YYYY-MM-DD HH:mm:ss')
            this.venta.idTurno = 0
            this.venta.usuarioVentas = ''
            this.venta.serie = 'V'
            this.venta.pagoCon = this.sum(this.venta.formasPago, 'monto')
            this.venta.cambioTotal = comprobante.pagoCon - this.venta.total
            this.venta.formaPago = this.venta.formasPago[0].formaPago

            console.log(this.venta)


            var a = await this.convertBase64();
            // var printWindow = window.open(a); 
            // printWindow.print();
            print({
                printable: a,
                type: 'pdf',
                base64: true,
            })
        },
        async convertBase64() {
            var opt = {
                margin: .1,
                filename: 'Ticket' + this.venta.serie + "_" + this.venta.folio + ".pdf",
                // jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                html2canvas: { scale: 4, letterRendering: true, },

                autoPaging: 'text'
            }

            //GENRA EL BASE64
            let file = '';
            const jabubu = await html2pdf().from(document.getElementById('ticket')).set(opt).toPdf().get('pdf').then((pdf) => {
            }).outputPdf();

            file += btoa(jabubu)
            // console.log(file)
            return file
        },
        async cobrarSinImprimir() {
            console.log(this.venta)

            if (this.venta.formasPago.length == 0) {
                this.$q.notify({ type: 'negative', message: 'Agrege al menos una forma de pago.', color: 'red' })
                return;
            }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'primary', spinnerSize: 140, message: 'Guardando venta. Espere...', messageColor: 'yellow' })

            try {
                let empresa = { ...this.$store.state.empresaStore };

                let comprobante = { ...this.venta }
                comprobante.folio = '0';
                comprobante.emisor = {
                    rfc: empresa.rfc,
                    nombre: empresa.nombre,
                    regimenFiscal: { ...empresa.regimenFiscal },
                    facAtrAdquirente: '',
                };
                comprobante.fecha = moment(new Date).format('YYYY-MM-DD HH:mm:ss')
                comprobante.idTurno = 0
                comprobante.usuarioVentas = ''
                comprobante.serie = 'V'
                comprobante.pagoCon = this.sum(this.venta.formasPago, 'monto')
                comprobante.cambioTotal = comprobante.pagoCon - this.venta.total
                comprobante.formaPago = this.venta.formasPago[0].formaPago

                let response = await axios.post('PuntoVenta/PostPuntoVenta/' + this.token.empresaBase, comprobante)
                console.log(response.data)

                //ASIGNAMOS VALORES
                comprobante._id = response.data._id;
                comprobante.folio = response.data.folio;

                this.$store.state.listVentasStore.push(comprobante);
                let array = this.$store.state.listVentasStore;
                this.$store.state.listVentasStore = array.sort(((a, b) => b.folio - a.folio))

                this.$q.notify({ type: 'positive', message: `Venta registrada.` })
                this.$q.loading.hide()

                // this.cierraVentana();

                //this.guardandoVenta = 0

                //if (this.enviarCorreo == true) {
                //  await this.mandarCorreo();
                //}
                this.dialogCobro = false
                this.cancelarVenta();
            } catch (error) {
                this.$q.notify({ type: 'negative', message: 'Error al registrar venta.', color: 'red' })
                console.log(error.data)
                this.$q.loading.hide()
            }
        },

        SumaCobros() {
            var total_ = 0;
            for (var i = 0; i < this.venta.formasPago.length; i++) {
                this.mensajeCobro = 'Por Cobrar:'
                total_ = total_ + Number(this.venta.formasPago[i].monto);
            }

            total_ = this.venta.total - total_
            if (total_ < 0) {
                this.mensajeCobro = 'Cambio:'
                total_ = total_ * -1
            }
            return total_
        },

        realizarCobro() {
            this.SumaCobros()
            if (this.venta.conceptos.length == 0) {
                this.$q.notify({ type: 'negative', message: 'Agrege al menos un producto.', color: 'red' })
                return;
            }
            this.mensajeCobro = 'Por Cobrar:'
            this.objFormaPago.monto = this.venta.total
            this.dialogCobro = true
        },

        pushCobro() {
            if (this.objFormaPago.monto == 0) {
                this.$q.notify({ type: 'negative', message: 'Agrege un monto mayor a 0.00', color: 'red' })
                return;
            }
            let cobroDefault = {
                formaPago: { id: 1, clave: "01", formaPago: "01 | Efectivo", descripcion: "Efectivo" },
                monto: 0.00,
                referencia: ''
            }
            this.venta.formasPago.push(this.objFormaPago)
            this.objFormaPago = Object.assign({}, cobroDefault);
        },

        eliminarCobro(item) {
            let indice = this.venta.formasPago.indexOf(item);
            this.venta.formasPago.splice(indice, 1);
        },

        AgregarUltimo() {
            if (this.$store.state.ventaStore.conceptos.length > 0) {
                var indice = this.$store.state.ventaStore.conceptos.length
                console.log(indice);
                var ultimo = this.$store.state.ventaStore.conceptos[indice - 1]
                this.$store.state.ventaStore.conceptos.push(ultimo)
                this.AgrupaYCalculaTotales();
            }
        },

        EliminarUltimo() {
            var item = this.$store.state.ventaStore.conceptos.length
            var indice = this.$store.state.ventaStore.conceptos.indexOf(item)
            this.$store.state.ventaStore.conceptos.splice(indice, 1);
            this.AgrupaYCalculaTotales();
        },

        eliminarArticulo(item) {
            console.log(item)
            let indice = this.$store.state.ventaStore.conceptos.indexOf(item);
            this.$store.state.ventaStore.conceptos.splice(indice, 1);
            this.AgrupaYCalculaTotales();
        },

        async GetFormaPago() {
            console.log(this.$store.state.formaPagoStore)
            if (this.$store.state.formaPagoStore.length != 0) {
                console.log(this.$store.state.formaPagoStore.length)

                return
            }
            try {
                let result = await axios.get('https://api-erp.contago.com.mx/api/CatalogosSat/GetFormaPago/');
                console.log(result)

                this.$store.state.formaPagoStore = result.data;
            } catch (error) {

            }
        },

        async getClientes() {
            try {
                let response = await axios.get("CatalogosComprobantes/GetReceptorAsync/" + this.token.empresaBase);
                console.log(response)
                this.$store.state.listClienteStore = response.data;
            } catch (error) {
                console.log(error);
            }
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        cancelarVenta() {
            let ventaDefaul = {
                _id: '',
                folioFiscal: '',
                serie: '',
                folio: '',
                fecha: null,
                formaPago: { id: 1, clave: "01", formaPago: "01 | Efectivo", descripcion: "Efectivo" },
                subTotal: 0,
                descuento: 0,
                moneda: {
                    id: 100,
                    clave: 'MXN',
                    descripcion: 'Peso Mexicano',
                    moneda: 'MXN | Peso Mexicano'
                },
                total: 0,
                tipoComprobante: 'I',
                tipoComprobanteInterno: 'PuntoVenta',
                metodoPago: { id: 1, clave: "PUE", metodoPago: "PUE | Pago en una sola exhibición", descripcion: "Pago en una sola exhibición" },
                empresa: {},
                receptor: {
                    rfc: 'XAXX010101000',
                    nombre: 'PUBLICO EN GENERAL',
                    receptor: 'XAXX010101000 | PUBLICO EN GENERAL',
                    domicilioFiscalReceptor: '',
                    usoCfdi: {
                        clave: "S01",
                        moral: "Sí",
                        fisica: "Sí",
                        usoCfdi: "S01 | Sin efectos fiscales.",
                        descripcion: "Sin efectos fiscales."
                    },
                    regimenFiscalReceptor: {
                        clave: "616",
                        moral: "Sí",
                        fisica: "No",
                        descripcion: "Sin obligaciones fiscales",
                        regimenFiscal: "616 | Sin obligaciones fiscales"
                    },
                },
                rfc: '',
                nombre: '',
                notas: '',
                estatus: 'Sin Timbrar',
                conceptos: [],
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
                idTurno: 0,
                usuarioVentas: '',
                pagoCon: 0.00,
                cambioTotal: 0.00,
                formasPago: [],
            }
            this.$store.state.ventaStore = { ...ventaDefaul }
        },

        handleKeyUp() {
            console.log(event.keyCode)
            switch (event.keyCode) {
                case 107:
                    // PRESIONAR TECLA MAS -
                    if (this.dialogCobro == false && this.dialogConfiguracion == false) {
                        this.AgregarUltimo()
                    }
                    break
                case 109:
                    // PRESIONAR TECLA MENOS +
                    if (this.dialogCobro == false && this.dialogConfiguracion == false) {
                        this.EliminarUltimo()
                    }
                    break
                case 17:
                    // PRESIONAR CTRL
                    if (this.dialogCobro == false && this.dialogConfiguracion == false) {
                        this.realizarCobro();
                    } else if (this.dialogCobro == true) {
                        this.cobrarSinImprimir()
                    }
                    break
                case 27:
                    // PRESIONAR ESC
                    if (this.dialogCobro == true) {
                        this.dialogCobro = false
                    }
                    else if (this.dialogConfiguracion == true) {
                        this.dialogConfiguracion = false
                    }
                    else if (this.dialogCobro == false && this.dialogConfiguracion == false) {
                        this.cancelarVenta();
                    }
                    break
                case 113:
                    // PRESIONAR F2
                    if (this.dialogCobro == false && this.dialogConfiguracion == false) {
                        this.dialogConfiguracion = true
                    }
                    break
                case 13:
                    if (this.dialogCobro == true) {
                        this.$refs.monto.autofocus = true;
                        this.pushCobro()
                    }
                    break
            }
        }
    }

}
</script>
<style>
.footerVenta {
    position: fixed;
    padding-left: 10px;
    bottom: 40px;
    width: 100%;
    padding-right: 70px;
}
</style>
<style lang="sass">
.my-sticky-header-column-table
  /* height or max-height is important */
  height: 310px

  tr th
    position: sticky
    /* higher than z-index for td below */
    z-index: 2
    /* bg color is important; just specify one */
    background: #fff

  /* this will be the loading indicator */
  thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
    /* highest z-index */
    z-index: 3
  thead tr:first-child th
    top: 0
    z-index: 1
  tr:first-child th:first-child
    /* highest z-index */
    z-index: 3

  td:first-child
    z-index: 1

  td:first-child, th:first-child
    position: sticky
    left: 0

  /* prevent scrolling behind sticky top row on focus */
  tbody
    /* height of all previous header rows */
    scroll-margin-top: 48px
</style>