<template>
    <q-card style="width: 1000px;">
        <q-toolbar>
            <q-btn push color="red-10" icon="mdi-close-circle-outline" round flat size="18px" padding="xs"
                @click="CierraDialog">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Cerrar</q-tooltip>
            </q-btn>
            <q-toolbar-title><span class="text-weight-bold">Proyecto</span></q-toolbar-title>

            <!-- BOTON PARA GUARDAR -->
            <q-btn v-if="proyecto._id == ''" push color="green-10" icon="mdi-content-save" round flat size="18px"
                padding="xs" @click="GuardarProyecto">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Guardar</q-tooltip>
            </q-btn>
            <q-btn v-else push color="green-10" icon="mdi-content-save" round flat size="18px" padding="xs"
                @click="EditarProyecto">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Editar</q-tooltip>
            </q-btn>
        </q-toolbar>
        <q-separator color="primary" inset size="4px" />
        <q-card-section class="q-pb-sm">
            <div class="row q-col-gutter-sm">

                <!-- DESCRIPCIÓN -->
                <div class="col-12">
                    <q-input outlined v-model="proyecto.clave" label="Clave del Proyecto" dense />
                </div>

                <!-- DESCRIPCIÓN -->
                <div class="col-12">
                    <q-input outlined v-model="proyecto.descripcion" label="Descripción del Proyecto" dense />
                </div>

                <!-- CLIENTE -->
                <div class="col-12">
                    <q-select v-model="proyecto.receptor" use-input input-debounce="0" label="Cliente"
                        :options="optionsClientes" @filter="filterCliente" fill-input hide-selected
                        :loading="loadingClientes" dense outlined option-label="receptor">
                        <template v-slot:no-option>
                            <q-item>
                                <q-item-section class="text-grey">
                                    No exixte algún cliente que coincida con los criterios
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-select>
                </div>

                <!-- PRESUPUESTO -->
                <div class="col-6">
                    <q-field v-model="proyecto.presupuesto" label="Presupuesto" dense outlined>
                        <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                            <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                v-show="floatingLabel" v-bind="importeFormat"></vue-number>
                        </template>
                    </q-field>
                </div>

                <!--FECHA DE ENTREGA  -->
                <div class="col-6">
                    <datetime v-model="proyecto.fechaEntrega" title="Fecha de Entrega" type="datetime"
                        :phrases="{ ok: 'Ok', cancel: 'Cancelar' }"
                        :format="{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit' }"
                        value-zone="America/Mexico_City" class="custom-datetime"></datetime>
                </div>

            </div>
        </q-card-section>
    </q-card>
</template>
<script>

import axios from 'axios'
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar';
import moment from 'moment';
import { component as VueNumber } from '@coders-tm/vue-number-format'
import { Datetime } from 'vue-datetime';

export default {
    components: {
        VueNumber, Datetime
    },
    data() {
        return {
            //CLIENTES
            loadingClientes: false,
            optionsClientes: [],
            importeFormat: {
                decimal: '.',
                separator: ',',
                prefix: '',
                precision: 2,
                masked: false,
                minimumFractionDigits: 2,
            },
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        proyecto() {
            return this.$store.state.proyectoStore
        },
        optionsClientesDefault() {
            return this.$store.state.listClienteStore
        },
    },
    created() {
    },
    methods: {
        async GuardarProyecto() {
            // console.log(this.proyecto)
            if (this.proyecto.clave == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique una clave de proyecto.' })
                return;
            }

            if (this.proyecto.descripcion == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique una descripcion de proyecto.' })
                return;
            }

            if (this.proyecto.receptor.receptor == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el RFC correspodiente al proyecto.' })
                return;
            }

            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando proyecto. Espere...', messageColor: 'white' })

            try {
                let response = await axios.post('Ventas/PostProyectoAsync/erp_' + this.token.rfc, this.proyecto)
                console.log(response)

                this.proyecto._id = response.data._id
                this.$store.state.listaProyectosStore.push(this.proyecto)
                this.CierraDialog();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `El proyecto ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        async EditarProyecto() {
            // console.log(this.proyecto)
            this.proyecto.usuarioModificador = this.token.nombre
            if (this.proyecto.clave == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique una clave de proyecto.' })
                return;
            }

            if (this.proyecto.descripcion == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique una descripcion de proyecto.' })
                return;
            }

            if (this.proyecto.receptor.receptor == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el RFC correspodiente al proyecto.' })
                return;
            }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Actualizando proyecto. Espere...', messageColor: 'white' })

            try {
                let response = await axios.put('Ventas/PutProyectoAsync/erp_' + this.token.rfc, this.proyecto)
                console.log(response)

                let indice = this.$store.state.listaProyectosStore.findIndex(x => x._id === this.proyecto._id);
                Object.assign(this.$store.state.listaProyectosStore[indice], this.proyecto)
                this.CierraDialog();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `El proyecto ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        CierraDialog() {
            this.$emit("Close");
        },
        FormatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        filterCliente(val, update) {
            let stringOptions = this.optionsClientesDefault
            if (val === '') {
                update(() => {
                    this.optionsClientes = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsClientes = stringOptions.filter(v => v.receptor.toLowerCase().indexOf(needle) > -1)
            })
        },

    },
}
</script>
<style>
.my-card {
    width: 100%;
}
</style>