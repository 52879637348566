<template>
    <q-card style="width: 700px; max-width: 80vw;">
        <q-card-section>
            <q-toolbar>
                <q-toolbar-title>
                    Figura de transporte
                </q-toolbar-title>
                <q-btn v-if="objetoStore._id == ''" dense round color="green" icon="mdi-content-save" class="q-mr-sm"
                    @click="guardar()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>

                <q-btn v-else round color="orange" dense icon="mdi-content-save" class="q-mr-sm" @click="actualizar()">
                    <q-tooltip>
                        Editar
                    </q-tooltip>
                </q-btn>

                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
            <q-card-section class="scroll">
                <div class="row q-col-gutter-sm">
                    <div class="col-12">
                        <q-select v-model="objetoStore.tipoFigura" emit-value map-options @filter="filtroTipoFigura"
                            :options="itemsFiltroTipoFigura" option-label="tipoFigura" use-input input-debounce="0"
                            dense filled label="Tipo de figura" virtual-scroll-slice-size="1" />
                    </div>
                    <div class="col-12 ">
                        <q-input dense filled label="Número de licencia" v-model="objetoStore.numLicencia" />
                    </div>
                    <div class="col-12 ">
                        <q-input dense filled label="RFC" v-model="objetoStore.rfcFigura"
                            @input="convertirAMayusculasRFC" maxlength="13" />
                    </div>
                    <div class="col-12 ">
                        <q-input dense filled label="Nombre" v-model="objetoStore.nombreFigura"
                            @input="convertirAMayusculas" />
                    </div>
                    <template>
                        <div v-if="objetoStore.rfcFigura == 'XEXX010101000'" class="col-12 ">
                            <q-input dense filled label="Número de identificación"
                                v-model="objetoStore.numRegIdTribFigura" />
                        </div>
                        <div v-if="objetoStore.rfcFigura == 'XEXX010101000'" class="col-12">
                            <q-select v-model="objetoStore.residenciaFiscalFigura" emit-value map-options
                                @filter="filtroPais" :options="itemsFiltroPais" option-label="pais" use-input
                                input-debounce="0" dense filled label="País de residencia fiscal"
                                virtual-scroll-slice-size="1" />
                        </div>
                    </template>
                    <div class="col-12"
                        v-if="objetoStore.tipoFigura.clave === '02' || objetoStore.tipoFigura.clave === '03'">
                        <q-select dense filled v-model="objetoStore.partesTransporte" use-input use-chips multiple
                            input-debounce="0" :options="itemsfiltroPartesTransporte" option-label="parteTransporte"
                            @filter="filtroPartesTransporte" label="Partes del transporte">
                        </q-select>
                    </div>
                    <div class="col-12">
                        <domicilio class="full-width" ref="miComponenteHijo"></domicilio>
                    </div>
                </div>
            </q-card-section>
        </q-card-section>
    </q-card>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import domicilio from '../../CatalogosCartaPorte/Domicilios/Domicilio.vue'

export default {
    components: {
        domicilio
    },

    data() {
        return {
            itemsFiltroTipoFigura: null,
            itemsFiltroPais: null,
            itemsfiltroPartesTransporte: null,
            itemsFiltroDomicilio: null
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        objetoStore() {
            return this.$store.state.figuraTransporteStore;
        },
        itemTipoFiguras() {
            return this.$store.state.listaCatalogoTipoFigura;
        },
        itemsPais() {
            return this.$store.state.listaCatalogoPais;
        },
        itemsPartesTransporte() {
            return this.$store.state.listaCatalogoPartesTransporte;
        },
        itemsDomicilio() {
            return this.$store.state.listaDomiciliosStore
        }
    },

    created() {
    },
    methods: {
        cierraVentana() {
            this.$emit('cierraVentana')
        },
        filtroTipoFigura(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroTipoFigura = this.itemTipoFiguras.filter(v => v.tipoFigura.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroTipoFigura.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroPais(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroPais = this.itemsPais.filter(v => v.pais.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroPais.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroPartesTransporte(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroPartesTransporte = this.itemsPartesTransporte.filter(v => v.parteTransporte.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroPartesTransporte.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroDomicilio(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroDomicilio = this.itemsDomicilio.filter(v => v.domicilio.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroDomicilio.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        async guardar() {
            //VALIDAMOS Y ASIGNAMOS EL DOMICILIO
            const childResult = await this.$refs.miComponenteHijo.AsignaValores();
            if (!childResult) {
                return;
            }
            if (this.objetoStore.tipoFigura.tipoFigura == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el tipo de figura' })
                return;
            }
            if (this.objetoStore.tipoFigura.clave === '01') {
                if (this.objetoStore.numLicencia == '') {
                    this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el número de licencia' })
                    return;
                }
            }
            if (this.objetoStore.rfcFigura == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el RFC de la figura' })
                return;
            }
            if (this.objetoStore.rfcFigura.length < 12) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'El RFC que proporciono no esta completo' })
                return;
            }
            if (this.objetoStore.nombreFigura == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el Nombre de la figura' })
                return;
            }
            if (this.objetoStore.tipoFigura.clave === '02' || this.objetoStore.tipoFigura.clave === '03') {
                if (this.objetoStore.partesTransporte.length == 0) {
                    this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique las partes de transporte' })
                    return;
                }
            }

            // ASIGNAMOS A UN OBJETO
            let objFigura = { ...this.$store.state.figuraTransporteStore }
            let objDomicilio = { ...childResult }
            objFigura.domicilio = objDomicilio;

            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando figura de transporte. Espere...', messageColor: 'white' })
                let response = await axios.post('CatalogosComprobantes/PostFiguraDeTransporte/erp_' + this.token.rfc, objFigura)
                console.log(response)

                this.objetoStore._id = response.data
                this.$store.state.listaFigurasTransporteStore.push(objFigura)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `La figura de transporte ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        async actualizar() {
            //VALIDAMOS Y ASIGNAMOS EL DOMICILIO
            const childResult = await this.$refs.miComponenteHijo.AsignaValores();
            if (!childResult) {
                return;
            }

            if (this.objetoStore.tipoFigura.tipoFigura == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el tipo de figura' })
                return;
            }

            if (this.objetoStore.tipoFigura.clave === '01') {
                if (this.objetoStore.numLicencia == '') {
                    this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el número de licencia' })
                    return;
                }
            }

            if (this.objetoStore.rfcFigura == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el RFC de la figura' })
                return;
            }

            if (this.objetoStore.rfcFigura.length < 12) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'El RFC que proporciono no esta completo' })
                return;
            }

            if (this.objetoStore.nombreFigura == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el Nombre de la figura' })
                return;
            }

            if (this.objetoStore.tipoFigura.clave === '02' || this.objetoStore.tipoFigura.clave === '03') {
                if (this.objetoStore.partesTransporte.length == 0) {
                    this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique las partes de transporte' })
                    return;
                }
            }

            // ASIGNAMOS A UN OBJETO
            let objFigura = { ...this.$store.state.figuraTransporteStore }
            let objDomicilio = { ...childResult }
            objFigura.domicilio = objDomicilio;

            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Actualizando figura de transporte. Espere...', messageColor: 'white' })
                let response = await axios.put('CatalogosComprobantes/PutFiguraTransporte/erp_' + this.token.rfc, objFigura)
                console.log(response)

                let indice = this.$store.state.listaFigurasTransporteStore.findIndex(x => x._id === objFigura._id);
                Object.assign(this.$store.state.listaFigurasTransporteStore[indice], objFigura)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `La figura de transporte ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        convertirAMayusculasRFC() {
            this.objetoStore.rfcFigura = this.objetoStore.rfcFigura.replace(/\s/g, '').toUpperCase();
        },

        convertirAMayusculas() {
            this.objetoStore.nombreFigura = this.objetoStore.nombreFigura.toUpperCase();
        }
    }
}
</script>
<style>
.my-card {
    width: 100%;
}
</style>