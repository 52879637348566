<template>
    <q-layout view="hHh lpR fFf">
        <q-header elevated v-if="logueado">
            <q-toolbar>
                <q-btn flat dense round icon="mdi-home" aria-label="Menu" @click="irInicio" />
                <q-toolbar-title>
                    <div class="text-h6 text-weight-bolder">ERP</div>
                </q-toolbar-title>
                <!-- <q-toggle v-model="isDarkMode" label="Modo Oscuro" color="dark"></q-toggle> -->
                <div class="text-h6 q-mr-lg">{{ $store.state.usuario.rfc }}</div>
                <q-btn flat round dense icon="mdi-account" @click="drawerPerfil = !drawerPerfil" />
            </q-toolbar>
        </q-header>

        <!-- DRAWER DERECHO -->
        <q-drawer v-model="drawerPerfil" side="right" bordered v-if="logueado">
            <drawerPerfil></drawerPerfil>
        </q-drawer>

        <q-page-container>
            <router-view />
        </q-page-container>

        <q-footer style="height: 30px;" bordered class="bg-dark text-white" v-if="logueado">
            <q-toolbar>
                <q-toolbar-title>
                </q-toolbar-title>
            </q-toolbar>
        </q-footer>
    </q-layout>
</template>
  
<script>
import { ref } from 'vue'
import axios from "axios";
import drawerPerfil from "../DrawerPerfil/DrawerPerfil.vue"

export default {

    components: {
        drawerPerfil
    },
    data() {
        return {
            drawerPerfil: false,
            isDarkMode: false,
            leftDrawerOpen: false,
            drawer: false,
            miniState: true,
        }
    },

    watch: {
        isDarkMode(val) {
            this.$q.dark.set(val);
        }
    },
    computed: {
        logueado() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.$store.dispatch("autoLogin");
    },
    methods: {
        irInicio() {
            this.$router.push({ name: 'Home' })
        }
    }
}
</script>
  