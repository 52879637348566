<template>
    <div>
        <!-- CONCILIA LOS CLIENTES -->
        <q-dialog v-model="dialogConcilia" maximized transition-show="slide-up" transition-hide="slide-down">
            <div class="q-pa-md q-gutter-sm">
                <q-card class="my-card">
                    <q-toolbar>
                        <q-btn push color="red-10" icon="mdi-close-circle-outline" round flat size="18px" padding="xs"
                            v-close-popup>
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Cerrar</q-tooltip>
                        </q-btn>
                        <q-toolbar-title>
                            CONCILIAR CONCEPTOS
                        </q-toolbar-title>
                        <q-btn push color="green-10" icon="mdi-account-multiple-check" round flat size="18px"
                            padding="xs" @click="conciliaConceptos">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Agregar conceptos</q-tooltip>
                        </q-btn>
                    </q-toolbar>
                    <q-separator class="full-width" color="primary" inset size="4px" />
                    <q-card-section>
                        <span>A continuación se muestra la lista de conceptos que se importaran al sistema</span>
                        <q-table title="Conceptos" :loading="loadingTabla" :data="itemsConcilia"
                            :columns="columnsConcilia" row-key="rfc">
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td key="noIdentificacion" :props="props">{{ props.row.noIdentificacion }}</q-td>
                                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                                    <q-td key="valorUnitario" :props="props">{{ props.row.valorUnitario }}</q-td>
                                    <q-td key="unidad" :props="props">{{ props.row.unidad }}</q-td>
                                    <q-td key="claveProdServ" :props="props">{{ props.row.claveProdServ.claveProdServ
                                        }}</q-td>
                                    <q-td key="claveUnidad" :props="props">{{ props.row.claveUnidad.claveUnidad
                                        }}</q-td>
                                    <q-td key="objImp" :props="props">{{ props.row.objImp.objImp }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </q-card-section>
                </q-card>
            </div>
        </q-dialog>
        <!-- NUEVO CLIENTE -->
        <q-dialog v-model="dialogNuevoConcepto" width="800px" persistent transition-show="scale"
            transition-hide="scale">
            <Concepto @cierraVentana="closeDialogConcepto()"></Concepto>
        </q-dialog>
        <!-- LISTA DE CONCEPTOS -->
        <q-table title="Conceptos" :data="itemsConceptos" :loading="loadingTabla" :columns="columns"
            row-key="noIdentificacion" :filter="filter">
            <template v-slot:top-right>
                <q-input class="q-mr-lg" filled dense debounce="300" v-model="filter" placeholder="Filtrar">
                    <template v-slot:append>
                        <q-icon name="search" />
                    </template>
                </q-input>
                <q-btn round color="green" icon="mdi-plus" @click="nuevoConcepto()" class="q-mr-sm">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Nuevo concepto</q-tooltip>
                </q-btn>
                <q-btn round color="blue" icon="mdi-magnify" @click="getConceptos()" class="q-mr-sm">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Consultar conceptos</q-tooltip>
                </q-btn>
                <q-btn round color="red-10" icon="mdi-tag-check-outline" @click="openDialogConcilia()">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Conciliar conceptos</q-tooltip>
                </q-btn>
            </template>
            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>

            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width>
                        <q-btn size="md" color="blue" rounded flat dense @click="editarConcepto(props.row)"
                            icon="mdi-pencil">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Editar</q-tooltip>
                        </q-btn>
                        <q-btn size="md" color="red" rounded flat dense @click="eliminarConcepto(props.row)"
                            icon="mdi-delete">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Eliminar</q-tooltip>
                        </q-btn>

                    </q-td>
                    <q-td key="noIdentificacion" :props="props">{{ props.row.noIdentificacion }}</q-td>
                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                    <q-td key="claveProdServ" :props="props">{{ props.row.claveProdServ.claveProdServ }}</q-td>
                    <q-td key="claveUnidad" :props="props">{{ props.row.claveUnidad.claveUnidad }}</q-td>
                    <q-td key="unidad" :props="props">{{ props.row.unidad }}</q-td>

                </q-tr>
            </template>
        </q-table>
    </div>
</template>
<script>
import axios from 'axios';
import Concepto from './Concepto.vue'
import { QSpinnerCube } from 'quasar';

export default {
    components: { Concepto },
    data() {
        return {
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true, },
                { name: 'noIdentificacion', align: 'center', label: 'No. Identificación', field: 'noIdentificacion', sortable: true, classes: 'bg-grey-2 ellipsis', headerClasses: 'bg-primary text-white' },
                { name: 'descripcion', align: 'center', label: 'Descripción', field: 'descripcion', sortable: true },
                { name: 'claveProdServ', align: 'center', label: 'Clave Producto o Servicio', field: 'claveProdServ', sortable: true },
                { name: 'claveUnidad', align: 'center', label: 'Clave de Unidad', field: 'claveUnidad', sortable: true },
                { name: 'unidad', align: 'center', label: 'Unidad', field: 'unidad', sortable: true },
            ],
            loadingTabla: false,
            filter: '',
            dialogNuevoConcepto: false,

            //CONCILIACION DE CLIENTES
            dialogConcilia: false,
            itemsConcilia: [],
            columnsConcilia: [
                { name: 'noIdentificacion', align: 'left', label: 'No. identificación', field: 'noIdentificacion', sortable: true, classes: 'bg-grey-2 ellipsis', headerClasses: 'bg-primary text-white' },
                { name: 'descripcion', align: 'left', label: 'Descripción', field: 'descripcion', sortable: true },
                { name: 'valorUnitario', align: 'left', label: 'Valor unitario', field: 'valorUnitario', sortable: true },
                { name: 'unidad', align: 'left', label: 'Unidad', field: 'unidad', sortable: true },
                { name: 'claveProdServ', align: 'left', label: 'Clave prod serv', field: 'claveProdServ', sortable: true },
                { name: 'claveUnidad', align: 'left', label: 'Clave unidad', field: 'claveUnidad', sortable: true },
                { name: 'objImp', align: 'left', label: 'Objeto impuestos', field: 'objImp', sortable: true },
            ],
        }
    },
    computed: {
        itemsConceptos() {
            return this.$store.state.listaConceptosStore;
        },
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.getConceptos();
    },
    methods: {
        nuevoConcepto() {
            this.iniciaizarObjeto();
            this.dialogNuevoConcepto = true
        },

        closeDialogConcepto() {
            this.dialogNuevoConcepto = false
        },

        editarConcepto(data) {
            console.log(data)
            this.$store.state.conceptoStore = { ...data }
            this.dialogNuevoConcepto = true
        },

        async getConceptos() {
            this.loadingTabla = true
            try {
                let response = await axios.get("CatalogosComprobantes/GetConceptosAsync/" + this.token.empresaBase);
                console.log('conceptos', response)
                this.$store.state.listaConceptosStore = response.data;
                this.loadingTabla = false
            } catch (error) {
                console.log(error);
                this.loadingTabla = false
            }
        },

        async eliminarConcepto(value) {
            try {
                let response = await axios.put("CatalogosComprobantes/DeleteConceptoAsync/" + this.token.empresaBase + "/" + value._id);
                console.log(response)

                var index = this.itemsConceptos.indexOf(value);
                this.itemsConceptos.splice(index, 1);

                this.$q.notify({ type: 'positive', message: `Concepto ha sido eliminado.` })

            } catch (error) {
                console.log(error);
                this.$q.notify({ type: 'negative', message: 'Error al eliminar concepto, intentelo de nuevo.', color: 'red' })
            }
        },

        iniciaizarObjeto() {
            let concepto = {
                _id: '',
                claveProdServ: {
                    claveProdServ: '',
                },
                noIdentificacion: '',
                cantidad: 1,
                claveUnidad: {
                    claveUnidad: '',
                },
                unidad: '',
                descripcion: '',
                valorUnitario: 0,
                importe: 0,
                descuento: 0,
                //precio: 0,
                //total: 0,
                objImp: { objImp: '' },
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
                cuentaPredial: {
                    numero: ''
                },
                //material: '',
                // imagen: {
                //     base64: '',
                // },
                //linea: '',
                // marca: '',
                //submarca: '',
                //iepsGas: 0,
                //almacenable: false,
                //estatus: 'Vigente',
                concepto: '',
            }
            this.$store.state.conceptoStore = concepto
        },

        //CONCILIACION
        async openDialogConcilia() {
            this.$q.loading.show({ group: 'first', message: 'Consultando Claves de Productos o Servicios', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetClaveProdServ();
            this.$q.loading.show({ group: 'second', message: 'Consultando Claves de Unidad', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetClaveUnidad();
            this.$q.loading.show({ group: 'first', message: 'Consultando Objetos de Impuesto', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetObjImp();
            this.$q.loading.show({ group: 'first', message: 'Consultando Impuestos de Traslado', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetTraslados();
            this.$q.loading.show({ group: 'first', message: 'Consultando Impuestos de Retención', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetRetenciones();
            this.$q.loading.show({ group: 'first', message: 'Consultando conceptos del sistema anterior', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetConceptosViejos();
            this.$q.loading.hide()
        },

        async GetConceptosViejos() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando clientes...' });
            this.itemsConcilia = [];
            try {
                let response = await axios.get('Protocolos/GetConceptoAsync/erp_' + this.token.rfc);
                let pre = [...response.data]

                //CATALOGOS
                const ClaveProdServ = this.$store.state.claveProdServStore;
                const ClaveUnidad = this.$store.state.claveUnidadStore;
                const ObjImp = this.$store.state.objImpStore;

                //CONCILIAMOS QUE LOS CLIENTES NO ESTEN PREVIAMENTE DADOS DE ALTA
                let confirmados = pre.filter(f => !this.$store.state.listaConceptosStore.some(s => f.nombre === s.nombre));
                confirmados.forEach(e => {
                    let claveProdServ_ = ClaveProdServ.find(f => f.clave === e.claveProdServ.clave);
                    e.claveProdServ = claveProdServ_;
                    let claveUnidad_ = ClaveUnidad.find(f => f.clave === e.claveUnidad.clave);
                    e.claveUnidad = claveUnidad_;
                    let objImp_ = ObjImp.find(f => f.clave === e.objImp.clave);
                    e.objImp = objImp_;
                });
                this.itemsConcilia = [...confirmados];
                this.$q.loading.hide();
                this.dialogConcilia = true;
            } catch (error) {
                console.log(response.data);
                this.$q.loading.hide()
            }
        },

        async conciliaConceptos() {
            this.$q.loading.show({ group: 'first', message: 'Guardando conceptos', spinnerColor: 'amber', messageColor: 'amber' })
            for (let e of this.itemsConcilia) {
                const claveProdServ = e.claveProdServ;
                const noIdentificacion = e.noIdentificacion.trim();
                const cantidad = e.cantidad
                const claveUnidad = e.claveUnidad;
                const unidad = e.unidad.trim();
                const descripcion = e.descripcion.trim();
                const valorUnitario = e.valorUnitario;
                const importe = e.importe;
                const descuento = e.descuento;
                const objImp = e.objImp;
                const impuestos = e.impuestos;
                const cuentaPredial = e.cuentaPredial;
                const concepto = e.concepto;

                let conceptoG = {
                    _id: '',
                    claveProdServ: claveProdServ,
                    noIdentificacion: noIdentificacion,
                    cantidad: cantidad,
                    claveUnidad: claveUnidad,
                    unidad: unidad,
                    descripcion: descripcion,
                    valorUnitario: valorUnitario,
                    importe: importe,
                    descuento: descuento,
                    objImp: objImp,
                    impuestos: impuestos,
                    cuentaPredial: cuentaPredial,
                    concepto: concepto
                }
                try {
                    let response = await axios.post('CatalogosComprobantes/PostConceptoAsync/' + this.token.empresaBase, conceptoG)
                    conceptoG._id = response.data
                    this.$store.state.listaConceptosStore.push(conceptoG)
                    console.log('Guardado')
                } catch (error) {
                    console.log(error)
                }
            };
            this.$q.loading.hide()
        },

        //CATALOGOS INICIALES
        async GetClaveProdServ() {
            if (this.$store.state.claveProdServStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('https://api-erp.contago.com.mx/api/CatalogosSat/GetClaveProdServ/');
                this.$store.state.claveProdServStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetClaveUnidad() {
            if (this.$store.state.claveUnidadStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('https://api-erp.contago.com.mx/api/CatalogosSat/GetClaveUnidad/');
                this.$store.state.claveUnidadStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetObjImp() {
            if (this.$store.state.objImpStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('https://api-erp.contago.com.mx/api/CatalogosSat/GetObjImp/');
                this.$store.state.objImpStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetTraslados() {
            if (this.$store.state.trasladosStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('https://api-erp.contago.com.mx/api/Impuestos/GetTraslados/');
                this.$store.state.trasladosStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

        async GetRetenciones() {
            if (this.$store.state.retencionesStore.length != 0) {
                return
            }
            try {
                let response = await axios.get('https://api-erp.contago.com.mx/api/Impuestos/GetRetenciones/');
                this.$store.state.retencionesStore = response.data
            } catch (error) {
                console.log(error)
            }
        },

    }
}
</script>