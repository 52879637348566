<template>
    <q-page>
        <div class="row">
            <div class="col-12 q-pa-md">
                <q-toolbar class="bg-primary text-white">
                    <q-toolbar-title>
                        Configuración
                    </q-toolbar-title>
                    <q-btn flat round dense class="q-mr-sm" icon="mdi-image-off" @click="eliminarLogo()"><q-tooltip>
                            Eliminar Logo
                        </q-tooltip></q-btn>
                    <q-btn flat round dense icon="mdi-content-save" @click="guardarOactualizar()">
                        <q-tooltip>
                            Guardar
                        </q-tooltip></q-btn>
                </q-toolbar>
            </div>
            <div class="col-12 col-md-6">
                <div class="row q-mt-md">
                    <!-- RFC -->
                    <div class="col-12 col-md-6 q-pa-sm" color="bg-primary">
                        <q-input filled outlined v-model="empresa.rfc" label="RFC" />
                    </div>
                    <!-- CODIGO POSTAL -->
                    <div class="col-12 col-md-6 q-pa-sm" color="bg-primary">
                        <q-input filled outlined v-model="empresa.domicilioFiscal" label="Código Postal" />
                    </div>
                    <!-- NOMBRE -->
                    <div class="col-12 col-md-12 q-pa-sm" color="bg-primary">
                        <q-input filled outlined v-model="empresa.nombre" label="Nombre" />
                    </div>
                    <!-- REGIMEN FISCAL -->
                    <template v-if="ValidaRegimen != ''">
                        <div class="col-12">
                            <q-select dense class="full-width" outlined fill-input v-model="empresa.regimenFiscal"
                                :options="regimenFiscal" label="Régimen Fiscal" option-label="regimenFiscal" />
                        </div>
                    </template>
                    <!-- CARGAR LOGO -->
                    <div class="col-12 col-md-12 q-pa-sm" color="bg-primary">
                        <q-uploader :multiple="false" label="Cargar Archivo" class="full-width" no-thumbnails url="/"
                            accept=".jpg,.jpeg,.png" @added="uploadImage" />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="row q-mt-md ">
                    <div class="col-12 col-md-12 q-pa-sm text-center" color="bg-primary">
                        <q-img :src="empresa.logo.base64" style=" width: 600px;">
                            <div class=" absolute-bottom text-subtitle1 text-center">
                                LOGO DE LA EMPRESA
                            </div>
                        </q-img>
                    </div>
                </div>
            </div>
        </div>
        <div class="row q-mt-md">
            <div class="col-12 q-pa-md">
                <q-toolbar class="bg-primary text-white">
                    <q-toolbar-title>
                        Correo
                    </q-toolbar-title>
                </q-toolbar>
            </div>
        </div>
        <div class="row q-mt-md">
            <div class="col-12 col-md-6  q-pa-sm">
                <q-input outlined v-model="empresa.nombreCorreo" filled label="Nombre" />
            </div>
            <div class="col-12 col-md-6  q-pa-sm">
                <q-input outlined v-model="empresa.correo" filled label="Correo" />
            </div>
        </div>
        <div class="row ">
            <div class="col-12 col-md-4  q-pa-sm">
                <q-input v-model="empresa.password" filled :type="isPwd ? 'password' : 'text'">
                    <template v-slot:append>
                        <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                            @click="isPwd = !isPwd" />
                    </template>
                </q-input>
            </div>
            <div class="col-12 col-md-4  q-pa-sm">
                <q-input outlined v-model="empresa.puerto" filled label="Puerto" />
            </div>
            <div class="col-12 col-md-4  q-pa-sm">
                <q-input outlined v-model="empresa.host" filled label="Host" />
            </div>
        </div>
    </q-page>
</template>
<script>
import axios from "axios";
import { resizeImagePixels } from '../../plugins/rezizeImage.js'

export default {
    components: {},
    data() {
        return {
            regimenFiscal: [],
            isPwd: true,
        };
    },
    mounted() {

    },
    computed: {
        empresa() {
            return this.$store.state.empresaStore;
        },

        token() {
            return this.$store.state.usuario;
        },

        correo() {
            return this.$store.state.correoStore;
        },

        ValidaRegimen() {
            let rfc = this.$store.state.empresaStore.rfc
            if (rfc.length === 12) {
                //VALIDAMOS EL REGIMEN FISCAL
                this.regimenFiscal = [];
                let filtroRegimen = this.$store.state.regimenFiscalStore.filter(f => f.moral === 'Sí');
                this.regimenFiscal = [...filtroRegimen]
                return 'M';
            } else if (rfc.length === 13) {
                //VALIDAMOS EL REGIMEN FISCAL
                this.regimenFiscal = [];
                let filtroRegimen = this.$store.state.regimenFiscalStore.filter(f => f.fisica === 'Sí');
                this.regimenFiscal = [...filtroRegimen]
                if (rfc === 'XAXX010101000' || rfc === 'XEXX010101000') {
                    let b = filtroRegimen.find(f => f.clave === '616');
                    this.$store.state.empresaStore.regimenFiscalReceptor = b;
                }
                return 'F';
            }
            return "";
        },
    },
    created() {
        this.GetRegimenFiscal();
        this.GetEmpresa();
        // this.GetCorreo();

    },
    methods: {
        async GetRegimenFiscal() {
            if (this.$store.state.regimenFiscalStore.length == 0) {
                try {
                    let response = await axios.get("CatalogosSat/GetCatRegimenFiscalAsync/");
                    this.$store.state.regimenFiscalStore = response.data;
                } catch (error) {
                    console.log(error);
                }
            }
        },

        async GetEmpresa() {
            try {
                let response = await axios.get("Empresa/GetEmpresaAsync/" + this.token.empresaBase);
                let x = response.data;
                this.$store.state.empresaStore = { ...x }
            } catch (error) {
                console.log(error)
            }
        },

        guardarOactualizar() {
            if (this.empresa._id == 0 || this.empresa._id == null) {
                this.PostEmpresa();
            } else {
                this.putEmpresa();
            }
        },

        async PostEmpresa() {
            console.log(this.empresa)
            this.$q.loading.show({ message: '<b>Guardando Información...' })
            try {
                let response = await axios.post('Empresa/PostEmpresaAsync/erp_' + this.token.rfc, this.empresa);
                console.log(response.data)
                this.$q.notify({ type: 'positive', message: 'Configuración guardada exitosamente.' })
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique la información e intentelo de nuevo.' })
                this.$q.loading.hide()
            }
        },

        async putEmpresa() {
            this.$q.loading.show({ message: '<b>Guardando Información...' })
            console.log(this.empresa)
            try {
                let response = await axios.put('Empresa/PutEmpresaAsync/erp_' + this.token.rfc, this.empresa)
                console.log(response.data)
                this.$q.notify({ type: 'positive', message: 'Configuración guardada exitosamente.' })
                this.$q.loading.hide()

            } catch (error) {
                console.log(error)
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique la información e intentelo de nuevo.' })
                this.$q.loading.hide()
            }
        },

        eliminarLogo() {
            this.$store.state.empresaStore.logo.base64 = '../../assets/blanco.png'
        },

        convertBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
            });
        },

        async uploadImage(event) {
            console.log(event)
            const file = event[0];
            try {
                // const result = await this.convertBase64(file);
                const resizedImageBase64 = await resizeImagePixels(file, 600, 400);
                console.log(resizedImageBase64);
                this.$store.state.empresaStore.logo.base64 = resizedImageBase64;
            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },
    },
};
</script>
<style>