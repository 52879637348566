<template>
    <q-layout view="hHh lpR fFf">
        <q-header elevated>
            <q-toolbar>
                <q-btn flat dense round icon="mdi-home" aria-label="Menu" @click="$router.push({ name: 'Home' })" />
                <q-toolbar-title>
                    <div class="text-h6 text-weight-bolder">NOTA DE CRÉDITO</div>
                </q-toolbar-title>
                <div class="text-h6 q-mr-lg">{{ $store.state.usuario.rfc }}</div>
                <q-btn flat round dense icon="mdi-account" @click="drawerPerfil = !drawerPerfil" />
            </q-toolbar>
        </q-header>

        <!-- DRAWER DERECHO -->
        <q-drawer v-model="drawerPerfil" side="right" bordered>
            <drawerPerfil></drawerPerfil>
        </q-drawer>

        <!-- DRAWER IZQUIERDO -->
        <q-drawer v-model="leftDrawerOpen" show-if-above :mini="miniState" @mouseover="miniState = false"
            @mouseout="miniState = true" :width="250" :breakpoint="500" bordered content-class="bg-dark">
            <q-scroll-area class="fit" :horizontal-thumb-style="{ opacity: 0 }">
                <q-list padding>
                    <q-item active clickable v-ripple @click="irListNotaCredito">
                        <q-item-section avatar>
                            <q-icon name="mdi-file-document-minus-outline" />
                        </q-item-section>
                        <q-item-section>
                            Nota de crédito
                        </q-item-section>
                    </q-item>
                    <q-separator />
                    <!-- CLIENTES -->
                    <q-item active clickable v-ripple @click="irListaClientes">
                        <q-item-section avatar>
                            <q-icon name="mdi-account" />
                        </q-item-section>
                        <q-item-section>
                            Clientes
                        </q-item-section>
                    </q-item>
                    <q-separator />
                    <!-- CONCEPTOS -->
                    <q-item active clickable v-ripple @click="irListaConceptos">
                        <q-item-section avatar>
                            <q-icon name="mdi-basket-fill" />
                        </q-item-section>
                        <q-item-section>
                            Conceptos
                        </q-item-section>
                    </q-item>
                    <q-separator />

                </q-list>
            </q-scroll-area>
        </q-drawer>

        <q-page-container>
            <router-view />
        </q-page-container>

        <q-footer style="height: 30px;" bordered class="bg-dark text-white">
            <q-toolbar>
                <q-toolbar-title>
                </q-toolbar-title>
            </q-toolbar>
        </q-footer>
    </q-layout>
</template>

<script>
import { ref } from 'vue'
import axios from "axios";
import drawerPerfil from "../../DrawerPerfil/DrawerPerfil.vue"

export default {

    name: 'MainLayout',
    components: {
        drawerPerfil
    },
    data() {
        return {
            drawerPerfil: false,
            isDarkMode: false,
            leftDrawerOpen: false,
            drawer: false,
            miniState: true,
        }
    },

    watch: {
        isDarkMode(val) {
            this.$q.dark.set(val);
        }
    },
    methods: {
        irListNotaCredito() {
            this.$router.push({ name: 'ListNotaCredito' })
        },

        irListaClientes(){
            this.$router.push({ name: 'ListaClientesNotaCredito' })
        },

        irListaConceptos(){
            this.$router.push({ name: 'ListaConceptosNotaCredito' })
        },        
    }
}
</script>
