<template>
    <div class="row q-col-gutter-sm">
        <!-- PARA AGREGAR DESTINATARIOS -->
        <q-dialog v-model="dialogDestinatario" transition-show="scale" transition-hide="scale">
            <q-card>
                <q-toolbar>
                    <q-btn push color="red-10" icon="mdi-close-circle-outline" round flat size="18px" padding="xs"
                        v-close-popup>
                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                            content-style="font-size: 14px" :offset="[10, 10]">Cerrar</q-tooltip>
                    </q-btn>
                    <q-toolbar-title>
                        SELECCIONE SUS DESTINATARIOS
                    </q-toolbar-title>
                </q-toolbar>
                <q-separator class="full-width" color="primary" inset size="4px" />
                <q-card-section>
                    <div class="col-12">
                        <q-table title="DESTINATARIOS" :data="optionsDestinatarioDefault" :columns="columnsDestinatario"
                            row-key="item" :rows-per-page-options="[10]" class="my-sticky-column-table" dense flat>
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td auto-width>
                                        <q-btn size="md" color="green-10" rounded flat dense
                                            @click="pushDestinatario(props.row)" icon="mdi-plus">
                                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                                content-style="font-size: 14px" :offset="[10, 10]">Agregar
                                                destinatario</q-tooltip>
                                        </q-btn>
                                    </q-td>
                                    <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                                    <q-td key="numRegIdTrib" :props="props">{{ props.row.numRegIdTrib }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </div>
                </q-card-section>
            </q-card>
        </q-dialog>
        <!-- PARA AGREGAR PROPIETARIOS -->
        <q-dialog v-model="dialogPropietario" transition-show="scale" transition-hide="scale">
            <q-card>
                <q-toolbar>
                    <q-btn push color="red-10" icon="mdi-close-circle-outline" round flat size="18px" padding="xs"
                        v-close-popup>
                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                            content-style="font-size: 14px" :offset="[10, 10]">Cerrar</q-tooltip>
                    </q-btn>
                    <q-toolbar-title>
                        SELECCIONE SUS PROPIETARIOS
                    </q-toolbar-title>
                </q-toolbar>
                <q-separator class="full-width" color="primary" inset size="4px" />
                <q-card-section>
                    <div class="col-12">
                        <q-table title="PROPIETARIOS" :data="optionsPropietarioDefault" :columns="columnsPropietario"
                            row-key="item" :rows-per-page-options="[10]" class="my-sticky-column-table" dense flat>
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td auto-width>
                                        <q-btn size="md" color="green-10" rounded flat dense
                                            @click="pushPropietario(props.row)" icon="mdi-plus">
                                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                                content-style="font-size: 14px" :offset="[10, 10]">Agregar
                                                destinatario</q-tooltip>
                                        </q-btn>
                                    </q-td>
                                    <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                                    <q-td key="numRegIdTrib" :props="props">{{ props.row.numRegIdTrib }}</q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </div>
                </q-card-section>
            </q-card>
        </q-dialog>
        <!-- PARA AGREGAR MERCANCIAS -->
        <q-dialog v-model="dialogMercancias" transition-show="scale" transition-hide="scale">
            <q-card>
                <q-toolbar>
                    <q-btn push color="red-10" icon="mdi-close-circle-outline" round flat size="18px" padding="xs"
                        v-close-popup>
                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                            content-style="font-size: 14px" :offset="[10, 10]">Cerrar</q-tooltip>
                    </q-btn>
                    <q-toolbar-title>
                        MERCANCIAS
                    </q-toolbar-title>
                </q-toolbar>
                <q-separator class="full-width" color="primary" inset size="4px" />
                <q-card-section>
                    <div class="row q-col-gutter-sm">
                        <!-- BUSCAR POR NO IDENTIFICACION -->
                        <div class="col-12">
                            <q-select v-model="selectMercancia" use-input input-debounce="0"
                                label="Buscar por No Identificación" :options="optionsMercancia"
                                @filter="filterMercancia" fill-input hide-selected :loading="loadingMercancia" dense
                                outlined option-label="noIdentificacion">

                                <template v-slot:no-option>
                                    <q-item>
                                        <q-item-section class="text-grey">
                                            No exixte coincidencias
                                        </q-item-section>
                                    </q-item>
                                </template>
                            </q-select>
                        </div>

                        <template v-if="selectMercancia">
                            <!-- NO IDENTIFICACION -->
                            <div class="col-12">
                                <q-input outlined v-model="selectMercancia.noIdentificacion" label="No. Identificación"
                                    dense />
                            </div>
                            <!-- FRACCION ARANCELARIA -->
                            <div class="col-12">
                                <q-select v-model="selectMercancia.fraccionArancelaria" use-input input-debounce="0"
                                    label="Fracción arancelaria" :options="optionsFraccionArancelaria"
                                    @filter="filterFraccionArancelaria" fill-input hide-selected
                                    :loading="loadingFraccionArancelaria" dense outlined
                                    option-label="fraccionArancelaria">

                                    <template v-slot:no-option>
                                        <q-item>
                                            <q-item-section class="text-grey">
                                                No exixte coincidencias
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                </q-select>
                            </div>
                            <!-- CANTIDAD ADUANA -->
                            <div class="col-12 col-md-6">
                                <q-field v-model="selectMercancia.cantidadAduana" label="Cantidad Aduana" dense
                                    outlined>

                                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                        <vue-number :id="id" class="q-field__input text-right" :value="value"
                                            @input="emitValue" v-show="floatingLabel"
                                            v-bind="numberFormat"></vue-number>
                                    </template>
                                </q-field>
                            </div>
                            <!-- FRACCION ARANCELARIA -->
                            <div class="col-12 col-md-6">
                                <q-select v-model="selectMercancia.unidadAduana" use-input input-debounce="0"
                                    label="Unidad aduana" :options="optionsUnidadAduana" @filter="filterUnidadAduana"
                                    fill-input hide-selected :loading="loadingUnidadAduana" dense outlined
                                    option-label="unidadAduana">

                                    <template v-slot:no-option>
                                        <q-item>
                                            <q-item-section class="text-grey">
                                                No exixte coincidencias
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                </q-select>
                            </div>
                            <!-- VALOR UNITARIO ADUANA -->
                            <div class="col-12 col-md-6">
                                <q-field v-model="selectMercancia.valorUnitarioAduana" label="Valor unitario" dense
                                    outlined>

                                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                        <vue-number :id="id" class="q-field__input text-right" :value="value"
                                            @input="emitValue" v-show="floatingLabel"
                                            v-bind="numberFormat"></vue-number>
                                    </template>
                                </q-field>
                            </div>
                            <!-- VALOR DOLARES -->
                            <div class="col-12 col-md-6">
                                <q-field v-model="selectMercancia.valorDolares" label="Valor dolares" dense outlined>

                                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                        <vue-number :id="id" class="q-field__input text-right" :value="value"
                                            @input="emitValue" v-show="floatingLabel"
                                            v-bind="numberFormat"></vue-number>
                                    </template>
                                </q-field>
                            </div>
                        </template>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn flat label="Cancelar" color="red-10" v-close-popup />
                    <q-btn flat label="Agregar" color="green-10" @click="pushMercancia" />
                </q-card-actions>
            </q-card>
        </q-dialog>
        <!-- CLAVE DE PEDIMENTO -->
        <div class="col-12 col-md-6">
            <q-select v-model="comercio.clavePedimento" label="Clave de pedimento" :options="optionsClavePedimento"
                dense outlined option-label="claveDePedimento">
            </q-select>
        </div>
        <!-- CERTIFICADO ORIGEN -->
        <div class="col-12 col-md-6">
            <q-select v-model="comercio.certificadoOrigen" label="Certificado origen"
                :options="optionsCertificadoOrigen" dense outlined option-label="certificadoOrigen">
            </q-select>
        </div>
        <!-- INCOTERM -->
        <div class="col-12 col-md-6">
            <q-select v-model="comercio.incoterm" use-input input-debounce="0" label="Incoterm"
                :options="optionsIncoterm" @filter="filterIncoterm" fill-input hide-selected :loading="loadingIncoterm"
                dense outlined option-label="incoterm">

                <template v-slot:no-option>
                    <q-item>
                        <q-item-section class="text-grey">
                            No exixte coincidencias
                        </q-item-section>
                    </q-item>
                </template>
            </q-select>
        </div>
        <!-- TIPO DE CAMBIO USD  -->
        <div class="col-12 col-md-3">
            <q-field v-model="comercio.tipoCambioUSD" label="Tipo de cambio USD" dense outlined>

                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                    <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                        v-show="floatingLabel" v-bind="cambioFormat"></vue-number>
                </template>
            </q-field>
        </div>
        <!-- TOTAL USD  -->
        <div class="col-12 col-md-3">
            <q-field v-model="comercio.totalUSD" label="Total USD" dense outlined>

                <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                    <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                        v-show="floatingLabel" v-bind="numberFormat"></vue-number>
                </template>
            </q-field>
        </div>
        <!-- EMISOR -->
        <div class="col-12">
            <q-select v-model="comercio.emisor" use-input input-debounce="0" label="Emisor" :options="optionsEmisor"
                @filter="filterEmisor" fill-input hide-selected :loading="loadingEmisor" dense outlined
                option-label="nombre">

                <template v-slot:no-option>
                    <q-item>
                        <q-item-section class="text-grey">
                            No exixte coincidencias
                        </q-item-section>
                    </q-item>
                </template>
            </q-select>
        </div>
        <!-- RECEPTOR -->
        <div class="col-12">
            <q-select v-model="comercio.receptor" use-input input-debounce="0" label="Receptor"
                :options="optionsReceptor" @filter="filterReceptor" fill-input hide-selected :loading="loadingReceptor"
                dense outlined option-label="nombre" clearable>

                <template v-slot:no-option>
                    <q-item>
                        <q-item-section class="text-grey">
                            No exixte coincidencias
                        </q-item-section>
                    </q-item>
                </template>
            </q-select>
        </div>
        <!-- PROPIETARIO -->
        <div class="col-12">
            <q-table title="PROPIETARIOS" :data="comercio.propietario" :columns="columnsPropietario" row-key="item"
                :rows-per-page-options="[10]" class="my-sticky-column-table" dense>

                <template v-slot:top-right>
                    <q-btn push color="white" text-color="primary" round icon="mdi-plus" @click="openDialogPropietario">
                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                            content-style="font-size: 14px" :offset="[10, 10]">Agregar propietario</q-tooltip>
                    </q-btn>
                </template>

                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width>
                            <q-btn size="md" color="red-10" rounded flat dense @click="deletePropietario(props.row)"
                                icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px" :offset="[10, 10]">Eliminar propietario</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                        <q-td key="numRegIdTrib" :props="props">{{ props.row.numRegIdTrib }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
        <!-- DESTINATARIOS -->
        <div class="col-12">
            <q-table title="DESTINATARIOS" :data="comercio.destinatarios" :columns="columnsDestinatario" row-key="item"
                :rows-per-page-options="[10]" class="my-sticky-column-table" dense>

                <template v-slot:top-right>
                    <q-btn push color="white" text-color="primary" round icon="mdi-plus"
                        @click="openDialogDestinatario">
                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                            content-style="font-size: 14px" :offset="[10, 10]">Agregar destinatarios</q-tooltip>
                    </q-btn>
                </template>

                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width>
                            <q-btn size="md" color="red-10" rounded flat dense @click="deleteDestinatario(props.row)"
                                icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px" :offset="[10, 10]">Eliminar destinatario</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                        <q-td key="numRegIdTrib" :props="props">{{ props.row.numRegIdTrib }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
        <!-- MERCANCIAS -->
        <div class="col-12">
            <q-table title="MERCANCIAS" :data="comercio.mercancias" :columns="columnsMercancias" row-key="item"
                :rows-per-page-options="[10]" class="my-sticky-column-table" dense>

                <template v-slot:top-right>
                    <q-btn push color="white" text-color="primary" round icon="mdi-arrow-collapse-horizontal"
                        @click="conciliarMercancias">
                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                            content-style="font-size: 14px" :offset="[10, 10]">Conciliar mercancias</q-tooltip>
                    </q-btn>
                    <q-btn push color="white" text-color="primary" round icon="mdi-plus" @click="openDialoMercancias">
                        <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                            content-style="font-size: 14px" :offset="[10, 10]">Agregar mercancias</q-tooltip>
                    </q-btn>
                </template>

                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width>
                            <q-btn size="md" color="red-10" rounded flat dense @click="deleteMercancia(props.row)"
                                icon="mdi-delete">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px" :offset="[10, 10]">Eliminar mercancia</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="noIdentificacion" :props="props">{{ props.row.noIdentificacion }}</q-td>
                        <q-td key="fraccionArancelaria" :props="props">
                            {{ props.row.fraccionArancelaria.fraccionArancelaria }}
                        </q-td>
                        <q-td key="cantidadAduana" :props="props">
                            {{ props.row.cantidadAduana }}
                            <q-popup-edit v-model.number="props.row.cantidadAduana" buttons label-set="Aplicar"
                                label-cancel="Cerrar" :validate="validacionCantidad" @hide="validacionCantidad"
                                v-slot="scope">
                                <q-input type="number" v-model.number="scope.value"
                                    hint="Indique un valor diferente de 0" :error="errorCantidad"
                                    :error-message="errorMessageCantidad" dense autofocus @keyup.enter="scope.set" />
                            </q-popup-edit>
                        </q-td>
                        <q-td key="unidadAduana" :props="props">{{ props.row.unidadAduana.unidadAduana }}</q-td>
                        <q-td key="valorUnitarioAduana" :props="props">
                            {{ FormatCurrency(props.row.valorUnitarioAduana) }}
                            <q-popup-edit v-model.number="props.row.valorUnitarioAduana" buttons label-set="Aplicar"
                                label-cancel="Cerrar" :validate="validacionValorUnitario"
                                @hide="validacionValorUnitario" v-slot="scope">
                                <q-input type="number" v-model.number="scope.value"
                                    hint="Indique un valor diferente de 0" :error="errorValorUnitario"
                                    :error-message="errorMessageValorUnitario" dense autofocus
                                    @keyup.enter="scope.set" />
                            </q-popup-edit>
                        </q-td>
                        <q-td key="valorDolares" :props="props">{{ FormatCurrency(props.row.valorDolares) }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { QSpinnerCube } from 'quasar';
import { component as VueNumber } from '@coders-tm/vue-number-format'

export default {
    components: {
        VueNumber
    },
    data() {
        return {
            numberFormat: {
                decimal: '.',
                separator: ',',
                prefix: '',
                precision: 2,
                masked: false,
                minimumFractionDigits: 2,
            },
            cambioFormat: {
                decimal: '.',
                separator: ',',
                prefix: '',
                precision: 6,
                masked: false,
                minimumFractionDigits: 6,
            },
            loadingIncoterm: false,
            optionsIncoterm: [],

            loadingEmisor: false,
            optionsEmisor: [],

            loadingPropietario: false,
            optionsPropietario: [],

            loadingReceptor: false,
            optionsReceptor: [],

            //DESTINATARIOS
            columnsDestinatario: [
                { name: 'actions', align: 'left', label: 'Acciones', field: 'actions' },
                { name: 'nombre', align: 'left', label: 'Nombre', field: 'nombre', sortable: true },
                { name: 'numRegIdTrib', align: 'left', label: 'Num Reg Id Trib', field: 'numRegIdTrib', sortable: true },
            ],
            dialogDestinatario: false,
            filterDestinatario: '',

            //PROPIETARIOS
            columnsPropietario: [
                { name: 'actions', align: 'left', label: 'Acciones', field: 'actions' },
                { name: 'nombre', align: 'left', label: 'Nombre', field: 'nombre', sortable: true },
                { name: 'numRegIdTrib', align: 'left', label: 'Num Reg Id Trib', field: 'numRegIdTrib', sortable: true },
            ],
            dialogPropietario: false,
            filterPropietario: '',

            //MERCANCIAS
            columnsMercancias: [
                { name: 'actions', align: 'left', label: 'Acciones', field: 'actions' },
                { name: 'noIdentificacion', align: 'left', label: 'No. identificación', field: 'noIdentificacion', sortable: true },
                { name: 'fraccionArancelaria', align: 'left', label: 'Fracción arancelaria', field: 'fraccionArancelaria', sortable: true },
                { name: 'cantidadAduana', align: 'right', label: 'Cantidad aduana', field: 'cantidadAduana', sortable: true },
                { name: 'unidadAduana', align: 'left', label: 'Unidad aduana', field: 'unidadAduana', sortable: true },
                { name: 'valorUnitarioAduana', align: 'right', label: 'Valor unitario aduana', field: 'valorUnitarioAduana', sortable: true },
                { name: 'valorDolares', align: 'right', label: 'Valor en dólares', field: 'valorDolares', sortable: true },
            ],
            dialogMercancias: false,
            loadingMercancia: false,
            optionsMercancia: [],
            selectMercancia: null,
            loadingUnidadAduana: false,
            optionsUnidadAduana: [],
            loadingFraccionArancelaria: false,
            optionsFraccionArancelaria: [],

            //VALIDACIONES CANTIDAD
            errorCantidad: false,
            errorMessageCantidad: '',
            errorValorUnitario: false,
            errorMessageValorUnitario: '',

        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        comercio() {
            return this.$store.state.comprobanteStore.comprobante.comercioExterior;
        },

        optionsClavePedimento() {
            return this.$store.state.listClavePedimento;
        },

        optionsCertificadoOrigen() {
            return this.$store.state.listCertificadoOrigen;
        },

        optionsIncotermDefault() {
            return this.$store.state.listIncoterm;
        },

        optionsEmisorDefault() {
            return this.$store.state.listaEmisorCEStore;
        },

        optionsPropietarioDefault() {
            return this.$store.state.listaPropietariosCEStore;
        },

        optionsReceptorDefault() {
            return this.$store.state.listaReceptorCEStore;
        },

        optionsDestinatarioDefault() {
            return this.$store.state.listaDestinatariosCEStore;
        },

        optionsMercanciaDefault() {
            return this.$store.state.listaMercanciasCEStore
        },

        optionsFraccionArancelariaDefault() {
            return this.$store.state.listaCatalogoFraccionArancelaria;
        },

        optionsUnidadAduanaDefault() {
            return this.$store.state.listaCatalogoUnidadAduana;
        }

    },
    created() {
        this.GetCatalogos();
    },
    methods: {
        //CATALOGOS DEL SAT
        async GetCatalogos() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando clave de pedimento...' });
            await this.GetClavePedimento();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando certificados de origen...' });
            await this.GetCertificadoOrigen();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando incoterm...' });
            await this.GetIncoterm();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando emisores...' });
            await this.GetEmisor();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando propietarios...' });
            await this.GetPropietario();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando receptores...' });
            await this.GetReceptor();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando destinatarios...' });
            await this.GetDestinatario();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando mercancias...' });
            await this.GetMercancias();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando unidad de aduana...' });
            await this.GetUnidadAduana();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando fracción arancelaria...' });
            await this.GetFraccionArancelaria();
            this.$q.loading.hide()

        },

        async GetClavePedimento() {
            if (this.$store.state.listClavePedimento.length == 0) {
                try {
                    let response = await axios.get("CatalogosSat/GetCatClaveDePedimentoAsync");
                    this.$store.state.listClavePedimento = response.data;
                    console.log(response.data)
                } catch (error) {
                    console.log(error);
                }
            }
        },

        async GetCertificadoOrigen() {
            if (this.$store.state.listCertificadoOrigen.length == 0) {
                try {
                    let response = await axios.get("CatalogosSat/GetCatCertificadoOrigenAsync");
                    this.$store.state.listCertificadoOrigen = response.data;
                    console.log(response.data)
                } catch (error) {
                    console.log(error);
                }
            }
        },

        async GetIncoterm() {
            if (this.$store.state.listIncoterm.length == 0) {
                try {
                    let response = await axios.get("CatalogosSat/GetCatIncotermAsync");
                    this.$store.state.listIncoterm = response.data;
                    console.log(response.data)
                } catch (error) {
                    console.log(error);
                }
            }
        },

        async GetEmisor() {
            try {
                let response = await axios.get("CatalogosComprobantes/GetEmisorCEAsync/erp_" + this.token.rfc);
                let catalogo = response.data;
                this.$store.state.listaEmisorCEStore = catalogo;
            } catch (error) {
                console.log(error);
            }
        },

        async GetPropietario() {
            try {
                let response = await axios.get("CatalogosComprobantes/GetPropietarioCEAsync/erp_" + this.token.rfc);
                let catalogo = response.data;
                this.$store.state.listaPropietariosCEStore = catalogo;
            } catch (error) {
                console.log(error);
            }
        },

        async GetReceptor() {
            try {
                let response = await axios.get("CatalogosComprobantes/GetReceptorCEAsync/erp_" + this.token.rfc);
                let catalogo = response.data;
                this.$store.state.listaReceptorCEStore = catalogo;
            } catch (error) {
                console.log(error);
            }
        },

        async GetDestinatario() {
            try {
                let response = await axios.get("CatalogosComprobantes/GetDestinatarioCEAsync/erp_" + this.token.rfc);
                let catalogo = response.data;
                this.$store.state.listaDestinatariosCEStore = catalogo;
                console.log(catalogo)
            } catch (error) {
                console.log(error);
            }
        },

        async GetMercancias() {
            this.loading = true
            try {
                let response = await axios.get("CatalogosComprobantes/GetMercanciasCEAsync/erp_" + this.token.rfc);
                let catalogo = response.data;
                this.$store.state.listaMercanciasCEStore = catalogo;
            } catch (error) {
                console.log(error);
            }
        },

        async GetFraccionArancelaria() {
            if (this.$store.state.listaCatalogoFraccionArancelaria.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatFraccionArancelariaAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoFraccionArancelaria = catalogo;

            } catch (error) {
                console.log(error);
            }
        },

        async GetUnidadAduana() {
            if (this.$store.state.listaCatalogoUnidadAduana.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatUnidadAduanaAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoUnidadAduana = catalogo;

            } catch (error) {
                console.log(error);
            }
        },

        filterIncoterm(val, update) {
            let stringOptions = this.optionsIncotermDefault
            if (val === '') {
                update(() => {
                    this.optionsIncoterm = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsIncoterm = stringOptions.filter(v => v.incoterm.toLowerCase().indexOf(needle) > -1)
            })
        },

        filterEmisor(val, update) {
            let stringOptions = this.optionsEmisorDefault
            if (val === '') {
                update(() => {
                    this.optionsEmisor = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsEmisor = stringOptions.filter(v => v.nombre.toLowerCase().indexOf(needle) > -1)
            })
        },

        // filterPropietario(val, update) {
        //     let stringOptions = this.optionsPropietarioDefault
        //     if (val === '') {
        //         update(() => {
        //             this.optionsPropietario = stringOptions
        //         })
        //         return
        //     }

        //     update(() => {
        //         const needle = val.toLowerCase()
        //         this.optionsPropietario = stringOptions.filter(v => v.nombre.toLowerCase().indexOf(needle) > -1)
        //     })
        // },

        filterReceptor(val, update) {
            let stringOptions = this.optionsReceptorDefault
            if (val === '') {
                update(() => {
                    this.optionsReceptor = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsReceptor = stringOptions.filter(v => v.nombre.toLowerCase().indexOf(needle) > -1)
            })
        },

        filterMercancia(val, update) {
            let stringOptions = this.optionsMercanciaDefault
            if (val === '') {
                update(() => {
                    this.optionsMercancia = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsMercancia = stringOptions.filter(v => v.noIdentificacion.toLowerCase().indexOf(needle) > -1)
            })
        },

        filterFraccionArancelaria(val, update, abort) {
            let stringOptions = this.optionsFraccionArancelariaDefault
            if (val === '') {
                update(() => {
                    this.optionsFraccionArancelaria = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsFraccionArancelaria = stringOptions.filter(v => v.fraccionArancelaria.toLowerCase().indexOf(needle) > -1)
            })
        },

        filterUnidadAduana(val, update, abort) {
            let stringOptions = this.optionsUnidadAduanaDefault
            if (val === '') {
                update(() => {
                    this.optionsUnidadAduana = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsUnidadAduana = stringOptions.filter(v => v.unidadAduana.toLowerCase().indexOf(needle) > -1)
            })
        },

        openDialogDestinatario() {
            this.dialogDestinatario = true;
        },

        pushDestinatario(item) {
            this.comercio.destinatarios.push(item)
        },

        deleteDestinatario(item) {
            let indice = this.comercio.destinatarios.findIndex(objeto => objeto._id === item._id);
            this.comercio.destinatarios.splice(indice, 1);
        },

        openDialogPropietario() {
            this.dialogPropietario = true;
        },

        pushPropietario(item) {
            this.comercio.propietario.push(item)
        },

        deletePropietario(item) {
            let indice = this.comercio.propietario.findIndex(objeto => objeto._id === item._id);
            this.comercio.propietario.splice(indice, 1);
        },

        conciliarMercancias() {
            this.comercio.mercancias = [];
            let conceptos = [...this.$store.state.comprobanteStore.comprobante.conceptos]
            let mercancias = [...this.$store.state.listaMercanciasCEStore]
            console.log(conceptos);
            conceptos.forEach(o => {
                let oMercancia = mercancias.find(f => f.noIdentificacion === o.noIdentificacion);
                if (oMercancia) {
                    oMercancia.cantidadAduana = o.cantidad;
                    oMercancia.valorUnitarioAduana = Math.round(o.valorUnitario * 100) / 100;
                    oMercancia.valorDolares = Math.round(o.importe * 100) / 100;
                    this.comercio.mercancias.push(oMercancia)
                }
            });
        },

        openDialoMercancias() {
            this.dialogMercancias = true;
        },

        pushMercancia() {
            //VALIDAMOS LA MERCANCIA
            let mercancia = { ...this.selectMercancia }

            if (mercancia.noIdentificacion === '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el no. de identificación`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                });
                return;
            }
            if (!mercancia.fraccionArancelaria) {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique la fracción arancelaria`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                });
                return;
            }
            if (mercancia.cantidadAduana == 0 || mercancia.cantidadAduana === '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique la cantidad de aduana`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                });
                return;
            }
            if (!mercancia.unidadAduana) {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique la unidad de aduana`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                });
                return;
            }
            if (mercancia.valorUnitarioAduana == 0 || mercancia.valorUnitarioAduana === '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el valor unitario de aduana`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                });
                return;
            }
            if (mercancia.valorDolares == 0 || mercancia.valorDolares === '') {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el valor en dólares`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                });
                return;
            }

            this.comercio.mercancias.push(mercancia)
            this.selectMercancia = null;
        },

        deleteMercancia(item) {
            let indice = this.comercio.mercancias.findIndex(objeto => objeto._id === item._id);
            this.comercio.mercancias.splice(indice, 1);
        },

        //FORMATOS
        FormatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        //MODIFICAR TABLA DE MERCANCIAS
        validacionCantidad(val) {
            if (val < 0) {
                this.errorCantidad = true
                this.errorMessageCantidad = 'El valor debe ser mayor a 0'
                return false
            }
            //RECALCULAMOS
            for (let a of this.comercio.mercancias) {
                let x = a.cantidadAduana;
                let y = a.valorUnitarioAduana;
                let z = x * y;
                a.valorDolares = z;
            }
            this.errorCantidad = false;
            this.errorMessageCantidad = '';
            return true;
        },

        validacionValorUnitario(val) {
            if (val < 0) {
                this.errorValorUnitario = true
                this.errorMessageValorUnitario = 'El valor debe ser mayor a 0'
                return false
            }
            //RECALCULAMOS
            for (let a of this.comercio.mercancias) {
                let x = a.cantidadAduana;
                let y = a.valorUnitarioAduana;
                let z = x * y;
                a.valorDolares = z;
            }
            this.errorValorUnitario = false;
            this.errorMessageValorUnitario = '';
            return true;
        },


    },
}
</script>