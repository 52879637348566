<template>
    <q-card>
        <q-card-section>
            <q-toolbar>
                <q-toolbar-title>
                    Destinatario
                </q-toolbar-title>
                <q-btn v-if="objetoStore._id == ''" dense round color="green" icon="mdi-content-save" class="q-mr-sm"
                    @click="guardar()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>

                <q-btn v-else round color="orange" dense icon="mdi-content-save" class="q-mr-sm" @click="actualizar()">
                    <q-tooltip>
                        Editar
                    </q-tooltip>
                </q-btn>

                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
            <q-card-section class="scroll">
                <div class="row q-col-gutter-sm">
                    <div class="col-12 ">
                        <q-input dense filled label="Nombre" v-model="objetoStore.nombre"
                            @input="convertirAMayusculas" />
                    </div>
                    <div class="col-12 ">
                        <q-input dense filled label="Número de Identificación o registro fiscal"
                            v-model="objetoStore.numRegIdTrib" />
                    </div>
                    <div class="col-12">
                        <domicilio class="full-width" ref="miComponenteHijo"></domicilio>
                    </div>
                </div>
            </q-card-section>
        </q-card-section>
    </q-card>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import domicilio from '../../CatalogosCartaPorte/Domicilios/Domicilio.vue'

export default {
    components: {
        domicilio
    },

    data() {
        return {
            itemsFiltroDomicilio: null,
        }
    },

    computed: {
        token() {
            return this.$store.state.usuario;
        },
        objetoStore() {
            return this.$store.state.destinatarioCEStore;
        },
        itemsDomicilio() {
            return this.$store.state.listaDomiciliosStore
        }
    },

    created() {

    },

    methods: {
        cierraVentana() {
            this.$emit('cierraVentana')
        },

        async guardar() {
            //VALIDAMOS Y ASIGNAMOS EL DOMICILIO
            const childResult = await this.$refs.miComponenteHijo.AsignaValores();
            if (!childResult) {
                return;
            }

            // ASIGNAMOS A UN OBJETO
            let objDestinatario = { ...this.$store.state.destinatarioCEStore }
            let objDomicilio = { ...childResult }
            objDestinatario.domicilio = objDomicilio;

            if (objDestinatario.nombre == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre del destinatario' })
                return;
            }
            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando destinatario. Espere...', messageColor: 'white' })
                let response = await axios.post('CatalogosComprobantes/PostDestinatarioCEAsync/erp_' + this.token.rfc, objDestinatario)
                objDestinatario._id = response.data
                this.$store.state.listaDestinatariosCEStore.push(objDestinatario)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `El destinatario ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },

        async actualizar() {
            //VALIDAMOS Y ASIGNAMOS EL DOMICILIO
            const childResult = await this.$refs.miComponenteHijo.AsignaValores();
            if (!childResult) {
                return;
            }

            // ASIGNAMOS A UN OBJETO
            let objDestinatario = { ...this.$store.state.destinatarioCEStore }
            let objDomicilio = { ...childResult }
            objDestinatario.domicilio = objDomicilio;

            //VALIDAMOS QUE TENGA EL NOMBRE
            if (objDestinatario.nombre == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre del destinatario' })
                return;
            }

            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Actualizando destinatario. Espere...', messageColor: 'white' })
                let response = await axios.put('CatalogosComprobantes/PutDestinatarioCEAsync/erp_' + this.token.rfc, objDestinatario)
                console.log(response)

                let indice = this.$store.state.listaDestinatariosCEStore.findIndex(x => x._id === objDestinatario._id);
                Object.assign(this.$store.state.listaDestinatariosCEStore[indice], objDestinatario)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `El destinatario ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        filtroDomicilio(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroDomicilio = this.itemsDomicilio.filter(v => v.domicilio.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroDomicilio.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        convertirAMayusculas() {
            this.objetoStore.nombre = this.objetoStore.nombre.toUpperCase();
        },

        convertirAMayusculasCURP() {
            this.objetoStore.curp = this.objetoStore.curp.toUpperCase();
        }
    }
}
</script>
<style></style>