<template>
    <q-card>
        <q-card-section>
            <q-toolbar>
                <q-toolbar-title>
                    Remolque
                </q-toolbar-title>
                <q-btn v-if="objetoStore._id == ''" dense round color="green" icon="mdi-content-save" class="q-mr-sm"
                    @click="guardar()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>

                <q-btn v-else round color="orange" dense icon="mdi-content-save" class="q-mr-sm" @click="actualizar()">
                    <q-tooltip>
                        Editar
                    </q-tooltip>
                </q-btn>

                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
            <q-card-section style="max-height: 700px;" class="scroll">
                <div class="row q-col-gutter-sm">
                    <div class="col-12">
                        <q-select v-model="objetoStore.subTipoRem" emit-value map-options @filter="filtroRemolques"
                            :options="itemsFiltroRemolques" option-label="subTipoRem" use-input input-debounce="0" dense
                            filled label="Tipo de remolque" virtual-scroll-slice-size="1" />
                    </div>
                    <div class="col-12 ">
                        <q-input dense filled label="Placa" v-model="objetoStore.placa" />
                    </div>

                </div>
            </q-card-section>
        </q-card-section>
    </q-card>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'

export default {

    data() {
        return {
            itemsFiltroRemolques: null
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        objetoStore() {
            return this.$store.state.remolqueStore;
        },
        itemRemolques() {
            return this.$store.state.listaCatalogoSubTipoRem;
        }
    },

    created() {
    },
    methods: {
        cierraVentana() {
            this.$emit('cierraVentana')
        },
        filtroRemolques(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroRemolques = this.itemRemolques.filter(v => v.subTipoRem.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroRemolques.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        async guardar() {

            console.log(this.objetoStore)

            if (this.objetoStore.subTipoRem == null) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el tipo de remolque' })
                return;
            }

            if (this.objetoStore.placa == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique las placas del remolque' })
                return;
            }


            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando remolque. Espere...', messageColor: 'white' })

            try {
                let response = await axios.post('CatalogosComprobantes/PostRemolque/erp_' + this.token.rfc, this.objetoStore)
                console.log(response)

                this.objetoStore._id = response.data
                this.$store.state.listaRemolquesStore.push(this.objetoStore)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `El remolque ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        async actualizar() {
            console.log(this.objetoStore)

            if (this.objetoStore.subTipoRem == null) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el tipo de remolque' })
                return;
            }

            if (this.objetoStore.placa == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique las placas del remolque' })
                return;
            }

            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Actualizando remolque. Espere...', messageColor: 'white' })

            try {
                let response = await axios.put('CatalogosComprobantes/PutRemolque/erp_' + this.token.rfc, this.objetoStore)
                console.log(response)

                let indice = this.$store.state.listaRemolquesStore.findIndex(x => x._id === this.objetoStore._id);
                Object.assign(this.$store.state.listaRemolquesStore[indice], this.objetoStore)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `El remolque ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },
    }
}
</script>
<style></style>