<template>
    <div class="q-pa-md">
        <!-- DIALOG PARA MOSTRAR EL PDF -->
        <q-dialog v-model="dialogPdf" maximized transition-show="slide-up" transition-hide="slide-down">
            <visor-pdf @closeDialog="CierraDialogPdf" @closeDialogTimbrado="CierraDialogPdfTimbrado"></visor-pdf>
        </q-dialog>

        <!-- DIALOG PARA HACER UNA NUEVA FACTURA -->
        <q-dialog v-model="dialogComprobante" maximized transition-show="slide-up" transition-hide="slide-down">
            <comprobante @CloseComprobante="CierraDialog"></comprobante>
        </q-dialog>

        <!-- DIALOG PARA CANCELAR -->
        <q-dialog v-model="dialogCancelacion" transition-show="slide-up" transition-hide="slide-down" persistent>
            <cancelacion @CloseDialogCancelacion="dialogCancelacion = false"></cancelacion>
        </q-dialog>

        <!-- DIALOG PARA VALIDAR EL ESTATUS EN EL SAT -->
        <q-dialog v-model="dialogValidaEstatus" transition-show="slide-up" transition-hide="slide-down">
            <ValidaEstatus @CloseDialogValida="dialogValidaEstatus = false"></ValidaEstatus>
        </q-dialog>

        <!-- SELECCIONA AÑO Y MES, BOTON DE BUSCAR Y EXPORTAR A EXCEL -->
        <div class="row no-wrap q-mt-md q-pa-sm">
            <q-btn dense color="white" text-color="primary" label="Timbres Disponibles">
                <q-badge color="green-14" floating transparent>{{timbresDisponibles}}</q-badge>
            </q-btn>
            <q-space />
            <q-input v-model="fehaIMasked" label="Fecha Inicial" class="q-mr-sm" name="event" outlined dense>
                <template v-slot:before>
                    <q-icon name="event" />
                </template>
                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="fechaI" @input="UltimoDiaMes">
                        <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Ok" color="primary" flat />
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-input>

            <q-input v-model="fechaFMasked" label="Fecha Final" class="q-mr-xs" outlined dense>
                <template v-slot:before>
                    <q-icon name="event" />
                </template>
                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="fechaF">
                        <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Ok" color="primary" flat />
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-input>
            <q-btn push color="amber-9" @click="GetComprobantes" icon="mdi-text-box-search-outline" rounded flat
                size="18px" padding="xs">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Consultar</q-tooltip>
            </q-btn>
            <q-btn push color="green-14" @click="NuevoComprobante" icon="mdi-plus-circle" rounded flat size="18px"
                padding="xs">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Nueva Factura</q-tooltip>
            </q-btn>
            <q-btn push color="green-10" @click="ExportExcel" icon="mdi-file-excel-box-outline" rounded flat size="18px"
                padding="xs">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Exportar Excel</q-tooltip>
            </q-btn>
        </div>

        <!-- TABLA DE COMPROBANTES -->
        <q-table title="Reporte ISR" :data="dataComprobantes" :columns="columns" row-key="rfc"
            :rows-per-page-options="[10]" :filter="filter" class="my-sticky-column-table" dense>
            <template v-slot:top>
                <q-space />
                <q-input borderless dense debounce="300" v-model="filter" placeholder="Filtrar">
                    <template v-slot:append>
                        <q-icon name="mdi-magnify" />
                    </template>
                </q-input>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width>
                        <q-btn size="md" color="red-10" rounded flat dense @click="VerPdf(props.row)"
                            icon="mdi-file-pdf-box">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Ver PDF</q-tooltip>
                        </q-btn>
                        <q-btn size="md" color="amber-10" rounded flat dense @click="Similar(props.row)"
                            icon="mdi-reload">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Comprobante similar</q-tooltip>
                        </q-btn>
                        <template v-if="props.row.estatus === 'Vigente'">
                            <q-btn size="md" color="red-10" rounded flat dense @click="OpenDialogCancelacion(props.row)"
                                icon="mdi-cancel">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px" :offset="[10, 10]">Cancelar</q-tooltip>
                            </q-btn>
                        </template>
                        <template v-if="props.row.estatus === 'Sin timbrar'">
                            <q-btn size="md" color="yellow-10" rounded flat dense @click="Editar(props.row)"
                                icon="mdi-pencil">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px" :offset="[10, 10]">Editar</q-tooltip>
                            </q-btn>
                        </template>
                        <template v-if="props.row.estatus != 'Sin timbrar'">
                            <q-btn size="md" color="green-10" rounded flat dense @click="ValidaEstatusSat(props.row)"
                                icon="mdi-check-decagram-outline">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px" :offset="[10, 10]">Validar eststus en
                                    SAT</q-tooltip>
                            </q-btn>
                        </template>
                    </q-td>

                    <q-td key="serie" :props="props">{{ props.row.serie }}</q-td>
                    <q-td key="folio" :props="props">
                        <q-badge :color="colorComprobante(props.row.estatus)" rounded>
                            {{ props.row.folio }}
                        </q-badge>
                    </q-td>
                    <q-td key="fecha" :props="props">{{ FormatDate(props.row.fecha) }}</q-td>
                    <q-td key="rfc" :props="props">{{ props.row.rfc }}</q-td>
                    <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>

                    <q-td key="subTotal" :props="props">{{ FormatCurrency(props.row.subTotal) }}</q-td>
                    <q-td key="total" :props="props">{{ FormatCurrency(props.row.total) }}</q-td>
                    <q-td key="moneda" :props="props">{{ props.row.moneda }}</q-td>
                    <q-td key="solicitudCancelacion" :props="props">{{ props.row.solicitudCancelacion }}</q-td>
                    <q-td key="folioFiscal" :props="props">{{ props.row.folioFiscal }}</q-td>
                </q-tr>
            </template>

        </q-table>
    </div>
</template>
<script>
import axios from 'axios'
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar';
import moment from 'moment';
import comprobante from './ComplementoDePago.vue'
import VisorPdf from '../../PDF/VisorPdf.vue'
import Cancelacion from '../Cancelacion/Cancelacion.vue';
import ValidaEstatus from '../Cancelacion/ValidaEstatusSat.vue';

export default {
    components: {
        comprobante,
        VisorPdf,
        Cancelacion,
        ValidaEstatus,
    },
    data() {
        return {
            fechaI: new Date(),
            fechaF: new Date(),
            filter: '',
            columns: [
                { name: 'actions', align: 'left', label: 'Acciones', field: 'actions' },
                { name: 'serie', align: 'left', label: 'Serie', field: 'serie', sortable: true },
                { name: 'folio', align: 'left', label: 'Folio', field: 'folio', sortable: true },
                { name: 'fecha', align: 'left', label: 'Fecha', field: 'fecha', sortable: true },
                { name: 'rfc', align: 'left', label: 'RFC', field: 'rfc', sortable: true },
                { name: 'nombre', align: 'left', label: 'Nombre', field: 'nombre', sortable: true },
                { name: 'subTotal', align: 'right', label: 'SubTotal', field: 'subTotal', sortable: true },
                { name: 'total', align: 'right', label: 'Total', field: 'total', sortable: true },
                { name: 'moneda', align: 'left', label: 'Moneda', field: 'moneda', sortable: true },
                { name: 'solicitudCancelacion', align: 'left', label: 'Solicitud de Cancelación', field: 'solicitudCancelacion', sortable: true },
                { name: 'folioFiscal', align: 'left', label: 'Folio Fiscal', field: 'folioFiscal', sortable: true },
            ],
            dialogComprobante: false,
            dialogPdf: false,

            dialogCancelacion: false,
            dialogValidaEstatus: false,
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        dataComprobantes() {
            return this.$store.state.listComplementoDePagoStore;
        },

        fehaIMasked() {
            moment.locale('es-mx');
            return this.fechaI ? moment(this.fechaI).format('DD/MMMM/yyyy') : ''
        },

        fechaFMasked() {
            moment.locale('es-mx');
            return this.fechaF ? moment(this.fechaF).format('DD/MMMM/yyyy') : ''
        },

        timbresDisponibles(){
            return this.$store.state.timbresDisponiblesStore;
        },

    },

    created() {
        this.GetCatalogos();
    },

    methods: {
        async GetEmpresa() {
            try {
                let response = await axios.get("Empresa/GetEmpresaAsync/" + this.token.empresaBase);
                let x = response.data;
                this.$store.state.empresaStore = { ...x }
            } catch (error) {
                console.log(error)
            }
        },

        async GetComprobantes() {
            this.$q.loading.show({
                spinner: QSpinnerCube,
                spinnerColor: 'primary',
                spinnerSize: 140,
                message: 'Consultando, esprere..',
            })
            try {
                let fechaI_ = new Date(this.fechaI);
                let fechaF_ = new Date(this.fechaF);
                let fechaI = format(fechaI_, "yyyy-MM-dd");
                let fechaF = format(fechaF_, "yyyy-MM-dd");
                let response = await axios.get('Comprobantes/GetComprobanteAsync/erp_' + this.token.rfc + '/comprobantes_pagos/PAGO/' + fechaI + '/' + fechaF);
                const x = response.data;
                console.log(x)
                this.$store.state.listComplementoDePagoStore = x;
                this.$q.loading.hide()
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
            }
        },

        //CATALOGOS
        async GetCatalogos() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando empresa...' });
            await this.GetEmpresa();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando clientes / receptores...' });
            await this.GetReceptor();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando timbres disponibles...' });
            await this.GetTimbresDisponibles();
            this.$q.loading.hide()
        },

        async GetReceptor() {
            try {
                let response = await axios.get("CatalogosComprobantes/GetReceptorAsync/" + this.token.empresaBase);
                let x = response.data;
                this.$store.state.listClienteStore = [...x]
            } catch (error) {
                console.log(error)
            }
        },

        NuevoComprobante() {
            let comprobante = {
                _id: '',
                version: '4.0',
                serie: '',
                folio: '',
                fecha: new Date().toISOString(),
                formaPago: null,
                condicionesDePago: '',
                subTotal: 0,
                descuento: 0,
                moneda: { clave: "XXX", descripcion: "Los códigos asignados para las transacciones en que intervenga ninguna moneda", moneda: "XXX | Los códigos asignados para las transacciones en que intervenga ninguna moneda" },
                tipoCambio: 1,
                total: 0,
                tipoComprobante: 'P',
                tipoComprobanteInterno: 'PAGO',
                exportacion: { "clave": "01", "descripcion": "No aplica", "exportacion": "01 | No aplica" },
                metodoPago: null,
                lugarExpedicion: '',
                informacionGlobal: null,
                cfdiRelacionados: [],
                emisor: {
                    rfc: '',
                    nombre: '',
                    regimenFiscal: {
                        clave: '',
                    },
                    facAtrAdquirente: '',
                },
                receptor: {
                    receptor: '', usoCfdi: {
                        clave: "CP01",
                        descripcion: "Pagos",
                        fisica: "Sí",
                        moral: "Sí",
                        regimen_fiscal: "601, 603, 605, 606, 608, 610, 611, 612, 614, 616, 620, 621, 622, 623, 624, 607, 615, 625, 626",
                        usoCfdi: "CP01 | Pagos",
                    }
                },
                conceptos: [
                    {
                        item: 1,
                        claveProdServ: {
                            clave: '84111506',
                            claveProdServ: '84111506 | Servicios de facturación',
                            descripcion: 'Servicios de facturación',
                            palabrasSimilares: '',
                        },
                        noIdentificacion: '',
                        cantidad: 1,
                        claveUnidad: {
                            clave: 'ACT',
                            claveUnidad: 'ACT | Actividad',
                            descripcion: 'Actividad',
                        },
                        unidad: '',
                        descripcion: 'Pago',
                        valorUnitario: 0,
                        importe: 0,
                        descuento: 0,
                        objImp: {
                            clave: '01',
                            descripcion: 'No objeto de impuesto.',
                            objImp: '01 | No objeto de impuesto.',
                        },
                        impuestos: {
                            retenciones: [],
                            traslados: [],
                        },
                        cuentaPredial: null,
                    }
                ],
                impuestos: {
                    totalImpuestosRetenidos: 0,
                    totalImpuestosTrasladados: 0,
                    traslados: [],
                    retenciones: [],
                },
                folioFiscal: '',
                estatus: '',
                notas: '',
                oc: '',
                usuarioCreador: '',
                usuarioModificador: '',
                timbreFiscalDigital: null,
                cartaPorte: null,
                pago: {
                    version: '2.0',
                    totales: {
                        totalRetencionesIVA: 0,
                        totalRetencionesISR: 0,
                        totalRetencionesIEPS: 0,
                        totalTrasladosBaseIVA16: 0,
                        totalTrasladosImpuestoIVA16: 0,
                        totalTrasladosBaseIVA8: 0,
                        totalTrasladosImpuestoIVA8: 0,
                        totalTrasladosBaseIVA0: 0,
                        totalTrasladosImpuestoIVA0: 0,
                        totalTrasladosBaseIVAExento: 0,
                        montoTotalPagos: 0,
                    },
                    pagos: [
                        {
                            fechaPago: new Date().toISOString(),
                            formaDePagoP: null,
                            monedaP: { "clave": "MXN", "descripcion": "Peso Mexicano", "moneda": "MXN | Peso Mexicano" },
                            tipoCambioP: 1,
                            monto: 0,
                            numOperacion: '',
                            rfcEmisorCtaOrd: '',
                            nomBancoOrdExt: '',
                            ctaOrdenante: '',
                            rfcEmisorCtaBen: '',
                            ctaBeneficiario: '',
                            tipoCadPago: null,
                            certPago: '',
                            cadPago: '',
                            selloPago: '',
                            doctoRelacionados: [],
                            impuestosP: {
                                retencionesP: [],
                                trasladosP: [],
                            }
                        },
                    ],
                }
            };

            const comprobanteStore = {
                titulo: 'Nuevo Complemento de Pago',
                comprobante: comprobante
            }
            this.$store.state.comprobanteStore = { ...comprobanteStore };
            this.dialogComprobante = true;
        },

        UltimoDiaMes() {
            let fechaI = this.fechaI;
            fechaI = fechaI.replaceAll('/', '-');
            const fecha = parse(fechaI, 'yyyy-MM-dd', new Date());
            const ultimoDiaDelMes = endOfMonth(fecha);
            this.fechaF = ultimoDiaDelMes;
        },

        ExportExcel() { },

        FormatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        FormatDate(value) {
            let fecha = new Date(value);
            let dia = fecha.getDate();
            let mes = fecha.toLocaleString('es-ES', { month: 'long' }); // Obtener el mes en formato de letra
            let anio = fecha.getFullYear();
            let formatoDeseado = `${dia}-${mes}-${anio}`;
            return formatoDeseado;
        },

        colorComprobante(item) {
            var color = '';
            switch (item) {
                case 'Vigente':
                    color = 'green-14';
                    break;
                case 'Cancelado':
                    color = 'red-10';
                    break;
            }
            return color;
        },

        CierraDialog() {
            this.dialogComprobante = false;
        },

        async VerPdf(item) {
            let id = item._id;
            console.log('vamos a ver el pdf')
            let empresa = { ...this.$store.state.empresaStore };
            let color = empresa.color;
            try {
                //MOSTRAMOS EL PDF TIMBRADO
                this.$store.state.vistaPreviaStore = {
                    comprobante: null,
                    color: color,
                    logo: '',
                    rfc: empresa.rfc,
                    folioFiscal: item.folioFiscal,
                    tipoComprobanteInterno: 'PAGO'
                };
                this.dialogPdf = true;
            } catch (error) {
                console.log(error)
            }
        },

        CierraDialogPdf() {
            this.dialogPdf = false;
        },

        CierraDialogPdfTimbrado() {
            this.dialogPdf = false;
        },

        async Similar(item) {
            let id = item._id;
            try {
                let response = await axios.get('Comprobantes/GetComprobanteIdAsync/erp_' + this.token.rfc + '/comprobantes_pagos/' + id);
                let x = response.data;
                //BUSCAMOS EL RECEPTRO
                let receptor = this.$store.state.listClienteStore.find(f => f.nombre === x.receptor.nombre);
                receptor.usoCfdi = x.receptor.usoCfdi;
                console.log(receptor);

                x._id = '';
                x.estatus = 'Sin timbrar';
                x.folio = '';
                x.folioFiscal = '';
                x.timbreFiscalDigital = null;
                x.fecha = new Date().toISOString();
                x.receptor = receptor;

                const comprobanteStore = {
                    titulo: 'Nueva Carta Porte',
                    comprobante: x
                }
                this.$store.state.comprobanteStore = { ...comprobanteStore };
                this.dialogComprobante = true;

                console.log(x)
            } catch (error) {
                console.log(error)
            }
        },

        async Editar(item) {
            let id = item._id;
            try {
                let response = await axios.get('Comprobantes/GetComprobanteIdAsync/erp_' + this.token.rfc + '/' + id);
                let x = response.data;
                //BUSCAMOS EL RECEPTOR
                let receptor = this.$store.state.listClienteStore.find(f => f.nombre === x.receptor.nombre);
                receptor.usoCfdi = x.receptor.usoCfdi;
                console.log(receptor);

                x.estatus = 'Sin timbrar';
                x.folioFiscal = '';
                x.timbreFiscalDigital = null;
                x.receptor = receptor;

                const comprobanteStore = {
                    titulo: 'Editar Carta Porte',
                    comprobante: x
                }
                this.$store.state.comprobanteStore = { ...comprobanteStore };
                this.dialogComprobante = true;

                console.log(x)
            } catch (error) {
                console.log(error)
            }
        },
    
        async GetTimbresDisponibles(){
            try {
                let opciones = {
                    useGrouping: true, // Activar separador de miles
                    maximumFractionDigits: 0 // No mostrar decimales
                };
                let response = await axios.get("Comprobantes/GetTimbresDisponiblesAsync/" + this.token.rfc);
                let x = response.data;
                this.$store.state.timbresDisponiblesStore = x.toLocaleString("en-US", opciones);
            } catch (error) {
                console.log(error)
            }
        },

        async OpenDialogCancelacion(item) {
            let ObjCancelacion = {
                folioFiscal: item.folioFiscal,
                rfcEmisor: this.token.rfc,
                rfcReceptor: item.rfc,
                total: this.FormatCurrency(item.total),
                ventana: "Pago",
                nombreReceptorr: item.nombre,
                folio: item.serie + " " + item.folio,
            }
            this.$store.state.cancelacionStore = { ...ObjCancelacion }
            console.log(ObjCancelacion);
            this.dialogCancelacion = true;
        },

        async ValidaEstatusSat(item) {
            let ObjValida = {
                rfc: item.rfc,
                nombre: item.nombre,
                rfcEmisor: this.token.rfc,
                rfcReceptor: item.rfc,
                total: item.total,
                folioFiscal: item.folioFiscal,
                tipoComprobante: "P",
                tipoComprobanteInterno: "PAGO",
                comprobante: item,
            };
            this.$store.state.validaEstatusStore = { ...ObjValida }
            this.dialogValidaEstatus = true;
        },

    },
}
</script>

<style lang="sass">
    .my-sticky-column-table
    
      thead tr:first-child th:first-child
        background-color: #ffffff
    
      td:first-child
        background-color: #ffffff
    
      th:first-child,
      td:first-child
        position: sticky
        left: 0
        z-index: 1
    </style>