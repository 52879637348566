<template>
    <div>
        <q-dialog v-model="dialogNuevoUsuario" width="800px" persistent transition-show="scale" transition-hide="scale">
            <UsuarioInterno @cierraVentana="closeDialogUsuario()"></UsuarioInterno>
        </q-dialog>
        <q-table title="Usuarios Internos" :data="itemsUsuarios" :loading="loadingTabla" :columns="columns" row-key="_id">
            <template v-slot:top-right>
                <q-btn round color="green" icon="mdi-plus" @click="nuevoUsuario()" class="q-mr-sm" />
                <q-btn round color="blue" icon="mdi-magnify" @click="getUsuarios()" />
            </template>
            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>

            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td key="rfc" :props="props">{{ props.row.rfc }}</q-td>
                    <q-td key="curp" :props="props">{{ props.row.curp }}</q-td>
                    <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                    <q-td key="usuario" :props="props">{{ props.row.usuario }}</q-td>
                    <q-td key="departamento" :props="props">{{ props.row.departamento }}</q-td>
                    <q-td key="correo" :props="props">{{ props.row.correo }}</q-td>
                    <q-td key="telefono" :props="props">{{ props.row.telefono }}</q-td>
                    <q-td auto-width>
                        <q-btn size="md" color="blue" rounded flat dense @click="editarUsuario(props.row)"
                            icon="mdi-pencil">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Editar</q-tooltip>
                        </q-btn>
                        <q-btn size="md" color="red" rounded flat dense @click="eliminarUsuario(props.row)"
                            icon="mdi-delete">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Eliminar</q-tooltip>
                        </q-btn>
                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>
<script>
import axios from 'axios';
import UsuarioInterno from './UsuarioInterno.vue'
export default {
    components: { UsuarioInterno },
    data() {
        return {
            columns: [
                { name: 'rfc', align: 'center', label: 'RFC', field: 'rfc', sortable: true, classes: 'bg-grey-2 ellipsis', headerClasses: 'bg-primary text-white' },
                { name: 'curp', align: 'center', label: 'CURP', field: 'curp', sortable: true },
                { name: 'nombre', align: 'center', label: 'Nombre', field: 'nombre', sortable: true },
                { name: 'usuario', align: 'center', label: 'Usurio', field: 'usuario', sortable: true },
                { name: 'departamento', align: 'center', label: 'Departamento', field: 'departamento', sortable: true },
                { name: 'correo', align: 'center', label: 'Correo', field: 'correo', sortable: true },
                { name: 'telefono', align: 'center', label: 'Télefono', field: 'telefono', sortable: true },
            ],
            loadingTabla: false,

            dialogNuevoUsuario: false
        }
    },
    computed: {
        itemsUsuarios() {
            return this.$store.state.listaUsuariosInternosStore;
        },
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.getUsuarios();
    },
    methods: {
        nuevoUsuario() {
            this.iniciaizarObjeto();
            this.dialogNuevoUsuario = true
        },
        closeDialogUsuario() {
            this.dialogNuevoUsuario = false
        },
        async getUsuarios() {
            this.loadingTabla = true
            try {
                let response = await axios.get("UsuariosInternos/GetUsuarioInternos/" + this.token.empresaBase);
                console.log(response)
                this.$store.state.listaUsuariosInternosStore = response.data;
                this.loadingTabla = false
            } catch (error) {
                console.log(error);
                this.loadingTabla = false
            }
        },

        async eliminarUsuario(value) {
            try {
                let response = await axios.put("UsuariosInternos/EliminarUsuarioInterno/" + this.token.empresaBase + "/" + value._id);
                console.log(response)

                var index = this.itemsUsuarios.indexOf(value);
                this.itemsUsuarios.splice(index, 1);

                this.$q.notify({ type: 'positive', message: `Usuario ha sido eliminado.` })
            } catch (error) {
                console.log(error);
                this.$q.notify({ type: 'negative', message: 'Error al eliminar el usuario, intentelo de nuevo.', color: 'red' })
            }
        },
        iniciaizarObjeto() {
            let objetoUsuario = {
                _id: '',
                rfc: '',
                curp: '',
                nombre: '',
                usuario: '',
                pin: '',
                departamento: '',
                serie: '',
                permisos: [],
                telefono: '',
                correo: '',
                estatus: 'Vigente',
                foto: {
                    base64: '',
                },
                almacen: {},
            }
            this.$store.state.usuarioInternoStore = { ...objetoUsuario }
        },

        editarUsuario(data) {
            console.log(data)
            this.$store.state.usuarioInternoStore = { ...data }
            this.dialogNuevoUsuario = true
        },
    }
}
</script>