import jsPDF from 'jspdf'
import moment from 'moment'

function formatoCantidad(numero) {
    let respuesta = numero.toFixed(3);
    return respuesta;
}

function formatoImpuestos(numero) {
    let respuesta = numero.toFixed(4);
    return respuesta;
}

function formatoNumerico(value) {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

function formtaoFecha(value) {
    moment.locale('es');
    return moment(value).format('DD-MMMM-YYYY HH:mm:ss')
}
async function urlToBase64(url) {
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(blob);
        });
    } catch (error) {
        console.error(error);
        return null;
    }
}

const ticketBase64 = async (venta) => {
    return new Promise((resolve, reject) => {
        try {
            var doc = new jsPDF({
                orientation: "portrait",  // Orientación vertical
                unit: "mm",              // Unidades en milímetros
                format: [80, 210]        // Tamaño de la página personalizado
            });
            //CABECERA 
            let x = 5;
            let y = 10;

            var imgWidth = 60; // Ancho de la imagen en mm
            var pageWidth = doc.internal.pageSize.width;

            // Calcular la posición x para centrar la imagen horizontalmente
            var xImagen = (pageWidth - imgWidth) / 2;

            // Agregar la imagen centrada
            doc.addImage(venta.empresa.logo.base64, 'PNG', xImagen, y, imgWidth);

            doc.addImage(venta.empresa.logo.base64, 'PNG', y, x, 70, 30);

            // Agregar contenido al PDF
            doc.text("Contenido de tu ticket aquí", 10, x += 35);


            const base64 = doc.output('datauristring');
            resolve(base64);

        }
        catch (error) {
            console.log(error)
        }
    });
}

export { ticketBase64 };