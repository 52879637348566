import { render, staticRenderFns } from "./ListaRemision.vue?vue&type=template&id=7fc775d8&scoped=true"
import script from "./ListaRemision.vue?vue&type=script&lang=js"
export * from "./ListaRemision.vue?vue&type=script&lang=js"
import style0 from "./ListaRemision.vue?vue&type=style&index=0&id=7fc775d8&prod&scoped=true&lang=css"
import style1 from "./ListaRemision.vue?vue&type=style&index=1&id=7fc775d8&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fc775d8",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QAvatar,QCardActions,QBtn,QSpace,QInput,QIcon,QPopupProxy,QDate,QTooltip,QTable,QTr,QTd,QCheckbox,QBadge});qInstall(component, 'directives', {ClosePopup});
