<template>
    <div class="q-pa-md">

        <!-- DIALOGO DE CONFIRMACION -->
        <q-dialog v-model="dialogConfirm" persistent>
            <q-card style="width: 600px;">
                <q-card-section class="row items-center">
                    <q-avatar icon="mdi-delete-alert" color="primary" text-color="white" />
                    <span class="text-h5 q-ml-md">Estas seguro de eliminar la remisión.?</span>
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat label="Aceptar" color="primary" @click="eliminarRemision()" />
                    <q-btn flat label="Cerrar" color="primary" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- DIALOG PARA HACER UNA REMISION -->
        <q-dialog v-model="dialogRemision" maximized transition-show="slide-up" transition-hide="slide-down">
            <Remision @Close="dialogRemision = false"></Remision>
        </q-dialog>

        <!-- DIALOG PARA HACER UNA NUEVA FACTURA -->
        <q-dialog v-model="dialogComprobante" maximized transition-show="slide-up" transition-hide="slide-down">
            <comprobante @CloseComprobante="dialogComprobante = false"></comprobante>
        </q-dialog>

        <!-- SELECCIONA AÑO Y MES, BOTON DE BUSCAR Y EXPORTAR A EXCEL -->
        <div class="row no-wrap items-center q-mt-md q-pa-sm">
            <div class="text-h5">Remisión</div>
            <q-space />
            <q-input v-model="fehaIMasked" label="Fecha Inicial" class="q-mr-sm" name="event" filled dense>
                <template v-slot:before>
                    <q-icon name="event" />
                </template>
                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="fechaI" @input="UltimoDiaMes">
                        <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Ok" color="primary" flat />
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-input>

            <q-input v-model="fechaFMasked" label="Fecha Final" class="q-mr-xs" filled dense>
                <template v-slot:before>
                    <q-icon name="event" />
                </template>
                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="fechaF">
                        <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Ok" color="primary" flat />
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-input>
            <q-btn push color="amber-9" icon="mdi-text-box-search-outline" rounded flat size="18px" padding="xs"
                @click="getRemisiones()">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Consultar</q-tooltip>
            </q-btn>
            <q-btn push color="green-14" @click="nuevaRemision()" icon="mdi-plus-circle" rounded flat size="18px"
                padding="xs">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Nueva Remisión</q-tooltip>
            </q-btn>

            <q-btn push color="green-10" @click="ExportExcel()" icon="mdi-file-excel-box-outline" rounded flat
                size="18px" padding="xs">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Exportar Excel</q-tooltip>
            </q-btn>
        </div>

        <!-- TABLA DE COMPROBANTES -->
        <q-table :loading="loading" title="Remisión" :data="dataRemision" :columns="columns" row-key="_id"
            :rows-per-page-options="[10]" :filter="filter" class="my-sticky-column-table shadow-0" selection="multiple"
            :selected.sync="selectedRemisiones">
            <template v-slot:top>
                <q-btn class="q-ml-sm" icon="mdi-application-export" color="primary" label="Facturar"
                    @click="facturarRemision()" />
                <q-space />
                <q-input borderless dense debounce="300" v-model="filter" placeholder="Filtrar">
                    <template v-slot:append>
                        <q-icon name="mdi-magnify" />
                    </template>
                </q-input>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width>
                        <q-checkbox v-model="props.selected" />
                        <q-btn size="md" color="red-10" rounded flat dense @click="VerPdf(props.row)"
                            icon="mdi-file-pdf-box">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Ver PDF</q-tooltip>
                        </q-btn>
                        <q-btn size="md" color="amber-10" rounded flat dense @click="Similar(props.row)"
                            icon="mdi-reload">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Comprobante similar</q-tooltip>
                        </q-btn>
                        <q-btn v-if="props.row.estatus != 'Facturado'" size="md" color="red" rounded flat dense
                            @click="eliminar(props.row)" icon="mdi-delete">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Eliminar</q-tooltip>
                        </q-btn>
                        <!-- <q-btn
                            v-if="props.row.estatus != 'Facturado' && props.row.estatus != 'Autorizada' && props.row.estatus != 'Cancelado'"
                            size="md" color="red" rounded flat dense @click="cancelarRemision(props.row)"
                            icon="mdi-cancel">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Cancelar</q-tooltip>
                        </q-btn> -->
                        <template v-if="props.row.estatus == 'Nuevo'">
                            <q-btn size="md" color="blue" rounded flat dense @click="Editar(props.row)"
                                icon="mdi-pencil">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px" :offset="[10, 10]">Editar</q-tooltip>
                            </q-btn>
                        </template>
                    </q-td>

                    <q-td key="serie" :props="props">{{ props.row.serie }}</q-td>
                    <q-td key="folio" :props="props">
                        <q-badge :class="props.row.estatus" rounded>
                            {{ props.row.folio }}
                        </q-badge>
                    </q-td>
                    <q-td key="fecha" :props="props">{{ FormatDate(props.row.fecha) }}</q-td>
                    <q-td key="rfc" :props="props">{{ props.row.receptor.rfc }}</q-td>
                    <q-td key="nombre" :props="props">{{ props.row.receptor.nombre }}</q-td>
                    <q-td key="ordenCompra" :props="props">{{ props.row.ordenCompra }}</q-td>
                    <q-td key="proyecto" :props="props">{{ props.row.proyecto.proyecto }}</q-td>
                    <q-td key="subTotal" :props="props">{{ FormatCurrency(props.row.subTotal) }}</q-td>
                    <q-td key="total" :props="props">{{ FormatCurrency(props.row.total) }}</q-td>
                    <q-td key="moneda" :props="props">{{ props.row.moneda.moneda }}</q-td>
                    <q-td key="totalFacturado" :props="props">{{ FormatCurrency(props.row.totalFacturado) }}</q-td>
                </q-tr>
            </template>
            <template v-slot:bottom>
                <q-badge rounded color="blue" class="text-subtitle2 q-mr-sm" label="Nuevo" />
                <q-badge rounded color="red" class="text-subtitle2  q-mr-sm" label="Cancelado" />
                <q-badge rounded color="green" class="text-subtitle2  q-mr-sm" label="Autorizado" />
                <q-badge rounded color="purple" class="text-subtitle2" label="Facturado" />
            </template>

        </q-table>
    </div>
</template>
<script>
import axios from 'axios'
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar';
import moment from 'moment';
import Remision from './Remision.vue'
import comprobante from '../../Comprobantes/Facturacion/Facturacion.vue'

export default {
    components: {
        Remision, comprobante
    },
    data() {
        return {
            fechaI: new Date(),
            fechaF: new Date(),
            filter: '',
            columns: [
                // { name: 'actions', align: 'left', label: 'Acciones', field: 'actions' },
                { name: 'serie', align: 'left', label: 'Serie', field: 'serie', sortable: true },
                { name: 'folio', align: 'left', label: 'Folio', field: 'folio', sortable: true },
                { name: 'fecha', align: 'left', label: 'Fecha', field: 'fecha', sortable: true },
                { name: 'rfc', align: 'left', label: 'RFC', field: 'rfc', sortable: true },
                { name: 'nombre', align: 'left', label: 'Nombre', field: 'nombre', sortable: true },

                { name: 'ordenCompra', align: 'left', label: 'Orden de Compra', field: 'ordenCompra', sortable: true },
                { name: 'proyecto', align: 'left', label: 'Proyecto', field: 'proyecto', sortable: true },

                { name: 'subTotal', align: 'right', label: 'SubTotal', field: 'subTotal', sortable: true },
                { name: 'total', align: 'right', label: 'Total', field: 'total', sortable: true },
                { name: 'moneda', align: 'left', label: 'Moneda', field: 'moneda', sortable: true },
                { name: 'totalFacturado', align: 'right', label: 'Total Facturado', field: 'totalFacturado', sortable: true },

            ],
            dialogRemision: false,
            loading: false,
            dialogConfirm: false,
            selectedRemisiones: [],
            dialogComprobante: false,

            claveProdServ: {
                clave: '',
                descripcion: '',
                palabrasSimilares: '',
                claveProdServ: ''
            },
            claveUnidad: {
                clave: '',
                descripcion: '',
                claveUnidad: ''
            }
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        fehaIMasked() {
            moment.locale('es-mx');
            return this.fechaI ? moment(this.fechaI).format('DD/MMMM/yyyy') : ''
        },

        fechaFMasked() {
            moment.locale('es-mx');
            return this.fechaF ? moment(this.fechaF).format('DD/MMMM/yyyy') : ''
        },
        dataRemision() {
            return this.$store.state.listaRemisionesStore
        },
        remision() {
            return this.$store.state.remisionStore
        }
    },
    created() {
    },
    methods: {
        async facturarRemision() {
            try {
                let proyecto = this.selectedRemisiones[0].proyecto;
                let rece = this.selectedRemisiones[0].receptor;
                let moneda = this.selectedRemisiones[0].moneda;
                let formaPago = this.selectedRemisiones[0].formaPago;
                let condicionesPago = this.selectedRemisiones[0].condicionesPago;
                let metodoPago = this.selectedRemisiones[0].metodoPago;
                let ordenCompra = this.selectedRemisiones[0].ordenCompra;
                console.log(this.selectedRemisiones)
                let receptor = this.$store.state.listClienteStore.find(f => f.nombre === rece.nombre);
                receptor.usoCfdi = rece.usoCfdi;

                let subTotal = 0
                let descuento = 0
                let total = 0;
                let conceptos = []
                let impuestos = {
                    traslados: [],
                    retenciones: [],
                    totalImpuestosRetenidos: 0,
                    totalImpuestosTrasladados: 0
                }

                for (var c of this.selectedRemisiones) {
                    //VALIDAMOS QUE SEAN DEL MISMO PROYECTO
                    if (c.proyecto._id != proyecto._id) {
                        this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error, todas las remisiones deben tener el mismo proyecto.', color: 'red' })
                        return
                    }
                }

                //RECORREMOS LOS CONCEPTOS PARA AGREGARLOS A LA FACTURA
                for (var concepto of c.conceptos) {
                    console.log(concepto)
                    if (concepto.claveProdServ == null) {
                        concepto.claveProdServ = this.claveProdServ
                    }
                    if (concepto.claveUnidad == null) {
                        concepto.claveUnidad = this.claveUnidad
                    }
                    let Obj = {}
                    Obj = { ...concepto }
                    conceptos.push(Obj)
                    Obj = {}
                }

                let impuestosRes = await this.AgrupaYCalculaTotales(conceptos)

                subTotal = impuestosRes.subTotal
                total = impuestosRes.total
                descuento = impuestosRes.descuento
                impuestos.traslados = impuestosRes.traslados
                impuestos.retenciones = impuestosRes.retenciones
                impuestos.totalImpuestosRetenidos = impuestosRes.totalImpuestosRetenidos
                impuestos.totalImpuestosTrasladados = impuestosRes.totalImpuestosTrasladados

                let comprobante = {
                    _id: '',
                    version: '4.0',
                    serie: '',
                    folio: '',
                    fecha: new Date().toISOString(),
                    formaPago: formaPago,
                    condicionesDePago: condicionesPago,
                    subTotal: subTotal,
                    descuento: descuento,
                    moneda: { clave: "MXN", descripcion: "Peso Mexicano", moneda: "MXN | Peso Mexicano" },
                    tipoCambio: 1,
                    total: total,
                    tipoComprobante: 'I',
                    tipoComprobanteInterno: 'FACTURA',
                    exportacion: { "clave": "01", "descripcion": "No aplica", "exportacion": "01 | No aplica" },
                    metodoPago: metodoPago,
                    lugarExpedicion: '62661',
                    informacionGlobal: {
                        periodicidad: null,
                        meses: null,
                        año: '2024'
                    },
                    cfdiRelacionados: [
                        { tipoRelacion: null, uuid: [] }
                    ],
                    emisor: {
                        rfc: 'IIA040805DZ4',
                        nombre: 'INDISTRIA ILUMINADORA DE ALMACENES',
                        regimenFiscal: {
                            clave: '601',
                        },
                        facAtrAdquirente: '',
                    },
                    receptor: receptor,
                    conceptos: conceptos,
                    impuestos: impuestos,
                    folioFiscal: '',
                    estatus: '',
                    notas: '',
                    oc: ordenCompra,
                    usuarioCreador: this.token.nombre,
                    usuarioModificador: '',
                    timbreFiscalDigital: null,
                };

                const comprobanteStore = {
                    titulo: 'Nueva Factura',
                    comprobante: comprobante
                }
                this.$store.state.comprobanteStore = { ...comprobanteStore };
                this.dialogComprobante = true;

            } catch (error) {
                console.log(error)
            }
        },

        async getRemisiones() {
            this.loading = true
            let fechaI_ = new Date(this.fechaI);
            let fechaF_ = new Date(this.fechaF);
            let fechaI = format(fechaI_, "yyyy-MM-dd");
            let fechaF = format(fechaF_, "yyyy-MM-dd");
            try {
                let response = await axios.get("Ventas/GetRemisionAsync/erp_" + this.token.rfc + '/' + fechaI + '/' + fechaF);
                let catalogo = response.data;
                console.log(catalogo)
                this.$store.state.listaRemisionesStore = catalogo;
                this.loading = false

            } catch (error) {
                this.loading = false
                console.log(error);
            }
        },

        async GetSerie() {
            try {
                let response = await axios.get("Ventas/GetSerieVentasAsync/" + this.token.empresaBase + '/v_remision/REMISION');
                let x = response.data;
                this.$store.state.serieStore = [...x]
            } catch (error) {
                console.log(error)
            }
        },
        nuevaRemision() {
            let objeto = {
                _id: '',
                serie: '',
                folio: '',
                fecha: new Date().toISOString(),
                emisor: {
                    rfc: 'IIA040805DZ4',
                    nombre: 'INDISTRIA ILUMINADORA DE ALMACENES',
                    regimenFiscal: {
                        clave: '601',
                    },
                    facAtrAdquirente: '',
                },
                receptor: { receptor: '', usoCfdi: null },
                atn: '',
                moneda: { clave: "MXN", descripcion: "Peso Mexicano", moneda: "MXN | Peso Mexicano" },
                proyecto: { proyecto: '' },

                ordenTrabajo: '',
                ordenCompra: '',
                condicionesPago: '',
                notas: '',

                subTotal: 0,
                descuento: 0,
                tipoCambio: 1,
                total: 0,
                tipoComprobanteInterno: 'REMISION',
                conceptos: [],
                impuestos: {
                    totalImpuestosRetenidos: 0,
                    totalImpuestosTrasladados: 0,
                    traslados: [],
                    retenciones: [],
                },
                estatus: 'Vigente',
                usuarioCreador: this.token.nombre,
                usuarioModificador: '',
                totalFacturado: 0
            };
            this.$store.state.remisionStore = { ...objeto };
            this.dialogRemision = true
            this.GetSerie();
        },

        ExportExcel() {
        },

        VerPdf() {
        },

        Similar() {
            let receptor = this.$store.state.listClienteStore.find(f => f.nombre === item.receptor.nombre);

            const x = { ...item }
            x._id = '';
            x.estatus = 'Vigente';
            x.folio = '';
            x.fecha = new Date().toISOString();
            x.receptor = receptor;
            this.$store.state.remisionStore = { ...x };
            this.dialogRemision = true;
        },

        eliminar(item) {
            console.log(item)
            this.dialogConfirm = true
            this.$store.state.remisionStore = item
        },

        async eliminarRemision() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Eliminando remisión. Espere...', messageColor: 'white' })
            let remision = this.$store.state.remisionStore

            try {
                let response = await axios.put('Ventas/DeleteRemisionAsync/erp_' + this.token.rfc + '/Eliminado/' + remision._id + '/' + this.token.nombre)
                console.log(response)
                // SI LA ACCION ES ELIMINAR, LO QUITAMOS DE LA LISTA
                let indice = this.dataRemision.indexOf(remision)
                this.dataRemision.splice(indice, 1)
                this.dialogConfirm = false
                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `La remisión ha sido eliminado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error, intentelo mas tarde.', color: 'red' })
            }
        },

        async cancelarRemision(item) {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Cancelando remisión. Espere...', messageColor: 'white' })
            let remision = item

            try {
                let response = await axios.put('Ventas/DeleteRemisionAsync/erp_' + this.token.rfc + '/Cancelado/' + remision._id + '/' + this.token.nombre)
                console.log(response)

                let indice = this.$store.state.listaRemisionesStore.findIndex(x => x._id === remision._id);
                this.$store.state.listaRemisionesStore[indice].estatus = 'Cancelado'
                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `El remisión ha sido cancelada exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error, intentelo mas tarde.', color: 'red' })
            }
        },
        Editar(data) {
            this.$store.state.remisionStore = { ...data }
            let receptor = this.$store.state.listClienteStore.find(f => f.nombre === data.receptor.nombre);
            this.$store.state.remisionStore.receptor = receptor
            this.dialogRemision = true
        },

        //CATALOGOS
        async GetEmpresa() {
            try {
                let response = await axios.get("Empresa/GetEmpresaAsync/" + this.token.empresaBase);
                let x = response.data;
                this.$store.state.empresaStore = { ...x }
            } catch (error) {
                console.log(error)
            }
        },

        async GetReceptor() {
            try {
                let response = await axios.get("CatalogosComprobantes/GetReceptorAsync/" + this.token.empresaBase);
                let x = response.data;
                this.$store.state.listClienteStore = [...x]
            } catch (error) {
                console.log(error)
            }
        },

        FormatDate(value) {
            let fecha = new Date(value);
            let dia = fecha.getDate();
            let mes = fecha.toLocaleString('es-ES', { month: 'long' }); // Obtener el mes en formato de letra
            let anio = fecha.getFullYear();
            let formatoDeseado = `${dia}-${mes}-${anio}`;
            return formatoDeseado;
        },
        FormatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        UltimoDiaMes() {
            let fechaI = this.fechaI;
            fechaI = fechaI.replaceAll('/', '-');
            const fecha = parse(fechaI, 'yyyy-MM-dd', new Date());
            const ultimoDiaDelMes = endOfMonth(fecha);
            this.fechaF = ultimoDiaDelMes;
        },

        AgrupaYCalculaTotales(conceptos) {
            let totalTraslados = 0;
            let totalRetenidos = 0;
            let subTotal = 0;
            let descuento = 0;
            let total = 0;
            let trasladados = [];
            let retenidos = [];

            let impuestos = {
                traslados: [],
                retenciones: [],
                totalImpuestosTrasladados: 0,
                totalImpuestosRetenidos: 0,
                total: 0,
                subTotal: 0,
                descuento: 0
            }


            for (let c of conceptos) {
                for (let t of c.impuestos.traslados) {
                    trasladados.push(t);
                }

                for (let r of c.impuestos.retenciones) {
                    retenidos.push(r)
                }
            }

            subTotal = conceptos.reduce(function (acumulador, objeto) {
                return acumulador + parseFloat(objeto.importe);
            }, 0);
            descuento = conceptos.reduce(function (acumulador, objeto) {
                return acumulador + parseFloat(objeto.descuento);
            }, 0);
            subTotal = subTotal.toFixed(2);
            descuento = descuento.toFixed(2);

            //AGRUPAMOS LOS IMPUESTOS
            let t = this.AgrupaYCalculaImpuestos(trasladados);
            let r = this.AgrupaYCalculaImpuestos(retenidos);
            totalTraslados = t.reduce(function (acumulador, objeto) {
                return acumulador + parseFloat(objeto.importe);
            }, 0);
            totalRetenidos = r.reduce(function (acumulador, objeto) {
                return acumulador + parseFloat(objeto.importe);
            }, 0);
            totalTraslados = totalTraslados.toFixed(2);
            totalRetenidos = totalRetenidos.toFixed(2);

            //ASIGNAMOS LAS VARIABLES TOTALES
            impuestos.traslados = t;
            impuestos.retenciones = r;
            impuestos.totalImpuestosTrasladados = parseFloat(totalTraslados);
            impuestos.totalImpuestosRetenidos = parseFloat(totalRetenidos);
            impuestos.subTotal = parseFloat(subTotal);
            impuestos.descuento = parseFloat(descuento);

            //ASIGNAMOS EL TOTAL
            total = impuestos.subTotal - impuestos.descuento + impuestos.totalImpuestosTrasladados - impuestos.totalImpuestosRetenidos;
            total = total.toFixed(2);
            impuestos.total = parseFloat(total);

            return impuestos
        },

        AgrupaYCalculaImpuestos(lista) {
            var agrupado = lista.reduce(function (acumulador, elemento) {
                // Crear una clave única para el grupo
                var clave = elemento.impuesto + '-' + elemento.tipoFactor + '-' + elemento.tasaOCuota;

                // Inicializar el grupo si es la primera vez que se encuentra la clave
                if (!acumulador[clave]) {
                    acumulador[clave] = {
                        impuesto: elemento.impuesto,
                        tipoFactor: elemento.tipoFactor,
                        tasaOCuota: elemento.tasaOCuota,
                        base_: 0,
                        importe: 0,
                    };
                }

                // Sumar base e importe al grupo correspondiente
                acumulador[clave].base_ += parseFloat(elemento.base_);
                acumulador[clave].importe += parseFloat(elemento.importe);

                return acumulador;
            }, {});

            // Convertir el objeto agrupado de nuevo a una lista
            var resultado = Object.values(agrupado);

            for (let r of resultado) {
                r.base_ = r.base_.toFixed(2);
                r.importe = r.importe.toFixed(2);
            }
            return resultado;
        },

    },
}
</script>
<style scoped>
.Nuevo {
    background-color: #2196f3;
    padding: 10px;
    font-size: 15px
}

.Facturado {
    background-color: #9c27b0;
    padding: 10px
}

.Cancelado {
    background-color: #f44336;
    padding: 10px
}

.Autorizado {
    background-color: #4caf50;
    padding: 10px
}
</style>
<style lang="sass">
    .my-sticky-column-table
    
      thead tr:first-child th:first-child
        background-color: #ffffff
    
      td:first-child
        background-color: #ffffff
    
      th:first-child,
      td:first-child
        position: sticky
        left: 0
        z-index: 1
    </style>