<template>
    <q-card>
        <q-card-section>
            <q-toolbar>
                <q-toolbar-title>
                    Mercancia
                </q-toolbar-title>
                <q-btn v-if="objetoStore._id == ''" dense round color="green" icon="mdi-content-save" class="q-mr-sm"
                    @click="guardar()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>

                <q-btn v-else round color="orange" dense icon="mdi-content-save" class="q-mr-sm" @click="actualizar()">
                    <q-tooltip>
                        Editar
                    </q-tooltip>
                </q-btn>

                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
            <q-card-section class="scroll">
                <div class="row q-col-gutter-sm">
                    <div class="col-12">
                        <q-select v-model="objetoStore.materialPeligroso" emit-value map-options :options="['Sí', 'No']"
                            use-input input-debounce="0" dense filled label="Material peligroso"
                            virtual-scroll-slice-size="1" />
                    </div>
                    <template v-if="objetoStore.materialPeligroso == 'Sí'">
                        <div class="col-12">
                            <q-select v-model="objetoStore.cveMaterialPeligroso" emit-value map-options
                                @filter="filtroMaterialPeligroso" :options="itemsFiltroMaterialPeligroso"
                                option-label="materialPeligroso" use-input input-debounce="0" dense filled
                                label="Material peligroso" virtual-scroll-slice-size="1" />
                        </div>
                    </template>
                    <div class="col-12">
                        <q-select v-model="objetoStore.bienesTransp" emit-value map-options
                            @filter="filtroClaveProdServ" :options="itemsFiltroClaveProServCP"
                            option-label="claveProdServCP" use-input input-debounce="0" dense filled
                            label="Bienes transportados" virtual-scroll-slice-size="1" />
                    </div>
                    <div class="col-12 ">
                        <q-input dense filled label="Descripción" v-model="objetoStore.descripcion" />
                    </div>
                    <div class="col-12 col-md-6">
                        <q-select v-model="objetoStore.claveUnidad" emit-value map-options @filter="filtroClaveUnidad"
                            :options="itemsFiltroClaveUnidad" option-label="claveUnidad" use-input input-debounce="0"
                            dense filled label="Clave unidad" virtual-scroll-slice-size="1" />
                    </div>
                    <div class="col-12 col-md-6">
                        <q-input dense filled label="Unidad" v-model="objetoStore.unidad" />
                    </div>
                    <div class="col-12 col-md-4">
                        <q-input dense filled label="Dimensiones" v-model="objetoStore.dimensiones" />
                    </div>
                    <div class="col-12 col-md-4">
                        <q-field dense filled label="Peso en KG" v-model="objetoStore.pesoEnKg">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                        </q-field>
                    </div>
                    <div class="col-12 col-md-4">
                        <q-field dense filled label="Cantidad" v-model="objetoStore.cantidad">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                        </q-field>
                    </div>
                    <div class="col-12">
                        <q-select v-model="objetoStore.tipoMateria" emit-value map-options @filter="filtroTipoMateria"
                            :options="itemsFiltroTipoMateria" option-label="tipoMateria" use-input input-debounce="0"
                            dense filled label="Tipo de materia" virtual-scroll-slice-size="1" />
                    </div>
                    <div class="col-12">
                        <q-select v-model="objetoStore.embalaje" emit-value map-options @filter="filtroEmbalaje"
                            :options="itemsFiltroTipoEmbalaje" option-label="tipoEmbalaje" use-input input-debounce="0"
                            dense filled label="Tipo de embalaje" virtual-scroll-slice-size="1" />
                    </div>
                    <div class="col-12 ">
                        <q-input dense filled label="Descripción del embalaje" v-model="objetoStore.descripEmbalaje" />
                    </div>
                    <!-- <div class="col-12">
                        <q-select v-model="objetoStore.fraccionArancelaria" emit-value map-options
                            @filter="filtroFraccionArancelaria" :options="itemsFiltroFraccionArancelaria"
                            option-label="fraccionArancelaria" use-input input-debounce="0" dense filled
                            label="Fracción arancelaria" virtual-scroll-slice-size="1" />
                    </div> -->

                    <!-- <div class="col-12 ">
                        <q-input dense filled label="UUID Comercio exterior" v-model="objetoStore.uuidComercioExt" />
                    </div> -->

                    <!-- <div class="col-12">
                        <q-bar dense class="bg-primary text-white">
                            <q-space />
                            <div>DOCUMENTACIÓN ADUANERA</div>
                            <q-space />
                        </q-bar>
                    </div> -->
                    <!-- <div class="col-12">
                        <q-select v-model="documentoAduanero.tipoDocumento" emit-value map-options
                            @filter="filtroDocAduanero" :options="itmesFiltroDocAduanero" option-label="documentoAduanero"
                            use-input input-debounce="0" dense filled label="Tipo de documento"
                            virtual-scroll-slice-size="1" />
                    </div> -->
                    <!-- <div class="col-12 col-md-6">
                        <q-input dense filled label="Número de pedimento" v-model="documentoAduanero.numPedimento" />
                    </div>
                    <div class="col-12 col-md-6">
                        <q-input dense filled label="RFC" v-model="documentoAduanero.rfcImpo">
                            <template v-slot:after>
                                <q-btn round color="primary" icon="mdi-plus" @click="agregarDocumento()">
                                    <q-tooltip>
                                        Agregar
                                    </q-tooltip>
                                </q-btn>
                            </template>
                        </q-input>
                    </div> -->
                    <!-- <div class="col-12">
                        <q-table dense class="my-sticky-column-table shadow-0 header-tabla q-mt-md" row-key="tipoDocumento"
                            :data="objetoStore.documentacionAduanera" :columns="columns" :rows-per-page-options="[0]"
                            hide-bottom>
                            <template v-slot:body="props">
                                <q-tr :props="props">
                                    <q-td auto-width key="acciones">
                                        <q-btn size="md" color="red" rounded flat dense
                                            @click="eliminarDocumento(props.row)" icon="mdi-delete">
                                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                                content-style="font-size: 14px">Eliminar</q-tooltip>
                                        </q-btn>
                                    </q-td>
                                    <q-td key="tipoDocumento" :props="props">{{ props.row.tipoDocumento.documentoAduanero }}
                                    </q-td>
                                    <q-td key="numPedimento" :props="props">
                                        {{ props.row.numPedimento }}
                                    </q-td>
                                    <q-td key="rfcImpo" :props="props">
                                        {{ props.row.rfcImpo }}
                                    </q-td>
                                </q-tr>
                            </template>
                        </q-table>
                    </div> -->

                </div>
            </q-card-section>
        </q-card-section>
    </q-card>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import { Money } from 'v-money'

export default {
    components: {
        Money
    },
    data() {
        return {
            materialPeligroso: 'No',
            itemsFiltroClaveProServCP: null,
            itemsFiltroClaveUnidad: null,
            itemsFiltroFraccionArancelaria: null,
            itemsFiltroClaveTransporte: null,
            itmesFiltroDocAduanero: null,
            itemsFiltroTipoEmbalaje: null,
            itemsFiltroTipoMateria: null,
            itemsFiltroMaterialPeligroso: null,

            documentoAduanero: {
                tipoDocumento: { documentoAduanero: '' },
                numPedimento: '',
                rfcImpo: ''
            },

            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'tipoDocumento', align: 'center', label: 'Tipo de documento', field: 'tipoDocumento', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'numPedimento', align: 'center', label: 'Núm. Pedimento', field: 'numPedimento', sortable: true, classes: 'text-left', headerClasses: 'max-width: 100px', },
                { name: 'rfcImpo', align: 'center', label: 'RFC', field: 'rfcImpo', sortable: true, classes: 'text-right', },
            ],

            moneyFormat: {
                decimal: '.',
                thousands: ',',
                precision: 2,
                masked: false
            },

        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        objetoStore() {
            return this.$store.state.mercanciaStore;
        },
        itemsClaveProServCP() {
            return this.$store.state.listaCatalogoClaveProServCP;
        },
        itemsClaveUnidad() {
            return this.$store.state.listaCatalogoClaveUnidad;
        },
        itemsFraccionArancelaria() {
            return this.$store.state.listaCatalogoFraccionArancelaria;
        },
        itemsClaveTransporte() {
            return this.$store.state.listaCatalogoCveTransporte;
        },
        itemsDocAduanero() {
            return this.$store.state.listaCatalogoDocAduanero;
        },
        itemsTipoEmbalaje() {
            return this.$store.state.listaCatalogoTipoEmbalaje;
        },
        itemsTipoMateria() {
            return this.$store.state.listaCatalogoTipoMateria;
        },
        itemsMaterialPeligroso() {
            return this.$store.state.listaCatalogoMaterialPeligroso;
        },
    },

    created() {
    },
    methods: {
        cierraVentana() {
            this.$emit('cierraVentana')
        },
        filtroClaveProdServ(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroClaveProServCP = this.itemsClaveProServCP.filter(v => v.claveProdServCP.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroClaveProServCP.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroMaterialPeligroso(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroMaterialPeligroso = this.itemsMaterialPeligroso.filter(v => v.materialPeligroso.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroMaterialPeligroso.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroClaveUnidad(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroClaveUnidad = this.itemsClaveUnidad.filter(v => v.claveUnidad.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroClaveUnidad.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroEmbalaje(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroTipoEmbalaje = this.itemsTipoEmbalaje.filter(v => v.tipoEmbalaje.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroTipoEmbalaje.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroFraccionArancelaria(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroFraccionArancelaria = this.itemsFraccionArancelaria.filter(v => v.fraccionArancelaria.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroFraccionArancelaria.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroDocAduanero(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itmesFiltroDocAduanero = this.itemsDocAduanero.filter(v => v.documentoAduanero.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itmesFiltroDocAduanero.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        filtroTipoMateria(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroTipoMateria = this.itemsTipoMateria.filter(v => v.tipoMateria.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroTipoMateria.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        async guardar() {

            console.log(this.objetoStore)

            if (this.objetoStore.materialPeligroso == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique si la mercancia es considerada peligrosa' })
                return;
            }
            if (this.objetoStore.materialPeligroso == 'Sí') {
                if (this.objetoStore.cveMaterialPeligroso.materialPeligroso == '') {
                    this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la clave del material peligroso' })
                    return;
                }
            }
            if (this.objetoStore.bienesTransp == null) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique los bienes a transportar' })
                return;
            }
            if (this.objetoStore.descripcion == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la descripción de la mercancia' })
                return;
            }
            if (this.objetoStore.claveUnidad == null) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la clave de unidad' })
                return;
            }

            if (this.objetoStore.unidad == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la unidad' })
                return;
            }

            if (this.objetoStore.tipoMateria == null) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el tipo de materia' })
                return;
            }

            if (this.objetoStore.embalaje == null) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el tipo de embalaje' })
                return;
            }

            if (this.objetoStore.descripEmbalaje == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la descripción del ebalaje' })
                return;
            }

            // if (this.objetoStore.pesoEnKg == 0 || this.objetoStore.pesoEnKg == '') {
            //     this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el peso de la mercancia en KG' })
            //     return;
            // }

            // if (this.objetoStore.dimensiones == '') {
            //     this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique las dimensiones de la mercancia' })
            //     return;
            // }

            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando mercancia. Espere...', messageColor: 'white' })

            try {
                let response = await axios.post('CatalogosComprobantes/PostMercancia/erp_' + this.token.rfc, this.objetoStore)
                console.log(response)

                this.objetoStore._id = response.data
                this.$store.state.listaMercanciasStore.push(this.objetoStore)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `La mercancia ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        async actualizar() {
            console.log(this.objetoStore)
            if (this.objetoStore.materialPeligroso == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique si la mercancia es considerada peligrosa' })
                return;
            }
            if (this.objetoStore.materialPeligroso == 'Sí') {
                if (this.objetoStore.cveMaterialPeligroso.materialPeligroso == '') {
                    this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la clave del material peligroso' })
                    return;
                }
            }
            if (this.objetoStore.bienesTransp == null) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique los bienes a transportar' })
                return;
            }
            if (this.objetoStore.descripcion == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la descripción de la mercancia' })
                return;
            }
            if (this.objetoStore.claveUnidad == null) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la clave de unidad' })
                return;
            }

            if (this.objetoStore.unidad == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la unidad' })
                return;
            }

            if (this.objetoStore.tipoMateria == null) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el tipo de materia' })
                return;
            }

            if (this.objetoStore.embalaje == null) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el tipo de embalaje' })
                return;
            }

            if (this.objetoStore.descripEmbalaje == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la descripción del ebalaje' })
                return;
            }

            // if (this.objetoStore.pesoEnKg == 0 || this.objetoStore.pesoEnKg == '') {
            //     this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el peso de la mercancia en KG' })
            //     return;
            // }

            // if (this.objetoStore.dimensiones == '') {
            //     this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique las dimensiones de la mercancia' })
            //     return;
            // }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Actualizando mercancia. Espere...', messageColor: 'white' })

            try {
                let response = await axios.put('CatalogosComprobantes/PutMercancia/erp_' + this.token.rfc, this.objetoStore)
                console.log(response)

                let indice = this.$store.state.listaMercanciasStore.findIndex(x => x._id === this.objetoStore._id);
                Object.assign(this.$store.state.listaMercanciasStore[indice], this.objetoStore)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `La mercancia ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        agregarDocumento() {
            if (this.documentoAduanero.tipoDocumento.documentoAduanero == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el tipo de documento' })
                return;
            }

            if (this.documentoAduanero.numPedimento == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique le número de pedimento' })
                return;
            }
            if (this.documentoAduanero.rfcImpo == 0) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el RFC' })
                return;
            }
            this.$store.state.mercanciaStore.documentacionAduanera.push(this.documentoAduanero);

            this.documentoAduanero = {
                tipoDocumento: {
                    documentoAduanero: ''
                },
                rfcImpo: '',
                numPedimento: ''
            }
        },
        eliminarDocumento(data) {
            let editedIndex = this.$store.state.mercanciaStore.documentacionAduanera.indexOf(data)
            this.$store.state.mercanciaStore.documentacionAduanera.splice(editedIndex, 1)
        },
    }
}
</script>
<style></style>