<template>
    <div>
        <div class="row q-col-gutter-sm">
            <q-uploader :factory="factoryFn" class="full-width" accept=".pdf"
                label="Seleccione el archivo a subir y de click en la nube" ref="uploader" />
        </div>
        <q-table title="Archivos" :data="dataArchivos" :columns="columns" row-key="_id" :rows-per-page-options="[3]"
            class="my-sticky-column-table">
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width>
                        <q-btn size="md" color="red-10" rounded flat dense @click="DeleteArchivo(props.row)"
                            icon="mdi-delete">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Eliminar</q-tooltip>
                        </q-btn>
                    </q-td>
                    <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                    <q-td key="tipo" :props="props">{{ props.row.tipo }}</q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>
<script>
import axios from 'axios'
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar';
import moment from 'moment';

export default {
    components: {

    },
    data() {
        return {
            columns: [
                { name: 'actions', align: 'left', label: 'Acciones', field: 'actions' },
                { name: 'nombre', align: 'left', label: 'Nombre', field: 'nombre', sortable: true },
                { name: 'tipo', align: 'left', label: 'Tipo', field: 'tipo', sortable: true },
            ],
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        dataArchivos() {
            return this.$store.state.addendaVw.archivos;
        },
    },
    created() {

    },
    methods: {
        async factoryFn(files) {
            //VALIDAMOS QUE NO SOBRE PASE 3 ARCHIVOS
            if (this.dataArchivos.length <= 2) {

            } else {
                this.$q.notify({
                    type: 'warning',
                    message: `Sólo se pueden cargar un máximo de 3 documentos.`,
                    actions: [
                        { label: 'Cerrar', color: 'White', handler: () => { } }
                    ]
                })
                return;
            }
            for (let d of files) {
                // console.log(d);

                let selectArchivo = {
                    nombre: "",
                    base64: "",
                    tipo: "",
                }
                const file = d;
                const fileName = file.name;
                let extension = fileName.split('.').pop();
                try {
                    const result = await this.convertBase64(file);
                    selectArchivo.nombre = fileName;
                    selectArchivo.datos = result.split(',')[1];
                    selectArchivo.tipo = extension;
                    console.log(selectArchivo);
                    //CARGAMOS EL ARCHIVO
                    this.dataArchivos.push(selectArchivo);
                    // selectArchivo = {};
                } catch (error) {
                    console.error(error);
                    return;
                }
            }
            // this.$nextTick(() => {
            //     this.$refs.uploader.reset();
            // });
        },

        convertBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
            });
        },

        DeleteArchivo(item) {
            let lista = this.$store.state.addendaVw.archivos;
            let indice = lista.findIndex(obj => obj.nombre === item.nombre);
            if (indice !== -1) {
                lista.splice(indice, 1);
            }
        },
    },
}
</script>