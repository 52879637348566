<template>
    <div class="q-pa-md">

        <!-- DIALOG PARA HACER UNA ORDEN DE COMPRAS -->
        <q-dialog v-model="dialogOrdenCompras" maximized transition-show="slide-up" transition-hide="slide-down">
            <OrdenCompras @Close="dialogOrdenCompras = false"></OrdenCompras>
        </q-dialog>

        <!-- DIALOGO DE CONFIRMACION -->
        <q-dialog v-model="dialogConfirm" persistent>
            <q-card style="width: 600px;">
                <q-card-section class="row items-center">
                    <q-avatar icon="mdi-delete-alert" color="primary" text-color="white" />
                    <span class="text-h5 q-ml-md">Estas seguro de eliminar la orden de compra.?</span>
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat label="Aceptar" color="primary" @click="eliminarOrdenCompra()" />
                    <q-btn flat label="Cerrar" color="primary" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- SELECCIONA AÑO Y MES, BOTON DE BUSCAR Y EXPORTAR A EXCEL -->
        <div class="row no-wrap items-center q-mt-md q-pa-sm">
            <div class="text-h5">Orden de Compras</div>
            <q-space />
            <q-input v-model="fehaIMasked" label="Fecha Inicial" class="q-mr-sm" name="event" filled dense>
                <template v-slot:before>
                    <q-icon name="event" />
                </template>
                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="fechaI" @input="UltimoDiaMes">
                        <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Ok" color="primary" flat />
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-input>

            <q-input v-model="fechaFMasked" label="Fecha Final" class="q-mr-xs" filled dense>
                <template v-slot:before>
                    <q-icon name="event" />
                </template>
                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="fechaF">
                        <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Ok" color="primary" flat />
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-input>
            <q-btn push color="amber-9" icon="mdi-text-box-search-outline" rounded flat size="18px" padding="xs"
                @click="getOrdenCompras()">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Consultar</q-tooltip>
            </q-btn>
            <q-btn push color="green-14" @click="nuevaOrden()" icon="mdi-plus-circle" rounded flat size="18px"
                padding="xs">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Nueva Órden de Compra</q-tooltip>
            </q-btn>
            <q-btn push color="green-10" @click="ExportExcel()" icon="mdi-file-excel-box-outline" rounded flat
                size="18px" padding="xs">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Exportar Excel</q-tooltip>
            </q-btn>
        </div>

        <!-- TABLA DE COMPROBANTES -->
        <q-table title="Órden de compras" :data="dataOrdenes" :columns="columns" row-key="rfc"
            :rows-per-page-options="[10]" :filter="filter" class="my-sticky-column-table shadow-0" :loading="loading">
            <template v-slot:top>
                <q-space />
                <q-input borderless dense debounce="300" v-model="filter" placeholder="Filtrar">
                    <template v-slot:append>
                        <q-icon name="mdi-magnify" />
                    </template>
                </q-input>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width>
                        <q-btn size="md" color="red-10" rounded flat dense @click="VerPdf(props.row)"
                            icon="mdi-file-pdf-box">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Ver PDF</q-tooltip>
                        </q-btn>
                        <q-btn size="md" color="amber-10" rounded flat dense @click="Similar(props.row)"
                            icon="mdi-reload">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Similar</q-tooltip>
                        </q-btn>
                        <q-btn v-if="props.row.estatus != 'OrdenCompra'" size="md" color="red" rounded flat dense
                            @click="eliminar(props.row)" icon="mdi-delete">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Eliminar</q-tooltip>
                        </q-btn>
                        <template>
                            <q-btn v-if="props.row.estatus == 'Nuevo'" size="md" color="yellow-10" rounded flat dense
                                @click="Editar(props.row)" icon="mdi-pencil">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px" :offset="[10, 10]">Editar</q-tooltip>
                            </q-btn>
                        </template>
                    </q-td>
                    <q-td key="serie" :props="props">{{ props.row.serie }}</q-td>
                    <q-td key="folio" :props="props">
                        <q-badge :class="props.row.tipoComprobanteInterno + '-' + props.row.estatus" rounded>
                            {{ props.row.folio }}
                        </q-badge>
                    </q-td>
                    <q-td key="fecha" :props="props">{{ FormatDate(props.row.fecha) }}</q-td>
                    <q-td key="fechaEntrega" :props="props">{{ FormatDate(props.row.fechaEntrega) }}</q-td>
                    <q-td key="solicitante" :props="props">{{ props.row.solicitante }}</q-td>
                    <q-td key="proveedor" :props="props">{{ props.row.proveedor.proveedor }}</q-td>
                    <q-td key="proyecto" :props="props">{{ props.row.proyecto.proyecto }}</q-td>
                    <q-td key="subTotal" :props="props">{{ FormatCurrency(props.row.subTotal) }}</q-td>
                    <q-td key="total" :props="props">{{ FormatCurrency(props.row.total) }}</q-td>
                </q-tr>
            </template>
            <template v-slot:bottom>
                <q-badge rounded color="blue" class="text-subtitle2 q-mr-sm" label="Nuevo" />
                <q-badge rounded color="red" class="text-subtitle2  q-mr-sm" label="Cancelado" />
                <q-badge rounded color="green" class="text-subtitle2  q-mr-sm" label="Autorizado" />
                <q-badge rounded color="purple" class="text-subtitle2" label="Orden Compra" />
            </template>
        </q-table>
    </div>
</template>
<script>
import axios from 'axios'
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar';
import moment from 'moment';
import OrdenCompras from './OrdenCompra.vue'

export default {
    components: {
        OrdenCompras
    },
    data() {
        return {
            fechaI: new Date(),
            fechaF: new Date(),
            filter: '',
            columns: [
                { name: 'actions', align: 'left', label: 'Acciones', field: 'actions' },
                { name: 'serie', align: 'left', label: 'Serie', field: 'serie', sortable: true },
                { name: 'folio', align: 'left', label: 'Folio', field: 'folio', sortable: true },
                { name: 'fecha', align: 'left', label: 'Fecha', field: 'fecha', sortable: true },
                { name: 'fechaEntrega', align: 'left', label: 'Fecha de Entrega', field: 'fechaEntrega', sortable: true },
                { name: 'solicitante', align: 'left', label: 'Solicitante', field: 'solicitante', sortable: true },
                { name: 'proveedor', align: 'left', label: 'Proveedor', field: 'proveedor', sortable: true },
                { name: 'proyecto', align: 'left', label: 'Proyecto', field: 'proyecto', sortable: true },
                { name: 'subTotal', align: 'right', label: 'SubTotal', field: 'subTotal', sortable: true },
                { name: 'total', align: 'right', label: 'Total', field: 'total', sortable: true },
            ],
            dialogOrdenCompras: false,
            loading: false,
            dialogConfirm: false,

        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        fehaIMasked() {
            moment.locale('es-mx');
            return this.fechaI ? moment(this.fechaI).format('DD/MMMM/yyyy') : ''
        },

        fechaFMasked() {
            moment.locale('es-mx');
            return this.fechaF ? moment(this.fechaF).format('DD/MMMM/yyyy') : ''
        },
        dataOrdenes() {
            return this.$store.state.listaOrdenComprasStore
        },
        ordenCompra() {
            return this.$store.state.ordenCompraStore
        }
    },
    created() {
    },
    methods: {
        nuevaOrden() {

            let objeto = {
                _id: '',
                serie: '',
                folio: '',
                fecha: new Date().toISOString(),
                tiempoEntrega: new Date().toISOString(),
                solicitante: '',
                proveedor: { proveedor: '' },
                notas: '',
                proyecto: { proyecto: '' },
                subTotal: 0,
                total: 0,
                tipoComprobanteInterno: 'ORDENCOMPRA',
                conceptos: [],
                impuestos: {
                    totalImpuestosRetenidos: 0,
                    totalImpuestosTrasladados: 0,
                    traslados: [],
                    retenciones: [],
                },
                cotizacion: '',
                estatus: '',
                usuarioCreador: '',
                usuarioModificador: '',
            };
            this.GetSerie();
            this.$store.state.ordenCompraStore = { ...objeto };
            this.dialogOrdenCompras = true
        },

        async getOrdenCompras() {
            this.loading = true
            let fechaI_ = new Date(this.fechaI);
            let fechaF_ = new Date(this.fechaF);
            let fechaI = format(fechaI_, "yyyy-MM-dd");
            let fechaF = format(fechaF_, "yyyy-MM-dd");
            try {
                let response = await axios.get("Compras/GetOrdenComprasAsync/erp_" + this.token.rfc + '/' + fechaI + '/' + fechaF);
                let catalogo = response.data;
                this.$store.state.listaOrdenComprasStore = catalogo;
                this.loading = false
            } catch (error) {
                this.loading = false
                console.log(error);
            }
        },

        async eliminarOrdenCompra() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Eliminando orden de compra. Espere...', messageColor: 'white' })
            let ordenCompra = this.$store.state.ordenCompraStore

            try {
                let response = await axios.put('Compras/DeleteOrdenComprasAsync/erp_' + this.token.rfc + '/Eliminado/' + ordenCompra._id + '/' + this.token.nombre)
                console.log(response)
                // SI LA ACCION ES ELIMINAR, LO QUITAMOS DE LA LISTA
                let indice = this.dataOrdenes.indexOf(ordenCompra)
                this.dataOrdenes.splice(indice, 1)
                this.dialogConfirm = false
                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `La orden de compra ha sido eliminado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error, intentelo mas tarde.', color: 'red' })
            }
        },

        ExportExcel() { },
        VerPdf() { },

        Similar(item) {
            console.log(item)

            const x = { ...item }
            x._idCotizacion = '';
            x._id = '';
            x.estatus = 'Nuevo';
            x.folio = '';
            x.fecha = new Date().toISOString();
            x.fechaEntrega = new Date().toISOString();
            this.$store.state.ordenCompraStore = x;
            this.dialogOrdenCompras = true;
        },

        Editar(data) {
            this.$store.state.ordenCompraStore = { ...data }
            this.dialogOrdenCompras = true
        },

        eliminar(item) {
            console.log(item)
            this.dialogConfirm = true
            this.$store.state.ordenCompraStore = item
        },

        async GetSerie() {
            try {
                let response = await axios.get("Compras/GetSerieComprasAsync/" + this.token.empresaBase + '/comp_orden_compra/ORDENCOMPRA');
                let x = response.data;
                this.$store.state.serieStore = [...x]
            } catch (error) {
                console.log(error)
            }
        },

        UltimoDiaMes() {
            let fechaI = this.fechaI;
            fechaI = fechaI.replaceAll('/', '-');
            const fecha = parse(fechaI, 'yyyy-MM-dd', new Date());
            const ultimoDiaDelMes = endOfMonth(fecha);
            this.fechaF = ultimoDiaDelMes;
        },

        FormatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        FormatDate(value) {
            let fecha = new Date(value);
            let dia = fecha.getDate();
            let mes = fecha.toLocaleString('es-ES', { month: 'long' }); // Obtener el mes en formato de letra
            let anio = fecha.getFullYear();
            let formatoDeseado = `${dia}-${mes}-${anio}`;
            return formatoDeseado;
        },
    },
}
</script>
<style scoped>
.ORDENCOMPRA-Nuevo {
    background-color: #2196f3;
    padding: 10px;
    font-size: 15px
}

.ORDENCOMPRA-OrdenCompra {
    background-color: #9c27b0;
    padding: 10px
}

.ORDENCOMPRA-Cancelado {
    background-color: #f44336;
    padding: 10px
}

.ORDENCOMPRA-Autorizado {
    background-color: #4caf50;
    padding: 10px
}
</style>
<style lang="sass">
    .my-sticky-column-table
    
      thead tr:first-child th:first-child
        background-color: #ffffff
    
      td:first-child
        background-color: #ffffff
    
      th:first-child,
      td:first-child
        position: sticky
        left: 0
        z-index: 1
    </style>