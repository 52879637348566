<template>
    <div class="q-ml-xl q-mr-lg">
        <div class="text-center q-my-xl">
            <img class="logo-home" alt="Contago logo" src="../../assets/logo_contago_sin_fondo.png">
        </div>

        <div class="row	flex-center q-pr-xl q-pl-xl q-pt-xl">
            <div class="col-12 col-md-2 col-sm-6 text-center q-pb-xl">
                <span class="q-pa-sm q-py-lg  shadow-6 mb-3 inline-block surface-card"
                    style="background: #FF931E; border-radius: 10px; cursor: pointer; width: 160px;"
                    @click="irFactura()">
                    <q-icon name="mdi-file-document-check-outline" style="color: aliceblue;font-size: 4em;" />
                    <div class="text-h6  q-mt-sm text-weight-bolder" style="color: aliceblue;">Factura</div>
                </span>
            </div>

            <div class="col-12 col-md-2 col-sm-6 text-center q-pb-xl">
                <span class="q-pa-sm q-py-lg  shadow-6 mb-3 inline-block surface-card"
                    style="background: #FF931E; border-radius: 10px; cursor: pointer; width: 160px;"
                    @click="irNotaCredito()">
                    <q-icon name="mdi-file-document-minus-outline" style="color: aliceblue;font-size: 4em;" />
                    <div class="text-h6  q-mt-sm text-weight-bolder" style="color: aliceblue;">Nota de crédito</div>
                </span>
            </div>

            <div class="col-12 col-md-2 col-sm-6 text-center q-pb-xl">
                <span class="q-pa-sm q-py-lg  shadow-6 mb-3 inline-block surface-card"
                    style="background: #FF931E; border-radius: 10px; cursor: pointer; width: 160px;"
                    @click="irIngresoCartaPorte()">
                    <q-icon name="mdi-truck-cargo-container" style="color: aliceblue;font-size: 4em;" />
                    <div class="text-h6  q-mt-sm text-weight-bolder" style="color: aliceblue;">Carta Porte</div>
                </span>
            </div>

            <div class="col-12 col-md-2 col-sm-6 text-center q-pb-xl">
                <span class="q-pa-sm q-pt-md shadow-6 mb-3 inline-block surface-card"
                    style="background: #FF931E; border-radius: 10px; cursor: pointer; width: 160px;"
                    @click="irComercioExterior()">
                    <q-icon name="mdi-earth" style="color: aliceblue;font-size: 4em;" />
                    <div class="text-h6  text-weight-bolder" style="color: aliceblue;">Comercio Exterior</div>
                </span>
            </div>

            <div class="col-12 col-md-2 col-sm-6 text-center q-pb-xl">
                <span class="q-pa-sm q-pt-md shadow-6 mb-3 inline-block surface-card"
                    style="background: #FF931E; border-radius: 10px; cursor: pointer; width: 160px;"
                    @click="irComplementoPago()">
                    <q-icon name="mdi-cash-check" style="color: aliceblue;font-size: 4em;" />
                    <div class="text-h6  text-weight-bolder" style="color: aliceblue;">Complemento de Pago</div>
                </span>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {

        };
    },
    methods: {
        irFactura(){
            this.$router.push({ name: 'ListFacturacion' });
        },

        irNotaCredito(){
            this.$router.push({ name: 'ListNotaCredito' });
        },

        irIngresoCartaPorte() {
            this.$router.push({ name: 'ListCartaPorte' });
        },

        irComercioExterior() {
            this.$router.push({ name: 'ListComercioExterior' });
        },

        irComplementoPago() {
            this.$router.push({ name: 'ListaComplementoPago' });
        }
    }
};
</script>
  
<style >
.logo-home {
    max-width: 30%;
    height: auto;
}

.main-menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
}

.menu-option {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>