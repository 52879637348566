<template>
    <q-card style="width: 1200px; max-width: 80vw; max-height: 90vw;">
        <q-card-section>
            <q-toolbar>
                <q-btn push color="red-9" @click="cierraVentana" icon="mdi-close-circle-outline" rounded flat
                    size="18px" padding="xs">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Cerrar</q-tooltip>
                </q-btn>
                <q-toolbar-title>
                    Cliente
                </q-toolbar-title>
                <q-btn v-if="cliente._id == ''" push color="green-9" @click="PostCliente" icon="mdi-content-save"
                    rounded flat size="18px" padding="xs">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Guardar</q-tooltip>
                </q-btn>
                <q-btn v-else push color="orange-9" @click="PutCliente" icon="mdi-content-save" rounded flat size="18px"
                    padding="xs">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Editar</q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />

        </q-card-section>
        <q-card-section>
            <div class="row  q-col-gutter-sm">
                <!-- NOMBRE -->
                <div class="col-12">
                    <q-input dense v-model="cliente.nombre" class="full-width" outlined label="Nombre"
                        @input="convertirAMayusculas" />
                </div>
                <!-- RFC -->
                <div class="col-12 col-md-6">
                    <q-input dense v-model="cliente.rfc" class="full-width " outlined label="RFC"
                        error-message="No cumple con los caracterer de un RFC" @input="convertirAMayusculasRfc" />
                </div>
                <!-- DOMICILIO FISCAL -->
                <div class="col-12 col-md-6">
                    <q-input dense v-model="cliente.domicilioFiscalReceptor" class="full-width" outlined
                        label="Domicilio Fiscal" />
                </div>
                <!-- NUM REG ID TRIB EN CASO DE SER EXTRANJERO -->
                <div class="col-12 col-md-6" v-show="cliente.rfc == 'XEXX010101000'">
                    <q-input dense v-model="cliente.numRegIdTrib" class="full-width" outlined
                        label="Número de Registro de Identidad Tributaria" />
                </div>
                <!-- RESIDENCIA FISCAL, EN CASO DE SER EXRANJERO -->
                <div class="col-12 col-md-6" v-show="cliente.rfc == 'XEXX010101000'">
                    <q-select dense class="full-width" outlined v-model="cliente.residenciaFiscal"
                        :options="residenciaFiscal" label="Residencia Fiscal" option-label="pais" />
                </div>
                <!-- REGIMEN FISCAL -->
                <template v-if="ValidaRegimen != ''">
                    <div class="col-12">
                        <q-select dense class="full-width" outlined fill-input v-model="cliente.regimenFiscalReceptor"
                            :options="regimenFiscal" label="Régimen Fiscal" option-label="regimenFiscal" />
                    </div>
                </template>
                <!-- USO DEL CFDI -->
                <template v-if="ValidaUsoCfdi">
                    <div class="col-12">
                        <q-select dense class="full-width" outlined fill-input v-model="cliente.usoCfdi"
                            :options="usoCfdi" label="Uso del CFDI" option-label="usoCfdi" />
                    </div>
                </template>
                <!-- DIRECCION -->
                <div class="col-12">
                    <q-input dense v-model="cliente.direccion" class="full-width" outlined label="Dirección" />
                </div>
                <div class="col-12">
                    <q-expansion-item header-class="bg-grey-3" class="full-width" v-model="otrasCaracteristicas"
                        icon="mdi-email-multiple" label="Agregar Correos">
                        <div class="row q-mt-sm  q-col-gutter-sm">
                            <div class="col-12 col-md-6">
                                <q-input dense v-model="nombreEmisor" class="full-width" outlined
                                    label="Nombre del Emisor" />
                            </div>
                            <div class="col-12 col-md-6">
                                <q-input dense v-model="correo" class="full-width" outlined label="Correo Electronico">
                                    <template v-slot:after>
                                        <q-btn round color="green" icon="mdi-plus" @click="pushCorreo()" />
                                    </template>
                                </q-input>
                            </div>
                            <div class="col-12">
                                <q-table class="shadow-0" dense :data="cliente.correos" :columns="columns"
                                    row-key="name">
                                    <template v-slot:body="props">
                                        <q-tr :props="props">
                                            <q-td key="nombreEmisor" :props="props">{{ props.row.nombreEmisor }}</q-td>
                                            <q-td key="correo" :props="props">{{ props.row.correo }}</q-td>
                                            <q-td auto-width>
                                                <q-btn size="md" color="red" rounded flat dense
                                                    @click="eliminarCorreo(props.row)" icon="mdi-delete">
                                                    <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                                        content-style="font-size: 14px">Eliminar</q-tooltip>
                                                </q-btn>
                                            </q-td>
                                        </q-tr>
                                    </template>
                                </q-table>
                            </div>

                        </div>

                    </q-expansion-item>
                </div>

            </div>

        </q-card-section>
    </q-card>
</template>
<script>
import axios from 'axios';
import { QSpinnerCube } from 'quasar'
export default {
    data() {
        return {
            regimenFiscal: [],
            usoCfdi: [],

            columns: [
                { name: 'nombreEmisor', label: 'Nombre del Emisor', field: 'nombreEmisor', align: 'center' },
                { name: 'correo', label: 'Correo Electrinico', field: 'correo', align: 'center' },
            ],
            otrasCaracteristicas: false,
            nombreEmisor: '',
            correo: '',
            loading: true
        }
    },
    created() {
        this.iniciaizar()
    },
    computed: {
        cliente() {
            return this.$store.state.clienteStore;
        },

        ValidaRegimen() {
            let rfc = this.$store.state.clienteStore.rfc
            // this.$store.state.clienteStore.regimenFiscalReceptor = null;
            if (rfc.length === 12) {
                //VALIDAMOS EL REGIMEN FISCAL
                this.regimenFiscal = [];
                let filtroRegimen = this.$store.state.regimenFiscalStore.filter(f => f.moral === 'Sí');
                this.regimenFiscal = [...filtroRegimen]
                return 'M';
            } else if (rfc.length === 13) {
                //VALIDAMOS EL REGIMEN FISCAL
                this.regimenFiscal = [];
                let filtroRegimen = this.$store.state.regimenFiscalStore.filter(f => f.fisica === 'Sí');
                this.regimenFiscal = [...filtroRegimen]
                if (rfc === 'XAXX010101000' || rfc === 'XEXX010101000') {
                    let b = filtroRegimen.find(f => f.clave === '616');
                    this.$store.state.clienteStore.regimenFiscalReceptor = b;
                }
                return 'F';
            }
            return "";
        },

        ValidaUsoCfdi() {
            // this.$store.state.clienteStore.usoCfdi = null;
            let rfc = this.$store.state.clienteStore.rfc
            let regimen = this.$store.state.clienteStore.regimenFiscalReceptor
            if (regimen) {
                if (rfc.length >= 12) {
                    this.usoCfdi = [];
                    let filtroUsoCfdi = this.$store.state.usoCfdiStore.filter(f => f.regimen_fiscal.includes(regimen.clave));
                    this.usoCfdi = [...filtroUsoCfdi];
                    if (rfc === 'XAXX010101000' || rfc === 'XEXX010101000') {
                        let b = filtroUsoCfdi.find(f => f.clave === 'S01');
                        this.$store.state.clienteStore.usoCfdi = b;
                    }
                    return true;
                }
            }
            return false;
        },

        regimenFiscalDefault() {
            return this.$store.state.regimenFiscalStore;
        },

        usoCfdiDefault() {
            return this.$store.state.usoCfdiStore;
        },

        residenciaFiscal() {
            return this.$store.state.listaCatalogoPais;
        },

        token() {
            return this.$store.state.usuario;
        },
    },

    methods: {
        async iniciaizar() {
            this.$q.loading.show({ group: 'first', message: 'Consultando los Regimenes Fiscales', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetRegimenFiscal();
            this.$q.loading.show({ group: 'second', message: 'Consultando el Uso del CFDI', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetUsoCfdi();
            this.$q.loading.show({ group: 'first', message: 'Consultando las Residencias Fiscales', spinnerColor: 'amber', messageColor: 'amber' })
            await this.GetResidenciaFiscal();
            this.$q.loading.hide()
        },

        async GetRegimenFiscal() {
            if (this.$store.state.regimenFiscalStore.length == 0) {
                try {
                    let response = await axios.get("CatalogosSat/GetCatRegimenFiscalAsync/");
                    this.$store.state.regimenFiscalStore = response.data;
                } catch (error) {
                    console.log(error);
                }
            }
        },

        async GetUsoCfdi() {
            if (this.$store.state.usoCfdiStore.length == 0) {
                try {
                    let response = await axios.get("CatalogosSat/GetCatUsoCfdiAsync");
                    this.$store.state.usoCfdiStore = response.data;
                } catch (error) {
                    console.log(error);
                }
            }
        },

        async GetResidenciaFiscal() {
            if (this.$store.state.listaCatalogoPais.length == 0) {
                try {
                    let response = await axios.get("CatalogosSat/GetCatPaisAsync");
                    this.$store.state.listaCatalogoPais = response.data;
                } catch (error) {
                    console.log(error);
                }
            }
        },

        async PostCliente() {
            //VALIDAMOS
            const rfc = this.cliente.rfc.trim();
            const nombre = this.cliente.nombre.trim();
            const domicilioFiscalReceptor = this.cliente.domicilioFiscalReceptor.trim()
            const residenciaFiscal = this.cliente.residenciaFiscal;
            const numRegIdTrib = this.cliente.numRegIdTrib.trim();
            const regimenFiscalReceptor = this.cliente.regimenFiscalReceptor;
            const usoCfdi = this.cliente.usoCfdi;
            const direccion = this.cliente.direccion;

            if (rfc === "") {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el RFC`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (nombre === "") {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el nombre`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (domicilioFiscalReceptor === "") {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el domicilio fiscal (Código Postal)`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (!regimenFiscalReceptor) {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el régimen fiscal`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (!usoCfdi) {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el uso del CFDI`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            let receptor = {
                _id: '',
                rfc: rfc,
                nombre: nombre,
                domicilioFiscalReceptor: domicilioFiscalReceptor,
                residenciaFiscal: residenciaFiscal,
                numRegIdTrib: numRegIdTrib,
                regimenFiscalReceptor: regimenFiscalReceptor,
                usoCfdi: usoCfdi,
                direccion: direccion,
                receptor: '',
            }

            console.log(receptor)
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'yellow', spinnerSize: 140, message: 'Guardando cliente. Espere...', messageColor: 'yellow' })
            try {
                let response = await axios.post('CatalogosComprobantes/PostReceptorAsync/' + this.token.empresaBase, this.cliente)

                receptor._id = response.data
                this.$store.state.listClienteStore.push(receptor)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `Cliente ha sido guardado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        async PutCliente() {
            //VALIDAMOS
            const _id = this.cliente._id
            const rfc = this.cliente.rfc.trim();
            const nombre = this.cliente.nombre.trim();
            const domicilioFiscalReceptor = this.cliente.domicilioFiscalReceptor.trim()
            const residenciaFiscal = this.cliente.residenciaFiscal;
            const numRegIdTrib = this.cliente.numRegIdTrib.trim();
            const regimenFiscalReceptor = this.cliente.regimenFiscalReceptor;
            const usoCfdi = this.cliente.usoCfdi;
            const direccion = this.cliente.direccion;

            if (rfc === "") {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el RFC`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (nombre === "") {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el nombre`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (domicilioFiscalReceptor === "") {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el domicilio fiscal (Código Postal)`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (!regimenFiscalReceptor) {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el régimen fiscal`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (!usoCfdi) {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el uso del CFDI`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            let receptor = {
                _id: _id,
                rfc: rfc,
                nombre: nombre,
                domicilioFiscalReceptor: domicilioFiscalReceptor,
                residenciaFiscal: residenciaFiscal,
                numRegIdTrib: numRegIdTrib,
                regimenFiscalReceptor: regimenFiscalReceptor,
                usoCfdi: usoCfdi,
                direccion: direccion,
                receptor: '',
            }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'yellow', spinnerSize: 140, message: 'Guardando cliente. Espere...', messageColor: 'yellow' })
            try {
                let response = await axios.put('CatalogosComprobantes/PutReceptorAsync/' + this.token.empresaBase, this.cliente)

                //BUSCAMOS EL INDICE Y SUSTITUIMOS
                let indice = this.$store.state.listClienteStore.findIndex(f => f._id === _id)
                Object.assign(this.$store.state.listClienteStore[indice], receptor)

                // this.$store.state.listClienteStore.push(receptor)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `Cliente editado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al editar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        pushCorreo() {
            let objeto = {
                nombreEmisor: this.nombreEmisor,
                correo: this.correo
            }
            this.cliente.correos.push(objeto)

            this.nombreEmisor = ''
            this.correo = ''
        },

        eliminarCorreo(data) {
            let editedIndexCorreo = this.cliente.correos.indexOf(data)
            this.cliente.correos.splice(editedIndexCorreo, 1)
        },

        cierraVentana() {
            this.$emit('cierraVentana')
        },

        convertirAMayusculas() {
            this.cliente.nombre = this.cliente.nombre.toUpperCase();
        },

        convertirAMayusculasRfc() {
            this.cliente.rfc = this.cliente.rfc.toUpperCase();
        }
    }
}
</script>