<template>
    <div class="q-pa-md q-gutter-sm">
        <!-- DIALOG PARA HACER UNA NUEVA FACTURA -->
        <q-dialog v-model="dialogComprobante" maximized transition-show="slide-up" transition-hide="slide-down"
            persistent>
            <comprobante @CloseComprobante="CierraDialog"></comprobante>
        </q-dialog>

        <q-card class="my-card">
            <q-toolbar>
                <q-btn push color="red-10" icon="mdi-close-circle-outline" round flat size="18px" padding="xs"
                    v-close-popup>
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Cerrar</q-tooltip>
                </q-btn>
                <q-toolbar-title>
                    BUSCAR REMISIÓN
                </q-toolbar-title>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
            <q-card-section>
                <div class="text-h5">Seleccione cliente</div>
                <div class="col-12">
                    <q-select v-model="selectCliente" use-input input-debounce="0" label="Cliente"
                        :options="optionsClientes" @filter="filterCliente" fill-input hide-selected
                        :loading="loadingClientes" dense outlined option-label="receptor">
                        <template v-slot:no-option>
                            <q-item>
                                <q-item-section class="text-grey">
                                    No exixte algún cliente que coincida con los criterios
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-select>
                </div>
                <div class="col-12">
                    <q-btn color="green-10" label="Buscar" class="my-card" @click="getRemisiones" />
                </div>

                <!-- REMISIPNES -->
                <div class="col-12">
                    <q-table title="REMISIONES" :data="remisiones" :columns="columnsRemisiones" row-key="id"
                        :rows-per-page-options="[10]" class="my-sticky-column-table" dense selection="single"
                        :selected.sync="remisionSelected">

                        <template v-slot:top-right>
                            <q-btn push color="white" text-color="green-10" round icon="mdi-file-document-check-outline"
                                @click="facturarRemision">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px" :offset="[10, 10]">Facturar</q-tooltip>
                            </q-btn>
                        </template>

                        <template v-slot:body="props">
                            <q-tr :props="props">
                                <q-td auto-width><q-checkbox v-model="props.selected"></q-checkbox></q-td>
                                <q-td key="serie" :props="props">{{ props.row.serie }}</q-td>
                                <q-td key="folio" :props="props">{{ props.row.folio }}</q-td>
                                <q-td key="fecha" :props="props">{{ props.row.fecha }}</q-td>
                                <q-td key="subTotal" :props="props">{{ FormatCurrency(props.row.subTotal) }}</q-td>
                                <q-td key="descuento" :props="props">{{ FormatCurrency(props.row.descuento) }}</q-td>
                                <q-td key="total" :props="props">{{ FormatCurrency(props.row.total) }}</q-td>
                                <q-td key="moneda" :props="props">{{ props.row.moneda }}</q-td>
                                <q-td key="ordenCompra" :props="props">{{ props.row.ordenCompra }}</q-td>
                                <q-td key="estatus" :props="props">{{ props.row.estatus }}</q-td>
                            </q-tr>
                        </template>
                    </q-table>
                </div>
            </q-card-section>
        </q-card>
    </div>
</template>

<script>
import axios from 'axios'
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar';
import moment from 'moment';
import comprobante from '../Comprobantes/ComercioExterior/ComercioExterior.vue'

export default {
    components: {
        comprobante
    },
    data() {
        return {
            //CLIENTES
            loadingClientes: false,
            optionsClientes: [],
            selectCliente: null,

            //REMISIONES
            remisiones: [],
            columnsRemisiones: [
                { name: 'serie', align: 'left', label: 'Serie', field: 'serie', sortable: true },
                { name: 'folio', align: 'left', label: 'Nombre', field: 'folio', sortable: true },
                { name: 'fecha', align: 'left', label: 'Fecha', field: 'fecha', sortable: true },
                { name: 'subTotal', align: 'right', label: 'SubTotal', field: 'subTotal', sortable: true },
                { name: 'descuento', align: 'right', label: 'Descuento', field: 'descuento', sortable: true },
                { name: 'total', align: 'right', label: 'Total', field: 'total', sortable: true },
                { name: 'moneda', align: 'left', label: 'Moneda', field: 'moneda', sortable: true },
                { name: 'ordenCompra', align: 'left', label: 'OC', field: 'ordenCompra', sortable: true },
                { name: 'estatus', align: 'left', label: 'Estatus', field: 'estatus', sortable: true },
            ],
            remisionSelected: [],

            //COMPROBANTE
            dialogComprobante: false,
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        optionsClientesDefault() {
            return this.$store.state.listClienteStore
        },

        optionsUsoCfdi() {
            return this.$store.state.usoCfdiStore
        },

        optionsMetodoPago() {
            return this.$store.state.metodoPagoStore
        },

        optionsFormaPago() {
            return this.$store.state.formaPagoStore
        },

        optionsMoneda() {
            return this.$store.state.monedaStore
        },

        optionsClaveProdServ() {
            return this.$store.state.claveProdServStore
        },

        optionsClaveUnidad() {
            return this.$store.state.claveUnidadStore
        },

        optionsObjImp() {
            return this.$store.state.objImpStore
        },

        optionsExportacion() {
            return this.$store.state.exportacionStore
        },
    },

    created() {
        this.GetCatalogos();
    },

    methods: {

        FormatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        async GetCatalogos() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando clientes / receptores...' });
            await this.getClientes();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando usos del CFDI...' });
            await this.GetUsoCfdi();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando métodos de pago...' });
            await this.GetMetodoPago();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando formas de pago...' });
            await this.GetFormaPago();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando monedas...' });
            await this.GetMoneda();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando claves del producto o servicio(SAT)...' });
            await this.GetClaveProdServ();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando unidades de medida(SAT)...' });
            await this.GetClaveUnidad();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando obetos de impuestos...' });
            await this.GetObjImp();
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando claves de exportacion...' });
            await this.GetExportacion();
            this.$q.loading.hide()
        },

        async getClientes() {
            try {
                let response = await axios.get("CatalogosComprobantes/GetReceptorAsync/" + this.token.empresaBase);
                this.$store.state.listClienteStore = response.data;
            } catch (error) {
                console.log(error);
            }
        },

        async GetUsoCfdi() {
            if (this.$store.state.usoCfdiStore.length != 0) {
                return;
            } else {
                try {
                    let response = await axios.get('CatalogosSat/GetCatUsoCfdiAsync')
                    let x = response.data;
                    this.$store.state.usoCfdiStore = [...x]
                } catch (error) {
                    console.log(error)
                }
            }
        },

        async GetMetodoPago() {
            if (this.$store.state.metodoPagoStore.length != 0) {
                return;
            } else {
                try {
                    let response = await axios.get('CatalogosSat/GetCatMetodoPagoAsync')
                    let x = response.data;
                    this.$store.state.metodoPagoStore = [...x]
                } catch (error) {
                    console.log(error)
                }
            }
        },

        async GetFormaPago() {
            if (this.$store.state.formaPagoStore.length != 0) {
                return;
            } else {
                try {
                    let response = await axios.get('CatalogosSat/GetCatFormaPagoAsync')
                    let x = response.data;
                    this.$store.state.formaPagoStore = [...x]
                } catch (error) {
                    console.log(error)
                }
            }
        },

        async GetMoneda() {
            if (this.$store.state.monedaStore.length != 0) {
                return;
            } else {
                try {
                    let response = await axios.get('CatalogosSat/GetCatMonedaAsync')
                    let x = response.data;
                    this.$store.state.monedaStore = [...x]
                } catch (error) {
                    console.log(error)
                }
            }
        },

        async GetClaveProdServ() {
            if (this.$store.state.claveProdServStore.length != 0) {
                return;
            } else {
                try {
                    let response = await axios.get('CatalogosSat/GetCatClaveProdServAsync')
                    let x = response.data;
                    this.$store.state.claveProdServStore = [...x]
                } catch (error) {
                    console.log(error)
                }
            }
        },

        async GetClaveUnidad() {
            if (this.$store.state.claveUnidadStore.length != 0) {
                return;
            } else {
                try {
                    let response = await axios.get('CatalogosSat/GetCatClaveUnidadAsync')
                    let x = response.data;
                    this.$store.state.claveUnidadStore = [...x]
                } catch (error) {
                    console.log(error)
                }
            }
        },

        async GetObjImp() {
            if (this.$store.state.objImpStore.length != 0) {
                return;
            } else {
                try {
                    let response = await axios.get('CatalogosSat/GetCatObjImpAsync')
                    let x = response.data;
                    this.$store.state.objImpStore = [...x]
                } catch (error) {
                    console.log(error)
                }
            }
        },

        async GetExportacion() {
            if (this.$store.state.exportacionStore.length == 0) {
                try {
                    let response = await axios.get('CatalogosSat/GetCatExportacionAsync')
                    let x = response.data;
                    this.$store.state.exportacionStore = [...x]
                } catch (error) {
                    console.log(error)
                }
            }
        },

        filterCliente(val, update) {
            let stringOptions = this.optionsClientesDefault
            if (val === '') {
                update(() => {
                    this.optionsClientes = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsClientes = stringOptions.filter(v => v.receptor.toLowerCase().indexOf(needle) > -1)
            })
        },

        async getRemisiones() {
            if (!this.selectCliente) {
                this.$q.notify({
                    type: 'warning',
                    message: `Seleccione un cliente`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            this.remisiones = [];
            this.$q.loading.show({ group: 'first', message: 'Consultando remisiones', spinnerColor: 'amber', messageColor: 'amber' });
            try {
                // let response = await axios.get('Protocolos/GetReimisionesAsync/erp_' + this.token.rfc + '/' + this.selectCliente.nombre);
                let response = await axios.get('Protocolos/GetReimisionesAsync/erp_dmm100728b66' + '/' + this.selectCliente.nombre);
                console.log(response.data);
                this.remisiones = [...response.data];
                this.$q.loading.hide();
            } catch (error) {
                console.log(error);
                this.$q.loading.hide();
            }
        },

        async facturarRemision() {
            this.$q.loading.show({ group: 'first', message: 'Preparando factura', spinnerColor: 'amber', messageColor: 'amber' });
            let id = this.remisionSelected[0].id
            try {
                // let response = await axios.get('Protocolos/GetReimisionesAsync/erp_' + this.token.rfc + '/' + this.selectCliente.nombre);
                let response = await axios.get('Protocolos/GetRemisionCeIdAsync/erp_dmm100728b66' + '/' + id);
                console.log(response.data);

                let pre = response.data;

                pre.formaPago = this.optionsFormaPago.find(f => f.clave === pre.formaPago.clave);
                pre.moneda = this.optionsMoneda.find(f => f.clave === pre.moneda.clave);
                pre.exportacion = this.optionsExportacion.find(f => f.clave === pre.exportacion.clave);
                pre.metodoPago = this.optionsMetodoPago.find(f => f.clave === pre.metodoPago.clave);
                pre.receptor = this.optionsClientesDefault.find(f => f.nombre === pre.receptor.nombre);

                pre.conceptos.forEach(e => {
                    e.objImp = this.optionsObjImp.find(f => f.clave === e.objImp.clave)
                });

                const comprobanteStore = {
                    titulo: 'Nuevo Comprobante de Comercio Exterior',
                    comprobante: pre,
                }
                this.$store.state.comprobanteStore = { ...comprobanteStore };
                this.dialogComprobante = true;

                this.$q.loading.hide();

            } catch (error) {
                console.log(error);
                this.$q.loading.hide();
            }
        },

        CierraDialog() {
            this.dialogComprobante = false;
        },
    },
}
</script>
<style>
.my-card {
    width: 100%;
}
</style>