<template>
    <q-page class=" q-pa-lg ">
        <q-dialog v-model="dialogNuevo" persistent transition-show="scale" transition-hide="scale">
            <FiguraDeTransporte @cierraVentana="dialogNuevo = false"></FiguraDeTransporte>
        </q-dialog>

        <!-- DIALOGO DE CONFIRMACION -->
        <q-dialog v-model="dialogConfirm" persistent>
            <q-card style="width: 600px;">
                <q-card-section class="row items-center">
                    <q-avatar icon="mdi-delete-alert" color="primary" text-color="white" />
                    <span class="text-h5 q-ml-md">Estás seguro de eliminar la figura de tranporte.?</span>
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat label="Aceptar" color="primary" @click="deleteItem()" />
                    <q-btn flat label="Cerrar" color="primary" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <div class="row	flex-left q-mb-md">
            <div class="col-12 col-md-6 col-sm-6 text-left ">
            </div>
            <div class="col-12 col-md-6 col-sm-6 text-right ">
                <q-btn round color="blue" class="q-mr-sm" icon="mdi-update" @click="GetItems()" />
                <q-btn round color="green" icon="mdi-plus" @click="nuevoElemento" />
            </div>
        </div>
        <div>
            <q-table class="shadow-0" title="Figuras de Transporte" :data="items" :columns="columns" row-key="_id"
                :filter="filter" :loading="loading" :pagination="pagination">
                <template v-slot:loading>
                    <q-inner-loading showing color="primary" />
                </template>
                <template v-slot:top-right>
                    <q-input filled borderless dense debounce="300" v-model="filter" placeholder="Buscar">
                        <template v-slot:append>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" color="red" rounded flat dense icon="mdi-delete"
                                @click="eliminar(props.row)">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                            <q-btn size="md" color="blue" rounded flat dense icon="mdi-pencil"
                                @click="editar(props.row)">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Editar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="tipoFigura" :props="props">{{ props.row.tipoFigura.tipoFigura
                            }}</q-td>
                        <q-td key="numLicencia" :props="props">{{ props.row.numLicencia }}</q-td>
                        <q-td key="rfcFigura" :props="props">{{ props.row.rfcFigura }}</q-td>
                        <q-td key="nombreFigura" :props="props">{{ props.row.nombreFigura }}</q-td>
                        <!-- <q-td key="numRegIdTribFigura" :props="props">{{ props.row.numRegIdTribFigura }}</q-td> -->
                        <!-- <q-td key="residenciaFiscalFigura" :props="props">{{ props.row.residenciaFiscalFigura.pais }}</q-td> -->
                        <!-- <q-td key="partesTransporte" :props="props">{{ props.row.partesTransporte.parteTransporte }}</q-td> -->
                        <!-- <q-td key="domicilio" :props="props">{{ props.row.domicilio.domicilio }}</q-td> -->
                    </q-tr>
                </template>
            </q-table>
        </div>
    </q-page>
</template>
<script>

import moment from 'moment';
import { QSpinnerCube } from 'quasar'
import axios from 'axios'
import FiguraDeTransporte from './FiguraDeTransporte.vue'
export default {
    components: { FiguraDeTransporte },
    data() {
        return {
            filter: '',
            loading: false,
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', },
                { name: 'tipoFigura', align: 'center', label: 'Tipo de figura', field: 'tipoFigura', sortable: true },
                { name: 'numLicencia', align: 'center', label: 'Núm. de licencia', field: 'numLicencia', sortable: true },
                { name: 'rfcFigura', align: 'center', label: 'RFC', field: 'rfcFigura', sortable: true },
                { name: 'nombreFigura', align: 'center', label: 'Nombre', field: 'nombreFigura', sortable: true },
                // { name: 'numRegIdTribFigura', align: 'center', label: 'Número de identificación', field: 'numRegIdTribFigura', sortable: true },
                // { name: 'residenciaFiscalFigura', align: 'center', label: 'País de residencia', field: 'residenciaFiscalFigura', sortable: true },
                // { name: 'partesTransporte', align: 'center', label: 'Parte de transporte', field: 'partesTransporte', sortable: true },
                // { name: 'domicilio', align: 'center', label: 'Domicilio', field: 'domicilio', sortable: true },
            ],
            data: [],
            dialogNuevo: false,
            pagination: {
                sortBy: 'subTipoRem',
                descending: false,
                page: 1,
                rowsPerPage: 10
                // rowsNumber: xx if getting data from a server
            },
            dialogConfirm: false
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        items() {
            return this.$store.state.listaFigurasTransporteStore
        }
    },
    created() {
        this.getCatalogoPais();
        this.GetCatTipoFiguraAsync();
        this.GetCatParteTransporteAsync();
        this.GetItems();
        this.GetCatalogos();
    },
    methods: {
        nuevoElemento() {
            let domicilio = {
                _id: '',
                calle: '',
                numeroExterior: '',
                numeroInterior: '',
                colonia: null,
                localidad: null,
                referencia: '',
                municipio: null,
                estado: null,
                pais: {
                    clave: "MEX",
                    descripcion: "México",
                    pais: "MEX | México"
                },
                codigoPostal: '',
            }
            this.$store.state.domicilioStore = domicilio;

            let objeto = {
                _id: '',
                tipoFigura: { clave: '', tipoFigura: '' },
                numLicencia: '',
                rfcFigura: '',
                nombreFigura: '',
                numRegIdTribFigura: '',
                residenciaFiscalFigura: null,
                partesTransporte: [],
                domicilio: null,
            }
            this.$store.state.figuraTransporteStore = objeto
            this.dialogNuevo = true
        },
        async GetCatTipoFiguraAsync() {
            if (this.$store.state.listaCatalogoTipoFigura.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatTipoFiguraAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoTipoFigura = catalogo;

            } catch (error) {
                console.log(error);
            }
        },
        async GetCatParteTransporteAsync() {
            if (this.$store.state.listaCatalogoPartesTransporte.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatParteTransporteAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoPartesTransporte = catalogo;

            } catch (error) {
                console.log(error);
            }
        },
        async getCatalogoPais() {
            if (this.$store.state.listaCatalogoPais.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatPaisAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoPais = catalogo;

            } catch (error) {
                console.log(error);
            }
        },

        async GetItems() {
            this.loading = true
            try {
                let response = await axios.get("CatalogosComprobantes/GetFigurasTransporte/erp_" + this.token.rfc);
                let catalogo = response.data;
                this.$store.state.listaFigurasTransporteStore = catalogo;
                this.loading = false

            } catch (error) {
                this.loading = false
                console.log(error);
            }
        },
        eliminar(item) {
            this.dialogConfirm = true
            this.$store.state.figuraTransporteStore = item
        },
        async deleteItem() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Eliminando figura del transporte. Espere...', messageColor: 'white' })
            let figuraTransporte = this.$store.state.figuraTransporteStore

            try {
                let response = await axios.put('CatalogosComprobantes/DeleteFiguraTransporte/erp_' + this.token.rfc + '/' + figuraTransporte._id)
                console.log(response)

                // SI LA ACCION ES ELIMINAR, LO QUITAMOS DE LA LISTA
                let indice = this.items.indexOf(figuraTransporte)
                this.items.splice(indice, 1)

                this.dialogConfirm = false
                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `La figura de tranporte ha sido eliminado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error, intentelo mas tarde.', color: 'red' })
            }
        },
        editar(data) {
            let obj = { ...data }
            this.$store.state.domicilioStore = { ...obj.domicilio }

            this.$store.state.figuraTransporteStore = { ...obj }
            this.dialogNuevo = true
        },

        async GetCatalogos() {
            await this.getCatalogoPais();
            await this.GetCatEstado();
        },

        async getCatalogoPais() {
            if (this.$store.state.listaCatalogoPais.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatPaisAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoPais = catalogo;

            } catch (error) {
                console.log(error);
            }
        },

        async GetCatEstado() {
            if (this.$store.state.listaCatalogoEstado.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatEstadoAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoEstado = catalogo;

            } catch (error) {
                console.log(error);
            }
        },
    }
}
</script>

<style></style>
<style lang="sass">
</style>