<template>
    <div class="q-pa-md q-gutter-sm">
        <q-card class="my-card">
            <q-card-section>
                <q-uploader label="Seleccione su reporte de ventas" accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" :factory="uploadDocument"/>
                <br>
                <q-btn color="primary" label="Cargar archivos y comparar" @click="ExtraeDatos"/>
            </q-card-section>
        </q-card>
    </div>
</template>
<script>
import axios from 'axios'
import * as XLSX from 'xlsx';
import { QSpinnerCube } from 'quasar';

export default {
    components: {
        
    },
    data() {
        return {
            archivo: null,
            listaDespachos: [],
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
        
    },
    methods: {
        uploadDocument(files){
            const file  = files[0]
            this.archivo = file;
        },

        async ExtraeDatos(){
            this.$q.loading.show({
                spinner: QSpinnerCube,
                spinnerColor: 'primary',
                spinnerSize: 140,
                message: 'Cargando excel..',
            })
            console.log(this.archivo)
            this.listaDespachos = [];

            const file = this.archivo;

            // const resultMagna = await this.readExcelFile(file, 0);
            // this.listaDespachos.push(...resultMagna);

            // const resultPremium = await this.readExcelFile(file, 1);
            // this.listaDespachos.push(...resultPremium);

            const resultDiesel = await this.readExcelFile(file, 2);
            this.listaDespachos.push(...resultDiesel);

            const listaFinal = this.listaDespachos.slice().sort((a, b) => a - b);

            this.$q.loading.show({
                spinner: QSpinnerCube,
                spinnerColor: 'primary',
                spinnerSize: 140,
                message: 'Validando datos..',
            })

            let listaChida = [];
            for(let a of this.listaDespachos){
                let res = await this.validaDespacho(a);
                let objFinal = {
                    a, res
                }
                listaChida.push(objFinal);
            }
            console.log(listaChida)

            this.$q.loading.hide()
        },

        readExcelFile(file, hoja) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                const sheetName = workbook.SheetNames[hoja];
                const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                const empty1Values = sheetData.map(row => row.__EMPTY_1);
                const resultValues = empty1Values.map(value => value.split('-')[0]);

                resolve(resultValues);
                };

                reader.onerror = (error) => {
                reject(error);
                };

                reader.readAsArrayBuffer(file);
            });
        },

        async validaDespacho(despacho){
            try{
                let response = await axios.get('Gasolineros/GetValidaDespachoFacturadoAsync/' + this.token.rfc + '/' + despacho);
                return response.data;
            }catch(error){
                console.log(error)
            }
        },
    },
}
</script>
<style lang="sass" scoped>
    .my-card
      width: 100%
    </style>