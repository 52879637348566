<template>
    <div>
        <div class="row q-col-gutter-sm">
            <div class="col-12 col-md-3">
                <q-input outlined v-model="item.version" label="Versión" dense readonly />
            </div>

            <div class="col-12 col-md-3">
                <q-select v-model="item.divisionVwPsv" label="División" :options="optionsDivision" dense outlined
                    option-label="descripcion">
                </q-select>
            </div>

            <div class="col-12 col-md-3">
                <q-select v-model="item.tipoDocumentoVWMVwPsv" label="Tipo de Documento VW"
                    :options="optionsTipoDocumentoVw" dense outlined option-label="descripcion">
                </q-select>
            </div>

            <div class="col-12 col-md-3">
                <q-select v-model="item.tipoDocumentoFiscalVwPsv" label="Tipo Documento Fiscal VW"
                    :options="optionsTipoDocumentoFiscalVwPsv" dense outlined option-label="descripcion">
                </q-select>
            </div>

            <!-- CODIGO IMPUESTO -->
            <div class="col-12 col-md-4">
                <q-select v-model="item.moneda.codigoImpuesto" label="Código de Impuesto"
                    :options="optionsCodigoImpuesto" dense outlined option-label="descripcion">
                </q-select>
            </div>
            <!-- TIPO MONEDA -->
            <div class="col-12 col-md-4">
                <q-select v-model="item.moneda.tipoMonedaVwPsv" label="Tipo de Moneda" :options="optionsTipoMonedaVwPsv"
                    dense outlined option-label="descripcion">
                </q-select>
            </div>
            <!-- TIPO DE CAMBIO -->
            <div class="col-12 col-md-4">
                <q-input outlined v-model="item.moneda.tipoCambio" label="Tipo de Cambio" dense />
            </div>

            <!-- PROVEEDOR -->
            <div class="col-12 col-md-2">
                <q-input outlined v-model="item.proveedorVwPsv.codigo" label="Código de Proveedor" dense />
            </div>
            <div class="col-12 col-md-10">
                <q-input outlined v-model="item.proveedorVwPsv.correoContacto" label="Correo de Contacto" dense />
            </div>
            <!-- REFERENCIAS -->
            <div class="col-12 col-md-6">
                <q-input outlined v-model="item.referencia.numeroASN" label="Número de ASN" dense />
            </div>
            <div class="col-12 col-md-6">
                <q-input outlined v-model="item.referencia.referenciaProveedor" label="Referencia del Proveedor"
                    dense />
            </div>
            <div class="col-12 col-md-6">
                <q-input outlined v-model="item.referencia.remision" label="Remisión" dense />
            </div>
            <div class="col-12 col-md-6">
                <q-input outlined v-model="item.referencia.unidadNegocios" label="Unidad de Negocios" dense />
            </div>
            <!-- SOLICITANTE -->
            <div class="col-12 col-md-6">
                <q-input outlined v-model="item.solicitanteVwPsv.correo" label="Correo Solicitante" dense />
            </div>
            <div class="col-12 col-md-6">
                <q-input outlined v-model="item.solicitanteVwPsv.nombre" label="Nombre Solicitante" dense />
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { QSpinnerCube } from 'quasar';

export default {
    components: {

    },
    data() {
        return {
            optionsDivision: [
                { clave: "VW", descripcion: "VW" },
                { clave: "INFODE", descripcion: "INFODE" },
                { clave: "VWSP", descripcion: "VWSP" },
            ],
            optionsTipoDocumentoVw: [
                { clave: "PSV", descripcion: "PSV" },
            ],
            optionsTipoDocumentoFiscalVwPsv: [
                { clave: "FA", descripcion: "FA | FACTURA" },
                { clave: "CA", descripcion: "CA | NOTA DE CRÉDITO" },
                { clave: "CR", descripcion: "CR | NOTA DE DÉBITO" },
            ],
            optionsCodigoImpuesto: [
                { clave: "1A", descripcion: "1A | IVA 16%" },
                { clave: "1B", descripcion: "1B | IVA POR OPERACIONES EN ZONA FRONTERIZA" },
                { clave: "1D", descripcion: "1D | 16% MATERIAL NACIONAL DE SERIE" },
                { clave: "F0", descripcion: "F0 | 10% IVA POR COMPRAS GRAVABLES" },
                { clave: "F3", descripcion: "F3 | 10% IVA POR FLETES" },
                { clave: "FC", descripcion: "FC | 10% SERVICIOS" },
                { clave: "FE", descripcion: "FE | 10% HONORARIOS" },
                { clave: "HE", descripcion: "HE | IVA 15% HONORARIOS" },
                { clave: "HO", descripcion: "H0 | 3.75% IVA POR BOLETOS DE AVIÓN" },
                { clave: "HZ", descripcion: "HZ | IVA 0% PROVEEDORES EXTRANJEROS" },
                { clave: "V0", descripcion: "V0 | IVA 0%" },
                { clave: "W3", descripcion: "W3 | 3.75% IVA POR BOLETOS DE AVIÓN" },
                { clave: "W5", descripcion: "W5 | 15% POR COMPRAS MATERIAL PRODUCTIVO" },
            ],
            optionsTipoMonedaVwPsv: [
                { clave: "CAD", descripcion: "CAD | CANADIAN DOLLAR" },
                { clave: "EUR", descripcion: "EUR | EUROPEAN CURRENCY UNIT" },
                { clave: "JPY", descripcion: "JPY | JAPANESE YEN" },
                { clave: "MXP", descripcion: "MXP | MEXICAN PESO AT AVERAGE RATE" },
                { clave: "USD", descripcion: "USD | AMERICAN DOLLAR" },
            ],
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        item() {
            return this.$store.state.addendaVw;
        },

    },
    created() {

    },
    methods: {

    }
}
</script>