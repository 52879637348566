import Vue from 'vue'
import './styles/quasar.sass'
import lang from 'quasar/lang/es.js'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/mdi-v4/mdi-v4.css'
import Quasar from 'quasar/src/vue-plugin.js';import Loading from 'quasar/src/plugins/Loading.js';import LoadingBar from 'quasar/src/plugins/LoadingBar.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';

Vue.use(Quasar, {
  config: {
  },
  plugins: {
    Loading, Notify, Dialog
  },
  lang: lang
},
)