<template>
    <div class="q-pa-md">
        <!-- VISOR DEL PDF -->
        <q-dialog v-model="dialogPdf" maximized  transition-show="slide-up" transition-hide="slide-down">
            <visor-pdf></visor-pdf>
        </q-dialog>

        <!-- DIALOG PARA TIMBRADO -->
        <q-dialog v-model="dialogComprobante" maximized transition-show="slide-up" transition-hide="slide-down">
            <facturacion-gas @cierraExitoso="timbradoExitoso"></facturacion-gas>
        </q-dialog>

        <!-- DIALOG DEL RANDOM -->
        <q-dialog v-model="dialogRandom" transition-show="slide-up" transition-hide="slide-down">
            <q-card style="min-width: 350px">
                <q-card-section>
                    <div class="text-h6">Indique el monto</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    <q-input dense v-model="cantidadRandom" autofocus @keyup.enter="prompt = false" />
                </q-card-section>

                <q-card-actions align="right" class="text-primary">
                    <q-btn flat label="Cancelar" v-close-popup />
                    <q-btn flat label="Aceptar" @click="SeleccionarRandom" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- DIALOG DEL DEMO -->
        <q-dialog v-model="dialgDemo" maximized>
            <demo></demo>
        </q-dialog>

        <!-- SELECCIONA AÑO Y MES, BOTON DE BUSCAR Y EXPORTAR A EXCEL -->
        <div class="row no-wrap items-center q-mt-md q-pa-sm">
            <q-btn dense color="white" text-color="primary" label="Timbres Disponibles">
                <q-badge color="green-14" floating transparent>22</q-badge>
            </q-btn>
            <q-space />
            <q-input v-model="fehaIMasked" label="Fecha Inicial" class="q-mr-sm" name="event" outlined dense>
                <template v-slot:before>
                    <q-icon name="event" />
                </template>
                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="fechaI" @input="UltimoDiaMes">
                        <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Ok" color="primary" flat />
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-input>

            <q-input v-model="fechaFMasked" label="Fecha Final" class="q-mr-xs" outlined dense>
                <template v-slot:before>
                    <q-icon name="event" />
                </template>
                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date v-model="fechaF">
                        <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Ok" color="primary" flat />
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-input>

            <q-select outlined  v-model="selectCombustible" :options="optionsCombustible" label="Combustible" style="width: 200px" dense>

            </q-select>

            <q-select outlined  v-model="selectFormaPago" :options="optionsFormaPago" label="Forma de Pago" style="width: 200px" dense>
                
            </q-select>

            <q-btn push color="amber-9" @click="GetComprobantes" icon="mdi-text-box-search-outline" rounded flat size="18px"
                padding="xs">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Consultar</q-tooltip>
            </q-btn>
            <q-btn push color="green-10" @click="ExportExcel" icon="mdi-file-excel-box-outline" rounded flat size="18px"
                padding="xs">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Exportar Excel</q-tooltip>
            </q-btn>
            <q-btn push color="green-10" @click="OpenDialogSeleccionado" icon="mdi-checkbox-multiple-marked" rounded flat
                size="18px" padding="xs">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Timbrar Seleccionados</q-tooltip>
            </q-btn>
            <q-btn push color="blue-10" @click="OpenDialogCantidadRandom" icon="mdi-axis-z-rotate-counterclockwise" rounded
                flat size="18px" padding="xs">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Seleccionar</q-tooltip>
            </q-btn>
            <q-space />
        </div>

        <!-- CIFRAS CONTROL -->
        <q-card class="my-card">
            <q-card-section>
                <q-table title="DATOS GENERALES" :data="dataControl" :columns="columnsControl" row-key="combustible" dense hide-bottom>

                </q-table>
            </q-card-section>
        </q-card>

        <br>

        <!-- TABLA DE TICKETS GASOLINERA -->
        <q-table title="TICKETS VENTA" :data="dataComprobantes" :columns="columns" row-key="numDespacho"
            :rows-per-page-options="[10]" :filter="filter" class="my-sticky-column-table" selection="multiple"
            :selected.sync="selected" dense>
            <template v-slot:top>
                <span class="text-body1" content-style="font-size: 20px">Suma despachos seleccionados {{FormatCurrency(sumSeleccionados)}}</span>
                <q-space />
                <q-input borderless dense debounce="300" v-model="filter" placeholder="Filtrar">
                    <template v-slot:append>
                        <q-icon name="mdi-magnify" />
                    </template>
                </q-input>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width><q-checkbox v-model="props.selected"></q-checkbox></q-td>
                    <q-td auto-width>
                        <template v-if="props.row.folioFiscal != ''">
                            <q-btn size="md" color="red" rounded flat dense @click="OpenPdf(props.row)"
                                icon="mdi-file-pdf-box">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px" :offset="[10, 10]">Ver Comprobante</q-tooltip>
                            </q-btn>
                        </template>
                    </q-td>
                    <q-td key="fecha" :props="props">{{ FormatDate(props.row.fecha) }}</q-td>
                    <q-td key="numDespacho" :props="props">{{ props.row.numDespacho }}</q-td>
                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                    <q-td key="cantidad" :props="props">{{ FormatQuantity(props.row.cantidad) }}</q-td>
                    <q-td key="unidad" :props="props">{{ props.row.unidad }}</q-td>
                    <q-td key="total" :props="props">{{ FormatCurrency(props.row.total) }}</q-td>
                    <q-td key="metodoPago" :props="props">{{ props.row.metodoPago.metodoPago }}</q-td>
                    <q-td key="formaPago" :props="props">{{ props.row.formaPago.formaPago }}</q-td>
                    <q-td key="folioFiscal" :props="props">{{ props.row.folioFiscal }}</q-td>
                    <q-td key="serieComprobante" :props="props">{{ props.row.serieComprobante }}</q-td>
                    <q-td key="folioComprobante" :props="props">{{ props.row.folioComprobante }}</q-td>
                    <q-td key="fechaComprobante" :props="props">{{ FormatDate(props.row.fechaComprobante) }}</q-td>
                    <q-td key="rfcComprobante" :props="props">{{ props.row.rfcComprobante }}</q-td>
                    <q-td key="nombreComprobante" :props="props">{{ props.row.nombreComprobante }}</q-td>
                    <q-td key="usoCfdiComprobante" :props="props">{{ props.row.usoCfdiComprobante }}</q-td>
                    <q-td key="metodoPagoComprobante" :props="props">{{ props.row.metodoPagoComprobante }}</q-td>
                    <q-td key="formaPagoComprobante" :props="props">{{ props.row.formaPagoComprobante }}</q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>
<script>
import axios from 'axios'
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar';
import moment from 'moment';
import FacturacionGas from './FacturacionGas.vue'
import VisorPdf from '../../../components/PDF/VisorPdf.vue'
import Demo from './Demo.vue'

export default {
    components: {
        FacturacionGas,
        VisorPdf,
        Demo
    },
    data() {
        return {
            dataComprobantes: [],
            fechaI: new Date(),
            fechaF: new Date(),
            filter: '',
            columns: [
                { name: 'actions', align: 'left', label: 'Acciones', field: 'actions' },
                { name: 'fecha', align: 'left', label: 'Fecha Despacho', field: 'fecha', sortable: true },
                { name: 'numDespacho', align: 'left', label: 'Num Despacho', field: 'numDespacho', sortable: true },
                { name: 'descripcion', align: 'left', label: 'Descripción', field: 'descripcion', sortable: true },
                { name: 'cantidad', align: 'right', label: 'Cantidad', field: 'cantidad', sortable: true },
                { name: 'unidad', align: 'left', label: 'Unidad', field: 'unidad', sortable: true },
                { name: 'total', align: 'right', label: 'Total', field: 'total', sortable: true },
                { name: 'metodoPago', align: 'left', label: 'Metodo de Pago', field: 'metodoPago', sortable: true },
                { name: 'formaPago', align: 'left', label: 'Forma de Pago', field: 'formaPago', sortable: true },
                { name: 'folioFiscal', align: 'left', label: 'Folio Fiscal', field: 'folioFiscal', sortable: true },
                { name: 'serieComprobante', align: 'left', label: 'Serie', field: 'serieComprobante', sortable: true },
                { name: 'folioComprobante', align: 'left', label: 'Folio', field: 'folioComprobante', sortable: true },
                { name: 'fechaComprobante', align: 'left', label: 'Fecha', field: 'fechaComprobante', sortable: true },
                { name: 'rfcComprobante', align: 'left', label: 'RFC', field: 'rfcComprobante', sortable: true },
                { name: 'nombreComprobante', align: 'left', label: 'Nombre', field: 'nombreComprobante', sortable: true },
                { name: 'usoCfdiComprobante', align: 'left', label: 'USo del CFDI', field: 'usoCfdiComprobante', sortable: true },
                { name: 'metodoPagoComprobante', align: 'left', label: 'Metodo de Pago', field: 'metodoPagoComprobante', sortable: true },
                { name: 'formaPagoComprobante', align: 'left', label: 'Forma de Pago', field: 'formaPagoComprobante', sortable: true },
            ],
            dialogComprobante: false,
            selected: [],

            //PARA LOS RANDOM
            dialogRandom: false,
            cantidadRandom: 0,

            //PARA LAS CIFRAS CONTROL
            dataControl: [],
            columnsControl: [
            { name: 'combustible', align: 'left', label: 'Combustible', field: 'combustible' },
            { name: 'litrosV', align: 'right', label: 'Litros Vendidos', field: 'litrosV', headerClasses: 'bg-orange-9 text-white', classes: 'bg-orange-4 text-black text-right ellipsis '},
            { name: 'totalV', align: 'right', label: 'Total Vendido', field: 'totalV', headerClasses: 'bg-orange-9 text-white', classes: 'bg-orange-4 text-black text-right ellipsis ' },
            { name: 'litrosF', align: 'right', label: 'Litros Facturados', field: 'litrosF', headerClasses: 'bg-orange-8 text-white', classes: 'bg-orange-3 text-black text-right ellipsis ' },
            { name: 'totalF', align: 'right', label: 'Total Facturado', field: 'totalF', headerClasses: 'bg-orange-8 text-white', classes: 'bg-orange-3 text-black text-right ellipsis ' },
            { name: 'litrosS', align: 'right', label: 'Litros Sin Factura', field: 'litrosS', headerClasses: 'bg-orange-7 text-white', classes: 'bg-orange-2 text-black text-right ellipsis ' },
            { name: 'totalS', align: 'right', label: 'Total Sin Factura', field: 'totalS',headerClasses: 'bg-orange-7 text-white', classes: 'bg-orange-2 text-black text-right ellipsis ' },            
            ],
        
            //PARA EL PDF
            dialogPdf: false,

            //FILTRADO
            selectCombustible: 'Todos',
            selectFormaPago: 'Todos',
            optionsCombustible: [],
            optionsFormaPago: [],

            dialgDemo: true,
            
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        fehaIMasked() {
            moment.locale('es-mx');
            return this.fechaI ? moment(this.fechaI).format('DD/MMMM/yyyy') : ''
        },

        fechaFMasked() {
            moment.locale('es-mx');
            return this.fechaF ? moment(this.fechaF).format('DD/MMMM/yyyy') : ''
        },

        //CIFRAS CONTROL
        magna(){
            let magna = this.dataComprobantes.filter(f => f.claveProdServ.clave === '15101514');
            let litrosV = magna.reduce((acumulador, objeto) => acumulador + objeto.cantidad, 0);
            let totalV = magna.reduce((acumulador, objeto) => acumulador + objeto.total, 0);

            let magnaF = magna.filter(f => f.folioFiscal != "");
            let litrosF = magnaF.reduce((acumulador, objeto) => acumulador + objeto.cantidad, 0);
            let totalF = magnaF.reduce((acumulador, objeto) => acumulador + objeto.total, 0);

            let magnaS = magna.filter(f => f.folioFiscal === "");
            let litrosS = magnaS.reduce((acumulador, objeto) => acumulador + objeto.cantidad, 0);
            let totalS = magnaS.reduce((acumulador, objeto) => acumulador + objeto.total, 0);

            const respuesta = {
                combustible: 'MAGNA',
                litrosV: this.FormatQuantity(litrosV),
                totalV: this.FormatCurrency(totalV),
                litrosF: this.FormatQuantity(litrosF),
                totalF: this.FormatCurrency(totalF),
                litrosS: this.FormatQuantity(litrosS),
                totalS: this.FormatCurrency(totalS),
            }
            return respuesta;
        },

        premium(){
            let magna = this.dataComprobantes.filter(f => f.claveProdServ.clave === '15101515');
            let litrosV = magna.reduce((acumulador, objeto) => acumulador + objeto.cantidad, 0);
            let totalV = magna.reduce((acumulador, objeto) => acumulador + objeto.total, 0);

            let magnaF = magna.filter(f => f.folioFiscal != "");
            let litrosF = magnaF.reduce((acumulador, objeto) => acumulador + objeto.cantidad, 0);
            let totalF = magnaF.reduce((acumulador, objeto) => acumulador + objeto.total, 0);

            let magnaS = magna.filter(f => f.folioFiscal === "");
            let litrosS = magnaS.reduce((acumulador, objeto) => acumulador + objeto.cantidad, 0);
            let totalS = magnaS.reduce((acumulador, objeto) => acumulador + objeto.total, 0);

            const respuesta = {
                combustible: 'PREMIUM',
                litrosV: this.FormatQuantity(litrosV),
                totalV: this.FormatCurrency(totalV),
                litrosF: this.FormatQuantity(litrosF),
                totalF: this.FormatCurrency(totalF),
                litrosS: this.FormatQuantity(litrosS),
                totalS: this.FormatCurrency(totalS),
            }
            return respuesta;
        },

        diesel(){
            let magna = this.dataComprobantes.filter(f => f.claveProdServ.clave === '15101505');
            let litrosV = magna.reduce((acumulador, objeto) => acumulador + objeto.cantidad, 0);
            let totalV = magna.reduce((acumulador, objeto) => acumulador + objeto.total, 0);

            let magnaF = magna.filter(f => f.folioFiscal != "");
            let litrosF = magnaF.reduce((acumulador, objeto) => acumulador + objeto.cantidad, 0);
            let totalF = magnaF.reduce((acumulador, objeto) => acumulador + objeto.total, 0);

            let magnaS = magna.filter(f => f.folioFiscal === "");
            let litrosS = magnaS.reduce((acumulador, objeto) => acumulador + objeto.cantidad, 0);
            let totalS = magnaS.reduce((acumulador, objeto) => acumulador + objeto.total, 0);

            const respuesta = {
                combustible: 'DIESEL',
                litrosV: this.FormatQuantity(litrosV),
                totalV: this.FormatCurrency(totalV),
                litrosF: this.FormatQuantity(litrosF),
                totalF: this.FormatCurrency(totalF),
                litrosS: this.FormatQuantity(litrosS),
                totalS: this.FormatCurrency(totalS),
            }
            return respuesta;
        },

        totales(){
            let magna = this.dataComprobantes;
            let litrosV = magna.reduce((acumulador, objeto) => acumulador + objeto.cantidad, 0);
            let totalV = magna.reduce((acumulador, objeto) => acumulador + objeto.total, 0);

            let magnaF = magna.filter(f => f.folioFiscal != "");
            let litrosF = magnaF.reduce((acumulador, objeto) => acumulador + objeto.cantidad, 0);
            let totalF = magnaF.reduce((acumulador, objeto) => acumulador + objeto.total, 0);

            let magnaS = magna.filter(f => f.folioFiscal === "");
            let litrosS = magnaS.reduce((acumulador, objeto) => acumulador + objeto.cantidad, 0);
            let totalS = magnaS.reduce((acumulador, objeto) => acumulador + objeto.total, 0);

            const respuesta = {
                combustible: 'TOTAL',
                litrosV: this.FormatQuantity(litrosV),
                totalV: this.FormatCurrency(totalV),
                litrosF: this.FormatQuantity(litrosF),
                totalF: this.FormatCurrency(totalF),
                litrosS: this.FormatQuantity(litrosS),
                totalS: this.FormatCurrency(totalS),
            }
            return respuesta;
        },

        sumSeleccionados(){
            let suma =  this.selected.reduce((acumulador, objeto) => acumulador + objeto.total, 0);
            return suma;
        },
    },
    created() {
        this.GetIniciales();
    },
    methods: {
        async GetIniciales(){
            this.$q.loading.show({
                spinner: QSpinnerCube,
                spinnerColor: 'primary',
                spinnerSize: 140,
                message: 'Consultando catálogos, esprere..',
            })

            await this.GetProductos();
            await this.GetFormaPago();

            this.$q.loading.hide()
        },

        async GetProductos(){
            this.optionsCombustible = [];
            try{
                let response = await axios.get('Gasolineros/GetProductosGasolinerasAsync/' + this.token.rfc);
                let x = response.data;
                this.optionsCombustible = [...x];                
            }catch(error){
                console.log(error)
            }
        },

        async GetFormaPago(){
            this.optionsFormaPago = [];
            try{
                let response = await axios.get('Gasolineros/GetFormaPagoGasolineraAsync/' + this.token.rfc);
                let x = response.data;
                this.optionsFormaPago = [...x];                
            }catch(error){
                console.log(error)
            }
        },

        async GetComprobantes() {
            this.$q.loading.show({
                spinner: QSpinnerCube,
                spinnerColor: 'primary',
                spinnerSize: 140,
                message: 'Consultando, esprere..',
            })
            try {
                let fechaI_ = new Date(this.fechaI);
                let fechaF_ = new Date(this.fechaF);
                let fechaI = format(fechaI_, "yyyy-MM-dd");
                let fechaF = format(fechaF_, "yyyy-MM-dd");
                let response = await axios.get('Gasolineros/GetDespachosComprobantesAsync/' + this.token.rfc + '/' + fechaI + '/' + fechaF + '/' + this.selectFormaPago + '/' + this.selectCombustible);
                const x = response.data;
                console.log(x)
                this.dataComprobantes = x;
                this.$q.loading.hide()

                //AGREGAMOS LOS TOTALES
                this.dataControl  = [];
                this.dataControl.push(this.magna);
                this.dataControl.push(this.premium);
                this.dataControl.push(this.diesel);
                this.dataControl.push(this.totales);
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
            }
        },

        UltimoDiaMes() {
            let fechaI = this.fechaI;
            fechaI = fechaI.replaceAll('/', '-');
            const fecha = parse(fechaI, 'yyyy-MM-dd', new Date());
            const ultimoDiaDelMes = endOfMonth(fecha);
            this.fechaF = ultimoDiaDelMes;
        },

        ExportExcel() { },

        FormatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        FormatQuantity(item) {
            const formatoNumerico = item.toLocaleString('en-US', {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
                useGrouping: true  // Agregar separador de miles
            });
            return formatoNumerico
        },

        FormatDate(value) {
            let fecha_a = value.replace('T', ' ');
            const cadenaFechaConHora = fecha_a;
            const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
            const formato = "dd-MMMM-yyyy";
            const configuracionLocal = { locale: es };
            let resultado = format(fecha, formato, configuracionLocal);
            return resultado;
        },

        colorComprobante(item) {
            console.log(item)
            var color = '';
            switch (item) {
                case 'Vigente':
                    color = 'green-14';
                    break;
                case 'Cancelado':
                    color = 'red-10';
                    break;
            }
            return color;
        },

        OpenDialogSeleccionado(item) {
            this.$store.state.ticketsStore = [];
            let sintimbrar = this.selected.filter(f => f.folioFiscal === '');
            // console.log(sintimbrar)
            this.$store.state.ticketsStore = [...sintimbrar]
            this.dialogComprobante = true;
        },

        //PARA SELECCIONAR TICKETS RANDOM
        OpenDialogCantidadRandom() {
            this.cantidadRandom = 0;
            this.dialogRandom = true;
            this.selected = [];
        },

        SeleccionarRandom() {
            this.$store.state.ticketsStore = [];
            let filtro = this.dataComprobantes.filter(f => f.folioFiscal === '');
            let lista = filtro.slice(0);
            let cantidadObjetivo = this.cantidadRandom;
            // let indice = this.IndiceRandom(lista);

            let seleccionados = [];
            let sumaImportes = 0;
            while (sumaImportes < cantidadObjetivo && lista.length > 0) {
                const indice = this.IndiceRandom(lista);
                const objetoSeleccionado = lista[indice];
                seleccionados.push(objetoSeleccionado);
                sumaImportes += objetoSeleccionado.total;
                lista.splice(indice, 1);
            }
            // console.log(seleccionados, sumaImportes)
            this.$store.state.ticketsStore = [...seleccionados];
            this.dialogComprobante = true;
            this.dialogRandom = false;
        },

        IndiceRandom(array){
            return Math.floor(Math.random() * array.length);
        },

        async OpenPdf(item){
            this.$store.state.folioFiscalStore = '';
            this.$store.state.rfcStore = '';
            this.$store.state.folioFiscalStore = item.folioFiscal;
            this.$store.state.rfcStore = this.token.rfc;
            this.dialogPdf = true;
            console.log(this.$store.state.rfcStore, this.$store.state.folioFiscalStore);
        },
    
        //PARA LA FACTURA GLOBAL
        timbradoExitoso(){
            this.selected = [];
            this.dialogComprobante = false;
            this.GetComprobantes();
        },
        
    },
}
</script>