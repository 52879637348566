import { render, staticRenderFns } from "./AddendaVwArchivos.vue?vue&type=template&id=b496684e"
import script from "./AddendaVwArchivos.vue?vue&type=script&lang=js"
export * from "./AddendaVwArchivos.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QUploader from 'quasar/src/components/uploader/QUploader.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QUploader,QTable,QTr,QTd,QBtn,QTooltip});
