<template>
    <q-layout view="hHh lpR fFf">
        <q-header elevated>
            <q-toolbar>
                <q-btn flat dense round icon="mdi-home" aria-label="Menu" @click="$router.push({ name: 'Home' })" />
                <q-toolbar-title>
                    <div class="text-h6 text-weight-bolder">CARTA PORTE</div>
                </q-toolbar-title>
                <div class="text-h6 q-mr-lg">{{ $store.state.usuario.rfc }}</div>
                <q-btn flat round dense icon="mdi-account" @click="drawerPerfil = !drawerPerfil" />
            </q-toolbar>
        </q-header>

        <!-- DRAWER DERECHO -->
        <q-drawer v-model="drawerPerfil" side="right" bordered>
            <drawerPerfil></drawerPerfil>
        </q-drawer>

        <!-- DRAWER IZQUIERDO -->
        <q-drawer v-model="leftDrawerOpen" show-if-above :mini="miniState" @mouseover="miniState = false"
            @mouseout="miniState = true" :width="250" :breakpoint="500" bordered content-class="bg-dark">
            <q-scroll-area class="fit" :horizontal-thumb-style="{ opacity: 0 }">
                <q-list padding>
                    <q-item active clickable v-ripple @click="irListCartaPorte">
                        <q-item-section avatar>
                            <q-icon name="mdi-cash-clock" />
                        </q-item-section>
                        <q-item-section>
                            Carta Porte
                        </q-item-section>
                    </q-item>
                    <q-separator />

                    <q-item active clickable v-ripple>
                        <q-item-section avatar>
                            <q-icon name="mdi-gas-station-outline" />
                        </q-item-section>
                        <q-item-section>
                            Complemento Pago
                        </q-item-section>
                    </q-item>
                    <q-separator />

                    <q-item active clickable v-ripple @click="irClientes">
                        <q-item-section avatar>
                            <q-icon name="mdi-account-group" />
                        </q-item-section>
                        <q-item-section>
                            Clientes
                        </q-item-section>
                    </q-item>
                    <q-separator />

                    <q-item active clickable v-ripple @click="irConceptos">
                        <q-item-section avatar>
                            <q-icon name="mdi-view-list" />
                        </q-item-section>
                        <q-item-section>
                            Conceptos
                        </q-item-section>
                    </q-item>
                    <q-separator />

                    <!-- <q-item active clickable v-ripple @click="irDomicilios">
                        <q-item-section avatar>
                            <q-icon name="mdi-home-map-marker" />
                        </q-item-section>
                        <q-item-section>
                            Domicilios
                        </q-item-section>
                    </q-item> -->

                    <q-item active clickable v-ripple @click="irUbicaciones">
                        <q-item-section avatar>
                            <q-icon name="mdi-map-marker-radius" />
                        </q-item-section>
                        <q-item-section>
                            Ubicaciones
                        </q-item-section>
                    </q-item>
                    <q-separator />

                    <q-separator />
                    <q-item active clickable v-ripple @click="irMercancias">
                        <q-item-section avatar>
                            <q-icon name="mdi-dolly" />
                        </q-item-section>
                        <q-item-section>
                            Mercancia
                        </q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item active clickable v-ripple @click="irFigurasTransporte">
                        <q-item-section avatar>
                            <q-icon name="mdi-card-account-details" />
                        </q-item-section>
                        <q-item-section>
                            Figura de transporte
                        </q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item active clickable v-ripple @click="irRemolques">
                        <q-item-section avatar>
                            <q-icon name="mdi-truck-trailer" />
                        </q-item-section>
                        <q-item-section>
                            Remolques
                        </q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item active clickable v-ripple @click="irSeguros">
                        <q-item-section avatar>
                            <q-icon name="mdi-shield-car" />
                        </q-item-section>
                        <q-item-section>
                            Seguros
                        </q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item active clickable v-ripple @click="irIdentificacionVehicular">
                        <q-item-section avatar>
                            <q-icon name="mdi-truck" />
                        </q-item-section>
                        <q-item-section>
                            Identificación Vehicular
                        </q-item-section>
                    </q-item>
                    <q-separator />
                </q-list>
            </q-scroll-area>
        </q-drawer>

        <q-page-container>
            <router-view />
        </q-page-container>

        <q-footer style="height: 30px;" bordered class="bg-dark text-white">
            <q-toolbar>
                <q-toolbar-title>
                </q-toolbar-title>
            </q-toolbar>
        </q-footer>
    </q-layout>
</template>

<script>
import { ref } from 'vue'
import axios from "axios";
import drawerPerfil from "../../DrawerPerfil/DrawerPerfil.vue"

export default {

    name: 'MainLayout',
    components: {
        drawerPerfil
    },
    data() {
        return {
            drawerPerfil: false,
            isDarkMode: false,
            leftDrawerOpen: false,
            drawer: false,
            miniState: true,
        }
    },

    watch: {
        isDarkMode(val) {
            this.$q.dark.set(val);
        }
    },
    methods: {
        irListCartaPorte() {
            this.$router.push({ name: 'ListCartaPorte' })
        },
        irClientes() {
            this.$router.push({ name: 'ListaClientesCartaPorte' })

        },
        irConceptos() {
            this.$router.push({ name: 'ListaConceptosCPI' })

        },
        irDomicilios() {
            this.$router.push({ name: 'ListaDomicilios' })

        },
        irFigurasTransporte() {
            this.$router.push({ name: 'ListaFigurasDeTransporte' })

        },
        irIdentificacionVehicular() {
            this.$router.push({ name: 'ListaIdentificacionVehicular' })

        },
        irMercancias() {
            this.$router.push({ name: 'ListaMercancias' })

        },
        irRemolques() {
            this.$router.push({ name: 'ListaRemolques' })

        },
        irSeguros() {
            this.$router.push({ name: 'ListaSeguros' })

        },
        irUbicaciones() {
            this.$router.push({ name: 'ListaUbicaciones' })

        },
    }
}
</script>
