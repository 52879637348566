<template>
    <div>
        <div class="row q-col-gutter-sm">
            <!-- CONCEPTO -->
            <!-- <div class="col-12">
                <q-select v-model="concepto" use-input input-debounce="0" label="Concepto" :options="optionsConceptos"
                    @filter="filterConceptos" fill-input hide-selected :loading="loadingConceptos" dense outlined
                    option-label="concepto" @input="AsignaValores()">
                    <template v-slot:no-option>
                        <q-item>
                            <q-item-section class="text-grey">
                                No exixte algún concepto que coincida con los criterios
                            </q-item-section>
                        </q-item>
                    </template>
</q-select>
</div> -->

            <!-- DESCRIPCION -->
            <div class="col-12">
                <!-- <q-input outlined v-model="descripcion" label="Descripción" dense /> -->
                <form autocorrect="off" autocapitalize="off" autocomplete="off" spellcheck="false">
                    <q-editor v-model="descripcion" min-height="2rem" @paste.native="evt => pasteCapture(evt)"
                        placeholder="Descripción" dense ref="editor_ref" :toolbar="[]" />
                </form>
            </div>

            <!--UNIDAD MEDIDA -->
            <div class="col-10">
                <q-input outlined v-model="unidad" label="Unidad" dense />
            </div>

            <!-- CANTIDAD -->
            <div class="col-12 col-md-2">
                <q-field v-model="cantidad" label="Cantidad" dense outlined>
                    <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                        <vue-number :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                            v-show="floatingLabel" v-bind="cantidadFormat"></vue-number>
                    </template>
                </q-field>
            </div>

            <!-- Material -->
            <div class="col-12 col-md-12">
                <q-input outlined v-model="material" label="Material" dense>
                    <template v-slot:after>
                        <q-btn round dense flat icon="mdi-plus-circle-outline" color="green-10" @click="pushConcepto()">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Agregar
                                concepto</q-tooltip>
                        </q-btn>
                    </template>
                </q-input>
            </div>
        </div>

        <!-- TABLA DE CONCEPTOS -->
        <q-table title="Conceptos" :data="pedido.conceptos" :columns="columns" row-key="item"
            :rows-per-page-options="[10]" class="my-sticky-column-table q-mt-md">

            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td auto-width key="acciones">
                        <q-btn size="md" color="primary" rounded flat dense @click="EliminarConcepto(props.row)"
                            icon="mdi-delete">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Eliminar Concepto</q-tooltip>
                        </q-btn>
                        <q-btn size="md" color="blue" rounded flat dense @click="EditConcepto(props.row)"
                            icon="mdi-pencil-outline">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Editar concepto</q-tooltip>
                        </q-btn>
                    </q-td>
                    <q-td key="item" :props="props">{{ props.row.item }}</q-td>
                    <q-td key="descripcion" :props="props">
                        <div v-html="props.row.descripcion"></div>
                        <q-popup-edit buttons v-model="props.row.descripcion" v-slot="scope">
                            <q-editor v-model="scope.value" min-height="5rem" autofocus @keyup.enter.stop
                                :toolbar="[]" />
                        </q-popup-edit>
                    </q-td>
                    <q-td key="cantidad" :props="props">{{ props.row.cantidad }}
                        <q-popup-edit buttons v-model="props.row.cantidad" v-slot="scope">
                            <q-input v-model="scope.value" dense autofocus counter @keyup.enter="scope.set" />
                        </q-popup-edit>
                    </q-td>

                    <q-td key="unidad" :props="props">{{ props.row.unidad }}
                        <q-popup-edit buttons v-model="props.row.unidad" v-slot="scope">
                            <q-input v-model="scope.value" dense autofocus counter @keyup.enter="scope.set" />
                        </q-popup-edit>
                    </q-td>

                    <q-td key="material" :props="props">{{ props.row.material }}
                        <q-popup-edit buttons v-model="props.row.material" v-slot="scope">
                            <q-input v-model="scope.value" dense autofocus counter @keyup.enter="scope.set" />
                        </q-popup-edit>
                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>
<script>
import axios from 'axios'
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar';
import moment from 'moment';
import { component as VueNumber } from '@coders-tm/vue-number-format'

export default {
    components: {
        VueNumber
    },
    data() {
        return {
            concepto: null,
            optionsConceptos: [],
            loadingConceptos: false,

            noItem: 1,
            indice: -1,
            cantidad: 1,
            descripcion: '',
            material: '',
            unidad: '',

            columns: [
                { name: 'actions', align: 'left', label: 'Acciones', field: 'actions' },
                { name: 'item', align: 'left', label: 'No. Item', field: 'item', sortable: true },
                { name: 'descripcion', align: 'left', label: 'Descripción', field: 'descripcion', sortable: true },
                { name: 'unidad', align: 'left', label: 'Unidad', field: 'unidad', sortable: true },
                { name: 'cantidad', align: 'left', label: 'Cantidad', field: 'cantidad', sortable: true },
                { name: 'material', align: 'left', label: 'Material', field: 'material', sortable: true },
            ],

            splitterModel: 50,

            importeFormat: {
                decimal: '.',
                separator: ',',
                prefix: '',
                precision: 6,
                masked: false,
                minimumFractionDigits: 6,
            },
            cantidadFormat: {
                decimal: '.',
                separator: ',',
                prefix: '',
                precision: 3,
                masked: false,
                minimumFractionDigits: 3,
            },

            pagination: {
                rowsPerPage: 0
            },
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        pedido() {
            return this.$store.state.pedidoCStore;
        },

        optionsConceptosDefault() {
            return this.$store.state.listaConceptosStore;
        },
    },
    created() {

    },
    methods: {
        EditConcepto(item) {
            this.indice = this.pedido.conceptos.findIndex(
                (f) => f.item === item.item
            );
            this.noItem = item.item;
            this.material = item.material;
            this.cantidad = item.cantidad;
            this.descripcion = item.descripcion;
            this.unidad = item.unidad;
        },
        filterConceptos(val, update) {
            let stringOptions = this.optionsConceptosDefault
            if (val === '') {
                update(() => {
                    this.optionsConceptos = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsConceptos = stringOptions.filter(v => v.concepto.toLowerCase().indexOf(needle) > -1)
            })
        },

        AsignaValores() {
            console.log(this.concepto);
            let c = { ...this.concepto }
            this.cantidad = c.cantidad;
            this.descripcion = c.descripcion;
        },

        EliminarConcepto(item) {
            let indice = this.pedido.conceptos.indexOf(item);
            this.pedido.conceptos.splice(indice, 1);
            this.AgrupaYCalculaTotales();
        },

        InicializaConcepto() {
            this.indice = -1;
            this.material = '';
            this.cantidad = 1;
            this.descripcion = '';
            this.unidad = '';
        },

        pushConcepto() {
            let item = 0;
            if (this.indice != -1) {
                item = this.noItem;
            } else {
                item = this.pedido.conceptos.length + 1;
            }

            let concepto = {
                item: item,
                material: this.material,
                cantidad: this.cantidad,
                descripcion: this.descripcion,
                unidad: this.unidad,
                precio: 0,
                importe: 0,
                valorUnitario: 0,
                objImp: { objImp: '' },
                impuestos: {
                    traslados: [],
                    retenciones: [],
                },
            }

            if (this.indice != -1) {
                // this.comprobante.conceptos[this.indice] = concepto;
                Object.assign(this.pedido.conceptos[this.indice], concepto)
            } else {
                this.pedido.conceptos.push(concepto)
            }
            concepto = {};
            this.concepto = null;
            this.InicializaConcepto()
        }

    },
}
</script>