<template>
    <div>
        <q-table title="Historal de Ventas" :loading="loadingTabla" :data="itemsVentas" :columns="columns"
            row-key="_id">
            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>
            <template v-slot:top-right>
                <q-input v-model="fehaIMasked" filled label="Fecha Inicial" class="q-mr-sm" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaI" @input="UltimoDiaMes">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </q-input>
                <q-input v-model="fechaFMasked" filled label="Fecha Final" class="q-mr-sm" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaF">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </q-input>
                <q-btn round color="blue" icon="mdi-magnify" @click="getPuntoVenta()" />
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td key="serie" :props="props">{{ props.row.serie }}</q-td>
                    <q-td key="folio" :props="props">{{ props.row.folio }}</q-td>
                    <q-td key="fecha" :props="props">{{ FormatDate(props.row.fecha) }}</q-td>
                    <q-td key="rfc" :props="props">{{ props.row.receptor.rfc }}</q-td>
                    <q-td key="nombre" :props="props">{{ props.row.receptor.nombre }}</q-td>
                    <q-td key="subTotal" :props="props">{{ formatCurrency(props.row.subTotal) }}</q-td>
                    <q-td key="total" :props="props">{{ formatCurrency(props.row.total) }}</q-td>
                    <q-td key="pagoCon" :props="props">{{ formatCurrency(props.row.pagoCon) }}</q-td>
                    <q-td key="cambioTotal" :props="props">{{ formatCurrency(props.row.cambioTotal) }}</q-td>
                    <q-td key="formaPago" :props="props">{{ props.row.formaPago.formaPago }}</q-td>
                    <q-td auto-width>
                        <q-btn size="md" color="blue" rounded flat dense @click="verTicket(props.row)"
                            icon="mdi-pencil">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">PDF</q-tooltip>
                        </q-btn>
                    </q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>
<script>
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import moment from 'moment';
import axios from 'axios'
import print from 'print-js'

import { ticketBase64 } from '../PDF/TicketPDF.js'
export default {
    data() {
        return {
            fechaI: new Date(),
            fechaF: new Date(),
            columns: [
                { name: 'serie', align: 'center', label: 'Serie', field: 'serie', sortable: true },
                { name: 'folio', align: 'center', label: 'Folio', field: 'folio', sortable: true },
                { name: 'fecha', align: 'center', label: 'Fecha', field: 'fecha', sortable: true },
                { name: 'rfc', align: 'center', label: 'RFC Cliente', field: 'rfc', sortable: true },
                { name: 'nombre', align: 'center', label: 'Nombre Cliente', field: 'nombre', sortable: true, },
                { name: 'subTotal', align: 'center', label: 'SubTotal', field: 'subTotal', sortable: true, classes: 'bg-grey-2 ellipsis', headerClasses: 'bg-primary text-white' },
                { name: 'total', align: 'center', label: 'Total', field: 'total', sortable: true, classes: 'bg-grey-2 ellipsis', headerClasses: 'bg-primary text-white' },
                { name: 'pagoCon', align: 'center', label: 'Pago con', field: 'pagoCon', sortable: true, classes: 'bg-grey-2 ellipsis', headerClasses: 'bg-primary text-white' },
                { name: 'cambioTotal', align: 'center', label: 'Cambio', field: 'cambioTotal', sortable: true, classes: 'bg-grey-2 ellipsis', headerClasses: 'bg-primary text-white' },
                { name: 'formaPago', align: 'center', label: 'Forma de Pago', field: 'formaPago', sortable: true },
            ],
            loadingTabla: false
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        itemsVentas() {
            return this.$store.state.listVentasStore;
        },
        fehaIMasked() {
            moment.locale('es-mx');
            return this.fechaI ? moment(this.fechaI).format('DD/MMMM/yyyy') : ''
        },
        fechaFMasked() {
            moment.locale('es-mx');
            return this.fechaF ? moment(this.fechaF).format('DD/MMMM/yyyy') : ''
        },
    },
    created() {
        this.getPuntoVenta();
    },
    methods: {
        async verTicket(item) {
            console.log(item)
            let base64 = await ticketBase64(item);
            var parts = base64.split(';base64,');
            var base64Content = parts[1];

            print({
                printable: base64Content,
                type: 'pdf',
                base64: true,
            })
        },
        async getPuntoVenta() {
            this.loadingTabla = true
            try {
                let response = await axios.get("PuntoVenta/GetPuntoVenta/" + this.token.empresaBase);
                console.log(response)
                this.$store.state.listVentasStore = response.data;
                this.loadingTabla = false
            } catch (error) {
                console.log(error);
                this.loadingTabla = false
            }
        },
        FormatDate(value) {
            let fecha_a = value.replace('T', ' ');
            let fecha_b = fecha_a.replace('Z', '');
            const cadenaFechaConHora = fecha_b;
            const fecha = parse(cadenaFechaConHora, 'yyyy-MM-dd HH:mm:ss', new Date());
            const formato = "dd-MMMM-yyyy HH:mm:ss";
            const configuracionLocal = { locale: es };
            let resultado = format(fecha, formato, configuracionLocal);
            return resultado;
        },
        UltimoDiaMes() {
            let fechaI = this.fechaI;
            fechaI = fechaI.replaceAll('/', '-');
            const fecha = parse(fechaI, 'yyyy-MM-dd', new Date());
            const ultimoDiaDelMes = endOfMonth(fecha);
            this.fechaF = ultimoDiaDelMes;
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        }

    }
}
</script>