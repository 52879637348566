<template>
    <q-card style="width: 1200px; max-width: 80vw;">
        <q-card-section>
            <q-toolbar>
                <q-toolbar-title>
                    Usuario Interno
                </q-toolbar-title>

                <q-btn v-if="usuario._id == ''" round color="green" dense icon="mdi-content-save" class="q-mr-sm"
                    @click="postUsuario()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>

                <q-btn v-else round color="orange" dense icon="mdi-content-save" class="q-mr-sm" @click="editarUsuario()">
                    <q-tooltip>
                        Editar
                    </q-tooltip>
                </q-btn>

                <q-btn round color="red" dense icon="mdi-close" @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip></q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
        </q-card-section>
        <q-card-section style="max-height: 50vh" class="scroll">
            <div class="row  q-col-gutter-sm">
                <div class="col-12 col-md-6">
                    <q-input v-model="usuario.rfc" class="full-width" filled label="RFC" dense />
                </div>
                <div class="col-12 col-md-6">
                    <q-input v-model="usuario.curp" class="full-width" filled label="CURP" dense />
                </div>
                <div class="col-12">
                    <q-input v-model="usuario.nombre" class="full-width" filled label="Nombre Completo" dense />
                </div>
                <div class="col-12 col-md-6">
                    <q-input v-model="usuario.usuario" class="full-width" filled label="Nombre de usuario o alias" dense />
                </div>
                <div class="col-12 col-md-6 ">
                    <q-input v-model="usuario.pin" filled dense :type="isPwd ? 'password' : 'text'" label="Pin">
                        <template v-slot:append>
                            <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                                @click="isPwd = !isPwd" />
                        </template>
                    </q-input>
                </div>
                <div class="col-12 col-md-6">
                    <q-input v-model="usuario.departamento" dense class="full-width" filled label="Departamento" />
                </div>
                <div class="col-12 col-md-6 ">
                    <q-select filled v-model="usuario.permisos" dense multiple :options="opcionesPermiso" use-chips
                        stack-label label="Permisos" />
                </div>
                <div v-if="validaPermiso" class="col-12 col-md-6">
                    <q-input v-model="usuario.serie" dense class="full-width" filled label="Serie" />
                </div>
                <div class="col-12 col-md-6 ">
                    <q-select filled v-model="usuario.almacen" dense :options="opcionesAlmacen" label="Almacen"
                        option-label="almacen" />
                </div>
                <div class="col-12 col-md-6">
                    <q-input v-model="usuario.correo" dense class="full-width" filled label="Correo" />
                </div>
                <div class="col-12 col-md-6">
                    <q-input v-model="usuario.telefono" dense class="full-width" filled label="Télefono" />
                </div>
            </div>
        </q-card-section>
    </q-card>
</template>
<script>
import axios from 'axios';

import { QSpinnerCube } from 'quasar'
export default {
    data() {
        return {
            isPwd: true,
            opcionesPermiso: ['Ventas', 'Compras', 'Administrador'],
            opcionesAlmacen: null
        }
    },
    computed: {
        usuario() {
            return this.$store.state.usuarioInternoStore
        },
        token() {
            return this.$store.state.usuario;
        },
        validarRFC() {
            return this.usuario.rfc.length == 12 || this.usuario.rfc.length == 13 || this.usuario.rfc == ''
        },
        validarCURP() {
            return this.usuario.curp.length == 18 || this.usuario.curp == ''
        },
        validaPermiso() {
            const valida = this.usuario.permisos.some(x => x == 'Ventas')
            return valida
        }
    },
    created() {
    },
    methods: {
        async postUsuario() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'yellow', spinnerSize: 140, message: 'Guardando usuario. Espere...', messageColor: 'yellow' })
            try {
                let response = await axios.post('UsuariosInternos/PostUsuarioInterno/' + this.token.empresaBase, this.usuario)
                console.log(response)

                this.usuario._id = response.data
                this.$store.state.listaUsuariosInternosStore.push(this.usuario)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `Usuario ha sido guardado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },
        async editarUsuario() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'yellow', spinnerSize: 140, message: 'Editando usuario. Espere...', messageColor: 'yellow' })
            console.log(this.usuario)

            try {
                let response = await axios.put('UsuariosInternos/PutUsuarioInterno/' + this.token.empresaBase, this.usuario)
                console.log(response.data)

                // BUSCAMOS EL INDICE
                let indice = this.$store.state.listaUsuariosInternosStore.findIndex(x => x._id === this.usuario._id);
                Object.assign(this.$store.state.listaUsuariosInternosStore[indice], this.usuario)

                this.$q.notify({ type: 'positive', message: `Usuario ha sido editado exitosamente.` })
                this.$q.loading.hide()
                this.cierraVentana()
            } catch (error) {
                console.log(error)
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
                this.$q.loading.hide()

            }
        },

        cierraVentana() {
            this.$emit('cierraVentana')
        },
    }
}
</script>