<template>
    <div class="q-pa-md">
        <q-card class="my-card">
            <q-toolbar>
                <q-btn push color="red-10" icon="mdi-close-circle-outline" round flat size="18px" padding="xs"
                    @click="CierraDialog">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Cerrar</q-tooltip>
                </q-btn>
                <q-toolbar-title><span class="text-weight-bold">Cotización</span></q-toolbar-title>
                <!-- BOTON PARA GUARDAR -->
                <q-btn v-if="cotizacion._id == ''" push color="green-10" icon="mdi-content-save" round flat size="18px"
                    padding="xs" @click="GuardarCotizacion()">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Guardar</q-tooltip>
                </q-btn>
                <!-- BOTON PARA EDITAR -->
                <q-btn v-else push color="orange-10" icon="mdi-content-save" round flat size="18px" padding="xs"
                    @click="EditarCotizacion()">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Editar</q-tooltip>
                </q-btn>
                <!-- BOTON PARA LA VISTA PREVIA -->
                <q-btn push color="red-10" icon="mdi-file-pdf-box" round flat size="18px" padding="xs"
                    @click="VistaPrevia">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Vista Previa</q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator color="primary" inset size="4px" />
            <q-card-section class="q-pb-sm">
                <q-stepper v-model="step" header-nav ref="stepper" color="primary" animated flat>
                    <q-step :name="1" title="Datos Generales" icon="mdi-clipboard-edit-outline">
                        <General></General>
                    </q-step>
                    <q-step :name="2" title="Conceptos" icon="mdi-format-list-bulleted-type">
                        <Conceptos></Conceptos>
                    </q-step>
                </q-stepper>
            </q-card-section>
            <q-card-actions align="right" class="q-pr-xl q-pb-md">
                <q-field dense filled label="SubTotal" bg-color="orange-2" class="q-mr-md" stack-label
                    style="width: 200px;">
                    <template v-slot:control>
                        <div class="self-center full-width no-outline" tabindex="0"> {{
                        FormatCurrency(cotizacion.subTotal) }}</div>
                    </template>
                </q-field>

                <q-field dense filled label="Total" bg-color="orange-2" stack-label style="width: 200px;">
                    <template v-slot:control>
                        <div class="self-center full-width no-outline" tabindex="0"> {{ FormatCurrency(cotizacion.total)
                            }}</div>
                    </template>
                </q-field>
            </q-card-actions>
        </q-card>
    </div>
</template>
<script>
import axios from 'axios'
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar';
import moment from 'moment';
import General from './CotizacionGeneral.vue'
import Conceptos from './CotizacionConceptos.vue'

export default {
    components: {
        General, Conceptos
    },
    data() {
        return {
            step: 1,

        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        cotizacion() {
            return this.$store.state.cotizacionStore
        }
    },
    created() {
    },
    methods: {
        async GuardarCotizacion() {
            console.log(this.cotizacion)
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando cotización. Espere...', messageColor: 'white' })

            try {
                let response = await axios.post('Ventas/PostCotizacionAsync/erp_' + this.token.rfc, this.cotizacion)
                console.log(response)

                this.cotizacion._id = response.data._id
                this.cotizacion.folio = response.data.folio
                this.$store.state.listaCotizacionesStore.push(this.cotizacion)
                this.CierraDialog();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `La cotización ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        async EditarCotizacion() {
            console.log(this.cotizacion)

            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Actualizando cotización. Espere...', messageColor: 'white' })

            try {
                let response = await axios.put('Ventas/PutCotizacionAsync/erp_' + this.token.rfc, this.cotizacion)
                console.log(response)

                let indice = this.$store.state.listaCotizacionesStore.findIndex(x => x._id === this.cotizacion._id);
                Object.assign(this.$store.state.listaCotizacionesStore[indice], this.cotizacion)
                this.CierraDialog();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `La cotización ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        VistaPrevia() { },

        CierraDialog() {
            this.$emit("Close");
        },

        FormatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
    },
}
</script>
<style>
.my-card {
    width: 100%;
}
</style>