<template>
    <div>
        <q-img class="" src="https://cdn.quasar.dev/img/material.png" style="height: 150px">
            <div class="absolute-bottom bg-transparent">
                <q-avatar size="56px" class="q-mb-sm">
                    <img src="https://cdn.quasar.dev/img/boy-avatar.png">
                </q-avatar>
                <div class="text-weight-bold">{{ token.nombre }}</div>
                <div class="text-weight-bold">{{ token.rfc }}</div>
                <div class="text-weight-bold">{{ token.empresa }}</div>
            </div>
        </q-img>
        <q-list padding>
            <q-item clickable v-ripple>
                <q-item-section avatar>
                    <q-icon name="mdi-account-edit" />
                </q-item-section>

                <q-item-section>
                    Editar Perfil
                </q-item-section>
            </q-item>

            <q-separator />
            <q-item clickable v-ripple @click="irUsuariosInternos()">
                <q-item-section avatar>
                    <q-icon name="mdi-clipboard-account" />
                </q-item-section>
                <q-item-section>
                    Usuarios Internos
                </q-item-section>
            </q-item>
            <q-separator />
            <q-item clickable v-ripple @click="irConfiguracion()">
                <q-item-section avatar>
                    <q-icon name="mdi-cog" />
                </q-item-section>
                <q-item-section>
                    Configuración
                </q-item-section>
            </q-item>
            <q-separator />

            <q-item tag="label" v-ripple>
                <q-item-section>
                    <q-item-label>Modo Oscuro</q-item-label>
                </q-item-section>
                <q-item-section side>
                    <q-toggle color="blue" :value="modoOscuro" v-model="isDarkMode" val="battery" />
                </q-item-section>
            </q-item>
            <q-separator />
            <q-item clickable active v-ripple @click="salir()">
                <q-item-section>
                    Cerrar Sesión
                </q-item-section>
                <q-item-section avatar>
                    <q-icon name="mdi-logout" />
                </q-item-section>
            </q-item>
        </q-list>
    </div>
</template>
<script>
import { ref } from 'vue'
import axios from "axios";
import moment from 'moment'
import { parseISO, toDate, format } from 'date-fns'

export default {

    components: {
    },
    data() {
        return {
            isPwd: false,
            isDarkMode: false,
        }
    },

    watch: {
        isDarkMode(val) {
            this.$store.state.modoOscuro = val
            this.$q.dark.set(val);
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        modoOscuro() {
            return this.$store.state.modoOscuro;
        },

    },
    created() {
    },
    methods: {

        async salir() {
            this.$q.loading.show({ message: '<b>Cerrando Sesión...</b>' })

            let respuesta;
            try {
                respuesta = await axios.put(
                    "https://api-framework.contago.com.mx/api/Usuarios/actualizaSesion/" +
                    this.$store.state.usuario.nombre +
                    "/INACTIVA"
                );
                await this.InicializarStore();
                this.$q.loading.hide()
                this.$store.dispatch("salir");

            } catch (err) {
                console.log(err);
                this.$q.loading.hide()

            }
        },

        async InicializarStore(){
    this.$store.state.claveProdServStore= []
    this.$store.state.claveUnidadStore= []
    this.$store.state.objImpStore= []
    this.$store.state.formaPagoStore= []
    this.$store.state.monedaStore= []
    this.$store.state.metodoPagoStore= []
    this.$store.state.usoCfdiStore= []
    this.$store.state.exportacionStore= []
    this.$store.state.periodicidadStore= []
    this.$store.state.mesesStore= []
    this.$store.state.cveTransporteStore= []
    this.$store.state.tipoPermisoStore= []

    //CATALOGOS DEL SAT
    this.$store.state.regimenFiscalStore= []
    this.$store.state.regimenAduaneroStore= []
    this.$store.state.listUsoCfdiStore= []
    this.$store.state.listClienteStore= []
    this.$store.state.listTipoRelacion= []
    this.$store.state.listMotivosCancelacionStore= []

    //IMPUESTOS
    this.$store.state.trasladosStore= []
    this.$store.state.retencionesStore= []

    this.$store.state.listaConceptosStore= []

    // PUENTO DE VENTA
    this.$store.state.listVentasStore= []
   
    //COMPROBANTES
    this.$store.state.listIngresoCartaPorteStore= []
    this.$store.state.serieStore= []
    //LISTAS DE COMPROBANTES
    this.$store.state.listFacturaStore= []
    this.$store.state.listNotaCreditoStore= []
    this.$store.state.listComplementoDePagoStore= []
    this.$store.state.listIngresoComercioExterior= []

    // USUARIOS INTERNON
    this.$store.state.listaUsuariosInternosStore= []

    // CATALOGOS CARTA PORTE
    this.$store.state.listaUbicacionesStore= []
    this.$store.state.listaDomiciliosStore= []
    this.$store.state.listaMercanciasStore= []
    this.$store.state.listaFigurasTransporteStore= []
    this.$store.state.listaRemolquesStore= []
    this.$store.state.listaSegurosStore= []
    this.$store.state.listaIdentificacionVehicularStore= []
    this.$store.state.listaTipoCadPagoStore= []

    this.$store.state.listaCatalogoPais= []
    this.$store.state.listaCatalogoColonia= []
    this.$store.state.listaCatalogoLocalidad= []
    this.$store.state.listaCatalogoMunicipio= []
    this.$store.state.listaCatalogoEstado= []

    this.$store.state.listaCatalogoConfigAutotransporte= []

    this.$store.state.listaCatalogoSubTipoRem= []

    this.$store.state.listaCatalogoClaveProServCP=[]
    this.$store.state.listaCatalogoClaveUnidad= []
    this.$store.state.listaCatalogoTipoEmbalaje= []
    this.$store.state.listaCatalogoFraccionArancelaria= []
    this.$store.state.listaCatalogoTipoMateria= []
    this.$store.state.listaCatalogoDocAduanero= []
    this.$store.state.listaCatalogoCveTransporte= []
    this.$store.state.listaCatalogoMaterialPeligroso= []
    this.$store.state.listaCatalogoUnidadAduana= []
    this.$store.state.listaCatalogoTipoFigura= []
    this.$store.state.listaCatalogoPartesTransporte= []


    // COMERCIO EXTERIOR
    this.$store.state.listaEmisorCEStore= []
    this.$store.state.listaDestinatariosCEStore= []
    this.$store.state.listaPropietariosCEStore= []
    this.$store.state.listaReceptorCEStore= []
    this.$store.state.listaMercanciasCEStore= []
    this.$store.state.listClavePedimento= []
    this.$store.state.listCertificadoOrigen= []
    this.$store.state.listIncoterm= []
        },


        irConfiguracion() {
            this.$router.push('Configuracion')
        },
        irUsuariosInternos() {
            this.$router.push('UsuariosInternos')
        },
        formatDate(value) {
            let fecha_ = value.replace('T', ' ')
            let fecha_1 = fecha_.replace('Z', ' ')
            let listo = new Date(fecha_1);

            moment.locale('es-mx');
            return moment(listo).format('YYYY-MM-DD HH:mm:ss')
        },

    }
}
</script>
  
