<template>
    <q-card class="my-card">
        <q-toolbar>
            <q-toolbar-title>Resultado consulta SAT</q-toolbar-title>
        </q-toolbar>
        <q-separator color="primary" inset size="4px" />
        <q-card-section>
            <div class="row q-col-gutter-sm">
                <div class="col-12">
                    <q-input readonly dense outlined v-model="validaEstatus.rfc" label="RFC" :label-color="colorEfo">
                    </q-input>
                </div>
                <div class="col-12">
                    <q-input readonly dense outlined v-model="validaEstatus.nombre" label="Nombre"
                        :label-color="colorEfo">
                    </q-input>
                </div>
                <div class="col-12">
                    <q-input readonly dense outlined v-model="validaEstatus.folioFiscal" label="Folio fiscal"
                        :label-color="colorEfo">
                    </q-input>
                </div>
                <div class="col-12">
                    <q-input readonly dense outlined v-model="validaEstatus.efossat" label="EFO"
                        :label-color="colorEfo">
                    </q-input>
                </div>
                <div class="col-12">
                    <q-input readonly dense outlined v-model="validaEstatus.estadoSAT" label="Estatus"
                        :label-color="colorEstatus">
                    </q-input>
                </div>
                <div class="col-12">
                    <q-input readonly dense outlined v-model="validaEstatus.estatusCancelacionSAT"
                        label="Estatus Cancelación" :label-color="colorEstatus">
                    </q-input>
                </div>
            </div>
        </q-card-section>
        <q-card-actions vertical>
            <q-btn color="red" v-if="validaEstatus.estadoSAT != 'Cancelado'" v-close-popup>Cerrar</q-btn>
            <q-btn color="red" v-if="validaEstatus.estadoSAT == 'Cancelado'" @click="AplicaCancelacion()">Aplicar
                cancelación en el sistema</q-btn>
        </q-card-actions>
    </q-card>
</template>
<script>
import axios from 'axios'
import { QSpinnerCube } from 'quasar';

export default {
    components: {

    },
    data() {
        return {
            validaEstatus: {},
            colorEfo: "green",
            colorEstatus: "green",
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        itemStore() {
            return this.$store.state.validaEstatusStore;
        },

    },
    created() {
        this.ValidaEstatusSat();
    },
    methods: {
        async ValidaEstatusSat() {
            let item = { ...this.itemStore }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Validando estatus en plataforma del SAT...' })
            try {
                let response = await axios.get("Comprobantes/GetEstatusSatAsync/" + item.rfcEmisor + "/" + item.rfcReceptor + "/" + item.total + "/" + item.folioFiscal);
                console.log(response.data);
                this.validaEstatus = response.data;
                if (response.data.efossat === "EL RFC NO ESTA DENTRO DE LA LISTA DE 69B") {
                    this.colorEfo = "green";
                } else {
                    this.colorEfo = "red";
                }
                if (response.data.estadoSAT === "Cancelado") {
                    this.colorEstatus = "red";
                } else {
                    this.colorEstatus = "green";
                }
                this.validaEstatus.rfc = item.rfc;
                this.validaEstatus.nombre = item.nombre;
                this.validaEstatus.folioFiscal = item.folioFiscal;
                this.validaEstatus.tipoComprobante = item.tipoComprobante;
            } catch (error) {
                console.log(error)
            }
            this.$q.loading.hide()
        },

        async AplicaCancelacion() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Aplicando cancelacion...' })
            let collection = "";
            let tipoC = this.itemStore.tipoComprobante;
            let tipoInterno = this.itemStore.tipoComprobanteInterno;
            let comprobante = this.itemStore.comprobante;
            console.log(comprobante);

            try {
                //EVALUAMOS PARA SABER DONDE SE VA A ACTUALIZAR
                if (tipoC === "I") {
                    collection = "comprobantes_emitidos";
                }
                if (tipoC === "E") {
                    collection = "comprobantes_emitidos";
                }
                if (tipoC === "P") {
                    collection = "comprobantes_pagos";
                }
                if (tipoC === "N") {
                    collection = "comprobantes_nomina";
                }
                let response = await axios.put("ComprobanteS/PutCanceladosAsync/erp_" + this.token.rfc + "/" + collection + "/" + this.validaEstatus.folioFiscal);
                console.log(response.data);
                if (tipoInterno === "FACTURA") {
                    comprobante.estatus = "Cancelado";
                    this.$store.commit('putFacturaStore', comprobante);
                    this.$emit("CloseDialogValida");
                }
            } catch (error) {
                console.log(error);
            }
            this.$q.loading.hide()

        },

    },
}
</script>