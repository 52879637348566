<template>
    <div>
        <div class="row q-col-gutter-sm">
            <!-- SERIE -->
            <div class="col-12 col-md-4">
                <q-select label="Serie" outlined v-model="comprobante.serie" use-input input-debounce="0"
                    @new-value="createSerie" :options="optionsSerie" @filter="filterSerie" dense>
                    <template v-slot:no-option>
                        <q-item>
                            <q-item-section class="text-grey">
                                Sin resultados
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>
            </div>
            <!-- FOLIO -->
            <div class="col-12 col-md-4">
                <q-input outlined v-model="comprobante.folio" label="Folio" dense />
            </div>
            <!-- FECHA -->
            <div class="col-12 col-md-4">
                <datetime v-model="comprobante.fecha" type="datetime" :phrases="{ ok: 'Ok', cancel: 'Cancelar' }"
                    :format="{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit' }"
                    value-zone="America/Mexico_City" class="custom-datetime"></datetime>
            </div>
            <!-- CLIENTE -->
            <div class="col-12">
                <q-select v-model="comprobante.receptor" use-input input-debounce="0" label="Cliente"
                    :options="optionsClientes" @filter="filterCliente" fill-input hide-selected :loading="loadingClientes"
                    dense outlined option-label="receptor">
                    <template v-slot:no-option>
                        <q-item>
                            <q-item-section class="text-grey">
                                No exixte algún cliente que coincida con los criterios
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>
            </div>
            <!-- USO DE CFDI -->
            <div class="col-12">
                <q-select v-model="comprobante.receptor.usoCfdi" label="Uso de CFDI" :options="optionsUsoCfdi" dense
                    outlined option-label="usoCfdi">
                </q-select>
            </div>
            <!-- PARA EL CASO DE PUBLICO EN GENERAL -->
            <template v-if="comprobante.receptor != null">
                <template v-if="comprobante.receptor.rfc === 'XAXX010101000'">
                    <div class="col-12 col-md-4">
                        <q-select v-model="comprobante.informacionGlobal.periodicidad" label="Periodicidad"
                            :options="optionsPeriodicidad" dense outlined option-label="periodicidad">
                        </q-select>
                    </div>
                    <div class="col-12 col-md-4">
                        <q-select v-model="comprobante.informacionGlobal.meses" label="Meses" :options="optionsMeses" dense
                            outlined option-label="meses">
                        </q-select>
                    </div>
                    <div class="col-12 col-md-4">
                        <q-input v-model="comprobante.informacionGlobal.año" label="Año" dense outlined>
                        </q-input>
                    </div>
                </template>
            </template>
            <!-- METODO DE PAGO -->
            <div class="col-12 col-md-6">
                <q-select v-model="comprobante.metodoPago" label="Metodo de Pago" :options="optionsMetodoPago" dense
                    outlined option-label="metodoPago">
                </q-select>
            </div>
            <!-- FORMA DE PAGO -->
            <div class="col-12 col-md-6">
                <q-select v-model="comprobante.formaPago" label="Forma de Pago" :options="optionsFormaPago" dense outlined
                    option-label="formaPago">
                </q-select>
            </div>
            <!-- CONDICIONES DE PAGO -->
            <div class="col-12">
                <q-input outlined v-model="comprobante.condicionesDePago" label="Condiciones de Pago" dense />
            </div>
            <!-- MONEDA -->
            <div class="col-12 col-md-6">
                <q-select v-model="comprobante.moneda" use-input input-debounce="0" label="Moneda" :options="optionsMoneda"
                    @filter="filterMoneda" fill-input hide-selected :loading="loadingMoneda" dense outlined
                    option-label="moneda">
                    <template v-slot:no-option>
                        <q-item>
                            <q-item-section class="text-grey">
                                No exixte algúna moneda que coincida con los criterios
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>
            </div>
            <!-- TIPO DE CAMBIO -->
            <div class="col-12 col-md-6">
                <q-input outlined v-model="comprobante.tipoCambio" label="Tipo de Cambio" dense />
            </div>
            <!-- EXPORTACION -->
            <div class="col-12">
                <q-select v-model="comprobante.exportacion" label="Exportación" :options="optionsExportacion" dense outlined
                    option-label="exportacion">
                </q-select>
            </div>
            <!-- NOTAS -->
            <div class="col-6">
                <q-input outlined v-model="comprobante.notas" label="Notas" dense />
            </div>
            <!-- ORDEN DE COMPRA -->
            <div class="col-6">
                <q-input outlined v-model="comprobante.oc" label="Orden de Compra" dense />
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { parse } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { es } from 'date-fns/locale';
import { QSpinnerCube } from 'quasar';
import moment from 'moment';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';

export default {
    components: {
        Datetime
    },
    data() {
        return {
            optionsSerie: [],

            //CLIENTES
            loadingClientes: false,
            optionsClientes: [],

            // MONEDA
            optionsMoneda: [],
            loadingMoneda: false,

            model: null,
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        comprobante() {
            return this.$store.state.comprobanteStore.comprobante;
        },

        fehaIMasked() {
            moment.locale('es-mx');
            return this.fechaI ? moment(this.fechaI).format('DD/MMMM/yyyy') : ''
        },

        fechaFMasked() {
            moment.locale('es-mx');
            return this.fechaF ? moment(this.fechaF).format('DD/MMMM/yyyy') : ''
        },

        optionsClientesDefault() {
            return this.$store.state.listClienteStore
        },

        optionsUsoCfdi() {
            return this.$store.state.usoCfdiStore;
        },

        optionsMetodoPago() {
            return this.$store.state.metodoPagoStore;
        },

        optionsFormaPago() {
            return this.$store.state.formaPagoStore;
        },

        optionsExportacion() {
            return this.$store.state.exportacionStore;
        },

        optionsPeriodicidad() {
            return this.$store.state.periodicidadStore;
        },

        optionsMeses() {
            return this.$store.state.mesesStore;
        },

        optionsMonedaDefault() {
            return this.$store.state.monedaStore;
        },

        optionsSerieDefault() {
            return this.$store.state.serieStore;
        },

    },
    created() {

    },
    methods: {

        // DATOS Y FILTROS DE LOS COMPRONENTES GENERALES
        createSerie(val, done) {
            let stringOptions = this.optionsSerieDefault;
            if (val.length > 0) {
                if (!stringOptions.includes(val)) {
                    stringOptions.push(val)
                }
                done(val, 'toggle')
            }
        },

        setModelSerie(val) {
            this.comprobante.serie = val
        },

        filterSerie(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.options = this.optionsSerieDefault.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            })
        },

        filterCliente(val, update) {
            let stringOptions = this.optionsClientesDefault
            if (val === '') {
                update(() => {
                    this.optionsClientes = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsClientes = stringOptions.filter(v => v.receptor.toLowerCase().indexOf(needle) > -1)
            })
        },

        filterMoneda(val, update) {
            let stringOptions = this.optionsMonedaDefault
            if (val === '') {
                update(() => {
                    this.optionsMoneda = stringOptions
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.optionsMoneda = stringOptions.filter(v => v.moneda.toLowerCase().indexOf(needle) > -1)
            })
        },

    },
}
</script>