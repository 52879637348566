<template>
    <q-card>
        <q-card-section>
            <q-toolbar>
                <q-toolbar-title>
                    Ubicación
                </q-toolbar-title>
                <q-btn v-if="objetoStore._id == ''" dense round color="green" icon="mdi-content-save" class="q-mr-sm"
                    @click="guardar()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>

                <q-btn v-else round color="orange" dense icon="mdi-content-save" class="q-mr-sm" @click="actualizar()">
                    <q-tooltip>
                        Editar
                    </q-tooltip>
                </q-btn>
                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
            <q-card-section style="max-height: 700px;" class="scroll">
                <div class="row q-col-gutter-sm">
                    <div class="col-12">
                        <q-select v-model="objetoStore.tipoUbicacion" emit-value map-options :options="['OR', 'DE']"
                            use-input input-debounce="0" dense filled label="Tipo de ubicación"
                            virtual-scroll-slice-size="1" />
                    </div>
                    <div class="col-12 ">
                        <q-input dense filled label="ID de Ubicación" @blur="validaIdUbicacion"
                            v-model="objetoStore.idUbicacion" />
                    </div>
                    <div class="col-12">
                        <q-input dense filled label="RFC Remitente o Destinatario"
                            v-model="objetoStore.rfcRemitenteDestinatario" maxlength="13"
                            @input="convertirAMayusculasRFC" />
                    </div>
                    <div class="col-12 ">
                        <q-input dense filled label="Nombre Remitente o destinatario"
                            v-model="objetoStore.nombreRemitenteDestinatario" @input="convertirAMayusculas" />
                    </div>
                    <div v-if="objetoStore.rfcRemitenteDestinatario == 'XEXX010101000'" class="col-12 ">
                        <q-input dense filled label="Número de identificación o registro fiscal"
                            v-model="objetoStore.numRegIdTrib" />
                    </div>
                    <div v-if="objetoStore.rfcRemitenteDestinatario == 'XEXX010101000'" class="col-12">
                        <q-select v-model="objetoStore.residenciaFiscal" emit-value map-options @filter="filtroPais"
                            :options="itemsFiltroPais" option-label="pais" use-input input-debounce="0" dense filled
                            label="País de residencia fiscal" virtual-scroll-slice-size="1" />
                    </div>
                    <div class="col-12">
                        <domicilio class="full-width" ref="miComponenteHijo"></domicilio>
                    </div>
                </div>
            </q-card-section>
        </q-card-section>
    </q-card>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import domicilio from '../../CatalogosCartaPorte/Domicilios/Domicilio.vue'

export default {
    components: {
        domicilio
    },

    data() {
        return {
            itemsFiltroPais: null,
            itemsFiltroDomicilio: null,
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        objetoStore() {
            return this.$store.state.ubicacionStore;
        },
        itemsPais() {
            return this.$store.state.listaCatalogoPais;
        },

        itemsDomicilio() {
            return this.$store.state.listaDomiciliosStore
        }
    },

    created() {
    },
    methods: {
        cierraVentana() {
            this.$emit('cierraVentana')
        },

        filtroPais(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroPais = this.itemsPais.filter(v => v.pais.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroPais.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        filtroDomicilio(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroDomicilio = this.itemsDomicilio.filter(v => v.domicilio.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroDomicilio.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        async guardar() {
            //VALIDAMOS Y ASIGNAMOS EL DOMICILIO
            const childResult = await this.$refs.miComponenteHijo.AsignaValores();
            if (!childResult) {
                return;
            }

            if (this.objetoStore.tipoUbicacion == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el tipo de ubicación' })
                return;
            }

            if (this.objetoStore.idUbicacion == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el ID de ubicación' })
                return;
            }

            if (this.objetoStore.rfcRemitenteDestinatario == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el RFC del remitente o destinatario' })
                return;
            }

            if (this.objetoStore.rfcRemitenteDestinatario == ' XEXX010101000') {
                if (this.objetoStore.numRegIdTrib == '') {
                    this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el número de identificación o registro fiscal' })
                    return;
                }
                if (this.objetoStore.residenciaFiscal == null) {
                    this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el país de residencia fiscal' })
                    return;
                }
            }

            if (this.objetoStore.rfcRemitenteDestinatario.length < 12) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'El RFC que proporciono no esta completo' })
                return;
            }

            if (this.objetoStore.nombreRemitenteDestinatario == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre del remitente o destinatario' })
                return;
            }

            // ASIGNAMOS A UN OBJETO
            let objUbicacion = { ...this.$store.state.ubicacionStore }
            let objDomicilio = { ...childResult }
            objUbicacion.domicilio = objDomicilio;

            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando ubicación. Espere...', messageColor: 'white' })
                let response = await axios.post('CatalogosComprobantes/PostUbicacion/erp_' + this.token.rfc, objUbicacion)

                objUbicacion._id = response.data
                this.$store.state.listaUbicacionesStore.push(objUbicacion)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `La ubicación ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        async actualizar() {
            //VALIDAMOS Y ASIGNAMOS EL DOMICILIO
            const childResult = await this.$refs.miComponenteHijo.AsignaValores();
            if (!childResult) {
                return;
            }

            if (this.objetoStore.tipoUbicacion == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el tipo de ubicación' })
                return;
            }

            if (this.objetoStore.idUbicacion == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el ID de ubicación' })
                return;
            }

            if (this.objetoStore.rfcRemitenteDestinatario == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el RFC del remitente o destinatario' })
                return;
            }

            if (this.objetoStore.rfcRemitenteDestinatario == ' XEXX010101000') {
                if (this.objetoStore.numRegIdTrib == '') {
                    this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el número de identificación o registro fiscal' })
                    return;
                }
                if (this.objetoStore.residenciaFiscal == null) {
                    this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el país de residencia fiscal' })
                    return;
                }
            }

            if (this.objetoStore.rfcRemitenteDestinatario.length < 12) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'El RFC que proporciono no esta completo' })
                return;
            }

            if (this.objetoStore.nombreRemitenteDestinatario == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre del remitente o destinatario' })
                return;
            }

            // ASIGNAMOS A UN OBJETO
            let objUbicacion = { ...this.$store.state.receptorCEStore }
            let objDomicilio = { ...childResult }
            objUbicacion.domicilio = objDomicilio;

            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Actualizando ubicación. Espere...', messageColor: 'white' })
                let response = await axios.put('CatalogosComprobantes/PutUbicacion/erp_' + this.token.rfc, objUbicacion)
                console.log(response)

                let indice = this.$store.state.listaUbicacionesStore.findIndex(x => x._id === objUbicacion._id);
                Object.assign(this.$store.state.listaUbicacionesStore[indice], objUbicacion)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `La ubicación ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        validaIdUbicacion() {
            if (this.objetoStore.idUbicacion) {
                try {
                    let variable = this.objetoStore.idUbicacion
                    let contador = this.objetoStore.idUbicacion.length
                    for (var i = 0; i < 6 - contador; i++) {
                        variable = '0' + variable
                    }
                    this.objetoStore.idUbicacion = variable
                } catch (err) {
                    console.log('Error: ' + err)
                }
            }
        },

        convertirAMayusculasRFC() {
            this.objetoStore.rfcRemitenteDestinatario = this.objetoStore.rfcRemitenteDestinatario.replace(/\s/g, '').toUpperCase();
        },

        convertirAMayusculas() {
            this.objetoStore.nombreRemitenteDestinatario = this.objetoStore.nombreRemitenteDestinatario.toUpperCase();
        }
    }
}
</script>
<style>
.my-card {
    width: 100%;
}
</style>