<template>
    <q-card style="width: 800px; max-width: 80vw;">
        <q-card-section>
            <q-toolbar>
                <q-toolbar-title>
                    Identidicación Vehicular
                </q-toolbar-title>
                <q-btn v-if="objetoStore._id == ''" dense round color="green" icon="mdi-content-save" class="q-mr-sm"
                    @click="guardar()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>

                <q-btn v-else round color="orange" dense icon="mdi-content-save" class="q-mr-sm" @click="actualizar()">
                    <q-tooltip>
                        Editar
                    </q-tooltip>
                </q-btn>

                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
            <q-card-section class="scroll">
                <div class="row q-col-gutter-sm">
                    <div class="col-12">
                        <q-select v-model="objetoStore.configVehicular" emit-value map-options
                            @filter="filtroConfigVehicular" :options="itemsFiltroConfigVehicular"
                            option-label="configAutotransporte" use-input input-debounce="0" dense filled
                            label="Configuración vehicular" virtual-scroll-slice-size="1" />
                    </div>
                    <div class="col-12 col-md-4">
                        <q-field dense filled label="Peso bruto" v-model="objetoStore.pesoBrutoVehicular">
                            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
                                <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue"
                                    v-bind="moneyFormat" v-show="floatingLabel" />
                            </template>
                        </q-field>
                    </div>

                    <div class="col-12 col-md-4">
                        <q-input dense filled label="Placa" v-model="objetoStore.placaVM" />
                    </div>
                    <div class="col-12 col-md-4">
                        <q-input type="number" dense filled label="Año modelo" v-model="objetoStore.anioModeloVM" />
                    </div>
                </div>
            </q-card-section>
        </q-card-section>
    </q-card>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import { Money } from 'v-money'

export default {
    components: {
        Money
    },
    data() {
        return {
            itemsFiltroConfigVehicular: null,
            moneyFormat: {
                decimal: '.',
                thousands: ',',
                precision: 2,
                masked: false,
                suffix: ' kg'
            },
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        objetoStore() {
            return this.$store.state.identificacionStore;
        },
        itemsConfigVehicular() {
            return this.$store.state.listaCatalogoConfigAutotransporte;
        },
    },

    created() {
    },
    methods: {
        filtroConfigVehicular(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroConfigVehicular = this.itemsConfigVehicular.filter(v => v.configAutotransporte.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroConfigVehicular.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },
        cierraVentana() {
            this.$emit('cierraVentana')
        },
        async guardar() {

            console.log(this.objetoStore)

            if (this.objetoStore.configVehicular == null) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la configuracion vehicular' })
                return;
            }

            if (this.objetoStore.placaVM == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la placa vehiculo' })
                return;
            }
            if (this.objetoStore.pesoBrutoVehicular == 0) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el peso bruto vehicular' })
                return;
            }
            if (this.objetoStore.anioModeloVM == 0) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el año de modelo del vehiculo' })
                return;
            }


            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando vehiculo. Espere...', messageColor: 'white' })

            try {
                let response = await axios.post('CatalogosComprobantes/PostIdentVehicular/erp_' + this.token.rfc, this.objetoStore)
                console.log(response)

                this.objetoStore._id = response.data
                this.$store.state.listaIdentificacionVehicularStore.push(this.objetoStore)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `El vehiculo ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        async actualizar() {
            console.log(this.objetoStore)
            if (this.objetoStore.placaVM == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la placa vehiculo' })
                return;
            }
            if (this.objetoStore.pesoBrutoVehicular == 0) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el peso bruto vehicular' })
                return;
            }
            if (this.objetoStore.anioModeloVM == 0) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el año de modelo del vehiculo' })
                return;
            }

            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Actualizando vehiculo. Espere...', messageColor: 'white' })

            try {
                let response = await axios.put('CatalogosComprobantes/PutIdentVehicular/erp_' + this.token.rfc, this.objetoStore)
                console.log(response)

                let indice = this.$store.state.listaIdentificacionVehicularStore.findIndex(x => x._id === this.objetoStore._id);
                Object.assign(this.$store.state.listaIdentificacionVehicularStore[indice], this.objetoStore)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `El vehiculo ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },
    }
}
</script>
<style></style>