<template>
  <div class="q-ml-xl q-mr-lg">
    <div class="text-center q-my-xl">
      <img class="logo-home" alt="Contago logo" src="../assets/logo_contago_sin_fondo.png">
    </div>

    <div class="row	flex-center q-pr-xl q-pl-xl q-pt-xl">
      <div class="col-12 col-md-2 col-sm-6 text-center q-pb-xl">
        <span class="q-pa-sm q-py-lg  shadow-6 mb-3 inline-block surface-card"
          style="background: #FF931E; border-radius: 10px; cursor: pointer; width: 160px;" @click="irComprobantes()">
          <q-icon name="mdi-file-sign" style="color: aliceblue;font-size: 4em;" />
          <div class="text-h6  q-mt-sm text-weight-bolder" style="color: aliceblue;">Comprobantes</div>
        </span>
      </div>

      <div class="col-12 col-md-2 col-sm-6 text-center q-pb-xl">
        <span class="q-pa-lg  shadow-6 mb-3 inline-block surface-card"
          style="background: #FF931E; border-radius: 10px; cursor: pointer;  width: 160px;" @click="irVentas()">
          <q-icon name="mdi-file-document-plus" style="color: aliceblue;font-size: 4em;" />
          <div class="text-h6  q-mt-sm text-weight-bolder" style="color: aliceblue;">Ventas</div>
        </span>
      </div>

      <div class="col-12 col-md-2 col-sm-6 text-center q-pb-xl">
        <span class="q-pa-lg  shadow-6 mb-3 inline-block surface-card"
          style="background: #FF931E; border-radius: 10px; cursor: pointer;  width: 160px;" @click="irCompras()">
          <q-icon name="mdi-file-document-minus" style="color: aliceblue;font-size: 4em;" />
          <div class="text-h6  q-mt-sm text-weight-bolder" style="color: aliceblue;">Compras</div>
        </span>
      </div>

      <div class="col-12 col-md-2 col-sm-6 text-center q-pb-xl">
        <span class="q-pa-lg  shadow-6 mb-3 inline-block surface-card"
          style="background: #FF931E; border-radius: 10px; cursor: pointer;  width: 160px;">
          <q-icon name="mdi-package-variant-closed" style="color: aliceblue;font-size: 4em;" />
          <div class="text-h6  q-mt-sm text-weight-bolder" style="color: aliceblue;">Inventarios</div>
        </span>
      </div>

      <div class="col-12 col-md-2 col-sm-6 text-center q-pb-xl">
        <span class="q-pa-lg  shadow-6 mb-3 inline-block surface-card"
          style="background: #FF931E; border-radius: 10px; cursor: pointer;  width: 160px;" @click="irTienda()">
          <q-icon name="mdi-cash-register" style="color: aliceblue;font-size: 4em;" />
          <div class="text-h6  q-mt-sm text-weight-bolder" style="color: aliceblue;">Tienda</div>
        </span>
      </div>

      <div class="col-12 col-md-2 col-sm-6 text-center q-pb-xl">
        <span class="q-pa-lg  shadow-6 mb-3 inline-block surface-card"
          style="background: #FF931E; border-radius: 10px; cursor: pointer;  width: 160px;">
          <q-icon name="mdi-bank" style="color: aliceblue;font-size: 4em;" />
          <div class="text-h6  q-mt-sm text-weight-bolder" style="color: aliceblue;">Bancos</div>
        </span>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuOptions: [
        { name: 'Opción 1', icon: 'mdi-home' },
        { name: 'Opción 2', icon: 'mdi-account' },
        { name: 'Opción 3', icon: 'mdi-settings' }
      ]
    };
  },
  methods: {
    irTienda() {
      this.$router.push({ name: "PuntoVenta" })
    },

    irComprobantes() {
      this.$router.push({ name: 'Comprobantes' });
    },

    irVentas() {
      this.$router.push({ name: 'ListaCotizacion' });
    },

    irCompras() {
      this.$router.push({ name: 'ListaPedidosC' })
    },
  }
};
</script>

<style>
.logo-inicio {
  background-image: url('../assets/logo_contago_sin_fondo.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
}

.logo-home {
  max-width: 30%;
  height: auto;
}

.main-menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
}

.menu-option {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>