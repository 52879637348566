<template>
    <div>
        <!-- DIALOGO DE CONFIRMACION -->
        <q-dialog v-model="dialogConfirm" persistent>
            <q-card style="width: 600px;">
                <q-card-section class="row items-center">
                    <q-avatar icon="mdi-delete-alert" color="primary" text-color="white" />
                    <span class="text-h5 q-ml-md">Estas seguro de eliminar el proveedor.?</span>
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat label="Aceptar" color="primary" @click="eliminarProveedor()" />
                    <q-btn flat label="Cerrar" color="primary" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <q-dialog v-model="dialogNuevoProveedor" persistent transition-show="scale" transition-hide="scale">
            <Proveedor @Close="closeDialog()"></Proveedor>
        </q-dialog>

        <q-table title="Proveedor" :data="itemsProveedor" :loading="loadingTabla" :columns="columns" row-key="_id">
            <template v-slot:top-right>
                <q-btn round color="green" icon="mdi-plus" class="q-mr-sm" @click="nuevoProveedor" />
                <q-btn round color="blue" icon="mdi-magnify" @click="getProveedor()" />
            </template>
            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>

            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td key="acciones" auto-width>
                        <q-btn size="md" color="blue" rounded flat dense @click="editarProveedor(props.row)"
                            icon="mdi-pencil">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Editar</q-tooltip>
                        </q-btn>
                        <q-btn size="md" color="red" rounded flat dense @click="eliminar(props.row)" icon="mdi-delete">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Eliminar</q-tooltip>
                        </q-btn>
                    </q-td>
                    <q-td key="rfc" :props="props">{{ props.row.rfc }}</q-td>
                    <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                    <q-td key="nombreComercial" :props="props">{{ props.row.nombreComercial }}</q-td>
                    <q-td key="telefono" :props="props">{{ props.row.telefono }}</q-td>
                    <q-td key="correo" :props="props">{{ props.row.correo }}</q-td>
                    <q-td key="direccion" :props="props">{{ props.row.direccion }}</q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>
<script>
import axios from 'axios';
import Proveedor from './Proveedor.vue'
export default {
    components: { Proveedor },
    data() {
        return {
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'rfc', align: 'center', label: 'RFC', field: 'rfc', sortable: true, classes: 'bg-grey-2 ellipsis', headerClasses: 'bg-primary text-white' },
                { name: 'nombre', align: 'center', label: 'Nombre', field: 'nombre', sortable: true, classes: 'bg-grey-2 ellipsis', headerClasses: 'bg-primary text-white' },
                { name: 'nombreComercial', align: 'center', label: 'Nombre Comercial', field: 'nombreComercial', sortable: true },
                { name: 'telefono', align: 'center', label: 'Teléfono', field: 'telefono', sortable: true },
                { name: 'correo', align: 'center', label: 'Correo', field: 'correo', sortable: true },
                { name: 'direccion', align: 'center', label: 'Dirección', field: 'direccion', sortable: true },
            ],
            loadingTabla: false,
            dialogNuevoProveedor: false,
            dialogConfirm: false
        }
    },
    computed: {
        itemsProveedor() {
            return this.$store.state.listaProveedoresStore;
        },
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.getProveedor();
    },
    methods: {
        nuevoProveedor() {
            this.iniciaizarObjeto();
            this.dialogNuevoProveedor = true
        },
        closeDialog() {
            this.dialogNuevoProveedor = false
        },
        async getProveedor() {
            this.loading = true
            try {
                let response = await axios.get("Compras/GetProveedorAsync/erp_" + this.token.rfc);
                let catalogo = response.data;
                this.$store.state.listaProveedoresStore = catalogo;
                this.loading = false

            } catch (error) {
                this.loading = false
                console.log(error);
            }
        },
        eliminar(item) {
            this.dialogConfirm = true
            this.$store.state.proveedorStore = item
        },
        async eliminarProveedor(value) {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Eliminando proveedor. Espere...', messageColor: 'white' })
            let proveedor = this.$store.state.proveedorStore
            try {
                let response = await axios.put('Ventas/DeleteProveedorAsync/erp_' + this.token.rfc + '/' + proveedor._id)
                console.log(response)
                // SI LA ACCION ES ELIMINAR, LO QUITAMOS DE LA LISTA
                let indice = this.itemsProveedor.indexOf(proveedor)
                this.itemsProveedor.splice(indice, 1)
                this.dialogConfirm = false
                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `El proveedor ha sido eliminado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error, intentelo mas tarde.', color: 'red' })
            }
        },
        iniciaizarObjeto() {
            let objeto = {
                _id: '',
                rfc: '',
                nombre: '',
                nombreComercial: '',
                telefono: '',
                correo: '',
                direccion: '',
                estatus: 'Vigente',
                usuarioCreador: this.token.nombre,
                usuarioModificador: '',
            }
            this.$store.state.proveedorStore = { ...objeto }
        },

        editarProveedor(data) {
            this.$store.state.proveedorStore = { ...data }
            this.dialogNuevoProveedor = true
        },

        FormatDate(value) {
            let fecha = new Date(value);
            let dia = fecha.getDate();
            let mes = fecha.toLocaleString('es-ES', { month: 'long' }); // Obtener el mes en formato de letra
            let anio = fecha.getFullYear();
            let formatoDeseado = `${dia}-${mes}-${anio}`;
            return formatoDeseado;
        },

        FormatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
    }
}
</script>