<template>
    <div>
        <!-- DIALOGO DE CONFIRMACION -->
        <q-dialog v-model="dialogConfirm" persistent>
            <q-card style="width: 600px;">
                <q-card-section class="row items-center">
                    <q-avatar icon="mdi-delete-alert" color="primary" text-color="white" />
                    <span class="text-h5 q-ml-md">Estas seguro de eliminar el proyecto.?</span>
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat label="Aceptar" color="primary" @click="eliminarProyecto()" />
                    <q-btn flat label="Cerrar" color="primary" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <q-dialog v-model="dialogNuevoProyecto" persistent transition-show="scale" transition-hide="scale">
            <Proyecto @Close="closeDialog()"></Proyecto>
        </q-dialog>

        <q-table title="Proyectos" :data="itemsProyectos" :loading="loadingTabla" :columns="columns" row-key="_id">
            <template v-slot:top-right>
                <q-btn round color="green" icon="mdi-plus" class="q-mr-sm" @click="nuevoProyecto" />
                <q-btn round color="blue" icon="mdi-magnify" @click="getProyectos()" />
            </template>
            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>

            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td key="acciones" auto-width>
                        <q-btn size="md" color="blue" rounded flat dense @click="editarProyecto(props.row)"
                            icon="mdi-pencil">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Editar</q-tooltip>
                        </q-btn>
                        <q-btn size="md" color="red" rounded flat dense @click="eliminar(props.row)" icon="mdi-delete">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px">Eliminar</q-tooltip>
                        </q-btn>
                    </q-td>
                    <q-td key="clave" :props="props">{{ props.row.clave }}</q-td>
                    <q-td key="descripcion" :props="props">{{ props.row.descripcion }}</q-td>
                    <q-td key="rfc" :props="props">{{ props.row.receptor.rfc }}</q-td>
                    <q-td key="nombre" :props="props">{{ props.row.receptor.nombre }}</q-td>
                    <q-td key="presupuesto" :props="props">{{ FormatCurrency(props.row.presupuesto) }}</q-td>
                    <q-td key="asignado" :props="props">{{ FormatCurrency(props.row.asignado) }}</q-td>
                    <q-td key="fechaEntrega" :props="props">{{ FormatDate(props.row.fechaEntrega) }}</q-td>
                </q-tr>
            </template>
        </q-table>
    </div>
</template>
<script>
import axios from 'axios';
import Proyecto from './Proyecto.vue'
export default {
    components: { Proyecto },
    data() {
        return {
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', sortable: true },
                { name: 'clave', align: 'center', label: 'Clave', field: 'clave', sortable: true, classes: 'bg-grey-2 ellipsis', headerClasses: 'bg-primary text-white' },
                { name: 'descripcion', align: 'center', label: 'Descripción', field: 'descripcion', sortable: true, classes: 'bg-grey-2 ellipsis', headerClasses: 'bg-primary text-white' },
                { name: 'rfc', align: 'center', label: 'RFC', field: 'rfc', sortable: true },
                { name: 'nombre', align: 'center', label: 'Nombre', field: 'nombre', sortable: true },
                { name: 'presupuesto', align: 'center', label: 'Presupuesto', field: 'presupuesto', sortable: true },
                { name: 'asignado', align: 'center', label: 'Asignado', field: 'asignado', sortable: true },
                { name: 'fechaEntrega', align: 'center', label: 'Fecha de Entrega', field: 'fechaEntrega', sortable: true },
            ],
            loadingTabla: false,
            dialogNuevoProyecto: false,
            dialogConfirm: false
        }
    },
    computed: {
        itemsProyectos() {
            return this.$store.state.listaProyectosStore;
        },
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.getProyectos();
    },
    methods: {
        nuevoProyecto() {
            this.iniciaizarObjeto();
            this.dialogNuevoProyecto = true
        },
        closeDialog() {
            this.dialogNuevoProyecto = false
        },
        async getProyectos() {
            this.loading = true
            try {
                let response = await axios.get("Ventas/GetProyectoAsync/erp_" + this.token.rfc);
                let catalogo = response.data;
                this.$store.state.listaProyectosStore = catalogo;
                this.loading = false

            } catch (error) {
                this.loading = false
                console.log(error);
            }
        },
        eliminar(item) {
            this.dialogConfirm = true
            this.$store.state.proyectoStore = item
        },
        async eliminarProyecto(value) {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Eliminando proyecto. Espere...', messageColor: 'white' })
            let proyecto = this.$store.state.proyectoStore
            try {
                let response = await axios.put('Ventas/DeleteProyectosAsync/erp_' + this.token.rfc + '/' + proyecto._id)
                console.log(response)
                // SI LA ACCION ES ELIMINAR, LO QUITAMOS DE LA LISTA
                let indice = this.itemsProyectos.indexOf(proyecto)
                this.itemsProyectos.splice(indice, 1)
                this.dialogConfirm = false
                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `El proyecto ha sido eliminado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error, intentelo mas tarde.', color: 'red' })
            }
        },
        iniciaizarObjeto() {
            let objeto = {
                _id: '',
                clave: '',
                descripcion: '',
                receptor: { receptor: '', usoCfdi: null },
                presupuesto: 0,
                asignado: 0,
                fechaEntrega: new Date().toISOString(),
                estatus: 'Vigente',
                usuarioCreador: this.token.nombre,
                usuarioModificador: '',
            }
            this.$store.state.proyectoStore = { ...objeto }
        },

        editarProyecto(data) {
            this.$store.state.proyectoStore = { ...data }
            let receptor = this.$store.state.listClienteStore.find(f => f.nombre === data.receptor.nombre);
            this.$store.state.proyectoStore.receptor = receptor
            this.dialogNuevoProyecto = true
        },

        FormatDate(value) {
            let fecha = new Date(value);
            let dia = fecha.getDate();
            let mes = fecha.toLocaleString('es-ES', { month: 'long' }); // Obtener el mes en formato de letra
            let anio = fecha.getFullYear();
            let formatoDeseado = `${dia}-${mes}-${anio}`;
            return formatoDeseado;
        },

        FormatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
    }
}
</script>