<template>
  <div class="q-pa-md q-gutter-sm">
    <!-- DIALOG PARA MOSTRAR EL PDF -->
    <q-dialog v-model="dialogPdf" maximized transition-show="slide-up" transition-hide="slide-down">
      <visor-pdf @closeDialog="CierraDialogPdf" @closeDialogTimbrado="CierraDialogPdfTimbrado"></visor-pdf>
    </q-dialog>

    <q-card class="my-card">
      <q-toolbar>
        <q-btn push color="red-10" icon="mdi-close-circle-outline" round flat size="18px" padding="xs"
          @click="CierraDialog">
          <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
            :offset="[10, 10]">Cerrar</q-tooltip>
        </q-btn>
        <!-- id:{{ item.comprobante._id }} -->

        <q-toolbar-title><span class="text-weight-bold">{{
      item.titulo
    }}</span></q-toolbar-title>
        <!-- BOTON PARA GUARDAR -->
        <q-btn push color="green-10" icon="mdi-content-save" round flat size="18px" padding="xs" @click="GuardaOEdita">
          <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
            :offset="[10, 10]">Guardar</q-tooltip>
        </q-btn>
        <!-- BOTON PARA TIMBRAR -->
        <q-btn push color="green-14" icon="mdi-check-circle-outline" round flat size="18px" padding="xs"
          @click="Timbrar">
          <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
            :offset="[10, 10]">Timbrar</q-tooltip>
        </q-btn>
        <!-- BOTON PARA LA VISTA PREVIA -->
        <q-btn push color="red-10" icon="mdi-file-pdf-box" round flat size="18px" padding="xs" @click="VistaPrevia">
          <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
            :offset="[10, 10]">Vista Previa</q-tooltip>
        </q-btn>
      </q-toolbar>
      <q-separator color="primary" inset size="4px" />
      <q-card-section>
        <q-stepper v-model="step" header-nav ref="stepper" color="primary" animated flat>
          <q-step :name="1" title="Datos Generales" icon="mdi-clipboard-edit-outline">
            <general></general>
          </q-step>
          <q-step :name="2" title="Conceptos" icon="mdi-format-list-bulleted-type">
            <conceptos></conceptos>
          </q-step>
          <q-step :name="3" title="CFDI´s Relacionados" icon="mdi-bookmark" caption="Opcional">
            <relacionados></relacionados>
          </q-step>
          <q-step :name="4" title="Complemento Carta Porte" icon="mdi-truck">
            <carta-porte></carta-porte>
          </q-step>
        </q-stepper>
      </q-card-section>
      <q-card-actions vertical align="right">
        <div class="text-h4">
          SubTotal: {{ FormatCurrency(item.comprobante.subTotal) }}
        </div>
        <div class="text-h4" v-if="item.comprobante.descuento != 0">
          SubTotal: {{ FormatCurrency(item.comprobante.descuento) }}
        </div>
        <div class="text-h4">
          Total: {{ FormatCurrency(item.comprobante.total) }}
        </div>
      </q-card-actions>
    </q-card>
  </div>
</template>
<script>
import axios from "axios";
import * as XLSX from "xlsx";
import { format } from "date-fns";
import { parse } from "date-fns";
import { endOfMonth } from "date-fns";
import { es } from "date-fns/locale";
import { QSpinnerCube } from "quasar";
import moment from "moment";

import General from "../../Comprobantes/ComprobanteGeneral/ComprobanteGeneral.vue";
import Conceptos from "../../Comprobantes/ComprobanteGeneral/ComprobanteConceptos.vue";
import CartaPorte from "./ComplementoCartaPorte.vue";
import Relacionados from '../../Comprobantes/ComprobanteGeneral/ComprobanteRelacionados.vue'

import VisorPdf from "../../PDF/VisorPdf.vue";

export default {
  components: {
    General,
    Conceptos,
    CartaPorte,
    VisorPdf,
    Relacionados,
  },
  data() {
    return {
      step: 1,
      general: false,
      detalles: false,
      relacionados: false,

      dialogPdf: false,
    };
  },
  computed: {
    token() {
      return this.$store.state.usuario;
    },

    item() {
      return this.$store.state.comprobanteStore;
    },

    fehaIMasked() {
      moment.locale("es-mx");
      return this.fechaI ? moment(this.fechaI).format("DD/MMMM/yyyy") : "";
    },

    fechaFMasked() {
      moment.locale("es-mx");
      return this.fechaF ? moment(this.fechaF).format("DD/MMMM/yyyy") : "";
    },
  },
  created() {
    this.GetCatalogos();
  },
  methods: {
    //CATALOGOS DEL SAT
    async GetCatalogos() {
      this.$q.loading.show({
        spinner: QSpinnerCube,
        spinnerColor: "red-8",
        spinnerSize: 140,
        message: "Consultando empresa...",
      });
      await this.GetEmpresa();
      this.$q.loading.show({
        spinner: QSpinnerCube,
        spinnerColor: "red-8",
        spinnerSize: 140,
        message: "Consultando series...",
      });
      await this.GetSerie();
      this.$q.loading.show({
        spinner: QSpinnerCube,
        spinnerColor: "red-8",
        spinnerSize: 140,
        message: "Consultando clientes / receptores...",
      });
      await this.GetReceptor();
      this.$q.loading.show({
        spinner: QSpinnerCube,
        spinnerColor: "red-8",
        spinnerSize: 140,
        message: "Consultando usos del CFDI...",
      });
      await this.GetUsoCfdi();
      this.$q.loading.show({
        spinner: QSpinnerCube,
        spinnerColor: "red-8",
        spinnerSize: 140,
        message: "Consultando métodos de pago...",
      });
      await this.GetMetodoPago();
      this.$q.loading.show({
        spinner: QSpinnerCube,
        spinnerColor: "red-8",
        spinnerSize: 140,
        message: "Consultando formas de pago...",
      });
      await this.GetFormaPago();
      this.$q.loading.show({
        spinner: QSpinnerCube,
        spinnerColor: "red-8",
        spinnerSize: 140,
        message: "Consultando monedas...",
      });
      await this.GetMoneda();
      this.$q.loading.show({
        spinner: QSpinnerCube,
        spinnerColor: "red-8",
        spinnerSize: 140,
        message: "Consultando claves del producto o servicio(SAT)...",
      });
      await this.GetClaveProdServ();
      this.$q.loading.show({
        spinner: QSpinnerCube,
        spinnerColor: "red-8",
        spinnerSize: 140,
        message: "Consultando unidades de medida(SAT)...",
      });
      await this.GetClaveUnidad();
      this.$q.loading.show({
        spinner: QSpinnerCube,
        spinnerColor: "red-8",
        spinnerSize: 140,
        message: "Consultando obetos de impuestos...",
      });
      await this.GetObjImp();
      this.$q.loading.show({
        spinner: QSpinnerCube,
        spinnerColor: "red-8",
        spinnerSize: 140,
        message: "Consultando impuestos...",
      });
      await this.GetImpuestos();
      this.$q.loading.show({
        spinner: QSpinnerCube,
        spinnerColor: "red-8",
        spinnerSize: 140,
        message: "Consultando claves de exportacion...",
      });
      await this.GetExportacion();
      this.$q.loading.show({
        spinner: QSpinnerCube,
        spinnerColor: "red-8",
        spinnerSize: 140,
        message: "Consultando peridicidad...",
      });
      await this.GetPeriodicidad();
      this.$q.loading.show({
        spinner: QSpinnerCube,
        spinnerColor: "red-8",
        spinnerSize: 140,
        message: "Consultando meses...",
      });
      await this.GetMeses();
      this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando tipo de relación...' });
      await this.GetTipoRelacion();
      this.$q.loading.show({
        spinner: QSpinnerCube,
        spinnerColor: "red-8",
        spinnerSize: 140,
        message: "Consultando conceptos...",
      });
      await this.GetConceptos();
      this.$q.loading.hide();
    },

    async GetConceptos() {
      this.loadingTabla = true;
      try {
        let response = await axios.get(
          "CatalogosComprobantes/GetConceptosAsync/" + this.token.empresaBase
        );
        this.$store.state.listaConceptosStore = response.data;
        this.loadingTabla = false;
      } catch (error) {
        console.log(error);
        this.loadingTabla = false;
      }
    },

    async GetEmpresa() {
      try {
        let response = await axios.get(
          "Empresa/GetEmpresaAsync/" + this.token.empresaBase
        );
        let x = response.data;
        this.$store.state.empresaStore = { ...x };
      } catch (error) {
        console.log(error);
      }
    },

    async GetSerie() {
      try {
        let response = await axios.get(
          "Comprobantes/GetSerieAsync/" +
          this.token.empresaBase +
          "/CARTA PORTE"
        );
        let x = response.data;
        this.$store.state.serieStore = [...x];
      } catch (error) {
        console.log(error);
      }
    },

    async GetReceptor() {
      try {
        let response = await axios.get(
          "CatalogosComprobantes/GetReceptorAsync/" + this.token.empresaBase
        );
        let x = response.data;
        this.$store.state.listClienteStore = [...x];
      } catch (error) {
        console.log(error);
      }
    },

    async GetUsoCfdi() {
      if (this.$store.state.usoCfdiStore.length != 0) {
        return;
      } else {
        try {
          let response = await axios.get("CatalogosSat/GetCatUsoCfdiAsync");
          let x = response.data;
          this.$store.state.usoCfdiStore = [...x];
        } catch (error) {
          console.log(error);
        }
      }
    },

    async GetMetodoPago() {
      if (this.$store.state.metodoPagoStore.length != 0) {
        return;
      } else {
        try {
          let response = await axios.get("CatalogosSat/GetCatMetodoPagoAsync");
          let x = response.data;
          this.$store.state.metodoPagoStore = [...x];
        } catch (error) {
          console.log(error);
        }
      }
    },

    async GetFormaPago() {
      if (this.$store.state.formaPagoStore.length != 0) {
        return;
      } else {
        try {
          let response = await axios.get("CatalogosSat/GetCatFormaPagoAsync");
          let x = response.data;
          this.$store.state.formaPagoStore = [...x];
        } catch (error) {
          console.log(error);
        }
      }
    },

    async GetMoneda() {
      if (this.$store.state.monedaStore.length != 0) {
        return;
      } else {
        try {
          let response = await axios.get("CatalogosSat/GetCatMonedaAsync");
          let x = response.data;
          this.$store.state.monedaStore = [...x];
        } catch (error) {
          console.log(error);
        }
      }
    },

    async GetClaveProdServ() {
      if (this.$store.state.claveProdServStore.length != 0) {
        return;
      } else {
        try {
          let response = await axios.get(
            "CatalogosSat/GetCatClaveProdServAsync"
          );
          let x = response.data;
          this.$store.state.claveProdServStore = [...x];
        } catch (error) {
          console.log(error);
        }
      }
    },

    async GetClaveUnidad() {
      if (this.$store.state.claveUnidadStore.length != 0) {
        return;
      } else {
        try {
          let response = await axios.get("CatalogosSat/GetCatClaveUnidadAsync");
          let x = response.data;
          this.$store.state.claveUnidadStore = [...x];
        } catch (error) {
          console.log(error);
        }
      }
    },

    async GetObjImp() {
      if (this.$store.state.objImpStore.length != 0) {
        return;
      } else {
        try {
          let response = await axios.get("CatalogosSat/GetCatObjImpAsync");
          let x = response.data;
          this.$store.state.objImpStore = [...x];
        } catch (error) {
          console.log(error);
        }
      }
    },

    async GetImpuestos() {
      if (this.$store.state.trasladosStore.length != 0) {
        return;
      } else {
        try {
          let response = await axios.get(
            "CatalogosSat/GetCatImpuestosSatAsync"
          );
          let x = response.data;
          this.$store.state.trasladosStore = x.filter(
            (f) => f.tipo === "Traslado"
          );
          this.$store.state.retencionesStore = x.filter(
            (f) => f.tipo === "Retencion"
          );
          // this.$store.state.objImpStore = [...x]
        } catch (error) {
          console.log(error);
        }
      }
    },

    async GetExportacion() {
      if (this.$store.state.exportacionStore.length == 0) {
        try {
          let response = await axios.get("CatalogosSat/GetCatExportacionAsync");
          let x = response.data;
          this.$store.state.exportacionStore = [...x];
        } catch (error) {
          console.log(error);
        }
      }
    },

    async GetPeriodicidad() {
      if (this.$store.state.periodicidadStore.length == 0) {
        try {
          let response = await axios.get(
            "CatalogosSat/GetCatPeriodicidadAsync"
          );
          let x = response.data;
          this.$store.state.periodicidadStore = [...x];
        } catch (error) {
          console.log(error);
        }
      }
    },

    async GetMeses() {
      if (this.$store.state.mesesStore.length == 0) {
        try {
          let response = await axios.get("CatalogosSat/GetCatMesesAsync");
          let x = response.data;
          this.$store.state.mesesStore = [...x];
        } catch (error) {
          console.log(error);
        }
      }
    },

    async GetTipoRelacion() {
      if (this.$store.state.listTipoRelacion.length == 0) {
        try {
          let response = await axios.get('CatalogosSat/GetCatTipoRelacionAsync')
          let x = response.data;
          this.$store.state.listTipoRelacion = [...x]
        } catch (error) {
          console.log(error)
        }
      }
    },

    async Timbrar() {
      //VALIDAMOS
      let comprobante = { ...this.item.comprobante };
      if (!comprobante.notas) {
        comprobante.notas = "";
      }
      if (!comprobante.oc) {
        comprobante.oc = "";
      }
      let empresa = { ...this.$store.state.empresaStore };

      if (!(await this.valida())) {
        return;
      }

      this.$q.loading.show({
        spinner: QSpinnerCube,
        spinnerColor: "red-8",
        spinnerSize: 140,
        message: "Timbrando, espere...",
      });
      //ASIGNAMOS LOS DATOS DEL EMISOR Y EL LUGAR DE EXPEDICION
      comprobante.lugarExpedicion = empresa.domicilioFiscal;
      comprobante.emisor = {
        rfc: empresa.rfc,
        nombre: empresa.nombre,
        regimenFiscal: { ...empresa.regimenFiscal },
        facAtrAdquirente: "",
      };

      try {
        let response = await axios.post(
          "Comprobantes/PostTimbraComprobanteAsync/" + this.token.empresaBase,
          comprobante
        );
        console.log(response.data);
        this.$q.loading.hide();
        this.$q.notify({
          type: "positive",
          message: `Comprobante timbrado exitosamente.`,
          actions: [
            {
              label: "Cerrar",
              color: "white",
              handler: () => {
                /* ... */
              },
            },
          ],
        });

        //ACTUALIZAMOS LA LISTA
        let id = this.item.comprobante._id;
        if (id === "") {
          this.$store.commit("postIngresoCartaPorteStore", response.data);
        } else {
          this.$store.commit("putIngresoCartaPorteStore", response.data);
        }

        console.log("1")
        //ACTUALIZAMOS LOS TIMBRES DISPONIBLES
        await this.GetTimbresDisponibles();
        console.log("2")

        //MOSTRAMOS EL PDF
        this.$store.state.vistaPreviaStore = {
          comprobante: null,
          color: empresa.color,
          logo: "",
          rfc: empresa.rfc,
          folioFiscal: response.data.folioFiscal,
          tipoComprobanteInterno: "CARTA PORTE",
        };
        this.dialogPdf = true;
      } catch (error) {
        console.log(error)
        this.$q.loading.hide();
        this.$q.notify({
          type: "negative",
          message: "Error al timbrar: " + error.response.data,
          actions: [
            {
              label: "Cerrar",
              color: "white",
              handler: () => {
                /* ... */
              },
            },
          ],
        });
      }
    },

    FormatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },

    async VistaPrevia() {
      let comprobante = { ...this.item.comprobante };
      if (!(await this.valida())) {
        return;
      }

      let empresa = { ...this.$store.state.empresaStore };
      if (!comprobante.notas) {
        comprobante.notas = "";
      }
      if (!comprobante.oc) {
        comprobante.oc = "";
      }

      let color = empresa.color;
      //ASIGNAMOS LOS DATOS DEL EMISOR Y EL LUGAR DE EXPEDICION
      comprobante.lugarExpedicion = empresa.domicilioFiscal;
      comprobante.emisor = {
        rfc: empresa.rfc,
        nombre: empresa.nombre,
        regimenFiscal: { ...empresa.regimenFiscal },
        facAtrAdquirente: "",
      };
      //MOSTRAMOS EL DIALOG CON EL PDF
      this.$store.state.vistaPreviaStore = {
        comprobante: comprobante,
        color: color,
        logo: "",
        tipoComprobanteInterno: "CARTA PORTE",
      };
      this.dialogPdf = true;
    },

    CierraDialog() {
      this.$emit("CloseComprobante");
    },

    CierraDialogPdf() {
      this.dialogPdf = false;
    },

    CierraDialogPdfTimbrado() {
      this.$emit("CloseComprobante");
      this.dialogPdf = false;
    },

    async GuardaOEdita() {
      let id = this.item.comprobante._id;
      if (id === "") {
        await this.Guardar();
      } else {
        await this.Editar();
      }
    },

    async Guardar() {
      if (!(await this.valida())) {
        return;
      }

      // VALIDAMOS
      let comprobante = { ...this.item.comprobante };
      if (!comprobante.notas) {
        comprobante.notas = "";
      }
      if (!comprobante.oc) {
        comprobante.oc = "";
      }
      let empresa = { ...this.$store.state.empresaStore };

      this.$q.loading.show({
        spinner: QSpinnerCube,
        spinnerColor: "red-8",
        spinnerSize: 140,
        message: "Timbrando, espere...",
      });
      //ASIGNAMOS LOS DATOS DEL EMISOR Y EL LUGAR DE EXPEDICION
      comprobante.lugarExpedicion = empresa.domicilioFiscal;
      comprobante.emisor = {
        rfc: empresa.rfc,
        nombre: empresa.nombre,
        regimenFiscal: { ...empresa.regimenFiscal },
        facAtrAdquirente: "",
      };
      comprobante.estatus = "Sin timbrar";

      try {
        let response = await axios.post(
          "Comprobantes/PostComprobanteAsync/" + this.token.empresaBase,
          comprobante
        );
        console.log(response.data);
        this.$q.loading.hide();
        this.$q.notify({
          type: "positive",
          message: `Comprobante guardado exitosamente.`,
          actions: [
            {
              label: "Cerrar",
              color: "white",
              handler: () => {
                /* ... */
              },
            },
          ],
        });

        let fecha_ = comprobante.fecha.split(".")[0];
        // console.log(comprobante.subTotal)
        // console.log(comprobante.total)
        // let subtotal_ = parseFloat(comprobante.subTotal.replace(/[^0-9.-]+/g, ""));
        // let total_ = parseFloat(comprobante.total.replace(/[^0-9.-]+/g, ""));
        let guardado = {
          _id: response.data,
          serie: comprobante.serie,
          folio: comprobante.folio,
          fecha: fecha_,
          rfc: comprobante.receptor.rfc,
          nombre: comprobante.receptor.nombre,
          subTotal: comprobante.subTotal,
          total: comprobante.total,
          moneda: comprobante.moneda.moneda,
          folioFiscal: "",
          metodoPago: comprobante.metodoPago.metodoPago,
          formaPago: comprobante.formaPago.formaPago,
          solicitudCancelacion: "",
          oc: comprobante.oc,
          error: "",
          estatus: "Sin timbrar",
        };
        console.log(guardado);
        // ACTUALIZAMOS LA LISTA
        this.$store.commit("postIngresoCartaPorteStore", guardado);
        this.$emit("CloseComprobante");
      } catch (error) {
        console.log("Error", error);
        this.$q.loading.hide();
        this.$q.notify({
          type: "negative",
          message: "Error al guardar: " + error.response.data,
          actions: [
            {
              label: "Cerrar",
              color: "white",
              handler: () => {
                /* ... */
              },
            },
          ],
        });
      }
    },

    async Editar() {
      if (!(await this.valida())) {
        return;
      }
      // VALIDAMOS
      let comprobante = { ...this.item.comprobante };
      if (!comprobante.notas) {
        comprobante.notas = "";
      }
      if (!comprobante.oc) {
        comprobante.oc = "";
      }

      let empresa = { ...this.$store.state.empresaStore };

      this.$q.loading.show({
        spinner: QSpinnerCube,
        spinnerColor: "red-8",
        spinnerSize: 140,
        message: "Timbrando, espere...",
      });
      //ASIGNAMOS LOS DATOS DEL EMISOR Y EL LUGAR DE EXPEDICION
      comprobante.lugarExpedicion = empresa.domicilioFiscal;
      comprobante.emisor = {
        rfc: empresa.rfc,
        nombre: empresa.nombre,
        regimenFiscal: { ...empresa.regimenFiscal },
        facAtrAdquirente: "",
      };
      comprobante.estatus = "Sin timbrar";

      try {
        let response = await axios.put(
          "Comprobantes/PutComprobanteAsync/" + this.token.empresaBase,
          comprobante
        );
        console.log(response.data);
        this.$q.loading.hide();
        this.$q.notify({
          type: "positive",
          message: `Comprobante guardado exitosamente.`,
          actions: [
            {
              label: "Cerrar",
              color: "white",
              handler: () => {
                /* ... */
              },
            },
          ],
        });

        let fecha_ = comprobante.fecha.split(".")[0];
        let guardado = {
          _id: comprobante._id,
          serie: comprobante.serie,
          folio: comprobante.folio,
          fecha: fecha_,
          rfc: comprobante.receptor.rfc,
          nombre: comprobante.receptor.nombre,
          subTotal: comprobante.subTotal,
          total: comprobante.total,
          moneda: comprobante.moneda.moneda,
          folioFiscal: "",
          metodoPago: comprobante.metodoPago.metodoPago,
          formaPago: comprobante.formaPago.formaPago,
          solicitudCancelacion: "",
          oc: comprobante.oc,
          error: "",
          estatus: "Sin timbrar",
        };
        // ACTUALIZAMOS LA LISTA
        this.$store.commit("putIngresoCartaPorteStore", guardado);
        this.$emit("CloseComprobante");
      } catch (error) {
        console.log("Error", error);
        this.$q.loading.hide();
        this.$q.notify({
          type: "negative",
          message: "Error al guardar: " + error.response.data,
          actions: [
            {
              label: "Cerrar",
              color: "white",
              handler: () => {
                /* ... */
              },
            },
          ],
        });
      }
    },

    async valida() {
      let comprobante = { ...this.item.comprobante };
      try {
        if (!comprobante.receptor || comprobante.receptor.receptor === "") {
          this.$q.notify({
            type: "warning",
            message: `Indique el cliente / receptor en el apartado de datos generales`,
            actions: [
              {
                label: "Cerrar",
                color: "white",
                handler: () => {
                  /* ... */
                },
              },
            ],
          });
          return false;
        }

        if (!comprobante.metodoPago) {
          this.$q.notify({
            type: "warning",
            message: `Indique el método de pago en el apartado de datos generales`,
            actions: [
              {
                label: "Cerrar",
                color: "white",
                handler: () => {
                  /* ... */
                },
              },
            ],
          });
          return;
        }

        if (!comprobante.formaPago) {
          this.$q.notify({
            type: "warning",
            message: `Indique la forma de pago en el apartado de datos generales`,
            actions: [
              {
                label: "Cerrar",
                color: "white",
                handler: () => {
                  /* ... */
                },
              },
            ],
          });
          return false;
        }

        if (comprobante.conceptos.length == 0) {
          this.$q.notify({
            type: "warning",
            message: `Agregue al menos un concepto, en el apartado de conceptos`,
            actions: [
              {
                label: "Cerrar",
                color: "white",
                handler: () => {
                  /* ... */
                },
              },
            ],
          });
          return false;
        }

        if (comprobante.cartaPorte.ubicaciones.length == 0) {
          this.$q.notify({
            type: "warning",
            message: `Agregue un origen y al menos un destino en el apartado complemento carta porte / ubicaciones`,
            actions: [
              {
                label: "Cerrar",
                color: "white",
                handler: () => {
                  /* ... */
                },
              },
            ],
          });
          return false;
        }

        if (comprobante.cartaPorte.ubicaciones.length == 1) {
          this.$q.notify({
            type: "warning",
            message: `Agregue al menos un destino en el apartado complemento carta porte / ubicaciones`,
            actions: [
              {
                label: "Cerrar",
                color: "white",
                handler: () => {
                  /* ... */
                },
              },
            ],
          });
          return false;
        }

        if (!comprobante.cartaPorte.mercancias.autotransporte.permSCT) {
          this.$q.notify({
            type: "warning",
            message: `Indique el permiso de la SCT en el apartado complemento carta porte / permiso SCT`,
            actions: [
              {
                label: "Cerrar",
                color: "white",
                handler: () => {
                  /* ... */
                },
              },
            ],
          });
          return false;
        }

        if (
          comprobante.cartaPorte.mercancias.autotransporte.numPermisoSCT === ""
        ) {
          this.$q.notify({
            type: "warning",
            message: `Indique el permiso de la SCT en el apartado complemento carta porte / permiso SCT`,
            actions: [
              {
                label: "Cerrar",
                color: "white",
                handler: () => {
                  /* ... */
                },
              },
            ],
          });
          return false;
        }

        if (
          !comprobante.cartaPorte.mercancias.autotransporte
            .identificadorVehicular.configVehicular
        ) {
          this.$q.notify({
            type: "warning",
            message: `Indique el identificador vehicular en el apartado complemento carta porte / identificador vehicular`,
            actions: [
              {
                label: "Cerrar",
                color: "white",
                handler: () => {
                  /* ... */
                },
              },
            ],
          });
          return false;
        }

        if (!comprobante.cartaPorte.mercancias.autotransporte.seguros) {
          this.$q.notify({
            type: "warning",
            message: `Indique el seguro vehicular en el apartado complemento carta porte / seguros`,
            actions: [
              {
                label: "Cerrar",
                color: "white",
                handler: () => {
                  /* ... */
                },
              },
            ],
          });
          return false;
        }

        if (comprobante.cartaPorte.figuraTransporte.length == 0) {
          this.$q.notify({
            type: "warning",
            message: `Agregue una figura del transporte en el apartado complemento carta porte / figuras del transporte`,
            actions: [
              {
                label: "Cerrar",
                color: "white",
                handler: () => {
                  /* ... */
                },
              },
            ],
          });
          return false;
        }
      } catch (error) {
        console.log(error);
      }
      return true;
    },

    async GetTimbresDisponibles() {
      try {
        let opciones = {
          useGrouping: true, // Activar separador de miles
          maximumFractionDigits: 0 // No mostrar decimales
        };
        let response = await axios.get("Comprobantes/GetTimbresDisponiblesAsync/" + this.token.rfc);
        let x = response.data;
        this.$store.state.timbresDisponiblesStore = x.toLocaleString("en-US", opciones);
      } catch (error) {
        console.log(error)
      }
    },
  },
};
</script>
<style>
.my-card {
  width: 100%;
}
</style>
