<template>
    <q-page class=" q-pa-lg ">
        <q-dialog v-model="dialogNuevo" persistent transition-show="scale" transition-hide="scale">
            <Domicilio @cierraVentana="dialogNuevo = false"></Domicilio>
        </q-dialog>

        <!-- DIALOGO DE CONFIRMACION -->
        <q-dialog v-model="dialogConfirm" persistent>
            <q-card style="width: 600px;">
                <q-card-section class="row items-center">
                    <q-avatar icon="mdi-delete-alert" color="primary" text-color="white" />
                    <span class="text-h5 q-ml-md">Estas seguro de eliminar el domicilio.?</span>
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat label="Aceptar" color="primary" @click="deleteItem()" />
                    <q-btn flat label="Cerrar" color="primary" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <div class="row	flex-left q-mb-md">
            <div class="col-12 col-md-6 col-sm-6 text-left ">
            </div>
            <div class="col-12 col-md-6 col-sm-6 text-right ">
                <q-btn round color="blue" class="q-mr-sm" icon="mdi-update" @click="GetItems()" />
                <q-btn round color="green" icon="mdi-plus" @click="nuevoElemento" />
            </div>
        </div>
        <div>
            <q-table class="shadow-0" title="Domicilios" :data="items" :columns="columns" row-key="_id" :filter="filter"
                :loading="loading" :pagination="pagination">
                <template v-slot:loading>
                    <q-inner-loading showing color="primary" />
                </template>
                <template v-slot:top-right>
                    <q-input filled borderless dense debounce="300" v-model="filter" placeholder="Buscar">
                        <template v-slot:append>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                </template>
                <template v-slot:body="props">
                    <q-tr :props="props">
                        <q-td auto-width key="acciones">
                            <q-btn size="md" color="red" rounded flat dense icon="mdi-delete"
                                @click="eliminar(props.row)">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Eliminar</q-tooltip>
                            </q-btn>
                            <q-btn size="md" color="blue" rounded flat dense icon="mdi-pencil"
                                @click="editar(props.row)">
                                <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                    content-style="font-size: 14px">Editar</q-tooltip>
                            </q-btn>
                        </q-td>
                        <q-td key="pais" :props="props">{{ props.row.pais.pais }}</q-td>
                        <q-td key="estado" :props="props">{{ props.row.estado.estado }}</q-td>
                        <q-td key="codigoPostal" :props="props">{{ props.row.codigoPostal }}</q-td>
                        <q-td key="municipio" :props="props">{{ props.row.municipio.municipio }}</q-td>
                        <!-- <q-td key="localidad" :props="props">{{ props.row.localidad.localidad }}</q-td> -->
                        <q-td key="colonia" :props="props">{{ props.row.colonia.colonia }}</q-td>
                        <q-td key="calle" :props="props">{{ props.row.calle }}</q-td>
                        <q-td key="numeroExterior" :props="props">{{ props.row.numeroExterior }}</q-td>
                        <q-td key="numeroInterior" :props="props">{{ props.row.numeroInterior }}</q-td>
                        <q-td key="referencia" :props="props">{{ props.row.referencia }}</q-td>
                    </q-tr>
                </template>
            </q-table>
        </div>
    </q-page>
</template>
<script>

import moment from 'moment';
import { QSpinnerCube } from 'quasar'
import axios from 'axios'
import Domicilio from './Domicilio.vue'
export default {
    components: { Domicilio },
    data() {
        return {
            filter: '',
            loading: false,
            columns: [
                { name: 'acciones', align: 'center', label: 'Acciones', field: 'acciones', },
                { name: 'pais', align: 'center', label: 'País', field: 'pais', sortable: true },
                { name: 'estado', align: 'center', label: 'Estado', field: 'estado', sortable: true },
                { name: 'codigoPostal', align: 'center', label: 'Código Postal', field: 'codigoPostal', sortable: true },
                { name: 'municipio', align: 'center', label: 'Municipio', field: 'municipio', sortable: true },
                // { name: 'localidad', align: 'center', label: 'Localidad', field: 'localidad', sortable: true },
                { name: 'colonia', align: 'center', label: 'Colonia', field: 'colonia', sortable: true },
                { name: 'calle', align: 'center', label: 'Calles', field: 'calle', sortable: true },
                { name: 'numeroExterior', align: 'center', label: 'Número exterior', field: 'numeroExterior', sortable: true },
                { name: 'numeroInterior', align: 'center', label: 'Número interior', field: 'numeroInterior', sortable: true },
                { name: 'referencia', align: 'center', label: 'Referencia', field: 'referencia', sortable: true },

            ],
            data: [],
            dialogNuevo: false,
            pagination: {
                sortBy: 'nombre',
                descending: false,
                page: 1,
                rowsPerPage: 10
                // rowsNumber: xx if getting data from a server
            },
            dialogConfirm: false
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        items() {
            return this.$store.state.listaDomiciliosStore
        }
    },
    created() {
        this.getCatalogoPais();
        this.GetCatEstado();
        this.GetItems();
    },
    methods: {
        nuevoElemento() {
            let domicilio = {
                _id: '',
                calle: '',
                numeroExterior: '',
                numeroInterior: '',
                colonia: null,
                localidad: null,
                referencia: '',
                municipio: null,
                estado: null,
                pais: {
                    clave: "MEX",
                    descripcion: "México",
                    pais: "MEX | México"
                },
                codigoPostal: '',
            }
            this.$store.state.domicilioStore = domicilio
            this.dialogNuevo = true
        },
        async getCatalogoPais() {
            if (this.$store.state.listaCatalogoPais.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatPaisAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoPais = catalogo;

            } catch (error) {
                console.log(error);
            }
        },

        async GetCatEstado() {
            if (this.$store.state.listaCatalogoEstado.length != 0) {
                return
            }
            try {
                let response = await axios.get("CatalogosSat/GetCatEstadoAsync/");
                let catalogo = response.data;
                this.$store.state.listaCatalogoEstado = catalogo;

            } catch (error) {
                console.log(error);
            }
        },

        async GetItems() {
            this.loading = true
            try {
                let response = await axios.get("CatalogosComprobantes/GetDomicilios/erp_" + this.token.rfc);
                let catalogo = response.data;
                this.$store.state.listaDomiciliosStore = catalogo;
                this.loading = false

            } catch (error) {
                this.loading = false
                console.log(error);
            }
        },
        eliminar(item) {
            this.dialogConfirm = true
            this.$store.state.domicilioStore = item
        },
        async deleteItem() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Eliminando domicilio. Espere...', messageColor: 'white' })
            let domicilio = this.$store.state.domicilioStore

            try {
                let response = await axios.put('CatalogosComprobantes/deleteDomicilio/erp_' + this.token.rfc + '/' + domicilio._id)
                console.log(response)

                // SI LA ACCION ES ELIMINAR, LO QUITAMOS DE LA LISTA
                let indice = this.items.indexOf(domicilio)
                this.items.splice(indice, 1)


                this.dialogConfirm = false
                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', position: 'top-right', message: `El domicilio ha sido eliminado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', position: 'top-right', message: 'Error, intentelo mas tarde.', color: 'red' })
            }
        },
        editar(data) {
            console.log(data)
            this.$store.state.domicilioStore = { ...data }
            this.dialogNuevo = true
        },
    }
}
</script>

<style></style>
<style lang="sass">
</style>